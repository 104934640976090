import Config from '../Config';
import {RestService} from './RestService';
import { format_without_time } from '../Utils';
import moment from 'moment';

export const FeedbackService = {
    fetchFeedbacksCount: async function(startdate = null, enddate = null, headers = {}){
        return RestService.get(`${Config.fetch_feedbacks_url}${startdate ? `&startdate=${moment(startdate).format(format_without_time)}`: ''}${enddate ? `&enddate=${moment(enddate).format(format_without_time)}`: ''}&count=true`, headers)
                .then(response => response.json());
    },
    fetchFeedbacks: async function(startdate = null, enddate = null, sort, direction, from, offset, headers = {}){
        return RestService.get(`${Config.fetch_feedbacks_url}${startdate ? `&startdate=${moment(startdate).format(format_without_time)}`: ''}${enddate ? `&enddate=${moment(enddate).format(format_without_time)}`: ''}${sort ? `&sort=${sort}`: ''}${direction ? `&direction=${direction}`: ''}${from ? `&from=${from}`: ''}${offset ? `&offset=${offset}`: ''}`, headers)
                .then(response => response.json());
    },
    fetchLatestFeedbacks: async function(count = 5, headers = {}){
        return RestService.get(`${Config.fetch_latest_feedbacks_url}${count ? `count=${count}`: ''}`, headers)
                .then(response => response.json());
    },
};