import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import DoneIcon from '@material-ui/icons/Done';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';
import { Chip } from '@material-ui/core';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      fontSize: 13,
      textAlign: 'right',
      zIndex: 10,
    },
    button: {
      fontSize: 13,
    //   width: '100%',
      textAlign: 'left',
    //   paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      color: '#586069',
      fontWeight: 600,
      '&:hover,&:focus': {
        color: '#0366d6',
      },
      '& span': {
        width: '100%',
      },
      '& svg': {
        width: 25,
        height: 25,
      },
    },
    tag: {
      margin: 3,
      fontSize: 8,
    },
    popper: {
      border: '1px solid rgba(27,31,35,.15)',
      boxShadow: '0 3px 12px rgba(27,31,35,.15)',
      borderRadius: 3,
      width: 300,
      zIndex: 1,
      fontSize: 13,
      color: '#586069',
      backgroundColor: '#f6f8fa',
    },
    header: {
      borderBottom: '1px solid #e1e4e8',
      padding: '8px 10px',
      fontWeight: 600,
    },
    inputBase: {
      padding: 10,
      width: '100%',
      borderBottom: '1px solid #dfe2e5',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    paper: {
      boxShadow: 'none',
      margin: 0,
      color: '#586069',
      fontSize: 13,
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: 'relative',
    },
    iconSelected: {
      width: 17,
      height: 17,
      marginRight: 5,
      marginLeft: -2,
    },
    color: {
      width: 14,
      height: 14,
      flexShrink: 0,
      borderRadius: 3,
      marginRight: 8,
      marginTop: 2,
    },
    text: {
      flexGrow: 1,
    },
    close: {
      opacity: 0.6,
      width: 18,
      height: 18,
    },
  }));
  
  export function AddTag(props){
  
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [value, setValue] = React.useState([]);
    const [pendingValue, setPendingValue] = React.useState([]);
    // const theme = useTheme();
    // const [masterTags, setMasterTags] =React.useState([]);

    const masterTags = props.masterTags;
    
    // React.useEffect(() => {
    //     fetchTags();
    // }, [true]);

    React.useEffect(() => {
        if(masterTags.length > 0){
            setValue(props.tags.map(v => masterTags.filter(d => d.tag === v)[0]));
        }
    }, [masterTags, props.tags]);

    // const fetchTags = () => {
    //     MasterService.getTags()
    //     .then(data => {
    //         setMasterTags(data);
    //     })
    //     .catch(error => console.log(error));
    // }
  
    const handleClick = (event) => {
      setPendingValue(value);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'toggleInput') {
        return;
      }
      setValue(pendingValue);
      if (anchorEl) {
        anchorEl.focus();
      }
      setAnchorEl(null);
      
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;
  
    return (
      <React.Fragment>
        <div className={classes.root}>
          {value.map((tag) => (
            <Chip className={classes.tag} key={tag.label} size="small" label={tag.label} color="primary" onDelete={(e) => props.deleteTag(props.asin, tag.tag)}/>
          ))}
          <ButtonBase
            disableRipple
            className={classes.button}
            aria-describedby={id}
            onClick={handleClick}
          >
            {/* <AddCircleIcon /> */}
            {/* <span>Add Tag</span> */}
            <AddBoxRoundedIcon color="inherit"/>
          </ButtonBase>
        </div>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          className={classes.popper}
        >
          <div className={classes.header}>Add Tags to ASIN : {props.asin}</div>
          <Autocomplete
            open
            onClose={handleClose}
            multiple
            classes={{
              paper: classes.paper,
              option: classes.option,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            value={pendingValue}
            onChange={(event, newValue) => {
                let n = newValue[newValue.length - 1];
                if(n.new){
                    delete n.new;
                }
                props.addTag(props.asin, n.tag);
                setPendingValue(newValue);
                handleClose();
            }}
            disableCloseOnSelect
            disablePortal
            renderTags={() => null}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== '') {
                    filtered.push({
                        tag: params.inputValue,
                        label: `Add "${params.inputValue}"`,
                        new: true
                    });
                }

                return filtered;
            }}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <DoneIcon
                  className={classes.iconSelected}
                  style={{ visibility: selected ? 'visible' : 'hidden' }}
                />
                {/* <span className={classes.color} style={{ backgroundColor: option.color }} /> */}
                <div className={classes.text}>
                  {option.label}
                </div>
                {/* <CloseIcon
                  className={classes.close}
                  style={{ visibility: selected ? 'visible' : 'hidden' }}
                /> */}
              </React.Fragment>
            )}
            options={[...masterTags].sort((a, b) => {
              // Display the selected labels first.
              let ai = value.indexOf(a);
              ai = ai === -1 ? value.length + masterTags.indexOf(a) : ai;
              let bi = value.indexOf(b);
              bi = bi === -1 ? value.length + masterTags.indexOf(b) : bi;
              return ai - bi;
            })}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
                className={classes.inputBase}
              />
            )}
          />
        </Popper>
      </React.Fragment>
    );
  };