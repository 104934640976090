import React from 'react';
import moment from 'moment';
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    startOfYear,
    endOfYear,
    addYears,
    isSameDay
  } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import { makeStyles, fade, Popper, TextField, ClickAwayListener } from '@material-ui/core';
import { PropTypes } from "prop-types";
import { format_display_fancy_notime } from '../Utils';

const useStyles = makeStyles((theme) => ({
    root: {
      zIndex: 3000,
    },
    button: {
      fontSize: 13,
    //   width: '100%',
      textAlign: 'left',
    //   paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      color: '#586069',
      fontWeight: 600,
      '&:hover,&:focus': {
        color: '#0366d6',
      },
      '& span': {
        width: '100%',
      },
      '& svg': {
        width: 25,
        height: 25,
      },
    },
    tag: {
      margin: 3,
    },
    popper: {
      border: '2px solid rgba(27,31,35,.15)',
      boxShadow: '0 3px 12px rgba(27,31,35,.15)',
      borderRadius: 3,
      //width: 300,
      zIndex: 1350,
      fontSize: 13,
      color: '#586069',
      backgroundColor: '#f6f8fa',
    },
    header: {
      borderBottom: '1px solid #e1e4e8',
      padding: '8px 10px',
      fontWeight: 600,
    },
    inputBase: {
      padding: 10,
      width: '100%',
      borderBottom: '1px solid #dfe2e5',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    paper: {
      boxShadow: 'none',
      margin: 0,
      color: '#586069',
      fontSize: 13,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        // paddingRight: theme.spacing(4),
        // [theme.breakpoints.up('md')]: {
        //   paddingRight: theme.spacing(2),
        // },
        width: 253,
        marginTop: 12,
        //float: 'left'
    },
    popperDisablePortal: {
      position: 'relative',
    },
    iconSelected: {
      width: 17,
      height: 17,
      marginRight: 5,
      marginLeft: -2,
    },
    color: {
      width: 14,
      height: 14,
      flexShrink: 0,
      borderRadius: 3,
      marginRight: 8,
      marginTop: 2,
    },
    text: {
      flexGrow: 1,
    },
    close: {
      opacity: 0.6,
      width: 18,
      height: 18,
    },
}));

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export const ranges = createStaticRanges([
  {
      label: 'Today',
      range: () => ({
          startDate: startOfDay(new Date()),
          endDate: endOfDay(new Date()),
      })
  },
  {
      label: 'Yesterday',
      range: () => ({
          startDate: startOfDay(addDays(new Date(), -1)),
          endDate: endOfDay(addDays(new Date(), -1)),
      })
  },
  {
      label: 'This Week',
      range: () => ({
          startDate: startOfWeek(new Date()),
          endDate: endOfWeek(new Date()),
      })
  },
  {
      label: 'Last Week',
      range: () => ({
          startDate: startOfWeek(addDays(new Date(), -7)),
          endDate: endOfWeek(addDays(new Date(), -7)),
      })
  },
  {
      label: 'This Month',
      range: () => ({
          startDate: startOfMonth(new Date()),
          endDate: endOfMonth(new Date()),
      })
  },
  {
      label: 'Last Month',
      range: () => ({
          startDate: startOfMonth(addMonths(new Date(), -1)),
          endDate: endOfMonth(addMonths(new Date(), -1)),
      })
  },
  {
      label: 'This Year',
      range: () => ({
          startDate: startOfYear(new Date()),
          endDate: endOfYear(new Date()),
      })
  },
  {
      label: 'Last Year',
      range: () => ({
          startDate: startOfYear(addYears(new Date(), -1)),
          endDate: endOfYear(addYears(new Date(), -1)),
      })
  },
  {
      label: 'Custom',
      range: () => ({
          startDate: new Date(),
          endDate: new Date(),
      })
  },
]);

export default function DateRangePickerCustom(props) {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selected, setSelected] = React.useState(false);
    const [dateRange, setDateRange] = React.useState(ranges);
    const [staticRangeSelected, setStaticRangeSelected] = React.useState(null);
    const [showLabel, setShowLabel] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);

    const [selection, setSelection] = React.useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    });

    React.useEffect(() => {
      if(!loaded){
        if(props.startdate) setSelection(selection => ({...selection, startDate: new Date(props.startdate)}));
        if(props.enddate) setSelection(selection => ({...selection, endDate: new Date(props.enddate)}));
        if(props.showLabel) setShowLabel(props.showLabel);
        if(props.staticRanges){
          setDateRange(props.staticRanges);
        }
        setLoaded(true)
      }
    }, [props.startdate, props.enddate, props.showLabel, props.staticRanges]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    React.useEffect(() => {
      if(selected){
        setSelected(false);
        handleClose();
      }
    }, [selected]);

    React.useEffect(() => {
      if(selection.startDate && selection.endDate){
        postSelection(selection);
      }
    }, [selection])
    
    const handleClose = (event, reason) => {
        if (reason === 'toggleInput') {
            return;
        }
        // console.log(1);
        // props.setStartDate(moment(selection.startDate));
        // props.setEndDate(moment(selection.endDate).add(1, 'd'));
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const postSelection = (selection) => {
      let s = dateRange.filter(s => s.isSelected(selection));
      if(s.length > 0){
        if(s.length === 1 && s[0].label !== 'Custom'){
          setSelected(true);
        }
        
        setStaticRangeSelected(s[0]);
        if(props.setStaticRangeSelected){
          props.setStaticRangeSelected(s[0]);
        }
      } else {
        setStaticRangeSelected(dateRange[dateRange.length - 1]);
        if(props.setStaticRangeSelected){
          props.setStaticRangeSelected(dateRange[dateRange.length - 1]);
        }
      }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;
 
    return (
      <ClickAwayListener onClickAway={handleClose}>
        <div className={classes.root}>
            <TextField
                fullWidth 
                margin="dense"
                className={classes.textField} 
                variant='outlined' 
                value={showLabel && staticRangeSelected && staticRangeSelected.label !== 'Custom' ? staticRangeSelected.label : moment(selection.startDate).format(format_display_fancy_notime)+" - "+moment(selection.endDate).format(format_display_fancy_notime)} 
                onClick={handleClick}
                label={"Date Range"}
                />
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                className={classes.popper}
            >
                <DateRangePicker
                    twoStepChange={true}
                    ranges={[selection]}
                    inputRanges={[]}
                    staticRanges={dateRange}
                    maxDate={new Date()}
                    onChange={(e) => {
                        //console.log(e);
                        setSelection(e.selection);
                        props.setStartDate(moment(e.selection.startDate));
                        props.setEndDate(moment(e.selection.endDate));
                        //postSelection(e.selection);
                    }}
                />
            </Popper>
        </div>
          </ClickAwayListener>
    )
};


DateRangePickerCustom.propTypes = {
    startdate: PropTypes.object.isRequired,
    enddate: PropTypes.object.isRequired,
    setStartDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired,
    staticRanges: PropTypes.array,
    setStaticRangeSelected: PropTypes.func,
    showLabel: PropTypes.bool,
};
  