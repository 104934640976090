import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Select, Paper, Typography, Button, Divider, Table, TableBody, TableRow, ListItemText, TablePagination, Chip, IconButton, Icon, MenuItem, InputBase } from "@material-ui/core";
import {red, blue} from '@material-ui/core/colors';
import moment from "moment";
import ItemSelection from '../../components/ItemSelection';
import EnhancedTableHead from "../../components/EnhancedTableHead";
import EnhancedTableToolbar from "../../components/EnhancedTableToolbar";
import {toInt, format_order_date, getStatusComponent, StyledTableCell, openLink, BootstrapTooltip, getTimeInIST} from "../../Utils";
// import XLSX from 'xlsx';
import { RowCountOptions, TABLE_COUNTS } from "../../Constants";
import { MasterService } from "../../Services/MasterService";
import { ReportService } from "../../Services/ReportService";
import queryString from 'query-string';
import Config from "../../Config";
import SearchIcon from '@material-ui/icons/Search';
import DateRangePickerCustom from "../../components/DateRangePickerCustom";
import { AppContext } from "../../AppContextProvider";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        // float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    iconButton: {
        padding: 8,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    divider: {
        height: 28,
        margin: 4,
        backgroundColor: '#ccc',
    },
    filterRoot: {
        minWidth: 110
    },
    select: {
        '&:focus': {
            backgroundColor: 'transparent'
        }
    } 
}));

export default function OrderReport(props){
    
    const {setTitle: setTitles, setTourSteps, setLinearLoading, closeProgressDialog, showProgressDialog} = useContext(AppContext);
    const classes = useStyles();
    const [selectedStatus, setSelectedStatus] = React.useState(null);
    const [filter, setFilter] = React.useState('');
    const [statusList, setStatusList] = React.useState([]);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').add(-30, 'days'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('PurchaseDate');
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [sortingAction, setSortingAction] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const filterTypes = [{label: 'Buyer Email', value: 'email'}, {label: 'Order ID', value: 'orderId'}, {label: 'ASIN', value: 'asin'}]
    const [selectedFilterType, setSelectedFilterType] = React.useState('email');

    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: false, label: 'S.No.', sortable: false },
        { id: 'PurchaseDate', align: 'left', numeric: false, disablePadding: true, label: 'Order Date', sortable: true },
        { id: 'OrderDetails', align: 'left', numeric: false, disablePadding: true, label: 'Order Details', sortable: false },
        { id: 'ProductDetails', align: 'center', numeric: false, disablePadding: true, label: 'Product Name', sortable: false },
        { id: 'CustomerOption', align: 'center', numeric: false, disablePadding: true, label: 'Customer Option', sortable: false },
        { id: 'OrderStatus', align: 'center', numeric: false, disablePadding: true, label: 'Order Status', sortable: true },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.sales_order_tab_count_key) ? toInt(localStorage.getItem(TABLE_COUNTS.sales_order_tab_count_key)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.sales_order_tab_count_key, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="searchbuyeremail"]',
                content: `Search for Orders by Buyer Email ID`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selectstatus]',
                content: `Filter by Status`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selectdaterange]',
                content: `Filter by Date`,
                style: {
                    backgroundColor: '#fff',
                },
            }
        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    React.useEffect(() => {
        setTitles("Orders Report");
        let buyer = null;
        if(props.location.search){
            let qString = queryString.parse(props.location.search);
            if(qString.buyer){
                buyer = qString.buyer;
                setFilter(buyer);
                if(qString.startdate)
                    setStartDate(moment(qString.startdate));
                if(qString.enddate)
                    setEndDate(moment(qString.enddate));
                setLinearLoading(true);
            }
        }

        MasterService.getOrderStatuses()
        .then(data => {
            let list = data;
            let allStatus = {name: 'All Status', value: '-1'};
            list.splice(0, 0, allStatus);
            setStatusList(list);
            setSelectedStatus(allStatus);
            setGenerate(true);
        })
        .catch(error => console.log(error));

    }, [true]);

    React.useEffect(() => {
        if(generate){
            if(!sortingAction){
                setCurrentRows([]);
            }
            setSortingAction(false);
            setLinearLoading(true);
            showProgressDialog();
            let selStatus = selectedStatus && selectedStatus.value !== '-1' ? selectedStatus.value : null;
            let filterValue = filter && filter.length > 0 ? filter : null;
            setTotalItems(0);
            setCurrentPageNumber(0);
            setTitle(filter || '');
            ReportService.fetchSalesOrderCount(filterValue, selectedFilterType, selStatus, startdate, enddate)
            .then(data => {
                setTotalItems(toInt(data.count));
                setFetchPage(true);
                if(toInt(data.count) === 0){
                    setCurrentRows([]);
                }
                setLinearLoading(false);
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
            setGenerate(false);
        }
    }, [generate, filter, selectedStatus, order, orderBy]);

    React.useEffect(() => {
        let rows = [];
        if(fetchPage && totalItems > 0){
            setLinearLoading(true);
            let selStatus = selectedStatus && selectedStatus.value !== '-1' ? selectedStatus.value : null;
            let filterValue = filter && filter.length > 0 ? filter : null;
            ReportService.fetchSalesOrder(filterValue, selectedFilterType, selStatus, startdate, enddate, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                // console.log(data);
                data.forEach( (element, index) => {
                    rows.push(
                        <TableRow key={index} style={{paddingBottom: 10, paddingTop: 10}} hover>
                            <StyledTableCell scope="row" align="center" padding='none' style={{width: 50}}>
                                <Typography variant="subtitle2">{(currentPageNumber * numItemsPerPage) + index + 1}</Typography>
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="left" padding='none' style={{width: 110, padding: 12}}>
                                <ListItemText 
                                    primary={getTimeInIST(element.PurchaseDate).fromNow()} 
                                    secondary={getTimeInIST(element.PurchaseDate).format(format_order_date)} 
                                    primaryTypographyProps={{variant: 'overline'}} 
                                    secondaryTypographyProps={{variant: 'overline'}}/>
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="left" padding='none' style={{width: 256, paddingRight: 16}}>
                                {!filter && (
                                    <BootstrapTooltip title="Click to view more orders by Buyer">
                                        <Typography 
                                            variant="body2" 
                                            color="primary" 
                                            gutterBottom 
                                            style={{cursor: 'pointer'}}
                                            onClick={(e) => {
                                                setFilter(element.BuyerEmail);
                                                setGenerate(true);
                                            }}>
                                            {element.BuyerEmail}
                                        </Typography>
                                    </BootstrapTooltip>
                                )}
                                <BootstrapTooltip title="Click to view order in Amazon">
                                    <Typography 
                                        variant="subtitle2" 
                                        color="primary" 
                                        gutterBottom 
                                        style={{cursor: 'pointer'}}
                                        onClick={(e) => {
                                            openLink(Config.seller_central_order_url.replace('{ORDERID}', element.AmazonOrderId))
                                        }}>
                                        {element.AmazonOrderId}
                                    </Typography>
                                </BootstrapTooltip>
                                <Typography variant="subtitle2" color="textSecondary" style={{fontSize: 14}}>
                                    Seller order id: {element.SellerOrderId}
                                </Typography>
                                {element.PaymentMethodDetail && (<Chip size="small" label={element.PaymentMethodDetail.split(/(?=[A-Z])/).join(' ')} />)}
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="left" padding='none'>
                                {
                                    element.orderitems.map((oi, i) => {
                                        return (
                                        <div key={i}>
                                            <Typography variant="subtitle2" color="primary" gutterBottom>
                                                {oi.Title}
                                            </Typography>
                                            <Typography variant="subtitle2" color="textSecondary" style={{fontSize: 12}}>
                                                ASIN: <b>{oi.ASIN}</b>
                                            </Typography>
                                            <Typography variant="subtitle2" color="textSecondary" style={{fontSize: 12}}>
                                                SKU: <b>{oi.SellerSKU}</b>
                                            </Typography>
                                            <Typography variant="subtitle2" color="textSecondary" style={{fontSize: 12}}>
                                                Quantity: <b>{oi.QuantityOrdered}</b>
                                            </Typography>
                                            <Typography variant="subtitle2" color="textSecondary" style={{fontSize: 12}}>
                                                Item subtotal: <b>{oi.ItemPriceAmount - (oi.PromotionDiscountAmount || 0)}</b>
                                            </Typography>
                                            <Typography variant="subtitle2" color="textSecondary" style={{fontSize: 12}}>
                                                Order Item ID: <b>{oi.OrderItemId}</b>
                                            </Typography>
                                            {i !== (element.orderitems.length - 1) && (<Divider style={{margin: 5}}/>)}
                                        </div>)
                                    })
                                }
                            </StyledTableCell>
                            <StyledTableCell align="center" padding='none' style={{width: 150}}>
                                <Typography variant="subtitle2">{element.ShipServiceLevel}</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="center" padding='none' style={{width: 150, paddingRight: 16}}>
                                {getStatusComponent(element.OrderStatus)}
                            </StyledTableCell>
                        </TableRow>);
                });
                setCurrentRows(rows);
                setLinearLoading(false);
            }).catch((error) => {
                console.log(error);
                setLinearLoading(false);
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setSortingAction(true);
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const exportReport = e => {
        showProgressDialog('Exporting...');
        let selStatus = selectedStatus && selectedStatus.value !== '-1' ? selectedStatus.value : null;
        let filterValue = filter && filter.length > 0 ? filter : null;
        ReportService.fetchSalesOrderExport(filterValue, selectedFilterType, selStatus, startdate, enddate, orderBy, order, 0, totalItems)
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Orders Report.xlsx');
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            closeProgressDialog();
        });
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={10} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item xs={12} lg={4}>
                                    <div style={{borderRadius: 4,  display: 'flex', alignItems: 'center', border: '1px solid rgba(0,0,0,0.15)', width: '97%', height: 38, marginTop: 8, marginLeft:8, marginRight: 8}}>
                                        <IconButton className={classes.iconButton} aria-label="menu">
                                            <SearchIcon />
                                        </IconButton>
                                        <InputBase
                                            className={classes.input}
                                            placeholder="Search"
                                            inputProps={{ 'aria-label': 'search' }}
                                            onChange={(e) => setFilter(e.target.value)}
                                        />
                                        <Divider className={classes.divider} orientation="vertical" />
                                        <Select
                                            variant="standard"
                                            value={selectedFilterType}
                                            disableUnderline
                                            classes={{root: classes.filterRoot, select: classes.select}}
                                            onChange={(e) => {
                                                e.persist()
                                                setSelectedFilterType(e.target.value)
                                            }}
                                        >
                                            {
                                                filterTypes.map(t => <MenuItem key={t.value} value={t.value}>{t.label}</MenuItem>)
                                            }
                                        </Select>
                                    </div>
                                    {/* <TextField
                                        margin="dense"
                                        data-tour="searchbuyeremail"
                                        fullWidth
                                        style={{marginTop: 12, marginLeft: 10, marginRight: 20, paddingRight: 20}}
                                        id="outlined-required"
                                        label="Search By"
                                        variant="outlined"
                                        value={filter || ''}
                                        type="search"
                                        onChange={(e) => setFilter(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <div>
                                                <Select
                                                    variant="standard"
                                                    value={selectedFilterType}
                                                    disableUnderline
                                                    classes={{root: classes.filterRoot}}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        setSelectedFilterType(e.target.value)
                                                    }}
                                                >
                                                    {
                                                        filterTypes.map(t => <MenuItem key={t.value} value={t.value}>{t.label}</MenuItem>)
                                                    }
                                                </Select>
                                                </div>
                                            )
                                        }}
                                    /> */}
                                </Grid>
                                {/* <Grid item xs={12} lg={4}>
                                    <TextField
                                        margin="dense"
                                        data-tour="searchbuyeremail"
                                        fullWidth
                                        style={{marginTop: 12, marginLeft: 10, marginRight: 20, paddingRight: 20}}
                                        id="outlined-required"
                                        label="Search By"
                                        variant="outlined"
                                        value={filter || ''}
                                        type="search"
                                        onChange={(e) => setFilter(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <div>
                                                <Select
                                                    variant="standard"
                                                    value={selectedFilterType}
                                                    disableUnderline
                                                    classes={{root: classes.filterRoot}}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        setSelectedFilterType(e.target.value)
                                                    }}
                                                >
                                                    {
                                                        filterTypes.map(t => <MenuItem key={t.value} value={t.value}>{t.label}</MenuItem>)
                                                    }
                                                </Select>
                                                </div>
                                            )
                                        }}
                                    />
                                </Grid> */}
                                <Grid item xs={12} lg={2} data-tour="selectstatus">
                                    <ItemSelection required label='Status' value={selectedStatus} optionLabel='name' options={statusList} selected={setSelectedStatus}/>
                                </Grid>
                                <Grid item xs={12} lg={3} data-tour="selectdaterange">
                                    {/* <Timeline startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/> */}
                                    <DateRangePickerCustom startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Button variant="contained" className={classes.button} color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                        Generate Report
                                    </Button>
                                    <IconButton disabled={totalItems === 0} style={{marginTop: 8}} onClick={exportReport}>
                                        <Icon>save_alt</Icon>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 10, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    { false && filter && !generate && 
                                        (<EnhancedTableToolbar 
                                            title={
                                                <ListItemText 
                                                    primaryTypographyProps={{align: 'left', variant: 'subtitle2'}} 
                                                    secondaryTypographyProps={{align: 'left'}} 
                                                    primary={title} 
                                                    secondary={`${totalItems} Orders`}/>} />)
                                    }
                                    <div style={{overflow: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows ={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Data</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );

};