import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, FormControl, Button, TextField, Card, CardHeader, CardContent, CardActions, Divider, colors } from "@material-ui/core";
import { UserService } from "../Services/UserService";
import clsx from 'clsx';
import { AppContext } from "../AppContextProvider";

const useStyles = makeStyles(theme => ({
    root: {},
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actions: {
        float: 'right',
    }
}));

export default function SPAPISettings(props) {

    const {state, showConfirmDialog, showSnackBar, updateSPAPICredentials, closeConfirmDialog, setLinearLoading} = useContext(AppContext);
    const user = state.user;
    const classes = useStyles();

    const [lwaClientId, setLwaClientId] = React.useState('');
    const [lwaClientSecret, setLwaClientSecret] = React.useState('');
    const [lwaRefreshToken, setLwaRefreshToken] = React.useState('');
    const [awsAccessKey, setAwsAccessKey] = React.useState('');
    const [awsSecretKey, setAwsSecretKey] = React.useState('');
    const [marketPlaceId, setMarketPlaceId] = React.useState('');

    React.useEffect(() => {
        //Prepopulate the UI
        setLwaClientId(user.lwa_client_id ? user.lwa_client_id: '');
        setLwaClientSecret(user.lwa_client_secret ? user.lwa_client_secret:  '');
        setLwaRefreshToken(user.lwa_refresh_token ? user.lwa_refresh_token: '');
        setAwsAccessKey(user.aws_access_key ? user.aws_access_key: '');
        setAwsSecretKey(user.aws_secret_key ? user.aws_secret_key: '');
        setMarketPlaceId(user.market_place_id ? user.market_place_id: '');
    }, [true]);

    const handleSPAPIChange = (type, value) => {
        switch(type){
            case 'lwaClientId': setLwaClientId(value); break;
            case 'lwaClientSecret': setLwaClientSecret(value); break;
            case 'lwaRefreshToken': setLwaRefreshToken(value); break;
            case 'awsAccessKey': setAwsAccessKey(value); break;
            case 'awsSecretKey': setAwsSecretKey(value); break;
            case 'marketPlaceId': setMarketPlaceId(value); break;
            default:;
        }
    }

    const submit = () => {
        if(lwaClientId.length === 0 || lwaClientSecret.length === 0 || lwaRefreshToken.length === 0 
            || awsAccessKey.length === 0 || awsSecretKey.length === 0 
            || marketPlaceId.length === 0){
            showSnackBar('Please fill all the required fields and try again.', "error")
        } else {
            showConfirmDialog("Confirm changes? ", undefined, () => {
                confirmChange();
            }, "Cancel", "Yes");
        }
    }

    const confirmChange = () => {
        let body = {
            "lwa_client_id": lwaClientId,
            "lwa_client_secret": lwaClientSecret,
            "lwa_refresh_token": lwaRefreshToken,
            "aws_access_key": awsAccessKey,
            "aws_secret_key": awsSecretKey,
            "market_place_id": marketPlaceId
        };
        setLinearLoading(true);
        UserService.changeSPAPICredentials(body)
        .then((data) => {
            console.log(data);
            if(data.success){
                showSnackBar(data.message, "success");
                updateSPAPICredentials(body);
            } else {
                showSnackBar(data.message, "error");
            }
            closeConfirmDialog();
            setLinearLoading(false);
        }).catch((error) => {
            showSnackBar("Something went wrong. Try again later.", "error");
            closeConfirmDialog();
            setLinearLoading(false);
        });
    }

    return (
        <div>
            <Card className={clsx(classes.root)}>
                <CardHeader 
                    title="Credentials"
                    titleTypographyProps={{align: 'left'}} />
                <Divider />
                <CardContent>
                    <Grid container spacing={2} alignItems="center" justify="center">
                        <Grid item xs={12} lg={5}>
                            <FormControl fullWidth>
                                <TextField
                                    id="standard-text-input-1"
                                    label="LWA Client ID"
                                    className={classes.textField}
                                    type="text"
                                    required
                                    error={lwaClientId === ''}
                                    helperText={lwaClientId === '' ? 'Cannot be empty' : ' '}
                                    margin="none"
                                    variant="outlined"
                                    onChange={(e) => handleSPAPIChange('lwaClientId', e.target.value)}
                                    value={lwaClientId}
                                    />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <FormControl fullWidth>
                                <TextField
                                    id="standard-text-input-2"
                                    label="LWA CLient Secret"
                                    className={classes.textField}
                                    type="text"
                                    required
                                    error={lwaClientSecret === ''}
                                    helperText={lwaClientSecret === '' ? 'Cannot be empty' : ' '}
                                    margin="none"
                                    variant="outlined"
                                    onChange={(e) => handleSPAPIChange('lwaClientSecret', e.target.value)}
                                    value={lwaClientSecret}
                                    />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <FormControl fullWidth>
                                <TextField
                                    id="standard-text-input-3"
                                    label="LWA Refresh Token"
                                    className={classes.textField}
                                    type="text"
                                    required
                                    error={lwaRefreshToken === ''}
                                    helperText={lwaRefreshToken === '' ? 'Cannot be empty' : ' '}
                                    margin="none"
                                    
                                    variant="outlined"
                                    onChange={(e) => handleSPAPIChange('lwaRefreshToken', e.target.value)}
                                    value={lwaRefreshToken}
                                    />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <FormControl fullWidth>
                                <TextField
                                    id="standard-text-input-4"
                                    label="AWS Access Key"
                                    className={classes.textField}
                                    type="text"
                                    error={awsAccessKey === ''}
                                    helperText={awsAccessKey === '' ? 'Cannot be empty' : ' '}
                                    margin="none"
                                    required
                                    variant="outlined"
                                    onChange={(e) => handleSPAPIChange('awsAccessKey', e.target.value)}
                                    value={awsAccessKey}
                                    />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <FormControl fullWidth>
                                <TextField
                                    id="standard-text-input-5"
                                    label="AWS Secret Key"
                                    className={classes.textField}
                                    type="text"
                                    error={awsSecretKey === ''}
                                    helperText={awsSecretKey === '' ? 'Cannot be empty' : ' '}
                                    margin="none"
                                    required
                                    variant="outlined"
                                    onChange={(e) => handleSPAPIChange('awsSecretKey', e.target.value)}
                                    value={awsSecretKey}
                                    />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <FormControl fullWidth>
                                <TextField
                                    id="standard-text-input-6"
                                    label="Market Place ID"
                                    className={classes.textField}
                                    type="text"
                                    error={marketPlaceId === ''}
                                    helperText={marketPlaceId === '' ? 'Cannot be empty' : ' '}
                                    margin="none"
                                    required
                                    variant="outlined"
                                    onChange={(e) => handleSPAPIChange('marketPlaceId', e.target.value)}
                                    value={marketPlaceId}
                                    />
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions className={classes.actions}>
                    <Button variant="contained" className={classes.saveButton} onClick={submit}>
                        Update Credentials
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
}
 