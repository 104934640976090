import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Divider, Table, TableBody, TableRow, TableCell, Link, ListItemText, useTheme } from "@material-ui/core";
import { ReportService } from "../../Services/ReportService";
import {red, blue} from '@material-ui/core/colors';
import { ProductService } from "../../Services/ProductService";
import moment from "moment";
import ItemSelection from '../../components/ItemSelection';
// import Timeline from "../../components/Timeline";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import EnhancedTableToolbar from "../../components/EnhancedTableToolbar";
import { Doughnut } from "react-chartjs-2";
import { toInt, toPercentage, formatNumber, format, colorGenerator } from "../../Utils";
import queryString from 'query-string';
import XLSX from 'xlsx';
import { Link as RouterLink} from 'react-router-dom';
import DateRangePickerCustom from "../../components/DateRangePickerCustom";
import { AppContext } from "../../AppContextProvider";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export default function GeoAnalyseByASIN(props) {

    const {setLinearLoading, setTourSteps, setTitle, showProgressDialog, closeProgressDialog, showSnackBar, mobileView} = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();
    const [asin, setASIN] = React.useState(null);
    const [asinList, setASINList] = React.useState([]);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').add(-30, 'd'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [regionData, setRegionData] = React.useState({});
    const [cityData, setCityData] = React.useState({});
    const [selectedState, setSelectedState] = React.useState(null);

    const order = 'desc';
    const orderBy = 'Title';
    const [currentRows, setCurrentRows] = React.useState([]);
    const [stateRows, setStateRows] = React.useState([]);
    const [cityRows, setCityRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);

    const [stateData, setStateData] = React.useState([]);
    const [cityRawData, setCityRawData] = React.useState([]);
    const [stateTotalCount, setStateTotalCount] = React.useState(0);
    const [stateTotalAmount, setStateTotalAmount] = React.useState(0);
    const [cityTotalCount, setCityTotalCount] = React.useState(0);
    const [cityTotalAmount, setCityTotalAmount] = React.useState(0);

    const doughnutChartOptions = {
        responsive : true,
        maintainAspectRatio: true, 
        cutoutPercentage: 80,
        onClick: null, 
        legend: {
            display: !mobileView,
            align: 'end',
            position: 'right',
            fullWidth: true,
            onClick: () => {},
            labels: {
                fontSize: 10
            }
        },
        elements: {
            arc: {
                borderWidth: 5,
                borderColor: theme.palette.white,
                backgroundColor: theme.palette.white
            },
        },
        tooltips: {
            intersect: false,
            caretSize: 10,
            yPadding: 20,
            xPadding: 20,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.black,
            titleFontColor: theme.palette.white,
            bodyFontColor: theme.palette.white,
            footerFontColor: theme.palette.white,
            callbacks: {
                label: function(tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue/total*100).toFixed(1));
                    // return " Count: " + currentValue + ' (' + percentage + '%)';
                    return ` ${currentValue}units (${percentage}%)`;
                },
                title: function(tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    }

    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: true, label: 'S.No', sortable: false },
        { id: 'ASIN', align: 'left', numeric: false, disablePadding: true, label: 'ASIN', sortable: false },
        { id: 'SellerSKU', align: 'left', numeric: false, disablePadding: true, label: 'Seller SKU', sortable: false },
        { id: 'Title', align: 'left', numeric: false, disablePadding: true, label: 'Product', sortable: false },
        { id: 'TotalCount', numeric: true, disablePadding: true, label: 'Units', sortable: false  }
    ];

    const columnsState = [
        { id: 'index', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No', sortable: false },
        { id: 'ShippingAddressStateOrRegion', align: 'left', numeric: false, disablePadding: true, label: 'State', sortable: false },
        { id: 'TotalCount', align: 'left', numeric: true, disablePadding: true, label: 'Count', sortable: false },
        { id: 'Percentage', align: 'left', numeric: true, disablePadding: true, label: 'Percentage', sortable: false },
        { id: 'TotalAmount', numeric: true, disablePadding: true, label: 'Total Amount', sortable: false  }
    ];

    const columnsCity = [
        { id: 'index', align: 'left', numeric: false, disablePadding: true, label: 'S.No', sortable: false },
        { id: 'ShippingAddressCity', align: 'left', numeric: false, disablePadding: true, label: 'City', sortable: false },
        { id: 'TotalCount', align: 'left', numeric: true, disablePadding: true, label: 'Count', sortable: false },
        { id: 'Percentage', align: 'left', numeric: true, disablePadding: true, label: 'Percentage', sortable: false },
        { id: 'TotalAmount', numeric: true, disablePadding: true, label: 'Total Amount', sortable: false  }
    ];

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="selectasin"]',
                content: `Filter by ASIN`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selecttimeline]',
                content: `Filter by Date Range`,
                style: {
                    backgroundColor: '#fff',
                },
            }
        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    React.useEffect(() => {
        if(asin && selectedState){
            fetchCityWiseData(selectedState);
            fetchRelativeData(selectedState);
        }
    }, [selectedState]);

    React.useEffect(() => {
        setTitle("Analyze by ASIN");
        let asin = null;
        if(props.location.search){
            let qString = queryString.parse(props.location.search);
            if(qString.asin){
                asin = qString.asin;
                if(qString.startdate)
                    setStartDate(moment(qString.startdate));
                if(qString.enddate)
                    setEndDate(moment(qString.enddate));
            }
            // setASIN(props.location.query.asin);
        }

        ProductService.listProductNames()
        .then(data => {
            let list = data.map(d => ({...d, label : `[${d.asin}][${d.seller_sku}] ${d.name.substring(0, 120)}${d.name.length > 120 ? '...' : ''}`}));
            setASINList(list);
            if(asin){
                let a = list.filter(l => l.asin === asin);
                if(a.length === 0){
                    showSnackBar(`ASIN ${asin} is not active in the platform`, 'warning');
                } else {
                    setASIN(a.length > 0 ? a[0] : null);
                    setGenerate(true);
                }
                // if(a.length > 0) {
                //     generateReport();
                // }
            }
        })
        .catch(error => console.log(error));

    }, [true]);

    React.useEffect(() => {
        if(generate && asin && asin.asin){
            generateReport();
            setGenerate(false);
        }
    }, [generate, asin]);

    const reselectASIN = (asin) => {
        let a = asinList.filter(l => l.asin === asin);
        setSelectedState(null);
        setCityData({});
        setRegionData({});
        setASIN(a.length > 0 ? a[0] : null);
        setGenerate(true);
        setCurrentRows([]);
        setStateRows([]);
        setCityRows([]);
    }

    const generateReport = () => {
        setRegionData({});
        setCityData({});
        setStateRows([]);
        setCurrentRows([]);
        setLinearLoading(true);
        showProgressDialog();
        ReportService.fetchGeoAnalysisState(asin.asin, startdate, enddate)
        .then(data => {
            let labels = [], count = [];
            let srow = [];
            let accumulator = 0, totalAmount = 0;
            let total = data.reduce(function(accumulator, currentValue) {
                return accumulator + parseFloat(currentValue.TotalCount);
            }, accumulator);
            setStateTotalCount(total);
            totalAmount = data.reduce(function(accumulator, currentValue) {
                return accumulator + parseFloat(currentValue.TotalAmount || 0);
            }, totalAmount);
            setStateTotalAmount(totalAmount);
            setStateData(data);
            data.forEach((element, index) => {
                labels.push(element.ShippingAddressStateOrRegion ? element.ShippingAddressStateOrRegion: 'Other');
                count.push(toInt(element.TotalCount));
                srow.push(
                    <TableRow key={index}>
                        <TableCell scope="row" align="center" padding='default' style={{width: 50}}>
                            {index + 1}
                        </TableCell>
                        <TableCell scope="row" align="left" padding='default'>
                            <RouterLink style={{cursor: 'pointer', textDecoration: 'none', color: theme.palette.text.link}} to={`/geo/analyze/location?state=${element.ShippingAddressStateOrRegion}&startdate=${startdate.format(format)}&enddate=${enddate.format(format)}`}>
                                {element.ShippingAddressStateOrRegion}
                            </RouterLink>
                        </TableCell>
                        <TableCell scope="row" align="right" padding='default'>
                            {element.TotalCount}
                        </TableCell>
                        <TableCell align="right" padding='default'>
                            {toPercentage(element.TotalCount, total)}
                        </TableCell>
                        <TableCell align="right" padding='default'>
                            {formatNumber(element.TotalAmount)}
                        </TableCell>
                    </TableRow>)
                });
            let rd = {
                labels: labels,
                datasets: [{
                    label: "State Wise",
                    data: count,
                    backgroundColor: colorGenerator(count.length)
                }]
            };
            setRegionData(rd);
            setStateRows(srow);
            setLinearLoading(false);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false); 
            closeProgressDialog();
        });
        // fetchCityWiseData(null);
    };

    const exportReport = type => e => {
        setLinearLoading(true);
        let row = [];
        let filename = asin.asin;
        if(type === 'state') {
            row.push(columnsState.map(c => c.label));
            stateData.forEach((d, index) => {
                row.push([index+1, d.ShippingAddressStateOrRegion, toInt(d.TotalCount), toPercentage(d.TotalCount, stateTotalCount), parseFloat(d.TotalAmount)]);
            });
            filename += ' - State wise Report';
        } else if(type === 'city') {
            row.push(columnsCity.map(c => c.label));
            cityRawData.forEach((d, index) => {
                row.push([index+1, d.ShippingAddressCity, toInt(d.TotalCount), toPercentage(d.TotalCount, cityTotalCount), parseFloat(d.TotalAmount)]);
            });
            filename += ' - ' + selectedState + " City wise Report";
        }

        const wb = XLSX.utils.book_new();
        const wsAll = XLSX.utils.aoa_to_sheet(row);
        XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
        XLSX.writeFile(wb, filename + ".xlsx");

        setLinearLoading(false);
    }

    const fetchRelativeData = (state) => {
        setLinearLoading(true);
        ReportService.fetchRelatedProductsFromLocation(state, null, startdate, enddate)
        .then(data => {
            let rows = data.map((d, index) => (
                <TableRow key={index}>
                    <TableCell scope="row" align="center" padding='default' style={{width: 50}}>
                        {index + 1}
                    </TableCell>
                    <TableCell scope="row" align="justify" padding='default' style={{paddingRight: 16}}>
                        <Link onClick={() => reselectASIN(d.ASIN)} style={{cursor: "pointer", textDecoration: 'none', color: theme.palette.text.link }}>
                            {d.ASIN}
                        </Link>
                    </TableCell>
                    <TableCell scope="row" align="justify" padding='default'>
                        {d.SellerSKU}
                    </TableCell>
                    <TableCell align="justify" padding='default' style={{paddingRight: 16}}>
                        {d.Title}
                    </TableCell>
                    <TableCell align="right" padding='default' style={{paddingLeft: 16, paddingRight: 8}}>
                        {d.TotalCount}
                    </TableCell>
                </TableRow>
            ));

            setCurrentRows(rows);
            setLinearLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false); 
        });
    } 

    const fetchCityWiseData = (state) => {
        setLinearLoading(true);
        ReportService.fetchGeoAnalysisCity(asin.asin, state, startdate, enddate)
        .then(data => {
            let labels = [], count = [];
            let crow = [], total = 0, totalAmount = 0;
            total = data.reduce(function(accumulator, currentValue) {
                return accumulator + parseFloat(currentValue.TotalCount);
            }, total);
            setCityTotalCount(total);
            totalAmount = data.reduce(function(accumulator, currentValue) {
                return accumulator + parseFloat(currentValue.TotalAmount);
            }, totalAmount);
            setCityTotalAmount(totalAmount);
            setCityRawData(data);
            data.forEach((element, index) => {
                labels.push(element.ShippingAddressCity);
                count.push(toInt(element.TotalCount));
                crow.push(
                    <TableRow key={index}>
                        <TableCell scope="row" align="center" padding='default' style={{width: 50}}>
                            {index + 1}
                        </TableCell>
                        <TableCell scope="row" align="left" padding='default'>
                            <RouterLink style={{cursor: 'pointer', textDecoration: 'none', color: theme.palette.text.link}} to={`/geo/analyze/location?state=${selectedState}&city=${element.ShippingAddressCity}&startdate=${startdate.format(format)}&enddate=${enddate.format(format)}`}>
                                {element.ShippingAddressCity}
                            </RouterLink>
                        </TableCell>
                        <TableCell scope="row" align="right" padding='default'>
                            {element.TotalCount}
                        </TableCell>
                        <TableCell align="right" padding='default'>
                            {toPercentage(element.TotalCount, total)}
                        </TableCell>
                        <TableCell align="right" padding='default'>
                            {formatNumber(element.TotalAmount)}
                        </TableCell>
                    </TableRow>);
            });
            let rd = {
                labels: labels,
                datasets: [{
                    label: "City Wise",
                    data: count,
                    backgroundColor: colorGenerator(count.length)
                }]
            };
            setCityData(rd);
            setCityRows(crow);
            setLinearLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false); 
        });
    }

    const regionPieClick = (elems) => {
        if(elems.length > 0){
            // let x = elems[0]._datasetIndex;
            let y =  elems[0]._index;
            // let value = regionData.datasets[x].data[y];
            let label = regionData.labels[y];
            // console.log(value, label);
            // label = 'GC Sheet';
            setSelectedState(label);
            // fetchCityWiseData(label);
            // fetchRelativeData(label);
        }
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={9} lg={11} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacing={0} alignItems="center" justify="center">
                                <Grid container item justify="flex-start" alignItems="center">
                                    {/* <Grid item xs={12} lg={12}>
                                        <Typography align="left" variant="subtitle2" className={classes.title} gutterBottom>Criteria</Typography>
                                    </Grid> */}
                                    <Grid item xs={12} lg={4} data-tour="selectasin">
                                        <ItemSelection clearable={true} required label='Select ASIN' value={asin} optionLabel='label' options={asinList} selected={setASIN}/>
                                    </Grid>
                                    <Grid item xs={12} lg={3} data-tour="selecttimeline">
                                        {/* <Timeline startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/> */}
                                        <DateRangePickerCustom startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/>
                                    </Grid>
                                    {/* <Grid item xs={12} lg={2}>
                                        {   regionData.datasets &&
                                            <ItemSelection label='State' value={stateList.filter(s => s.value === selectedState)[0]} optionLabel='label' options={stateList} selected={setSelectedState}/>
                                        }
                                    </Grid> */}
                                    <Grid item xs={12} lg={3}>
                                        <Button variant="contained" disabled={!asin} className={classes.button} color="primary" onClick={generateReport}>
                                            Generate Report
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 5, marginTop: 5}}/>
                                </Grid>
                                { regionData.labels && regionData.labels.length === 0 &&
                                    <Grid item xs={12} lg={12}>
                                        <Typography align="center" variant="subtitle2" gutterBottom>No Data Available</Typography>
                                    </Grid>
                                }
                                { regionData.labels && regionData.labels.length > 0 && 
                                    (<Grid item xs={12} xl={6} lg={6}>
                                        <Grid container spacing={1} justify="center"  alignItems="flex-start">
                                            <Grid item xs={12} lg={12}>
                                                <Typography align="left" variant="subtitle2" className={classes.title} gutterBottom>State Wise Sales</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={12} xl={12}>
                                                <Doughnut 
                                                    width={50} 
                                                    height={20} 
                                                    onElementsClick={elems => regionPieClick(elems)} 
                                                    options={doughnutChartOptions} 
                                                    data={regionData} 
                                                    style={{float: "left"}}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                { stateRows.length > 0 && 
                                    (<Grid item xs={12} xl={6} lg={6}>
                                        <EnhancedTableToolbar 
                                            title={
                                                <ListItemText 
                                                    primaryTypographyProps={{align: 'left', variant: 'h6'}} 
                                                    secondaryTypographyProps={{align: 'left'}}  
                                                    primary={`Total Count: ${stateTotalCount}`} 
                                                    secondary={`Total Amount: ${formatNumber(stateTotalAmount)}`}/>} 
                                            exportReport={exportReport('state')}/>
                                        <div style={{height: 500, overflowY: 'auto', display: 'block'}}>
                                            <Table className={classes.table} stickyHeader>
                                                <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={() => {}}
                                                    rows ={columnsState} />
                                                <TableBody>
                                                    {stateRows}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </Grid>
                                )}
                                { cityData.labels && cityData.labels.length > 0 && cityData.datasets && 
                                    (<Grid item xs={12} lg={12}>
                                        <Divider style={{marginBottom: 10, marginTop: 10}}/>
                                    </Grid>)
                                }
                                { cityData.labels && cityData.labels.length > 0 && cityData.datasets && 
                                    (<Grid item xl={6} lg={6}>
                                        <Grid container spacing={1} alignItems="flex-start">
                                            <Grid item xs={12} lg={12}>
                                                <Typography align="left" variant="subtitle2" className={classes.title} gutterBottom>City Wise Sales{ selectedState ? ` for ${selectedState}` : ''}</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={12} xl={12}>
                                                <Doughnut 
                                                    width={50} 
                                                    height={20} 
                                                    options={doughnutChartOptions} 
                                                    data={cityData}
                                                    style={{float: "left"}}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                { cityRows.length > 0 && 
                                    (<Grid item xs={12} xl={6} lg={6}>
                                        <EnhancedTableToolbar 
                                            title={
                                                    <ListItemText 
                                                        primaryTypographyProps={{align: 'left', variant: 'h6'}} 
                                                        secondaryTypographyProps={{align: 'left'}}  
                                                        primary={`Total Qty: ${cityTotalCount}`} 
                                                        secondary={`Total Amount: ${formatNumber(cityTotalAmount)}`}/>
                                                    } 
                                            exportReport={exportReport('city')}/>
                                        <div style={{height: 500, overflowY: 'auto', display: 'block'}}>
                                            <Table className={classes.table} stickyHeader>
                                                <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={() => {}}
                                                    rows ={columnsCity} />
                                                <TableBody>
                                                    {cityRows}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </Grid>
                                )}
                                { currentRows.length > 0 && 
                                    (<Grid item xs={12} lg={12}>
                                        <Divider style={{marginBottom: 10, marginTop: 10}}/>
                                    </Grid>)
                                }
                                { currentRows.length > 0 && 
                                    (<Grid item xs={12} lg={11} xl={10}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} lg={12}>
                                                <Typography align="left" variant="subtitle2" className={classes.title} gutterBottom>Products that sell in the{ selectedState ? ` ${selectedState} ` : ''}State</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={12}>
                                                <div style={{height: 500, overflowY: 'auto', display: 'block'}}>
                                                    <Table className={classes.table} stickyHeader>
                                                        <EnhancedTableHead
                                                            order={order}
                                                            orderBy={orderBy}
                                                            onRequestSort={() => {}}
                                                            rows ={columns} />
                                                        <TableBody>
                                                            {currentRows}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
 