import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, FormControl, TextField} from "@material-ui/core";
import {Autocomplete} from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    formControlAutoComplete: {
        zIndex: 3,
        margin: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
}));

export default function ItemSelection(props){
    const classes = useStyles();

    const {label, optionLabel, options, selected, multiple, limitTags} = props;
    const [value, setValue] = React.useState(multiple || false ? [] : null);

    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <FormControl variant="outlined" className={classes.formControlAutoComplete} fullWidth>
                    <Autocomplete
                        groupBy={props.groupBy || null}
                        size="small"
                        disableClearable={props.clearable ? !props.clearable : true}
                        disabled={props.disabled || false}
                        options={options || []}
                        getOptionLabel={option => optionLabel ? option[optionLabel] : option}
                        value={value}
                        multiple={multiple || false}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            selected(newValue);
                        }}
                        style={{zIndex: 500, marginTop: 8, marginRight: 10}}
                        renderInput={params => (
                            <TextField {...params} label={label} variant="outlined" fullWidth required={props.required || false}/>
                        )}
                        limitTags={limitTags}
                        />
                    </FormControl>
            </Grid>
        </Grid>
    );
};

ItemSelection.propTypes = {
    options: PropTypes.array,
    optionLabel: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    selected: PropTypes.func.isRequired,
    required: PropTypes.bool,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    groupBy: PropTypes.any,
    limitTags: PropTypes.any,
};
  