import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
  CardHeader,
  Divider,
  colors,
  IconButton,
  Grid,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  CircularProgress,
  ListItemIcon,
  Checkbox,
  useTheme
} from '@material-ui/core';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import { EmptyList } from './components';
import { BootstrapTooltip, getTimeInIST } from '../../Utils';
import DeleteIcon from '@material-ui/icons/Delete';
import { NotificationService } from '../../Services/NotificationService';
import { Virtuoso } from 'react-virtuoso';
import { AppContext } from '../../AppContextProvider';

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: '100%',
		marginTop: 50
	},
	actions: {
		backgroundColor: colors.grey[50],
		justifyContent: 'center'
	},
	icon: {
		marginTop: 8,
		color: theme.palette.icon,
		cursor: 'pointer',
	},
	iconSelected: {
		marginTop: 8,
		color: theme.palette.white,
		cursor: 'pointer',
	},
	content: {
		paddingLeft: 0,
		paddingRight: 0,
		padding: 0
	},
	selectedHeader: {
		backgroundColor: theme.palette.black,
		color: theme.palette.white,
	},
	unread: {
		backgroundColor: theme.palette.unread,
	},
	listitem: {
		cursor: 'pointer',
	},
}));

const NotificationsPage = props => {
	
	const { setNotifications : setAppNotifications, showProgressDialog, closeProgressDialog } = useContext(AppContext);

	const theme = useTheme();
	const [notifications, setNotifications] = React.useState([]);
	const [count, setCount] = React.useState(0);
	const [total, setTotal] = React.useState(0);
	const history = useHistory();
	const loading = React.useRef(false);
	const refresh = React.useRef(true);
	const [checked, setChecked] = React.useState([]);

	React.useEffect(() => {
		getCount();
	}, [true]);

	React.useEffect(() => {
		if(refresh.current){
			refresh.current = false;
			getNotifications();
		}
	}, [refresh.current]);

	const getCount = () => {
		NotificationService.getNotificationCount()
		.then(data => {
			setCount(data.count);
			refresh.current = true;
		})
		.catch(error => {
			console.log(error);
		});
	}

	const getNotifications = useCallback(() => {
		if (loading.current) {
			return;
		}
		if(total < count || refresh.current){
			loading.current = true;
			showProgressDialog();
			NotificationService.getNotifications(null, null, 0, total + 20)
			.then(data => {
				setNotifications(data);
				setTotal(data.length);
				loading.current = false;
				closeProgressDialog();
			})
			.catch(error => {
				console.log(error);
				loading.current = false;
				closeProgressDialog();
			});
		}
	}, [count, total, refresh.current]);

	const removeNotification = ids => {
		showProgressDialog();
		NotificationService.removeNotifications(ids)
		.then(data => {
			getCount();
			refresh.current = true;
			getNotifications();
			refreshAppBarNotifications();
			closeProgressDialog();
		})
		.catch(error => {
			console.log(error);
			closeProgressDialog();
		});
	}

	const removeAllNotifications = () => {
		if(checked.length > 0){
			removeNotification(checked.join(','));
			setChecked([]);
		} else {
			showProgressDialog();
			NotificationService.removeAllNotifications()
			.then(data => {
				getCount();
				refresh.current = true;
				getNotifications();
				refreshAppBarNotifications();
				closeProgressDialog();
			})
			.catch(error => {
				console.log(error);
				closeProgressDialog();
			});
		}
	}

	const markRead = ids => {
		NotificationService.markAsReadNotifications(Array.isArray(ids) ? ids.join(','): ids)
		.then(data => {
			refresh.current = true;
			getNotifications();
			refreshAppBarNotifications();
		})
		.catch(error => {
			console.log(error);
		});
	}

	const classes = useStyles();

	const markAllRead = e => {
		markRead(checked.length === 0 ? notifications.map(n => n.id) : checked);
		setChecked([]);
	}

	const openLink = notification => e => {
		markRead(notification.id);
		history.push(notification.link ? notification.link : '#');
	}

	const refreshAppBarNotifications = () => {
		NotificationService.getUnreadCount()
        .then(data => {
            if(data.count > 0){
				NotificationService.getNotifications(null, 'unread', 0, data.count)
				.then(data => {
					setAppNotifications(data);
				})
				.catch(error => {
					console.log(error);
				});
			} else {
				setAppNotifications([]);
			}
        })
        .catch(error => {
            console.log(error);
        });
	};

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];
	
		if (currentIndex === -1) {
		  newChecked.push(value);
		} else {
		  newChecked.splice(currentIndex, 1);
		}
	
		setChecked(newChecked);
	};

	return (
		<div className={classes.root}>
			<Grid container direction="row" spacing={4} justify="center">
				<Grid item xl={10} lg={10} xs={12}>
				<Card>
					{
						checked.length > 0 ?
							<CardHeader 
								title={`${checked.length} Selected`}
								titleTypographyProps={{style: {color: theme.palette.white}}}
								className={classes.selectedHeader}
								action={
									<div> 
									<BootstrapTooltip title="Mark all as Read">
										<IconButton aria-label="Mark all as Read" onClick={markAllRead}>
										<MarkunreadIcon className={classes.iconSelected}/>
										</IconButton>
									</BootstrapTooltip>
									<BootstrapTooltip title="Remove All notifications">
										<IconButton aria-label="Remove All notifications" onClick={removeAllNotifications}>
										<DeleteIcon className={classes.iconSelected}/>
										</IconButton>
									</BootstrapTooltip>
									</div>
								}
							/>
						:

							<CardHeader 
								title={`Notifications (${count})`}
								action={
									<div> 
									<BootstrapTooltip title="Mark all as Read">
										<IconButton aria-label="Mark all as Read" onClick={markAllRead}>
										<MarkunreadIcon className={classes.icon}/>
										</IconButton>
									</BootstrapTooltip>
									<BootstrapTooltip title="Remove All notifications">
										<IconButton aria-label="Remove All notifications" onClick={removeAllNotifications}>
										<DeleteIcon className={classes.icon}/>
										</IconButton>
									</BootstrapTooltip>
									</div>
								}
							/>

					}
					<Divider />
					<CardContent className={classes.content}>

					{
						notifications.length > 0 ? (
							<Virtuoso
								style={{ height: '70vh' }}
								overscan={125}
								totalCount={total}
								endReached={() => getNotifications()}
								item={i => {
									let notification = notifications[i];
									if(notification !== undefined){
										return (
											<ListItem
												className={clsx(classes.listItem, notification.unread === '1' ? classes.unread: null)}
												component={'div'}
												divider={i < notifications.length - 1}
												key={notification.id}
											>
												<ListItemIcon>
													<Checkbox
														onClick={handleToggle(notification.id)}
														edge="start"
														checked={checked.indexOf(notification.id) !== -1}
														tabIndex={-1}
														disableRipple
														inputProps={{ 'aria-labelledby': notification.id }}
													/>
												</ListItemIcon>
												<ListItemText
													onClick={openLink(notification)}
													primary={notification.message}
													className={classes.listitem}
													primaryTypographyProps={{ variant: 'body1' }}
													secondary={getTimeInIST(notification.created_date).fromNow()}
												/>
												<DeleteIcon className={classes.icon} onClick={(e) => removeNotification(notification.id)}/>
											</ListItem>
											);
									}
								}}
								footer={() => {
									return (
									<div style={{ padding: '2rem', textAlign: 'center' }}>
										{ total < count ? <CircularProgress /> : ''}
									</div>
									)
								}}
							/>
						)
					: (
						<EmptyList />
						)}
					</CardContent>
				</Card>
				</Grid>
			</Grid>
		</div>
	);
};

export default NotificationsPage;
