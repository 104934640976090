import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';
import { MasterService } from '../Services/MasterService';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { ProductService } from '../Services/ProductService';
import { AppContext } from '../AppContextProvider';
const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight : 90,
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        // },
    },
  }));

export default function AddASIN(props) {
    const {showProgressDialog, showSnackBar, closeProgressDialog} = useContext(AppContext);
    const { open, title, placeholder, internal, onSuccess, onError, onCancel, excludeList } = props;
    const classes = useStyles();
    const [asin, setASIN] = React.useState(null);
    const [enableMonitoringStats, setEnableMonitoringStats] = React.useState(true);
    const [enableMonitoringReviews, setenableMonitoringReviews] = React.useState(true);
    const [masterTags, setMasterTags] = React.useState([]);
    const [tags, setTags] = React.useState([]);
    const [asinError, setASINError] = React.useState(false);

    React.useEffect(() => {
        if(open){
            MasterService.getTags()
            .then(data => {
                setMasterTags(data);
            })
            .catch(error => console.log(error));
        }
    
    }, [open]);

    const handleChange = event => {
        if(event.target.name === 'enable_monitoring_stats'){
            setEnableMonitoringStats(event.target.checked);
        } else if(event.target.name === 'enable_monitoring_reviews'){
            setenableMonitoringReviews(event.target.checked);
        }
    };

    const flagChanges = (field, enabled) => {
        return ProductService.productFieldUpdate(asin, field, enabled)
    }

    const addTag = (tag) => {
        return ProductService.addTag(asin, tag)
    };

    const addASIN = () => {
        showProgressDialog(`Adding ASIN ${asin}...`);
        if(asin.length > 9 && !excludeList.includes(asin)){
            ProductService.addASIN(asin, internal)
            .then((data) => {
                if(data.length > 0){
                    let p = [flagChanges('enable_monitoring_stats', enableMonitoringStats), flagChanges('enable_monitoring_reviews', enableMonitoringReviews)];
                    tags.forEach(tag => p.push(addTag(tag.tag)));
                    Promise.all(p)
                    .then((data) => {
                        console.log(data);
                        let datas = [];
                        data.forEach((d, i) => {
                            if(i === 0 || i === 1){
                                datas.push(d.success);
                            } else {
                                datas.push(d.status === 'success');
                            }
                        })
                        if(datas.reduce((sum, next) => sum && next, true)){
                            showSnackBar('ASIN added.', "info");
                        } else if((datas[0] !== true || datas[1] !== true) && datas.slice(2, datas.length + 1).reduce((sum, next) => sum && next, true)){
                            showSnackBar('ASIN added. Something went wrong while updating Monitoring Flags. Please Update Manually', "warning");
                        } else if(datas[0] === true && datas[1] === true && !datas.slice(2, datas.length + 1).reduce((sum, next) => sum && next, true)){
                            showSnackBar('ASIN added. Something went wrong while updating Tags. Please try again later', "warning");
                        } else {
                            showSnackBar('ASIN added. Something went wrong while updating Monitoring flags and Tags. Please update manually', "warning");
                        }
                        onSuccess(asin);
                        closeProgressDialog();
                    });
                } else {
                    showSnackBar('Something went wrong. Could not find ASIN or could not retrieve data from Amazon', "error");
                    closeProgressDialog();
                }
            })
            .catch(error => {
                console.log(error);
                onError();
                showSnackBar('Something went wrong. Please try again later', "error");
                closeProgressDialog();
            })
        }
    }

    const handleClose = () => {
        setASINError(false);
        setASIN(null);
        onCancel();
    }
    
    return (
        <Dialog fullWidth onClose={handleClose} maxWidth="sm" open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <FormControl fullWidth className={classes.root}>
                <TextField
                    autoFocus
                    label={placeholder || 'ASIN'}
                    type="text"
                    required
                    fullWidth
                    helperText={ asin && (asin.length < 10 ? 'Keep Typing more characters' : (excludeList.includes(asin) ? 'Its already Added' : '' ))}
                    error={asinError}
                    variant="outlined"
                    onChange={(e) => {
                        setASIN(e.target.value);
                        if(e.target.value.length < 10 || excludeList.includes(e.target.value)){
                            setASINError(true);
                        } else {
                            setASINError(false);
                        }
                    }}
                />
            </FormControl>
            {
                internal && (
                    <div>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={enableMonitoringStats}
                                onChange={handleChange}
                                name="enable_monitoring_stats"
                                color="primary"
                            />
                            }
                            label="Enable Monitoring Stats"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={enableMonitoringReviews}
                                onChange={handleChange}
                                name="enable_monitoring_reviews"
                                color="primary"
                            />
                            }
                            label="Enable Monitoring Reviews"
                        />
                    </div>
                )
            }
            {
                internal && (
                    <div>
                        <FormControl fullWidth>
                            <Autocomplete
                                value={tags}
                                multiple
                                onChange={(event, newValue) => {
                                    newValue[newValue.length - 1].label = newValue[newValue.length - 1].tag
                                    setTags(newValue);
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    if (params.inputValue !== '') {
                                        let nv = {
                                            label: `Add "${params.inputValue}"`,
                                            tag: params.inputValue
                                        };
                                        filtered.push(nv);
                                        masterTags.push(nv);
                                    }

                                    return filtered;
                                }}
                                id="free-solo-dialog-demo"
                                options={masterTags}
                                getOptionLabel={(option) => {
                                                    return option.label;
                                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Add Tags" variant="outlined" />
                                )}
                                ChipProps={{
                                    color: "primary",
                                }}
                            />
                        </FormControl>
                    </div>
                )
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
            <Button onClick={addASIN} color="primary">
                Add
            </Button>
        </DialogActions>
        </Dialog>
    );
}

AddASIN.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    internal: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    excludeList: PropTypes.array,
  };