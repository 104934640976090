import Config from '../Config';
import {RestService} from './RestService';
import { format_without_time, format } from '../Utils';
import moment from 'moment';

export const ProductService = {
    listASINs: async function(filter = '', type = 'internal', headers = {}){
        return RestService.get(`${Config.list_asins}filter=${filter}&${type ? `type=${type}`: ''}`, headers)
                .then(response => response.json());
    },
    listProductNames: async function(filter = '', type = 'internal', headers = {}){
        return RestService.get(`${Config.list_product_names}filter=${filter}&${type ? `type=${type}`: ''}`, headers)
                .then(response => response.json());
    },
    executeReportJob: async function(headers = {}){
        return RestService.get(Config.report_execute, headers);
    },
    listASINMetricsCount: async function(filter = '', tag = null, type = 'internal', headers = {}){
        return RestService.get(`${Config.list_asin_metrics}count=true&filter=${filter}${tag ? `&tag=${tag}`: ''}${type ? `&type=${type}`: ''}`, headers)
                .then(response => response.json());
    },
    listASINMetrics: async function(filter = '', tag = null, type = 'internal', sort, direction, from, offset, headers = {}){
        return RestService.get(`${Config.list_asin_metrics}filter=${filter}${tag ? `&tags=${tag}`: ''}${type ? `&type=${type}`: ''}${sort ? `&sort=${sort}`: ''}${direction ? `&direction=${direction}`: ''}${from ? `&from=${from}`: ''}${offset ? `&offset=${offset}`: ''}`, headers)
                .then(response => response.json());
    },
    listMetricsByASIN: async function(asins = null, sales = false, headers = {}){
        return RestService.get(`${Config.list_asin_metrics}asins=${asins}${sales ? `&sales=${sales}` : ''}`, headers)
                .then(response => response.json());
    },
    asinBSRData: async function(asin = null, topCategory = null, nodeCategory = null, startdate = null, enddate = null, headers = {}){
        return RestService.get(`${Config.asin_bsr_data}asin=${asin}${topCategory ? `&top_category=${topCategory}`: ''}${nodeCategory ? `&node_category=${nodeCategory}`: ''}${startdate ? `&startdate=${moment(startdate).format(format_without_time)}`: ''}${enddate ? `&enddate=${moment(enddate).format(format_without_time)}`: ''}`, headers)
                .then(response => response.json());
    },
    addTag: async function(asin = null, tag = null, headers = {}){
        return RestService.post(`${Config.tags_url}asin=${asin}${tag ? `&tag=${tag}`: ''}`, headers)
                .then(response => response.json());
    },
    addTags: async function(asins = null, tags = null, headers = {}){
        return RestService.post(`${Config.tags_multiple_url}asins=${asins}${tags ? `&tags=${tags}`: ''}`, headers)
                .then(response => response.json());
    },
    removeTag: async function(asin = null, tag = null, headers = {}){
        return RestService.delete(`${Config.tags_url}asin=${asin}${tag ? `&tag=${tag}`: ''}`, headers)
        .then(response => {
                    if(response.status === 204){
                        return true;
                    } else {
                        return false;
                    }
        });
    },
    addKeyword: async function(asin = null, keyword = null, headers = {}){
        return RestService.post(`${Config.keyword_url}asin=${asin}${keyword ? `&keyword=${keyword}`: ''}`, headers)
                .then(response => response.json());
    },
    removeKeyword: async function(asin = null, keyword = null, headers = {}){
        return RestService.delete(`${Config.keyword_url}asin=${asin}${keyword ? `&keyword=${keyword}`: ''}`, headers)
        .then(response => {
                    if(response.status === 204){
                        return true;
                    } else {
                        return false;
                    }
        });
    },
    productFieldUpdate: async function(asins = null, field = null, enabled = null, headers = {}){
        return RestService.post(`${Config.product_field_update_url}asins=${asins}${field ? `&flag=${field}`: ''}${enabled ? `&enabled=${enabled}`: ''}`, headers)
                .then(response => response.json());
    },
    productThresholdUpdate: async function(asins = null, field = null, threshold = 0, headers = {}){
        return RestService.post(`${Config.product_threshold_update_url}asins=${asins}${field ? `&flag=${field}`: ''}${threshold ? `&threshold=${threshold}`: ''}`, headers)
                .then(response => response.json());
    },
    addASIN: async function(asin = null, type = false, headers = {}){
        return RestService.get(`${Config.product_matching_url}asin=${asin}&save=true&full=true${type ? `&type=${type}`: ''}`, headers)
        .then(response => response.json());
    },
    getCompetitorsForASIN: async function(asin = null, headers = {}){
        return RestService.get(`${Config.product_competitor_url}asin=${asin}`, headers)
        .then(response => response.json());
    },
    saveCompetitorsForASIN: async function(asin = null, competitor =  null, headers = {}){
        return RestService.post(`${Config.product_competitor_url}asin=${asin}&competitor=${competitor}`, headers)
        .then(response => response.json());
    },
    removeCompetitorsForASIN: async function(asin = null, competitor =  null, headers = {}){
        return RestService.delete(`${Config.product_competitor_url}asin=${asin}&competitor=${competitor}`, headers);
    },
    getTopProducts: async function(count = 5, type = null, startdate = null, enddate = null, headers = {}){
        return RestService.get(`${Config.top_products_url}count=${count}${type ? `&type=${type}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).utc().format(format)}`: ''}`, headers)
        .then(response => response.json());
    },
    getTopRankingProducts: async function(count = 5, type = null, headers = {}){
        return RestService.get(`${Config.top_ranking_products_url}count=${count}${type ? `&type=${type}`: ''}`, headers)
        .then(response => response.json());
    },
    getProductReviewsCount: async function(asin = null, rating = null, verified_purchase = null, filter = '', startdate = null, enddate = null, headers = {}){
        return RestService.get(`${Config.product_reviews_url}count=true${asin ? `&asin=${asin}`: ''}${rating ? `&rating=${rating}`: ''}${verified_purchase ? `&verified_purchase=${verified_purchase}`: ''}${filter ? `&filter=${filter}`: ''}${startdate ? `&startdate=${moment(startdate).format(format_without_time)}`: ''}${enddate ? `&enddate=${moment(enddate).format(format_without_time)}`: ''}`, headers)
        .then(response => response.json());
    },
    getProductReviews: async function(asin = null, rating = null, verified_purchase = null, filter = '', startdate = null, enddate = null, sort, direction, from = 0, offset = 5, headers = {}){
        return RestService.get(`${Config.product_reviews_url}${asin ? `&asin=${asin}`: ''}${rating ? `&rating=${rating}`: ''}${verified_purchase ? `&verified_purchase=${verified_purchase}`: ''}${filter ? `&filter=${filter}`: ''}${startdate ? `&startdate=${moment(startdate).format(format_without_time)}`: ''}${enddate ? `&enddate=${moment(enddate).format(format_without_time)}`: ''}${sort ? `&sort=${sort}`: ''}${direction ? `&direction=${direction}`: ''}${from ? `&from=${from}`: ''}${offset ? `&offset=${offset}`: ''}`, headers)
        .then(response => response.json());
    },
    getProductReviewsExport: async function(asin = null, rating = null, verified_purchase = null, filter = '', startdate = null, enddate = null, sort, direction, from = 0, offset = 5, headers = {}){
        return RestService.get(`${Config.product_reviews_url}export=true${asin ? `&asin=${asin}`: ''}${rating ? `&rating=${rating}`: ''}${verified_purchase ? `&verified_purchase=${verified_purchase}`: ''}${filter ? `&filter=${filter}`: ''}${startdate ? `&startdate=${moment(startdate).format(format_without_time)}`: ''}${enddate ? `&enddate=${moment(enddate).format(format_without_time)}`: ''}${sort ? `&sort=${sort}`: ''}${direction ? `&direction=${direction}`: ''}${from ? `&from=${from}`: ''}${offset ? `&offset=${offset}`: ''}`, headers)
                .then(response => response.blob());
    },
};