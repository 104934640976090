import Config from '../Config';
import {RestService} from './RestService';

export const UserService = {

    authenticate: async function(body, headers = {}){
        return RestService.post(Config.authenticate_url, headers, body)
                .then(response => response.json());
    },
    requestResetPassword: async function(body, headers = {}) {
        return RestService.post(Config.request_reset_password_url, headers, body)
        .then(response => response.json());
    },
    resetPassword: async function(body, headers = {}) {
        return RestService.post(Config.reset_password_url, headers, body)
        .then(response => response.json());
    },
    changePassword: async function(body, headers = {}) {
        return RestService.post(Config.change_password_url, headers, body)
        .then(response => response.json());
    },
    changeMWSCredentials: async function(body, headers = {}) {
        return RestService.post(Config.change_mws_credentials_url, headers, body)
        .then(response => response.json());
    },
    changeSPAPICredentials: async function(body, headers = {}) {
        return RestService.post(Config.change_spapi_credentials_url, headers, body)
        .then(response => response.json());
    },
    updateClientInfo: async function(body, headers = {}) {
        return RestService.post(Config.update_client_info_url, headers, body)
        .then(response => response.json());
    },
};