import Config from '../Config';
import {RestService} from './RestService';

export const MasterService = {
    getOrderStatuses: async function(headers = {}){
        return RestService.get(Config.list_order_statuses, headers)
                .then(response => response.json());
    },
    getTags: async function(headers = {}){
        return RestService.get(Config.list_product_tags, headers)
                .then(response => response.json());
    },
    getKeywords: async function(headers = {}){
        return RestService.get(Config.list_product_keywords, headers)
                .then(response => response.json());
    },
    fetchBackgroundJobs: async function(headers = {}){
        return RestService.get(Config.list_background_jobs, headers)
        .then(response => response.json());
    },
    enableJob: async function(jobId, enabled, headers = {}){
        return RestService.post(`${Config.enable_background_jobs}job_id=${jobId}&enabled=${enabled}`, headers, {})
        .then(response => response.json());
    },
    fetchBackgroundJobsStatus: async function(headers = {}){
        return RestService.get(Config.background_job_status, headers)
        .then(response => response.json());
    },
    getDashboard: async function(headers = {}){
        return RestService.get(Config.get_dashboard_url, headers)
        .then(response => response.json());
    },
    changeVisibility: async function(id, visibility = false, headers = {}){
        return RestService.post(`${Config.change_dashboard_report_visibility_url}id=${id}&visibility=${visibility}`, headers, {})
        .then(response => response.json());
    },
    changeOrder: async function(id, order = false, headers = {}){
        return RestService.post(`${Config.change_dashboard_report_order_url}id=${id}&order=${order}`, headers, {})
        .then(response => response.json());
    },
};