import React, { useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, Button, CardActions, Select, MenuItem, Input, List, ListItem, Typography, ListItemText, Grid, CircularProgress } from "@material-ui/core";
import { HtmlTooltip } from "../Utils";
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PropTypes from "prop-types";
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ProductService } from "../Services/ProductService";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5, 
        minHeight: 190,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 200,
        paddingRight: 0,
        paddingLeft: 0,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8
    },
    griditem: {
        cursor: 'move'
    },
    loader: {
        marginTop: 40
    }
}));

const TopRankingProducts = ({ forwardedRef, ...props }) => {
    const classes = useStyles();
    const count = 5;
    const [loading, setLoading] = useState(false);
    const {report, className} = props;
    // const [isShown, setIsShown] = useState(false);
    const [type, setType] = React.useState('top_category_id');
    const [data, setData] = useState([]);

    // const changeVisibility = e => {
    //     if(report.visible === '1'){
    //         hideReport(report.id);
    //     } else {
    //         showReport(report.id);
    //     }
    // }

    //   { isShown && (
    //                     <BootstrapTooltip title={report.visible === '1' ? 'Click to Hide' : 'Click to Show' }>
    //                         <IconButton size="small" className={classes.icon} onClick={changeVisibility}>
    //                             {report.visible === '1' ? <VisibilityOffIcon /> : <VisibilityIcon /> }
    //                         </IconButton>
    //                     </BootstrapTooltip>
    //                 ) }
    React.useEffect(() => {
        setLoading(true);
        ProductService.getTopRankingProducts(count, type)
        .then(data => {
            setData(data);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        })
    }, [type]);

    return (
        <Grid item xl={4} lg={4} xs={12}>
            <Card 
                ref={forwardedRef}
                className={clsx(classes.root, className)}
                //onMouseEnter={() => setIsShown(true)}
                //onMouseLeave={() => setIsShown(false)}
                >
                <CardHeader 
                    title={report.name}
                    className={classes.griditem}
                    titleTypographyProps={{align: 'left'}}
                    action={
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            className={classes.select}
                            input={<Input  disableUnderline={true}/>}>
                            <MenuItem value={'top_category_id'}>Top Category</MenuItem>
                            <MenuItem value={'category_id'}>Node Category</MenuItem>
                        </Select>
                    }/>
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress/>
                            </div>
                        :
                        <List>
                            {data.map(data => (
                                <ListItem
                                    classes={{ divider: classes.itemDivider }}
                                    divider
                                    dense
                                    component={RouterLink}
                                    key={data.asin}
                                    to={`/product/asin/metrics?asin=${data.asin}`}
                                >
                                    <HtmlTooltip arrow title={
                                        <div>
                                            <Typography variant="caption" component="span" color="inherit">
                                                <span>{data.name}</span><br/>
                                                <b>{'ASIN: '}</b>{data.asin}<br/>
                                            </Typography>
                                        </div>
                                    }>
                                        <ListItemText
                                            primary={data.seller_sku}
                                            primaryTypographyProps={{ variant: 'subtitle2' }}
                                        />
                                    </HtmlTooltip> 
                                    <Typography color="inherit">{data.rank}</Typography>
                                </ListItem>
                            ))}
                        </List>
                    }
                </CardContent>
                <CardActions className={classes.actions}>
                    <Button
                        color="inherit"
                        component={RouterLink}
                        size="small"
                        to={`/product/asin/metrics`}
                        variant="text"
                    >
                        See all
                    <ArrowForwardIcon className={classes.arrowForwardIcon} />
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

TopRankingProducts.propTypes = {
    report: PropTypes.object.isRequired,
};

export default TopRankingProducts;