import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Popover,
  Divider,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItem,
  List
} from '@material-ui/core';
import { useContext } from 'react';
import { DashboardContext } from '../DashboardContext';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    maxWidth: '100%'
  },
  icon: {
    marginTop: 8,
    color: theme.palette.icon,
    cursor: 'pointer',
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.hover
    },
    cursor: 'pointer'
  },
  checkbox: {
    paddingTop: 3,
    paddingBottom: 3
  }
}));

const DashboardPopover = props => {
  const { notifications, anchorEl, ...rest } = props;
  const {reportBlocks, hideReport, showReport} = useContext(DashboardContext);
  const [checked, setChecked] = React.useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    if(props.open){
      setChecked(reportBlocks.filter(r => r.visible === '1').map(r => r.id));
    }
  }, [props.open]);

  const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];
	
		if (currentIndex === -1) {
      newChecked.push(value);
      showReport(value);
		} else {
      newChecked.splice(currentIndex, 1);
      hideReport(value);
		}
		setChecked(newChecked);
  };

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <div className={classes.root}>
        {reportBlocks.length > 0 && (
          <List
            className={clsx(classes.root)}
            disablePadding
            dense
          >
            {reportBlocks.map((report, i) => (
              <ListItem
                className={clsx(classes.listItem)}
                component={'div'}
                divider={i < reportBlocks.length - 1}
                key={report.id}
                onClick={handleToggle(report.id)}
              >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(report.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      className={classes.checkbox}
                      inputProps={{ 'aria-labelledby': report.id }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={report.name}
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                  />
              </ListItem>
            ))}
          </List>
        ) }
        <Divider />
      </div>
    </Popover>
  );
};

DashboardPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DashboardPopover;
