import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../HomePage/home";
import Login from "../LoginPage/Login";
import NotFound from "../NotFound/NotFound";
import GeoAnalyzeByASIN from '../Reports/GeoAnalyzePage/GeoAnalyzeByASIN';
import GeoAnalyzeByLocation from '../Reports/GeoAnalyzePage/GeoAnalyzeByLocation';
import InventoryTabular from '../Reports/Inventory/InventoryTabular';
import InventoryGraphical from '../Reports/Inventory/InventoryGraphical';

// import Settings from "./Account/Settings";
import AccountManagement from "../Account/AccountManagement";
import TopProducts from "../Reports/Performance/TopProducts";
import TopProductsGraphical from "../Reports/Performance/TopProductsGraphical";
import TopCustomers from "../Reports/Performance/TopCustomers";
import OrderReport from "../Reports/Performance/OrderReport";
import SalesReport from "../Reports/Performance/SalesReport";
// import JobSettings from "./Account/JobSettings";
import ASINMetrics from "../Reports/ProductsPage/ASINMetrics";
import ASINPage from "../Reports/ProductsPage/ASINPage";
import ASINCompetitor from "../Reports/ProductsPage/ASINCompetitor";
import SalesVelocity from "../Reports/Performance/SalesVelocity";
import ZonalReport from "../Reports/GeoAnalyzePage/ZonalReport";
import NotificationsPage from "../components/Notification/NotificationsPage";
import SellerFeedback from "../Reports/Customer/SellerFeedback";
import Reset from "../ResetPage/Reset";
import CustomerAdvanceSearch from "../Reports/Customer/CustomerAdvanceSearch";
import ProductReviews from "../Reports/Performance/ProductReviews";
import SalesvsInventory from "../Reports/Performance/SalesvsInventory";

export default () =>
  <Switch>
    <Route path={`/login`} exact component={Login} />
    <Route path={`/reset/password`} exact component={Reset} />
    <Route path={`/`} exact component={Home} />
    <Route path={`/account/management/:tab`} exact component={AccountManagement} />
    {/* <Route path={`/account/settings`} exact component={Settings} />
    <Route path={`/account/settings/jobs`} exact component={JobSettings} /> */}
   
   
    <Route path={`/product/asin/metrics`} exact component={ASINMetrics} />
    <Route path={`/product/asin`} exact component={ASINPage} />
    <Route path={`/product/track/competitor`} exact component={ASINCompetitor} />

    <Route path={`/geo/analyze/asin`} exact component={GeoAnalyzeByASIN} />
    <Route path={`/geo/analyze/location`} exact component={GeoAnalyzeByLocation} />
    <Route path={`/geo/analyze/zonal`} exact component={ZonalReport} />
    <Route path={`/performance/product`} exact component={TopProducts} />
    <Route path={`/performance/product/graphical`} exact component={TopProductsGraphical} />
    <Route path={`/performance/customer`} exact component={TopCustomers} />
    <Route path={`/performance/customer/advance/search`} exact component={CustomerAdvanceSearch} />
    <Route path={`/performance/orders`} exact component={OrderReport} />
    <Route path={`/performance/sales`} exact component={SalesReport} />
    <Route path={`/performance/sales/velocity`} exact component={SalesVelocity} />
    <Route path={`/seller/feedback`} exact component={SellerFeedback} />
    <Route path={`/product/reviews`} exact component={ProductReviews} />
    <Route path={`/sales/vs/inventory`} exact component={SalesvsInventory} />

    <Route path={`/inventory/report`} exact component={InventoryTabular} />
    <Route path={`/inventory/graphical`} exact component={InventoryGraphical} />
    
    <Route path={`/notifications`} exact component={NotificationsPage} />

    {/* Not found */}
    <Route component={NotFound} />
  </Switch>;