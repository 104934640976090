import React, { useContext } from "react";
import "../css/login.css";
import { UserService } from "../Services/UserService";
import { Divider, Grid, Hidden, makeStyles } from "@material-ui/core";
import { FormControl, Button, InputLabel, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import clsx from 'clsx';
import moment from "moment";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from "react-router-dom";
import { AppContext } from "../AppContextProvider";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 300,
  },
}));

export default function Login(props) {

  const classes = useStyles();
  const history = useHistory();
  const { setTitle, showSnackBar, showProgressDialog, closeProgressDialog, setAuthenticating, setLinearLoading, updateUser, userHasAuthenticated,
    // setHasMWSCredentials, 
    setHasSPAPICredentials, showConfirmDialog, closeConfirmDialog } = useContext(AppContext);

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  React.useEffect(() => {
    setTitle('');
    const user = localStorage.getItem('asinwatchdog.user') !== null ? JSON.stringify(localStorage.getItem('asinwatchdog.user')) : null;
    const session = localStorage.getItem('asinwatchdog.session');
    if (user !== null && session !== null) {
      history.push("/");
    } else {
      setAuthenticating(false);
    }
  }, [true])

  // const validateForm = () => {
  //   return email.length > 5 && password.length > 3;
  // }

  const handleChange = event => {
    if (event.target.name === 'password') {
      setPassword(event.target.value)
    } else if (event.target.name === 'email') {
      setEmail(event.target.value)
    }
  }

  const requestResetPassword = event => {
    showConfirmDialog(undefined, 'Reset Password', (email) => {
      showProgressDialog();
      UserService.requestResetPassword({ email: email })
        .then(data => {
          closeProgressDialog();
          closeConfirmDialog();
          if (data.success) {
            showConfirmDialog(data.message, undefined);
          } else {
            showConfirmDialog(data.message, undefined);
          }
        })
        .catch(error => {
          closeProgressDialog();
          closeConfirmDialog();
          showConfirmDialog('something went wrong. Please try again later', undefined);
        })
    }, 'Cancel', 'Reset', true, 'Please enter Account Email address');
  }

  const handleSubmit = event => {
    event.preventDefault();
    try {
      setLinearLoading(true);
      let body = { email: email, password: password };
      UserService.authenticate(body)
        .then((data) => {
          if (data.id && data.status === '1' && data.licence_expiry_date && moment(data.licence_expiry_date).isAfter(moment())) {
            data.password = password;
            updateUser(data);
            console.log("Logged in");
            console.log(data);
            localStorage.setItem('asinwatchdog.user', JSON.stringify(data));
            localStorage.setItem('asinwatchdog.session', data['sysbrew-x-token']);
            localStorage.setItem('asinwatchdog.mapping', data['db_mapping']);
            userHasAuthenticated(true);
            // let hasMWSCredentials = data.seller_id !== null && data.aws_access_key_id !== null && data.secret_key !== null && data.market_place_id !== null;
            // setHasMWSCredentials(hasMWSCredentials);
            const hasSPAPICredentials = data.lwa_client_id !== null && data.lwa_client_secret !== null
              && data.lwa_refresh_token !== null && data.aws_access_key !== null
              && data.aws_secret_key !== null && data.market_place_id !== null;
            setHasSPAPICredentials(hasSPAPICredentials);
            // if(hasMWSCredentials){
            //   hasProductsFetched();
            history.push("/");
            // } else {
            //   requestToUpdateMWSCredentials();
            // }
          } else if (data.licence_expiry_date && moment(data.licence_expiry_date).isBefore(moment())) {
            showSnackBar("Your License has expired. Please Contact Admin", 'error');
          } else if (data.message) {
            showSnackBar(data.message, 'error');
          }
          setLinearLoading(false);
        })
        .catch((error) => {
          showSnackBar("Failed to Login. invalid credentials", 'error');
          setLinearLoading(false);
        });
    } catch (e) {
      alert(e.message);
      setLinearLoading(false);
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(prevState => (!prevState.showPassword));
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div className="Login" style={{ height: '100vh' }}>
      <Grid container direction="row" justify="center" alignItems="center" style={{ height: '50vh' }}>
        <Grid item xs={12} lg={6}>
          <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Logo" height={150} />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} lg={1} style={{ height: '50vh' }}>
            <Divider orientation="vertical" variant="middle" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={5}>
          <form onSubmit={handleSubmit}>
            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
              <InputLabel htmlFor="standard-adornment-email">Email</InputLabel>
              <OutlinedInput
                id="standard-adornment-email"
                type={'email'}
                value={email}
                name="email"
                onChange={handleChange}
                labelWidth={40}
              />
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
              <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                name="password"
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <Button variant="contained" color="primary" onClick={handleSubmit} size="large">
                Login
              </Button>
            </FormControl>
          </form>
          <Button variant="text" color="primary" onClick={requestResetPassword}>Forgot Password?</Button>
        </Grid>
      </Grid>
    </div>
  );
}