import Config from '../Config';
import {RestService} from './RestService';
import { format } from '../Utils';
import moment from 'moment';

export const InventoryService = {
    fetchInventoryMaxDate: async function(headers = {}){
        return RestService.get(Config.fetch_inventory_max_date, headers)
        .then(response => response.json());
    },
    fetchInventoryCount: async function(asin = null, startdate = null, enddate = null, fc = null, disposition = null, headers = {}){
        return RestService.get(`${Config.fetch_inventory_txn}${asin ? `asin=${asin}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).utc().format(format)}`: ''}${fc ? `&fcs=${fc}`: ''}${disposition ? `&dispositions=${disposition}`: ''}&count=true`, headers)
                .then(response => response.json());
    },
    fetchInventoryTxns: async function(asin = null, startdate = null, enddate = null, fc = null, disposition = null, sort, direction, from, offset, headers = {}){
        return RestService.get(`${Config.fetch_inventory_txn}${asin ? `asin=${asin}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).utc().format(format)}`: ''}${fc ? `&fcs=${fc}`: ''}${disposition ? `&dispositions=${disposition}`: ''}${sort ? `&sort=${sort}`: ''}${direction ? `&direction=${direction}`: ''}${from ? `&from=${from}`: ''}${offset ? `&offset=${offset}`: ''}`, headers)
                .then(response => response.json());
    },
    fetchInventoryGraphicalData: async function(asin = null, startdate = null, enddate = null, fc = null, disposition = null, sort, direction, version = 'v1', headers = {}){
        return RestService.get(`${Config.fetch_inventory_graph_data}${asin ? `asin=${asin}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).utc().format(format)}`: ''}${fc ? `&fcs=${fc}`: ''}${disposition ? `&dispositions=${disposition}`: ''}${sort ? `&sort=${sort}`: ''}${direction ? `&direction=${direction}`: ''}${version ? `&version=${version}`: ''}`, headers)
                .then(response => response.json());
    },
    getOutOfStockList: async function(headers = {}){
        return RestService.get(Config.fetch_inventory_out_of_stock, headers)
                .then(response => response.json());
    },
    getFCStockList: async function(type = null, count = 5, headers = {}){
        return RestService.get(`${Config.fetch_inventory_fc_stock}${count ? `count=${count}`: ''}${type ? `&type=${type}`: ''}`, headers)
                .then(response => response.json());
    },
};