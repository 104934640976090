import React, { useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, Button, CardActions, Select, MenuItem, Input, CircularProgress, Grid, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { DonutChart } from '../components/Chart';
import moment from "moment";
import {format, colorGenerator, formatQty, formatNumber} from '../Utils';
import { ReportService } from "../Services/ReportService";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5, 
        minHeight: 190,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 200,
        marginTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8,
        marginLeft: 8
    },
    griditem: {
        cursor: 'move'
    },
    loader: {
        marginTop: 40
    },
    chartContainer: {
        padding: theme.spacing(3)
    },
    chart: {
        height: 170
    },
    nodata: {
        position: 'relative',
        top: 40
    }
}));

const TopRankingCities = ({ forwardedRef, ...props }) => {
    const classes = useStyles();
    const history = useHistory();
    const count = 5;
    const {report, className} = props;
    // const [isShown, setIsShown] = useState(false);
    const [data, setData] = useState([]);
    const [type, setType] = React.useState('units');
    const [range, setRange] = React.useState('today');
    const [loading, setLoading] = useState(false);
    const colors = colorGenerator(count);
    const rangeOptions = [
        {
            label: 'Today',
            value: 'today',
            startdate: moment().startOf('d'),
            enddate: moment().endOf('d')
        },
        {
            label: 'Last 7 Days',
            value: 'last_7',
            startdate: moment().startOf('d').add(-7, 'days'),
            enddate: moment().endOf('d')
        },
        {
            label: 'Last 30 Days',
            value: 'last_30',
            startdate: moment().startOf('d').add(-30, 'days'),
            enddate: moment().endOf('d')
        },
        {
            label: 'This Year',
            value: 'this_year',
            startdate: moment().startOf('year').startOf('day'),
            enddate: moment().endOf('d')
        }
    ];

    React.useEffect(() => {
        let selectedRange = rangeOptions.filter(r => r.value === range)[0];
        setLoading(true);
        ReportService.getTopLocations(count, 'city', type, selectedRange.startdate, selectedRange.enddate)
        .then(data => {
            let chartData = [];
            data.forEach((d, i) => {
                chartData.push({
                    id: i,
                    label: d.location,
                    value: type === 'units' ? d.total : d.total,
                    state: d.state,
                    color: colors[i]
                });
            })
            setData(chartData);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        })
    }, [range, type]);

    const onClick = (index) => {
        let d = data[index];
        history.push(`/geo/analyze/location?state=${d.state}&city=${d.label}&startdate=${rangeOptions.filter(r => r.value === range)[0].startdate.utc().format(format)}&enddate=${rangeOptions.filter(r => r.value === range)[0].enddate.utc().format(format)}`);
    }

    // const changeVisibility = e => {
    //     if(report.visible === '1'){
    //         hideReport(report.id);
    //     } else {
    //         showReport(report.id);
    //     }
    // }

        /**
        * isShown && (
                        <BootstrapTooltip title={report.visible === '1' ? 'Click to Hide' : 'Click to Show' }>
                            <IconButton size="small" className={classes.icon} onClick={changeVisibility}>
                                {report.visible === '1' ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                            </IconButton>
                        </BootstrapTooltip>
                    ) 
        */

    return (
        <Grid item xl={4} lg={4} xs={12}>
            <Card 
                ref={forwardedRef}
                className={clsx(classes.root, className)}
                //onMouseEnter={() => setIsShown(true)}
                //onMouseLeave={() => setIsShown(false)}
                >
                <CardHeader 
                    title={report.name}
                    className={classes.griditem}
                    titleTypographyProps={{align: 'left'}}
                    action={
                        <>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                className={classes.select}
                                input={<Input  disableUnderline={true}/>}>
                                    <MenuItem value={'units'}>By Units</MenuItem>
                                    <MenuItem value={'sales'}>By Sales</MenuItem>
                            </Select>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select-2"
                                value={range}
                                onChange={(e) => setRange(e.target.value)}
                                className={classes.select}
                                input={<Input  disableUnderline={true}/>}>
                                    {rangeOptions.map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
                            </Select>
                        </>
                    }/>
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress />
                            </div>
                        :
                        <div className={classes.chartContainer}>
                            {
                                data.length === 0 ? 
                                    (
                                        <div className={classes.nodata}>
                                            No Data
                                        </div>
                                    )
                                :
                                    (
                                        <div>
                                            <DonutChart
                                                className={classes.chart}
                                                data={data}
                                                type={type}
                                                onClick={onClick}
                                            />
                                            <List>
                                                {data.map((data, i) => (
                                                    <ListItem
                                                        classes={{ divider: classes.itemDivider }}
                                                        divider
                                                        dense
                                                        key={data.id}
                                                        style={{cursor: 'pointer'}}
                                                        onClick={(e) => {onClick(i)}}
                                                    >
                                                        <ListItemText
                                                            primary={data.label}
                                                            primaryTypographyProps={{ color: 'inherit', variant: 'subtitle2' }}
                                                            secondary={data.state}
                                                        />
                                                        <Typography color="inherit">{type === 'units' ? formatQty(data.value) : formatNumber(data.value)}</Typography>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    )
                            }
                        </div>
                    }
                </CardContent>
                <CardActions className={classes.actions}>
                    <Button
                        color="inherit"
                        component={RouterLink}
                        size="small"
                        to={`/geo/analyze/location?startdate=${rangeOptions.filter(r => r.value === range)[0].startdate.utc().format(format)}&enddate=${rangeOptions.filter(r => r.value === range)[0].enddate.utc().format(format)}`}
                        variant="text"
                    >
                        See all
                    <ArrowForwardIcon className={classes.arrowForwardIcon} />
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

TopRankingCities.propTypes = {
    report: PropTypes.object.isRequired,
};

export default TopRankingCities;