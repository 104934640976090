import Config from '../Config';
import {RestService} from './RestService';

export const LocationService = {
    stateMapping: async function(headers = {}){
        return RestService.get(Config.state_mapping, headers)
                .then(response => response.json());
    },
    regionMapping: async function(headers = {}){
        return RestService.get(Config.region_mapping, headers)
                .then(response => response.json());
    },
};