import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Divider, useTheme } from "@material-ui/core";
import {red, blue} from '@material-ui/core/colors';
import { ProductService } from "../../Services/ProductService";
import moment from "moment";
import ItemSelection from '../../components/ItemSelection';
// import Timeline from "../../components/Timeline";
import { Line } from "react-chartjs-2";
import { getTimeInIST, format_display_fancy_notime, format_without_time, formatQty } from "../../Utils";
import { InventoryService } from "../../Services/InventoryService";
import DateRangePickerCustom from "../../components/DateRangePickerCustom";
import { useContext } from "react";
import { AppContext } from "../../AppContextProvider";
import { ReportService } from "../../Services/ReportService";


const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    sectionGraph: {
        // width: '60vw',
        height: '60vh',
        marginTop: theme.spacing(3),
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function SalesvsInventory(props){
    const {setTitle, setTourSteps, showProgressDialog, closeProgressDialog} = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();
    const [asin, setASIN] = React.useState(null);
    const [asinList, setASINList] = React.useState([]);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').add(-30, 'd'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [generate, setGenerate] = React.useState(false);
    const order = 'asc';
    const orderBy = 'snapshot_date';
    const [lineData, setLineData] = React.useState({});

    const lineOptions = {
        responsive : true, 
        maintainAspectRatio: false,
        layout: {
            padding: 32
        },
        elements: {
            arc: {
                borderWidth: 5,
                borderColor: theme.palette.white,
                backgroundColor: theme.palette.white
            },
        },
        tooltips: {
            position: 'custom',
            // Disable the on-canvas tooltip
            enabled: false,
            intersect: false,
            caretSize: 10,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.black,
            titleFontColor: theme.palette.white,
            bodyFontColor: theme.palette.white,
            footerFontColor: theme.palette.white,
            custom: function(tooltipModel, data) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<thead>';

                    titleLines.forEach(function(title) {
                        innerHtml += '<tr><th>' + getTimeInIST(title).format(format_display_fancy_notime) + '</th></tr>';
                    });
                    innerHtml += '</thead><tbody>';

                    // let dataIndex = tooltipModel.dataPoints[0].datasetIndex;
                    let index = tooltipModel.dataPoints[0].index;
                    // let fcs = lineData.datasets[dataIndex].fcData[index];


                    bodyLines.forEach(function(body, i) {
                        var colors = tooltipModel.labelColors[i];
                        var style = 'background:' + colors.backgroundColor;
                        style += '; border-color:' + colors.borderColor;
                        style += '; border-width: 2px';
                        var span = '<span style="' + style + '"></span>';
                        // innerHtml += '<tr><td colspan="2">' + span + '<b>' + body + '</b></td></tr>';
                        // innerHtml += '<tr><td colspan="2">' + span + '<hr style="margin-top: 0px;margin-bottom: 0px;border: 1px solid black;"/></td></tr>';
                        lineData.datasets.forEach(d => {
                            let o = d.data[index];
                            // console.log(index, o);
                            if(o){
                                innerHtml += '<tr><td colspan="2" style="color:'+d.borderColor+'">' + span + d.label + '</td><td align="right"><b>' + span + formatQty(o) + '</b> Units</td></tr>';
                            }
                        })
                    });
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.backgroundColor = theme.palette.black;
                tooltipEl.style.color = theme.palette.white;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            week : "ll",
                            month: "MMM YYYY"
                        },
                    }
                }
            ],
            yAxes: [{
                ticks: {
                    beginAtZero:true,
                    stepSize: 10
                }
                //ticks: {
                //    callback: function(label, index, labels) {
                //        return label
                //    }
                //},
            }]
        }
    };

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="selectasin"]',
                content: `Select ASIN to Compare Stats`,
                style: {
                    backgroundColor: '#fff',
                },
            },
        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    React.useEffect(() => {
        setTitle("Sales vs Inventory Report");

        ProductService.listProductNames()
        .then(data => {
            let list = data.map(d => ({...d, label : `[${d.asin}][${d.seller_sku}] ${d.name.substring(0, 120)}${d.name.length > 120 ? '...' : ''}`}));
            setASINList(list);
            if(asin){
                let a = list.filter(l => l.asin === asin);
                setASIN(a.length > 0 ? a[0] : null);
            }
        })
        .catch(error => console.log(error));

    }, [true]);

    React.useEffect(() => {
        if(generate && asin && asin.asin){
            showProgressDialog();
            Promise.all([
                InventoryService.fetchInventoryGraphicalData(asin.asin, startdate, enddate, undefined, undefined, orderBy, order),
                ReportService.fetchASINDailySales(asin.asin, startdate, enddate)
            ])
            .then(datas => {
                // console.log(datas)
                if(datas[0] && datas[1]){
                    let inventoryData = datas[0]
                    let ordersData = datas[1]
                    let labels = [], count = [], fcData = [], sales = [];
                    inventoryData.forEach(element => {
                        labels.push(element.snapshot_date);
                        count.push(element.quantity);
                        let s = ordersData.find(o => o.orderdate === getTimeInIST(element.snapshot_date).format(format_without_time));
                        sales.push(s ? s.TotalUnits : 0);
                        fcData.push(element.fc_list);
                    });
                    let ld = {
                        labels: labels,
                        datasets: [
                            {
                                type: 'line',
                                label: "Inventory",
                                data: count,
                                fcData: fcData,
                                backgroundColor: '#FFF',
                                borderColor: '#F85F73',
                                borderWidth: 3,
                                fill: false,
                                pointRadius: 5,
                            },
                            {
                                type: 'bar',
                                label: 'Sales',
                                backgroundColor: 'rgb(75, 192, 192)',
                                data: sales,
                                borderColor: 'rgb(75, 192, 192)',
                                borderWidth: 2,
                            }
                        ]
                    };
                    setLineData(ld);
                }
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                closeProgressDialog();
            });
            setGenerate(false);
        }
    }, [generate, asin, order, orderBy]);

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={2} justify="center">
                    <Grid item lg={11} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item xs={12} lg={3} data-tour="selectasin">
                                    <ItemSelection clearable={true} required label='Select ASIN' value={asin} optionLabel='label' options={asinList} selected={setASIN}/>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <DateRangePickerCustom startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Button variant="contained" disabled={!asin} className={classes.button} color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                        Generate Report
                                    </Button>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 5, marginTop: 5}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    {
                                        lineData.labels && lineData.labels.length > 0 &&
                                        <div className={classes.sectionGraph}>
                                            <Line options={lineOptions} data={lineData}/>
                                        </div> 
                                    }
                                    {
                                        lineData.labels && lineData.labels.length === 0 && 
                                            <Typography color="error" variant="subtitle2">No Data</Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );

};