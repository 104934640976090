import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Divider, Table, TableBody, TableRow, TableCell, useTheme, Tooltip, IconButton, Icon } from "@material-ui/core";
import { ReportService } from "../../Services/ReportService";
import {red, blue} from '@material-ui/core/colors';
import { ProductService } from "../../Services/ProductService";
import moment from "moment";
import ItemSelection from '../../components/ItemSelection';
import EnhancedTableHead from "../../components/EnhancedTableHead";
import {formatQty, toInt, HtmlTooltip, format, getTimeInIST } from "../../Utils";
import XLSX from 'xlsx';
import { AppContext } from "../../AppContextProvider";
import { MasterService } from "../../Services/MasterService";
import { LocationService } from "../../Services/LocationService";
import { Skeleton } from "@material-ui/lab";
import TimelineMonthYear from "../../components/TimelineMonthYear";
import { StickyColumn } from "../../components/StickyColumn";
import { Link as RouterLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    icon: {
        padding: '12px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
        marginTop: theme.spacing(1)
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
        // height: theme.spacing(7),
        
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
       
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export default function ZonalReport(props) {

    const {setTitle, setTourSteps, setLinearLoading, showProgressDialog, closeProgressDialog} = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();

    const [selectedMapping, setSelectedMapping] = React.useState([]);
    const [mapping, setMapping] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [asins, setASINs] = React.useState([]);
    const [asinList, setASINList] = React.useState([]);
    const [loadingData, setLoadingData] = React.useState([]);
    const order = 'desc';
    const orderBy = 'Title';
    const [generate, setGenerate] = React.useState(false);
    const [selectedTag, setSelectedTag] = React.useState(null);
    const [tags, setTags] = React.useState(null);
    const [minDate, setMinDate] = React.useState(null);
    const [startdate, setStartDate] = React.useState(moment().startOf('month'));
    const [enddate, setEndDate] = React.useState(moment().endOf('month'));
    const [zoneMap, setZoneMap] = React.useState(new Map());
    const [canGenerate , setCanGenerate] = React.useState(false);
    const updateZoneMap = (k,v) => {
        setZoneMap(zoneMap.set(k,v));
        // setZoneMap(new Map(zonalStateMap.set(k,v)));
    }

    const [stateCodeMap, setStateCodeMap] = React.useState(new Map());
    const updateStateCodeMap = (k,v) => {
        setStateCodeMap(stateCodeMap.set(k,v));
    }
    const [refreshColumn, setRefreshColumn] = React.useState(false);
    const [zonalColumns, setZonalColumns] = React.useState([]);
    const [finalColumns, setFinalColumns] = React.useState([]);


    const columnsStart = [
        { id: 'sku', align: 'left', numeric: false, disablePadding: true, label: 'SKU', sortable: false, tooltip: 'SKU' },
    ];

    const columnsEnd = [
        { id: 'total', align: 'center', numeric: false, disablePadding: true, label: 'Grand Total', sortable: false, tooltip: 'Grand Total' },
    ];

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="selectzone"]',
                content: `Filter by Zone`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selecttag]',
                content: `Filter by Tag`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selecttimeline]',
                content: `Filter by Date Range`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            
        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    React.useEffect(() => {
        setTitle("Zonal ASIN Sales");
        fetchTags();
        // let asin = null;
        // if(props.location.search){
        //     let qString = queryString.parse(props.location.search);
        //     if(qString.asin){
        //         asin = qString.asin;
        //         setLinearLoading(true);    
        //         setGenerate(true);
        //     }
        //     // setASIN(props.location.query.asin);
        // }

        ReportService.fetchSalesMinDate()
        .then(data => {
            if(data.date){
                setMinDate(getTimeInIST(data.date).startOf('day').startOf('month'));
            }
        })
        .catch(error => console.log(error));

        ProductService.listASINs()
        .then(data => {
            let list = data.map(d => ({...d, label : `[${d.asin}][${d.seller_sku}] ${d.name.substring(0, 120)}${d.name.length > 120 ? '...' : ''}`}));
            setASINList(list);
        })
        .catch(error => console.log(error));

        LocationService.regionMapping()
        .then(data => {
            let mapping = [];
            if(data.length > 0){
                data.forEach(d => {
                    mapping.push({...d, label: `${d.region_label} - ${d.zone} Zone`, type: 'Region'})
                })
            }
            // let all = {region : '-1', label: 'Region - All'};
            setMapping(mapping);
            setSelectedMapping(mapping);

            LocationService.stateMapping()
            .then(data => {
                mapping.forEach(z => {
                    data = data.filter((state, index, self) =>
                        index === self.findIndex((t) => (
                            t.state_code === state.state_code && t.region === state.region
                        ))
                    );

                    let m = []
                    if(z.region === '4'){
                        m = data
                            .filter(d => d.region === '4' && d.state_code !== 'UP')
                            .map(d => ({state: d.state, state_code: d.state_code, region: d.region, zone: d.zone}));
                    } else {
                        m = data
                            .filter(d => d.region === z.region)
                            .map(d => ({state: d.state, state_code: d.state_code, region: d.region, zone: d.zone}));
                    }
                    updateZoneMap(z.region, m);
                    data.forEach(d => {
                        updateStateCodeMap(d.state, d.state_code);
                    })
                    setCanGenerate(true);
                });
            })
            .catch((error) => console.log(error));
            // setGenerate(true);
        })
        .catch(error => console.log(error));

    }, [true]);

    const fetchTags = () => {
        MasterService.getTags()
        .then(data => {
            // console.log(data);
            let allTags = {label: 'All Tags', tag: '-1'};
            setTags([allTags, ...data]);
            setSelectedTag(allTags);
        })
        .catch(error => console.log(error));
    }

    React.useEffect(() => {
        if(refreshColumn){
            setRefreshColumn(false);
            let zc = [];
            selectedMapping.forEach(selectedRegions => {
                // console.log(selectedRegions, zoneMap);
                let states = zoneMap.get(selectedRegions.region);
                states.forEach(state => {
                    zc.push({ id: state.state_code+state.zone, align: 'center', numeric: false, disablePadding: true, label: state.state_code, sortable: false, type: 1, tooltip: state.state })
                })
                zc.push({ id: selectedRegions.region, align: 'center', numeric: false, disablePadding: true, label: `${selectedRegions.zone} Total`, sortable: false, type: 2, tooltip: `${selectedRegions.region_label} - ${selectedRegions.zone} Total`});
            });
            setZonalColumns(zc);
            setFinalColumns([...columnsStart, ...zc, ...columnsEnd]);
        }
    }, [refreshColumn, selectedMapping, zoneMap]);

    React.useEffect(() => {
        if(generate && selectedTag && selectedMapping.length > 0){
            setRefreshColumn(true);
            setData([]);
            setASINs([]);
            let tag = selectedTag.tag === '-1' ? null : selectedTag.tag;
            let zones = selectedMapping.map(s => s.region);
            generateReport(tag, zones);
            setGenerate(false);
        }
    }, [generate, selectedMapping, selectedTag]);

    const generateReport = (tag, zones) => {
        setLinearLoading(true);
        showProgressDialog(`Loading Stats`);
        setLoadingData(Array.from(Array(10), (_, i) => (<Skeleton key={i} width={'98.3vw'} height={100} />)));
        
        ReportService.fetchZonalReport(zones.join(','), tag, startdate, enddate)
        .then(d => {
            let data = d.map(d => {
                let state_code = stateCodeMap.get(d.ShippingAddressStateOrRegion);
                return ({...d, state_code: state_code});
            });
            let asins = new Set(data.map(d => d.ASIN));
            setASINs([...asins]);
            setData(data);
            setLoadingData([]);
            closeProgressDialog();
            setLinearLoading(false);
        })
        .catch(error => {
            console.log(error);
        });
        // fetchCityWiseData(null);
    };

    const exportReport = e => {
        setLinearLoading(true);
        let row = [];
        let filename = 'Zonal Sales Report';
        row.push(['ASIN', 'Product Title', ...finalColumns.map(c => c.tooltip)]);
        asins.forEach((element) => {
            let asin = asinList.filter(d => d.asin === element)[0];
            let asinRows = data.filter(d => d.ASIN === element);
            let gTotal = asinRows.reduce(function(accumulator, current) {
                return accumulator + toInt(current.TotalUnits || 0);
            }, 0);

            let r = [asin.asin, asin.name, asin.seller_sku];

            zonalColumns.forEach((col, i) => {
                let total = 0;
                if(col.type === 1){
                    //State
                    let r = asinRows.filter(a => a.state_code === col.label);
                    total = r.length > 0 ? r[0].TotalUnits : '';
                } else {
                    // Summary per zone
                    let zoneStates = zoneMap.get(col.id).map(d => d.state_code);
                    total= asinRows.filter(a => zoneStates.includes(a.state_code)).reduce(function(accumulator, current) {
                        return accumulator + toInt(current.TotalUnits || 0);
                    }, 0);
                }

                r.push(parseFloat(total || 0));
            });

            r.push(parseFloat(gTotal || 0));

            row.push(r);
        });
        const wb = XLSX.utils.book_new();
        const wsAll = XLSX.utils.aoa_to_sheet(row);
        XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
        XLSX.writeFile(wb, filename + ".xlsx");
        setLinearLoading(false);
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" justify="center" spacing={2}>
                    <Grid item xl={12} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="flex-start">
                                <Grid item xs={12} lg={4} data-tour="selectzone">
                                    <ItemSelection 
                                        clearable={true} 
                                        required 
                                        multiple
                                        label='Search by Zone' 
                                        value={selectedMapping} 
                                        optionLabel="label" 
                                        options={mapping} 
                                        limitTags={3}
                                        selected={setSelectedMapping}/>
                                </Grid>
                                <Grid item xs={12} lg={2} data-tour="selecttag">
                                    <ItemSelection 
                                        label='Select Tags' 
                                        clearable={true}
                                        value={selectedTag} 
                                        optionLabel='label' 
                                        options={tags}
                                        selected={(tag) => {
                                            setSelectedTag(tag);
                                        }}/>
                                </Grid>
                                <Grid item xs={12} lg={3} data-tour="selecttimeline">
                                    <TimelineMonthYear 
                                        minDate={minDate}
                                        startdate={startdate} 
                                        setStartDate={setStartDate} 
                                        enddate={enddate} 
                                        setEndDate={setEndDate}/>
                                </Grid>
                                <Grid item xs={8} lg={2}>
                                    <Button variant="contained" disabled={!canGenerate} className={classes.button} color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                        Generate Report
                                    </Button>
                                </Grid>
                                <Grid item xs={3} lg={1}>
                                    <Tooltip title="Export">
                                        <span>
                                            <IconButton className={classes.icon} aria-label="Export" disabled={data.length === 0} onClick={(e) => exportReport()}>
                                                <Icon>save_alt</Icon>
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 10, marginTop: 10}}/>
                                </Grid>
                                {/* { data.length === 0 &&
                                    <Grid item xs={12} lg={12}>
                                        <Typography align="center" variant="subtitle2" gutterBottom>No Data Available</Typography>
                                    </Grid>
                                } */}
                                <Grid container alignItems={'flex-start'} item xs={12} lg={12}>
                                    <Grid item xs={12} xl={12} lg={12}>
                                        <div style={{height: '65vh', width: '98.3vw', overflow: 'auto', display: 'block'}}>
                                            <Table className={classes.table} stickyHeader>
                                                <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={() => {}}
                                                    fixedColumns={[0]}
                                                    rows={finalColumns} />
                                                <TableBody>
                                                    {asins.map((element, index) => {
                                                            let a = asinList.filter(as => as.asin === element)[0];
                                                            let asinRows = data.filter(d => d.ASIN === element);
                                                            let gTotal = asinRows.reduce(function(accumulator, current) {
                                                                return accumulator + toInt(current.TotalUnits || 0);
                                                            }, 0);
                                                            let stateCol = [];

                                                            zonalColumns.forEach((col, i) => {
                                                                let total = 0;
                                                                if(col.type === 1){
                                                                    //State
                                                                    let r = asinRows.filter(a => a.state_code === col.label);
                                                                    total = r.length > 0 ? r[0].TotalUnits : '';
                                                                } else {
                                                                    // Summary per zone
                                                                    let zoneStates = zoneMap.get(col.id).map(d => d.state_code);
                                                                    total= asinRows.filter(a => zoneStates.includes(a.state_code)).reduce(function(accumulator, current) {
                                                                        return accumulator + toInt(current.TotalUnits || 0);
                                                                    }, 0);
                                                                }
                                                                stateCol.push(
                                                                    <TableCell key={element+i} align="center" padding='default' style={{paddingRight: 5}}>
                                                                        <HtmlTooltip arrow title={
                                                                            <div>
                                                                                <Typography variant="caption" component="span" color="inherit">
                                                                                    {`${a.seller_sku} - ${col.tooltip}`}
                                                                                </Typography>
                                                                            </div>
                                                                        }>
                                                                            <div>{total > 0 ? formatQty(total) : '-'}</div>
                                                                        </HtmlTooltip>
                                                                    </TableCell>
                                                                );
                                                            })

                                                            return (
                                                                <TableRow hover key={index} className={classes.row}>
                                                                    <StickyColumn scope="row" align="left" padding='default' style={{paddingLeft: 16, minWidth: 280, backgroundColor: index % 2 !== 0? theme.palette.white: theme.palette.background.default}}>
                                                                        <HtmlTooltip arrow title={
                                                                            <div>
                                                                                <Typography variant="caption" component="span" color="inherit">
                                                                                    <span>{a.name}</span><br/>
                                                                                    <b>{'ASIN: '}</b>{element}<br/>
                                                                                    {a.internal_status === '0' && <span style={{color: red[500]}}>Deactivated on the platform</span>}
                                                                                </Typography>
                                                                            </div>
                                                                        }>
                                                                        {
                                                                            a.internal_status === '1' ?
                                                                                <RouterLink style={{cursor: 'pointer', textDecoration: 'none', color: theme.palette.black}} to={`/geo/analyze/asin?asin=${element}&startdate=${startdate.format(format)}&enddate=${enddate.format(format)}`}>
                                                                                        {a.seller_sku}
                                                                                </RouterLink>   
                                                                            : 
                                                                                <div>a.seller_sku</div>
                                                                        }
                                                                        </HtmlTooltip>
                                                                    </StickyColumn>
                                                                    {stateCol}
                                                                    <TableCell align="center" padding='default' style={{paddingRight: 5}}>
                                                                        {formatQty(gTotal || 0)}
                                                                    </TableCell>
                                                            </TableRow>
                                                            )
                                                        }
                                                    )}
                                                </TableBody>
                                            </Table>
                                            {loadingData}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
 