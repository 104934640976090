import React, { createContext, useState } from "react";
import { MasterService } from "./Services/MasterService";

const DashboardContext = createContext(null);

function move(array, oldIndex, newIndex) {
    if (newIndex >= array.length) {
      newIndex = array.length - 1;
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
}

function moveElement(array, index, offset) {
    const newIndex = index + offset;
    return move(array, index, newIndex);
}

const DashboardContextProvider = ({ props, children }) => {
    let [reportBlocks, setReportBlocks] = useState([]);

    const hideReport = (reportBlockId) => {
        let index = reportBlocks.findIndex(r => r.id === reportBlockId);
        let newArr = [...reportBlocks];
        newArr[index].visible = '0';
        setReportBlocks(newArr);
        MasterService.changeVisibility(reportBlockId, false)
        .then(data => {
            console.log(data);
        })
        .catch(error => console.log(error));
    }
    
    const showReport = (reportBlockId) => {
        let index = reportBlocks.findIndex(r => r.id === reportBlockId);
        let newArr = [...reportBlocks];
        newArr[index].visible = '1';
        setReportBlocks(newArr);
        MasterService.changeVisibility(reportBlockId, true)
        .then(data => {
            console.log(data);
        })
        .catch(error => console.log(error));
    }

    const moveItem = (sourceId, destinationId) => {
        
        const sourceIndex = reportBlocks.findIndex(
          item => item.id === sourceId
        );

        const destinationIndex = reportBlocks.findIndex(
          item => item.id === destinationId
        );

        // If source/destination is unknown, do nothing.
        if(sourceId === -1 || destinationId === -1) {
          return;
        }

        const offset = destinationIndex - sourceIndex;

        let newArr = moveElement([...reportBlocks], sourceIndex, offset);
        setReportBlocks(newArr);

        newArr.forEach((report, order) => {
            MasterService.changeOrder(report.id, order);
        });
    };

    return (
      <DashboardContext.Provider 
        value={{
            reportBlocks, 
            setReportBlocks,
            hideReport,
            showReport,
            moveItem
        }}>
        {children}
      </DashboardContext.Provider>
    );
  };

export { DashboardContext, DashboardContextProvider };

