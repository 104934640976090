import React, { useContext, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import WifiOffIcon from '@material-ui/icons/WifiOff';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import useInterval from '../components/useInterval';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  makeStyles,
  Badge,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import { BootstrapTooltip } from '../Utils';
import NotificationsPopover from '../components/Notification/NotificationsPopover';
import { AppContext } from '../AppContextProvider';
import { NotificationService } from '../Services/NotificationService';
//Tour
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const disableBody = target => disableBodyScroll(target);
const enableBody = target => enableBodyScroll(target);

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
        [theme.breakpoints.down('md')]: {
          marginRight: 0,
        }
    },
    hide: {
        display: 'none',
    },
    title: {
        [theme.breakpoints.down('md')]: {
          flexGrow: 1,
          alignSelf: 'flex-end',
          marginTop: 8,
          fontSize: 14,
          textAlign: 'left'
        }
    },
    networkBadge: {
        position: "absolute", 
        right: 300,
        [theme.breakpoints.down('md')]: {
          right: 120,
        }
    },
}));

export default function MyAppBar(props){

    const history = useHistory();
    const classes = useStyles();
    const {state, handleDrawerOpen, handleLogout, handleNotificationsClose, showNotifications, setNotifications, tourAvailable, userWantsToTour} = useContext(AppContext);
    const [count, setCount] = useState(0);

    const notificationsRef = React.useRef(null);

    React.useEffect(() => {
        if(state.isAuthenticated && state.user !== null){
            getCount();
        }
    }, [state.user, state.isAuthenticated]);

    useInterval(() => {
        if(state.isAuthenticated && state.user !== null){
            getCount();
        }
    }, 60000);

    const getCount = () => {
        NotificationService.getUnreadCount()
        .then(data => {
            setCount(data.count);
            getNotifications(data.count > 20 ? 20: data.count);
        })
        .catch(error => {
            console.log(error);
        });
    }
    
    const getNotifications = (count = 10) => {
        NotificationService.getNotifications(null, 'unread', 0, count)
        .then(data => {
            setNotifications(data);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const removeNotification = id => {
        NotificationService.removeNotifications(id)
        .then(data => {
            getCount();
        })
        .catch(error => {
            console.log(error);
        });
    }
    
    const removeAllNotifications = () => {
        NotificationService.removeAllNotifications()
        .then(data => {
            getCount();
        })
        .catch(error => {
            console.log(error);
        });
    }

    const markRead = ids => {
        NotificationService.markAsReadNotifications(Array.isArray(ids) ? ids.join(','): ids)
        .then(data => {
            getCount();
        })
        .catch(error => {
            console.log(error);
        });
    }

    const showTour = e => {
        userWantsToTour(true);
    }

    const cancelTour = () => {
        userWantsToTour(false);
    }

    return (
        <AppBar position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: state.open,
            })}
            >
            <Toolbar disableGutters={!state.open}>
            { state.isAuthenticated  &&
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={handleDrawerOpen}
                    className={classNames(classes.menuButton, {
                        [classes.hide]: state.open,
                })}
                >
                    <MenuIcon />
                </IconButton>
            }
            { state.isAuthenticated && !state.open && 
                <Typography variant="h4" color="inherit" className={classes.title} noWrap>
                    {state.title}
                </Typography>
            }
            { state.isDisconnected &&
                <BootstrapTooltip title={'No internet connection available. App offline.'}>
                    <IconButton className={classes.networkBadge} color="inherit"><WifiOffIcon/></IconButton>
                </BootstrapTooltip>
            }
                {/* { state.isAuthenticated &&
                <Button color="inherit" className={classes.sectionMobile} style={{position: "absolute", right: 130}}>{state.user.name ? state.user.name : ''}</Button>
                } */}
                {state.isAuthenticated && tourAvailable && (
                    <Tooltip title="Help">
                        <IconButton color="inherit" style={{position: "absolute", right: 170}} onClick={showTour}>
                            <HelpOutlineIcon/>
                        </IconButton>
                    </Tooltip>
                    )
                }
                {state.isAuthenticated && (
                    <Tooltip title="Notifications">
                        <IconButton color="inherit" style={{position: "absolute", right: 120}} ref={notificationsRef} onClick={showNotifications}>
                            <Badge badgeContent={count} color={"error"}>
                                <NotificationsOutlinedIcon/>
                            </Badge>
                        </IconButton>
                    </Tooltip>
                )
                }
                {state.isAuthenticated && (
                    <Tooltip title="Settings">
                    <IconButton 
                        color="inherit" 
                        style={{position: "absolute", right: 70}}
                        onClick={(e) => {
                            history.push("/account/management/general");
                        }}>
                        <SettingsOutlinedIcon/>
                    </IconButton>
                    </Tooltip>
                )
                }
                {!state.isAuthenticating && (state.isAuthenticated ? 
                    <Tooltip title="Logout">
                        <IconButton color="inherit" style={{position: "absolute", right: 20}}  onClick={handleLogout}><ExitToAppOutlinedIcon/></IconButton>
                    </Tooltip>
                :
                    <Button color="inherit" style={{position: "absolute", right: 20, color: "white"}}><Link to="/login" style={{color: "inherit", textDecoration: "none"}}>Login</Link></Button>
                )}
                <NotificationsPopover
                    anchorEl={notificationsRef.current}
                    notifications={state.notifications}
                    onClose={handleNotificationsClose}
                    open={state.openNotifications}
                    removeNotification={removeNotification}
                    markRead={markRead}
                    removeAllNotifications={removeAllNotifications}
                />
                <Tour
                    steps={state.tourSteps}
                    showNumber={false}
                    rounded={5}
                    isOpen={state.userIsTouring}
                    onRequestClose={cancelTour}
                    onAfterOpen={disableBody}
                    onBeforeClose={enableBody} />
            </Toolbar>
        </AppBar>
    );
}