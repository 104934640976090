import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card, CardContent, Divider, colors, Checkbox, Hidden } from "@material-ui/core";
import clsx from 'clsx';
import { NotificationService } from "../Services/NotificationService";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    root: {},
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actions: {
        float: 'right',
    }
}));

export default function Notifications(props) {
    const classes = useStyles();
    const {showSnackBar} = useContext(AppContext);

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        NotificationService.getNotificationCategories()
        .then(data => {
            setCategories(data);
        })
        .catch(error => {
            console.log(error);
        })

        return () => {
            setCategories([]);
        }
    }, [true]);

    const handleChange = category => e => {
        let mode = e.target.name;
        let pref = e.target.checked;
        // console.log(category, e.target.name);

        NotificationService.updatePreference(category.type, mode, pref)
        .then(data => {
            if(data.status){
                showSnackBar('Preference updated', 'info');
                console.log(category);
                category[mode] = pref;
                setCategories(prevCat => (prevCat.map(cat => cat.type === category.type ? {...cat, mode : pref} : cat )));
            }
        })
        .catch(error => {
            console.log(error);
            showSnackBar('Failed to update preference. Please try again.', 'error');
        })
    };

    return (
        <div >
            <Grid container direction="row" spacing={4} justify="center">
                <Grid item xl={12} lg={12} xs={12}>
                    <Card className={clsx(classes.root)}>
                        {/* <CardHeader 
                            title="Notifications"
                            titleTypographyProps={{align: 'left'}} />
                        <Divider /> */}
                        <CardContent>
                            <Hidden smDown>
                                <Grid container justify="flex-start">
                                    <Grid item xs={4} sm={6} md={6} lg={6}>
                                        <Typography variant="h5" align="center">Category</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={2} md={2} lg={2}>
                                        <Typography variant="h5">Email</Typography>    
                                    </Grid>
                                    <Grid item xs={4} sm={2} md={2} lg={2}>
                                        <Typography variant="h5">SMS</Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Divider style={{marginTop: 8, marginBottom: 8}}/>
                                    </Grid>
                                </Grid>
                                {
                                    categories.length === 0 && 
                                        Array.from(Array(5), (_, i) => 
                                            <Grid key={i} container justify="flex-start">
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <Skeleton width={"100%"} height={50}/>
                                                </Grid>
                                            </Grid>)
                                }
                                {
                                    categories.map(cat => (
                                        <Grid key={cat.type} container justify="flex-start" alignItems="flex-start">
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Typography variant="body1" align="left" style={{padding: 12}}>{cat.description}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={2} md={2} lg={2}>
                                                <Checkbox
                                                    checked={cat.email}
                                                    onChange={handleChange(cat)}
                                                    name="email"
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={2} md={2} lg={2}>
                                                <Checkbox
                                                    checked={cat.sms}
                                                    onChange={handleChange(cat)}
                                                    name="sms"
                                                    color="primary"
                                                />
                                            </Grid>
                                            {/* <Grid item xs={12} lg={10}>
                                                <Divider variant="middle"/>
                                            </Grid> */}
                                        </Grid>
                                    ))
                                }
                            </Hidden>
                            <Hidden mdUp>
                                <Grid container justify="flex-start">
                                    <Grid item xs={12}>
                                        <Typography variant="h5" align="center">Category</Typography>
                                    </Grid>
                                    {
                                        categories.length === 0 && 
                                            Array.from(Array(5), (_, i) => 
                                                <Grid key={i} container justify="flex-start">
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <Skeleton width={"100%"} height={50}/>
                                                    </Grid>
                                                </Grid>)
                                    }
                                    {
                                        categories.map(cat => (
                                            <Grid key={cat.type} container justify="flex-start" alignItems="center">
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" align="left" style={{padding: 12}}>{cat.description}</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="h5">Email</Typography>    
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Checkbox
                                                        checked={cat.email}
                                                        onChange={handleChange(cat)}
                                                        name="email"
                                                        color="primary"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="h5">SMS</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Checkbox
                                                        checked={cat.sms}
                                                        onChange={handleChange(cat)}
                                                        name="sms"
                                                        color="primary"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider style={{marginTop: 8, marginBottom: 8}}/>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Hidden>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
 