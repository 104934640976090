import React, { useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, Button, CardActions, Select, MenuItem, Input, CircularProgress, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from "moment";
import ReactTooltip from 'react-tooltip';
import {format, formatQty, formatNumber, colorGenerator} from '../Utils';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import INDIA_TOPO_JSON from './india.topo.json';
import { ReportService } from "../Services/ReportService";

const PROJECTION_CONFIG = {
    scale: 1000,
    center: [78.9629, 22.5937]
};

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5, 
        minHeight: 190,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 200,
        marginTop: 10
    },
    itemDivider: {
        borderBottomColor: 'rgba(255,255,255,0.2)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8,
        marginLeft: 8
    },
    griditem: {
        cursor: 'move'
    },
    loader: {
        marginTop: 40
    },
    geographyStyle: {
        default: {
          outline: 'none'
        },
        hover: {
          fill: '#ccc',
          transition: 'all 250ms',
          outline: 'none'
        },
        pressed: {
          outline: 'none'
        }
    }
}));

const TopRankingStates = ({ forwardedRef, ...props }) => {
    const classes = useStyles();
    const history = useHistory();
    const count = 5;
    const {report, className} = props;
    // const [isShown, setIsShown] = useState(false);
    const [data, setData] = useState([]);
    const [type, setType] = React.useState('units');
    const [range, setRange] = React.useState('today');
    const [loading, setLoading] = useState(false);
    const [tooltipContent, setTooltipContent] = useState('');
    const colors = colorGenerator(count);
    const DEFAULT_COLOR = '#008000';
    
    const rangeOptions = [
        {
            label: 'Today',
            value: 'today',
            startdate: moment().startOf('d'),
            enddate: moment().endOf('d')
        },
        {
            label: 'Last 7 Days',
            value: 'last_7',
            startdate: moment().startOf('d').add(-7, 'days'),
            enddate: moment().endOf('d')
        },
        {
            label: 'Last 30 Days',
            value: 'last_30',
            startdate: moment().startOf('d').add(-30, 'days'),
            enddate: moment().endOf('d')
        },
        {
            label: 'This Year',
            value: 'this_year',
            startdate: moment().startOf('year').startOf('day'),
            enddate: moment().endOf('d')
        }
    ];

    React.useEffect(() => {
        let selectedRange = rangeOptions.filter(r => r.value === range)[0];
        setLoading(true);
        ReportService.getTopLocations(count, 'state', type, selectedRange.startdate, selectedRange.enddate)
        .then(data => {
            data.forEach((d, i) => {
                d.color = colors[i];
            })
            setData(data);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        })
    }, [range, type]);

    // const changeVisibility = e => {
    //     if(report.visible === '1'){
    //         hideReport(report.id);
    //     } else {
    //         showReport(report.id);
    //     }
    // }

        /**
        * isShown && (
                        <BootstrapTooltip title={report.visible === '1' ? 'Click to Hide' : 'Click to Show' }>
                            <IconButton size="small" className={classes.icon} onClick={changeVisibility}>
                                {report.visible === '1' ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                            </IconButton>
                        </BootstrapTooltip>
                    ) 
        */

    const onMouseEnter = (geo, current = { value: 0 }) => {
        return () => {
            if(current.location === undefined){
                setTooltipContent(`${geo.properties.name}: ${current.value}`);
            } else {
                setTooltipContent(`${current.location}: ${type === 'units' ? `${formatQty(current.total)} Units` : formatNumber(current.total)}`);
            }
        };
    };
    
    const onMouseLeave = () => {
        setTooltipContent('');
    };

    const onClick = (data) => {
        history.push(`/geo/analyze/location?state=${data.location}&startdate=${rangeOptions.filter(r => r.value === range)[0].startdate.utc().format(format)}&enddate=${rangeOptions.filter(r => r.value === range)[0].enddate.utc().format(format)}`);
    }

    return (
        <Grid item xl={4} lg={4} xs={12}>
            <Card 
                ref={forwardedRef}
                className={clsx(classes.root, className)}
                //onMouseEnter={() => setIsShown(true)}
                //onMouseLeave={() => setIsShown(false)}
                >
                <CardHeader 
                    title={report.name}
                    className={classes.griditem}
                    titleTypographyProps={{align: 'left'}}
                    action={
                        <>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                className={classes.select}
                                input={<Input  disableUnderline={true}/>}>
                                    <MenuItem value={'units'}>By Units</MenuItem>
                                    <MenuItem value={'sales'}>By Sales</MenuItem>
                            </Select>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select-2"
                                value={range}
                                onChange={(e) => setRange(e.target.value)}
                                className={classes.select}
                                input={<Input  disableUnderline={true}/>}>
                                    {rangeOptions.map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
                            </Select>
                        </>
                    }/>
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress />
                            </div>
                        :
                        <div>
                            <ReactTooltip>{tooltipContent}</ReactTooltip>
                            <ComposableMap
                                projectionConfig={PROJECTION_CONFIG}
                                projection="geoMercator"
                                width={800}
                                height={520}
                                data-tip=""
                            >
                                <Geographies geography={INDIA_TOPO_JSON}>
                                {({ geographies }) =>
                                    geographies.map(geo => {
                                    const current = data.find(s => s.state_code === geo.id);
                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            fill={current ? current.color : DEFAULT_COLOR}
                                            className={classes.geographyStyle}
                                            onMouseEnter={onMouseEnter(geo, current)}
                                            onMouseLeave={onMouseLeave}
                                            onClick={(e) => {
                                                current && onClick(current)
                                            }}
                                        />
                                    );
                                    })
                                }
                                </Geographies>
                            </ComposableMap>
                        </div>
                    }
                </CardContent>
                <CardActions className={classes.actions}>
                    <Button
                        color="inherit"
                        component={RouterLink}
                        size="small"
                        to={`/geo/analyze/location?startdate=${rangeOptions.filter(r => r.value === range)[0].startdate.utc().format(format)}&enddate=${rangeOptions.filter(r => r.value === range)[0].enddate.utc().format(format)}`}
                        variant="text"
                    >
                        See all
                    <ArrowForwardIcon className={classes.arrowForwardIcon} />
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

TopRankingStates.propTypes = {
    report: PropTypes.object.isRequired,
};

export default TopRankingStates;