import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, FormControl, Button, TextField, Card, CardHeader, CardContent, CardActions, Divider, colors } from "@material-ui/core";
import { UserService } from "../Services/UserService";
import clsx from 'clsx';
import { AppContext } from "../AppContextProvider";

const useStyles = makeStyles(theme => ({
    root: {},
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actions: {
        float: 'right',
    }
}));

export default function MWSSettings(props) {

    const {state, showConfirmDialog, showSnackBar, updateMWSCredentials, closeConfirmDialog, setLinearLoading} = useContext(AppContext);
    const user = state.user;
    const classes = useStyles();

    const [sellerId, setSellerId] = React.useState('');
    const [awsAccessKeyId, setAwsAccessKeyId] = React.useState('');
    const [secretKey, setSecreKey] = React.useState('');
    const [marketPlaceId, setMarketPlaceId] = React.useState('');

    React.useEffect(() => {
        //Prepopulate the UI
        setSellerId(user.seller_id ? user.seller_id: '');
        setAwsAccessKeyId(user.aws_access_key_id ? user.aws_access_key_id:  '');
        setSecreKey(user.secret_key ? user.secret_key: '');
        setMarketPlaceId(user.market_place_id ? user.market_place_id: '');
    }, [true]);

    const handleMWSChange = (type, value) => {
        switch(type){
            case 'sellerId': setSellerId(value); break;
            case 'awsAccessKeyId': setAwsAccessKeyId(value); break;
            case 'secretKey': setSecreKey(value); break;
            case 'marketPlaceId': setMarketPlaceId(value); break;
            default:;
        }
    }

    const submitMWS = () => {
        if(sellerId.length === 0 || awsAccessKeyId.length === 0 || secretKey.length === 0 || marketPlaceId.length === 0){
            showSnackBar('Please fill all the required fields and try again.', "error")
        } else {
            showConfirmDialog("Confirm changes? ", undefined, () => {
                confirmMWSChange();
            }, "Cancel", "Yes");
        }
    }

    const confirmMWSChange = () => {
        let body = {
            "seller_id": sellerId,
            "aws_access_key_id": awsAccessKeyId,
            "secret_key": secretKey,
            "market_place_id": marketPlaceId
        };
        setLinearLoading(true);
        UserService.changeMWSCredentials(body)
        .then((data) => {
            console.log(data);
            if(data.success){
                showSnackBar(data.message, "success");
                updateMWSCredentials(body);
            } else {
                showSnackBar(data.message, "error");
            }
            closeConfirmDialog();
            setLinearLoading(false);
        }).catch((error) => {
            showSnackBar("Something went wrong. Try again later.", "error");
            closeConfirmDialog();
            setLinearLoading(false);
        });
    }

    return (
        <div>
            <Card className={clsx(classes.root)}>
                <CardHeader 
                    title="MWS Credentials"
                    titleTypographyProps={{align: 'left'}} />
                <Divider />
                <CardContent>
                    <Grid container spacing={4} alignItems="center" justify="center">
                        <Grid item xs={12} lg={5}>
                            <FormControl fullWidth>
                                <TextField
                                    id="standard-text-input-1"
                                    label="Seller/Merchant ID"
                                    className={classes.textField}
                                    type="text"
                                    required
                                    error={sellerId === ''}
                                    helperText={sellerId === '' ? 'Cannot be empty' : ' '}
                                    margin="none"
                                    variant="outlined"
                                    onChange={(e) => handleMWSChange('sellerId', e.target.value)}
                                    value={sellerId}
                                    />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <FormControl fullWidth>
                                <TextField
                                    id="standard-text-input-2"
                                    label="AWS Access Key"
                                    className={classes.textField}
                                    type="text"
                                    required
                                    error={awsAccessKeyId === ''}
                                    helperText={awsAccessKeyId === '' ? 'Cannot be empty' : ' '}
                                    margin="none"
                                    variant="outlined"
                                    onChange={(e) => handleMWSChange('awsAccessKeyId', e.target.value)}
                                    value={awsAccessKeyId}
                                    />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <FormControl fullWidth>
                                <TextField
                                    id="standard-text-input-3"
                                    label="Secret Key"
                                    className={classes.textField}
                                    type="text"
                                    required
                                    error={secretKey === ''}
                                    helperText={secretKey === '' ? 'Cannot be empty' : ' '}
                                    margin="none"
                                    multiline
                                    variant="outlined"
                                    onChange={(e) => handleMWSChange('secretKey', e.target.value)}
                                    value={secretKey}
                                    />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <FormControl fullWidth>
                                <TextField
                                    id="standard-text-input-4"
                                    label="Market Place ID"
                                    className={classes.textField}
                                    type="text"
                                    error={marketPlaceId === ''}
                                    helperText={marketPlaceId === '' ? 'Cannot be empty' : ' '}
                                    margin="none"
                                    required
                                    variant="outlined"
                                    onChange={(e) => handleMWSChange('marketPlaceId', e.target.value)}
                                    value={marketPlaceId}
                                    />
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions className={classes.actions}>
                    <Button variant="contained" className={classes.saveButton} onClick={submitMWS}>
                        Update MWS Credentials
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
}
 