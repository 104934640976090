import React from "react";
import moment from "moment";
import "moment-timezone";
import { Chip, withStyles, Tooltip, makeStyles, TableCell } from "@material-ui/core";
// var randomColor = require('randomcolor');

const formatter = new Intl.NumberFormat('en-IN');

export function colorGenerator(count = 1){
  return ['#3333ff', '#CC0000', '#F38822', '#AAAAAA','#FF00FF', '#800000', '#C0C0C0','#808080','#000000','#FF0000','#808000','#00FF00','#008000','#00FFFF','#0000FF','#FF00FF','#800080','#0076CE','#008080','#04B000','#0B0F70','#275B4B','#2F8FD6','#466108','#5B6E31','#792C97','#800080','#972C95','#A3C0C6','#AE1D08','#B3D9FF','#B5BDC4','#BBEEFF','#BBFFEE','#C0F762','#C1D4CC','#C6A9A3','#C9DEEA','#CCAC95','#DAA520','#E1FCFF','#E1FFE4','#E5B8AD','#F797A5','#FCC00F','#FFE4E1','#FFF3CE'].slice(0, count);
  // return randomColor({count, luminosity: 'bright'});
}

export function randomHex(){
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++ ) {
      color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const format = "YYYY-MM-DD HH:mm:ss";
export const format_export = "DD-MM-YYYY HH:mm:ss";
export const format_fancy = 'Do MMM YYYY HH:mm:ss';
export const format_order_date = 'Do MMM YYYY h:mm a';
export const format_display_withtime = "Do MMM YYYY HH:mm:ss a";
export const format_without_time = "YYYY-MM-DD";
export const format_display = "DD/MM/YYYY";
// export const format_export = "DD/MM/YY";
export const format_display_date = "Do MMM YYYY HH:mm";
export const format_display_fancy_notime = "Do MMM YYYY";
export const format_display_fancy_notime_withDay = "dddd, Do MMM";
export const format_chart_hour = 'HH:mm:ss';


export function getTimeInIST(dateString) {
  const timeInUTC = moment.utc(dateString, format);
  return timeInUTC.clone().tz("Asia/Kolkata");
}

export function currentTimeInIST() {
  return moment
    .utc()
    .clone()
    .tz("Asia/Kolkata");
}

export function toInt(n) {
  return parseInt(n, 10);
}

export function formatNumber(number){
  return parseFloat(number).toLocaleString('en-IN', {style: 'currency', minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'INR'});
}

export function formatQty(number){
  return formatter.format(number);
  // return parseFloat(number).toLocaleString('en-IN', {style: 'unit', minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export function isInt(n) {
  return n % 1 === 0;
}

export function toPercentage(portion, total) {
  return parseFloat((portion/total) * 100).toFixed(1) + "%";
}

export function getStatusComponent(status){
  let label = status;
  let color = 'default';
  switch (status) {
    case 'Shipped':
      color = 'primary';
      break;
    case 'Canceled':
      color = 'secondary';
      break;
    case 'Unshipped':
      color = 'default';
      break;
    default:
      color = 'default';
      break;
  }

  return <Chip size="medium" label={label} color={color}/>;
}

export const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 14
  },
}));

export function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export const StyledTableCell = withStyles((theme) => ({
  root: {
      paddingLeft: 32, 
      paddingRight: 32,
      borderBottom: '1px solid rgba(238, 238, 238, 1)',
      fontSize: 12
  }
}))(TableCell);


export function openLink(link){
  window.open(link, "_blank")
}