import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Popover,
  CardHeader,
  CardActions,
  Divider,
  Button,
  colors,
  IconButton
} from '@material-ui/core';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import { NotificationList, EmptyList } from './components';
import { BootstrapTooltip } from '../../Utils';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    maxWidth: '100%'
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center'
  },
  icon: {
    marginTop: 8,
    color: theme.palette.icon,
    cursor: 'pointer',
  },
}));

const NotificationsPopover = props => {
  const { notifications, anchorEl, markRead, removeAllNotifications, removeNotification, ...rest } = props;

  const classes = useStyles();

  const markAllRead = e => {
    markRead(notifications.map(n => n.id));
  }

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <div className={classes.root}>
        <CardHeader 
          title="Notifications"
          action={
            <div>
              {
                false && notifications.length > 0 && 
                <BootstrapTooltip title="Mark all as Read">
                  <IconButton aria-label="Mark all as Read" onClick={markAllRead}>
                    <MarkunreadIcon className={classes.icon}/>
                  </IconButton>
                </BootstrapTooltip>
              }
              { false &&
                <BootstrapTooltip title="Remove All notifications">
                  <IconButton aria-label="Remove All notifications" onClick={removeAllNotifications}>
                    <DeleteIcon className={classes.icon}/>
                  </IconButton>
                </BootstrapTooltip>
              }
            </div>
          } />
        <Divider />
        {notifications.length > 0 ? (
          <NotificationList 
            notifications={notifications} 
            onClose={props.onClose} 
            markRead={markRead} 
            removeNotification={removeNotification}/>
        ) : (
          <EmptyList />
        )}
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            component={RouterLink}
            size="small"
            to="/notifications"
          >
            See all
          </Button>
        </CardActions>
      </div>
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  removeAllNotifications: PropTypes.func.isRequired,
  markRead: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default NotificationsPopover;
