import React, { useContext } from "react";
import "../css/login.css";
import { UserService } from "../Services/UserService";
import { Divider, Grid, makeStyles, Card, CardHeader, CardContent, FormHelperText } from "@material-ui/core";
import {  FormControl, Button, InputLabel, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import clsx from 'clsx';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from "react-router-dom";
import { AppContext } from "../AppContextProvider";
import { useState } from "react";
import queryString from 'query-string';

const useStyles = makeStyles(theme =>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 300,
  },
  actions: {
    float: 'right',
  }
}));

export default function Reset(props){

  const classes = useStyles();
  const history = useHistory();
  const {setTitle, showSnackBar, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog} = useContext(AppContext);

  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [reenterNewPassword, setReenterNewPassword] = React.useState('');
  const [messageNew2, setMessageNew2] = React.useState('');
  const [errorNewPass, setErrorNewPass] = React.useState(false);
  const [errorNewPass2, setErrorNewPass2] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const [display, setDisplay] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setTitle('Reset Password');
    if(props.location.search){
      let qString = queryString.parse(props.location.search);
      if(qString.token){
          setToken(qString.token);
          setDisplay(true);
          setLoaded(true);
      }
      // setASIN(props.location.query.asin);
    } else if(token === null){
      // showConfirmDialog('Invalid Request', undefined, () => {
      //   history.push('/login');
      //   closeConfirmDialog();
      // }, undefined, 'Login');
      setLoaded(true);
    }
  }, [true]);

  React.useEffect(() => {
    if(loaded){
      if(token === null){
        history.push('/login');
      }
    }
  }, [loaded]);

  // const validateForm = () => {
  //   return email.length > 5 && password.length > 3;
  // }

  const handleChange = (type, value) => {
      setErrorNewPass(false);
      setErrorNewPass2(false);
      
      if(value.length === 0){
          switch(type){
              case 'newPassword': setErrorNewPass(true);break;
              case 'reenterNewPassword': setErrorNewPass2(true); break;
              default:;
          }
      } else {
          switch(type){
              case 'newPassword': setErrorNewPass(false); setNewPassword(value); break;
              case 'reenterNewPassword': setErrorNewPass2(false); setReenterNewPassword(value); break;
              default:;
          }
                
      }

      if(type === 'reenterNewPassword' && value !== newPassword){
          setErrorNewPass2(true);
          setMessageNew2('Re-enter and new passwords are not same');
          return;
      } else {
          setErrorNewPass2(false);
          setMessageNew2('');
      }
  }

  const submit = () => {
      if(newPassword.length === 0 || reenterNewPassword.length === 0 || reenterNewPassword !== newPassword){
          setErrorNewPass(true);
          setErrorNewPass2(true);
      } else {
          showConfirmDialog("Confirm change of password? ", undefined, () => {
              confirmChange();
          }, "Cancel", "Yes");
      }
  }

  const confirmChange = () => {
      let body = {
          "password": newPassword,
      };
      let headers = {token: token};
      showProgressDialog();
      UserService.resetPassword(body, headers)
      .then((data) => {
          console.log(data);
          closeConfirmDialog();
          closeProgressDialog();
          if(data.success){
              showConfirmDialog(`${data.message}. Click here to Login.`, undefined, () => {
                history.push("/login");
                setDisplay(false);
                closeConfirmDialog();
              }, 'Dismiss', 'Login');
          } else {
              showConfirmDialog(data.message, undefined, () => {
                history.push("/login");
                closeConfirmDialog();
              }, 'Cancel', 'Request');
          }
      }).catch((error) => {
          showSnackBar("Something went wrong. Try again later.", "error");
          closeConfirmDialog();
          closeProgressDialog();
      });
  }

  const handleClickShowPassword = () => {
    setShowPassword(prevState => (!prevState));
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div className="Login" style={{height: '100vh'}}>
      <Grid container direction="row" justify="center" alignItems="center" style={{height: '50vh'}}>
        <Grid item xs={12} lg={3}>
          <Grid item xl={12} lg={12} xs={12}>
            <Card className={clsx(classes.root)}>
                <CardHeader 
                    title={display ? "Change Password" : "" }
                    titleTypographyProps={{align: 'left'}} />
                <Divider />
              {
                display ? 
                <CardContent>
                    <Grid container justify="flex-start" spacing={1}>
                        <Grid item xs={12} lg={12}>
                          <FormControl className={clsx(classes.margin, classes.textField)}  variant="outlined">
                            <InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
                            <OutlinedInput
                              id="standard-adornment-password"
                              type={showPassword ? 'text' : 'password'}
                              value={newPassword}
                              error={errorNewPass}
                              className={classes.textField}
                              margin="none"
                              onChange={(e) => handleChange('newPassword', e.target.value)}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              labelWidth={100}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          <FormControl className={clsx(classes.margin, classes.textField)}  variant="outlined">
                              <InputLabel htmlFor="standard-adornment-password">Re-enter New Password</InputLabel>
                              <OutlinedInput
                                id="standard-adornment-re-enter-password"
                                type={showPassword ? 'text' : 'password'}
                                value={reenterNewPassword}
                                error={errorNewPass2}
                                className={classes.textField}
                                margin="none"
                                onChange={(e) => handleChange('reenterNewPassword', e.target.value)}
                                labelWidth={170}
                              />
                              <FormHelperText>{messageNew2}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          <Button variant="contained" className={classes.actions} color="primary" onClick={submit}>
                            Change Password
                        </Button>
                        </Grid>
                    </Grid>
                </CardContent>
                :
                <CardContent>
                    <Grid container justify="flex-start" spacing={0}>
                        <Grid item xs={12} lg={12}>
                          <Button variant="contained" color="primary" onClick={() => { history.push('/login')}}>
                            Click to Proceed to Login
                          </Button>
                        </Grid>
                    </Grid>
                </CardContent>
              }
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}