import React from 'react';
import { ListItemText, makeStyles, Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
import { red, green, yellow } from '@material-ui/core/colors';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles(theme => ({
    red: {
        color: red[700]
    },
    yellow: {
        color: yellow[800]
    },
    green: {
        color: green[600]
    },
    black: {
        color: theme.palette.black
    }
}));

export default function DataBlock(props){
    const classes = useStyles();

    const { color } = props;

    let colorStyle = classes.black;
    switch (color) {
        case 'red':
            colorStyle = classes.red;
            break;
        case 'yellow':
            colorStyle = classes.yellow;
            break;
        default:
            colorStyle = classes.black;
            break;
    }; 

    return (
        props.direction ? 
            <ListItemText 
                primary={
                    <div>
                        {
                            props.direction === 1 ? <ArrowUpwardIcon className={classes.green} /> : <ArrowDownwardIcon className={classes.red} />
                        }
                        <Typography variant="h2" component="span" className={props.direction === 1 ? classes.green: props.direction === -1 ? classes.red : classes.black}>{props.title}</Typography>
                        <Typography variant="caption" component="span" className={props.direction === 1 ? classes.green: props.direction === -1 ? classes.red : classes.black}>%</Typography>
                    </div>
                }
                secondary={props.subtitle}
                secondaryTypographyProps={{variant: 'caption', color: 'inherit'}}/>
        :
            <ListItemText 
                primary={props.title}
                primaryTypographyProps={{variant: 'h2', className: colorStyle}}
                secondary={props.subtitle}
                secondaryTypographyProps={{variant: 'caption', color: 'inherit'}}/>
    
    );
}

DataBlock.prototype = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    direction: PropTypes.number,
    color: PropTypes.oneOf(['red', 'black', 'yellow'])
};

DataBlock.defaultProps = {
    color: 'black'
};

