// let organisation = localStorage.getItem('organisation') === null ? "sysbrew-test" : localStorage.getItem('organisation');

// const host = process.env.NODE_ENV === 'production' ? "https://api.asinwatchdog.net/api/" : "http://aesrv.activexenon.com/mws/api/";
const host = process.env.NODE_ENV === 'production' ? "https://api.asinwatchdog.net/api/" : "https://api.asinwatchdog.net/api/";

const Config = {

    authenticate_url: `${host}authenticate`,
    change_password_url: `${host}change/password`,
    request_reset_password_url: `${host}request/reset/password`,
    reset_password_url: `${host}reset/password`,
    change_mws_credentials_url: `${host}change/mws/credentials`,
    change_spapi_credentials_url: `${host}change/spapi/credentials`,
    update_client_info_url: `${host}client`,

    get_dashboard_url: `${host}dashboard?`,
    change_dashboard_report_visibility_url: `${host}dashboard/report/visible?`,
    change_dashboard_report_order_url: `${host}dashboard/report/order?`,
    list_asins: `${host}product/list?`,
    list_asin_metrics: `${host}product/metrics?`,
    asin_bsr_data: `${host}product/ranking/graph?`,
    list_fcs: `${host}fc/list?`,
    list_dispositions: `${host}disposition/list?`,
    list_product_names: `${host}product/name/list?`,
    report_execute: `${host}report/execute?`,
    state_mapping: `${host}location/list?`,
    region_mapping: `${host}location/region/list?`,
    fetch_geo_analysis_state: `${host}report/geo/analysis/state?`,
    fetch_geo_analysis_city: `${host}report/geo/analysis/city?`,
    fetch_relative_products_from_geo: `${host}report/geo/analysis/relative?`,
    fetch_inventory_txn: `${host}inventory/list?`,
    fetch_inventory_graph_data: `${host}inventory/graph?`,
    fetch_inventory_out_of_stock: `${host}inventory/products/outofstock?`,
    fetch_inventory_fc_stock: `${host}inventory/fc/stock?`,
    fetch_inventory_max_date: `${host}inventory/max/snapshot?`,
    fetch_top_performing_products: `${host}report/top/products?`,
    fetch_asin_daily_sales: `${host}report/asin/sales/daily?`,
    fetch_sales_stats: `${host}report/sales/stats?`,
    fetch_sales_product_stats: `${host}report/sales/stats/product?`,
    fetch_top_customers: `${host}report/top/customers?`,
    list_order_statuses: `${host}order/statuses?`,
    list_product_tags: `${host}product/tags?`,
    list_product_keywords: `${host}product/keywords?`,
    fetch_sales_orders: `${host}report/order/sales?`,
    fetch_advance_customer_search_report: `${host}report/advance/customer/search?`,
    fetch_sales_stats_for_asin: `${host}report/asin/sales/stats?`,
    fetch_sales_min_date: `${host}report/min/sales/date?`,
    fetch_zonal_stats: `${host}report/zonal/sales/stats?`,
    list_background_jobs: `${host}jobs?`,
    enable_background_jobs: `${host}jobs/enable?`,
    background_job_status: `${host}job/status?`,
    tags_url: `${host}product/tags?`,
    keyword_url: `${host}product/keyword?`,
    tags_multiple_url: `${host}product/tags/multiple?`,
    product_field_update_url: `${host}product/enable?`,
    product_threshold_update_url: `${host}product/threshold?`,
    product_matching_url: `${host}product/matching?`,
    product_competitor_url: `${host}product/competitor?`,
    product_reviews_url: `${host}product/reviews?`,
    notification_category_list_url: `${host}notification/categories?`,
    update_notification_pref_url: `${host}notification/category/preference?`,
    unread_notification_count_url: `${host}notification/count/unread?`,
    notification_count_url: `${host}notification/count?`,
    get_notifications_url: `${host}notification/list?`,
    remove_notifications_url: `${host}notification/id?`,
    mark_read_notifications_url: `${host}notification/read/id?`,
    remove_all_notifications_url: `${host}notification/all?`,
    top_products_url: `${host}top/products?`,
    top_ranking_products_url: `${host}top/ranking/products?`,
    top_ranking_locations_url: `${host}top/ranking/locations?`,
    fetch_latest_feedbacks_url: `${host}feedback/latest?`,
    fetch_feedbacks_url: `${host}feedback/list?`,

    seller_central_order_url: `https://sellercentral.amazon.in/orders-v3/order/{ORDERID}`,
    amazon_asin_url: `https://amazon.in/dp/{ASIN}`,
    


    gold_rate_url: `${host}goldRate/current`,
    update_gold_rate_url: `${host}goldRate/add/{rate}`,
    get_summary_url: `${host}report/summary/{startdate}/{enddate}`,
    get_loan_requests_url: `${host}loanRequests/list/{status}?`,
    get_loan_request_by_id_url: `${host}loanRequests/id/{id}`,
    get_count_loan_requests_url: `${host}loanRequests/count/{status}?`,
    update_loan_requests_url: `${host}loanRequests/update/{id}`,
    create_loan_requests_url: `${host}loanRequests/add/{type}`,
    update_loan_status: `${host}loanRequests/change/status`,
    get_roles: `${host}roles/list`,
    get_users_url: `${host}users/list/{type}`,
    get_branches_url: `${host}branch/list?`,
    toggle_user_status: `${host}users/togglestatus/{id}`,
    remove_user: `${host}users/delete`,
    remove_branch: `${host}branch/delete`,
    create_branch: `${host}branch/add`,
    get_branch_manager_history: `${host}branch/history/{id}`,
    create_user: `${host}users/add/{type}`,
    get_user_count: `${host}users/count/{type}`,
    get_branch_count: `${host}branch/count`,
    get_serviceareas_url: `${host}serviceareas/list`,
    google_url: "http://maps.google.com?q=",
    remove_servicearea: `${host}serviceareas/delete`,
    create_servicearea: `${host}serviceareas/add`,
    work_pattern: `${host}available/pattern`,
    holiday_list: `${host}available/holidays`,
    holiday_url: `${host}available/holidays/{holiday}`,
    broadcast_notification_url: `${host}available/broadcast/notification`,
    send_notification_url: `${host}available/individual/notification`,
    get_organisation_master_url: `${host}organisation`,
    update_organisation_master_url: `${host}organisation`,

    REFRESH_INTERVAL: 300000,
    SHORT_REFRESH_INTERVAL: 60000,
}

export default Config;