import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Table, TableBody, TableRow, ListItemText, useTheme } from "@material-ui/core";
import { ReportService } from "../../Services/ReportService";
import {red, blue} from '@material-ui/core/colors';
import moment from "moment";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import EnhancedTableToolbar from "../../components/EnhancedTableToolbar";
import {toInt, formatNumber, format, StyledTableCell} from "../../Utils";
import XLSX from 'xlsx';
import { Link as RouterLink} from 'react-router-dom';
import DateRangePickerCustom from "../../components/DateRangePickerCustom";
import { AppContext } from "../../AppContextProvider";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    section: {
        width: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    StyledTableCell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export default function TopProducts(props) {

    const {setTitle, setLinearLoading, showProgressDialog, closeProgressDialog} = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();
    const [data, setData] = React.useState([]);
    const [currentRows, setCurrentRows] = React.useState([]);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').add(-30, 'd'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [generate, setGenerate] = React.useState(true);

    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No', sortable: false },
        { id: 'Title', align: 'left', numeric: false, disablePadding: true, label: 'Product', sortable: false },
        { id: 'ASIN', align: 'left', numeric: false, disablePadding: true, label: 'ASIN', sortable: false },
        { id: 'SellerSKU', align: 'left', numeric: false, disablePadding: true, label: 'Seller SKU', sortable: false },
        { id: 'OrderCount', align: 'center', numeric: false, disablePadding: true, label: 'Order Count', sortable: false  },
        { id: 'TotalUnits', align: 'center', numeric: false, disablePadding: true, label: 'Total Units', sortable: false },
        { id: 'TotalAmount', align: 'center', numeric: false, disablePadding: false, label: 'Total Amount', sortable: false  }
    ];

    React.useEffect(() => {
        setTitle("Top Performing Product (in Sales)");
    }, [true]);

    React.useEffect(() => {
        if(generate){
            fetchReport();
            setGenerate(false);
        }
    }, [generate]);

    const fetchReport = () => {
        setLinearLoading(true);
        showProgressDialog();
        ReportService.fetchTopProducts(startdate, enddate)
        .then(data => {
            let rows = [];
            setData(data);
            data.forEach((element, index) => {
                rows.push(
                    <TableRow key={index} hover>
                        <StyledTableCell scope="row" align="center" padding='default' style={{width: 50}}>
                            {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="justify" style={{width: '30vw'}}>
                            {element.Title}
                        </StyledTableCell>
                        <StyledTableCell scope="row" align="justify">
                            <RouterLink  style={{cursor: 'pointer', textDecoration: 'none', color: theme.palette.text.link}} to={`/performance/product/graphical?asin=${element.ASIN}&startdate=${startdate.format(format)}&enddate=${enddate.format(format)}`}>
                                {element.ASIN}
                            </RouterLink>
                        </StyledTableCell>
                        <StyledTableCell scope="row" align="justify" padding='default'>
                            {element.SellerSKU}
                        </StyledTableCell>
                        <StyledTableCell align="right" padding='default'>
                            {element.OrderCount}
                        </StyledTableCell>
                        <StyledTableCell align="right" padding='default'>
                            {element.TotalUnits}
                        </StyledTableCell>
                        <StyledTableCell align="right" padding='default'>
                            {formatNumber(element.TotalAmount || 0)}
                        </StyledTableCell>
                    </TableRow>)
                });
            setCurrentRows(rows);
            setLinearLoading(false);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false);
            closeProgressDialog();
        });
    };

    const exportReport = e => {
        setLinearLoading(true);
        let row = [];
        let filename = 'Top Products Report';
        row.push(columns.map(c => c.label));
        data.forEach((d, index) => {
            row.push([index+1, d.Title, d.ASIN, d.SellerSKU, toInt(d.OrderCount), toInt(d.TotalUnits), parseFloat(d.TotalAmount || 0)]);
        });
        const wb = XLSX.utils.book_new();
        const wsAll = XLSX.utils.aoa_to_sheet(row);
        XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
        XLSX.writeFile(wb, filename + ".xlsx");
        setLinearLoading(false);
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" justify="center" spacing={2}>
                    <Grid item xl={9} lg={11} md={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center" justify="flex-start">
                                <Grid item xs={12} lg={3}>
                                    {/* <Timeline singleline startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/> */}
                                    <DateRangePickerCustom startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <Button variant="contained" className={classes.button} color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                        Generate Report
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                        { currentRows.length === 0 &&
                            <div style={{marginTop: 50}}>
                                <Typography align="center" variant="subtitle2" gutterBottom>No Data Available</Typography>
                            </div>
                        }
                        { currentRows.length > 0 && (
                            <Paper className={classes.section}>
                                <Grid container spacin={1} alignItems="center">
                                        <Grid item xs={12} lg={12}>
                                            <EnhancedTableToolbar 
                                                title={
                                                    <ListItemText primaryTypographyProps={{align: 'left', variant: 'h6'}} secondaryTypographyProps={{align: 'left'}}  primary={`Top Products`} secondary={`Product Count: ${currentRows.length}`}/>} 
                                                exportReport={exportReport}/>
                                            <div style={{height: 500, overflowY: 'auto', display: 'block'}}>
                                                <Table className={classes.table} stickyHeader size="small" padding="none">
                                                    <EnhancedTableHead
                                                        order={'desc'}
                                                        orderBy={'OrderCount'}
                                                        onRequestSort={() => {}}
                                                        rows ={columns} />
                                                    <TableBody>
                                                        {currentRows}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </Grid>
                                </Grid>
                            </Paper>
                            )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
 