import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, TextField, Radio, FormControlLabel, RadioGroup, Card, CardHeader, CardContent, Divider, CardActions, colors } from "@material-ui/core";
import { UserService } from "../Services/UserService";
import clsx from 'clsx';
import { AppContext } from "../AppContextProvider";

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    root: {},
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actions: {
        float: 'right',
    }
}));

export default function Settings(props) {
    const classes = useStyles();
    const {state, getTheme, showConfirmDialog, showSnackBar, setTheme, handleLogout, closeConfirmDialog, setLinearLoading} = useContext(AppContext);

    const [password, setPassword] = React.useState(null);
    const [newPassword, setNewPassword] = React.useState(null);
    const [reenterNewPassword, setReenterNewPassword] = React.useState(null);
    const [messageNew, setMessageNew] = React.useState('');
    const [messageNew2, setMessageNew2] = React.useState('');
    const [errorPass, setErrorPass] = React.useState(false);
    const [errorNewPass, setErrorNewPass] = React.useState(false);
    const [errorNewPass2, setErrorNewPass2] = React.useState(false);

    const [storeName, setStoreName] = React.useState(state.user.name);
    const [storeMobile, setStoreMobile] = React.useState(state.user.mobile);
    const [errorName, setErrorName] = React.useState(false);
    const [errorMobile, setErrorMobile] = React.useState(false);

    const [selectedTheme, setSelectedTheme] = React.useState('default');

    React.useEffect(() => {
        if(getTheme()){
            setSelectedTheme(getTheme())
        }
    }, [true]);

    const handleChangeProfile = (type, value) => {
        setErrorName(false);
        setErrorMobile(false);
        
        if(value.length === 0){
            switch(type){
                case 'name': setErrorName(true); break;
                case 'mobile': setErrorMobile(true);break;
                default:;
            }
        } else {
            switch(type){
                case 'name': 
                    setErrorName(value.length < 3); 
                    setStoreName(value); 
                    break;
                case 'mobile': 
                    setErrorMobile(value.length < 9 || !value.match(/^[1-9]{1}[0-9]{9}$/)); 
                    setStoreMobile(value); break;
                default:;
            }
                   
        }
    }
    
    const handleChange = (type, value) => {
        setErrorPass(false);
        setErrorNewPass(false);
        setErrorNewPass2(false);
        
        if(value.length === 0){
            switch(type){
                case 'password': setErrorPass(true); break;
                case 'newPassword': setErrorNewPass(true);break;
                case 'reenterNewPassword': setErrorNewPass2(true); break;
                default:;
            }
        } else {
            switch(type){
                case 'password': setErrorPass(false); setPassword(value); break;
                case 'newPassword': setErrorNewPass(false); setNewPassword(value); break;
                case 'reenterNewPassword': setErrorNewPass2(false); setReenterNewPassword(value); break;
                default:;
            }
                   
        }

        if(type === 'reenterNewPassword' && value !== newPassword){
            setErrorNewPass2(true);
            setMessageNew2('Re-enter and new passwords are not same');
            return;
        } else {
            setErrorNewPass2(false);
            setMessageNew2('');
        }

        if(type === 'newPassword' && password.length > 0 && value === password){
            setErrorNewPass(true);
            setMessageNew('New password cannot be same as old');
            return;
        } else {
            setErrorNewPass(false);
            setMessageNew('');
        }
    }

    const submit = () => {
        if(password.length === 0 || newPassword.length === 0 || reenterNewPassword.length === 0 || reenterNewPassword !== newPassword || password === newPassword){
            setErrorPass(true);
            setErrorNewPass(true);
            setErrorNewPass2(true);
        } else {
            showConfirmDialog("Confirm change of password? ", undefined, () => {
                confirmChange();
            }, "Cancel", "Yes");
        }
    }
    
    const submitProfileChanges = () => {
        if(!errorMobile && !errorName){
            showConfirmDialog("Confirm changes? ", undefined, () => {
                confirmProfileChange();
            }, "Cancel", "Yes");
        }
    }

    const confirmProfileChange = () => {
        let body = {
            "name": storeName,
            "mobile": storeMobile
        };
        setLinearLoading(true);
        UserService.updateClientInfo(body)
        .then((data) => {
            console.log(data);
            if(data.success){
                showSnackBar(data.message, "success");
                let user = state.user;
                user.name = storeName;
                user.mobile = storeMobile;
                localStorage.setItem('asinwatchdog.user', JSON.stringify(user));
            } else {
                showSnackBar(data.message, "error");
            }
            closeConfirmDialog();
            setLinearLoading(false);
        }).catch((error) => {
            showSnackBar("Something went wrong. Try again later.", "error");
            closeConfirmDialog();
            setLinearLoading(false);
        });
    }

    const confirmChange = () => {
        let body = {
            "password": password,
            "new_password": newPassword
        };
        setLinearLoading(true);
        UserService.changePassword(body)
        .then((data) => {
            console.log(data);
            if(data.success){
                showSnackBar(data.message, "success");
                handleLogout();
            } else {
                showSnackBar(data.message, "error");
            }
            closeConfirmDialog();
            setLinearLoading(false);
        }).catch((error) => {
            showSnackBar("Something went wrong. Try again later.", "error");
            closeConfirmDialog();
            setLinearLoading(false);
        });
    }

    return (
        <div >
            <Grid container direction="row" spacing={4} justify="center">
                <Grid item xl={12} lg={12} xs={12}>
                    <Card className={clsx(classes.root)}>
                        <CardHeader 
                            title="Store Profile"
                            titleTypographyProps={{align: 'left'}} />
                        <Divider />
                        <CardContent>
                            <Grid container justify="flex-start" spacing={1}>
                                <Grid item xs={12} lg={8}>
                                    <TextField
                                        fullWidth
                                        error={errorName}
                                        id="standard-name-input-1"
                                        label="Store Name"
                                        required
                                        className={classes.textField}
                                        type="text"
                                        margin="none"
                                        defaultValue={storeName}
                                        variant="outlined"
                                        onChange={(e) => handleChangeProfile('name', e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <TextField
                                        fullWidth
                                        error={errorMobile}
                                        id="standard-mobile-input-2"
                                        label="Mobile"
                                        className={classes.textField}
                                        type="tel"
                                        margin="none"
                                        variant="outlined"
                                        defaultValue={storeMobile}
                                        onChange={(e) => handleChangeProfile('mobile', e.target.value)}
                                        helperText={'Should be 10 digit number without country code'}
                                        inputProps={{ maxLength: 10 }}
                                        />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <CardActions className={classes.actions}>
                            <Button variant="contained" className={classes.saveButton} color="primary" onClick={submitProfileChanges}>
                                Save
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xl={12} lg={12} xs={12}>
                    <Card className={clsx(classes.root)}>
                        <CardHeader 
                            title="Change Password"
                            titleTypographyProps={{align: 'left'}} />
                        <Divider />
                        <CardContent>
                            <Grid container justify="flex-start" spacing={1}>
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        fullWidth
                                        error={errorPass}
                                        id="standard-password-input-1"
                                        label="Old Password"
                                        className={classes.textField}
                                        type="password"
                                        autoComplete="current-password"
                                        margin="none"
                                        variant="outlined"
                                        onChange={(e) => handleChange('password', e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        fullWidth
                                        error={errorNewPass}
                                        id="standard-password-input-2"
                                        label="New Password"
                                        className={classes.textField}
                                        type="password"
                                        margin="none"
                                        variant="outlined"
                                        onChange={(e) => handleChange('newPassword', e.target.value)}
                                        helperText={messageNew}
                                        />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        fullWidth
                                        error={errorNewPass2}
                                        id="standard-password-input-3"
                                        label="Re-enter New Password"
                                        className={classes.textField}
                                        type="password"
                                        margin="none"
                                        variant="outlined"
                                        onChange={(e) => handleChange('reenterNewPassword', e.target.value)}
                                        helperText={messageNew2}
                                        />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <CardActions className={classes.actions}>
                            <Button variant="contained" className={classes.saveButton} color="primary" onClick={submit}>
                                Change Password
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                {
                    false && (
                    <Grid item xl={12} lg={12} xs={12}>
                        <Card className={clsx(classes.root)}>
                            <CardHeader 
                                title="Change Font Size"
                                titleTypographyProps={{align: 'left'}} />
                            <Divider />
                            <CardContent>
                                <div className={classes.radio}>
                                    <RadioGroup row aria-label="position" name="theme">
                                        <FormControlLabel 
                                            label="Default" 
                                            control={<Radio
                                                        checked={selectedTheme === 'default'}
                                                        onChange={(event) => {
                                                            setSelectedTheme(event.target.value);
                                                            setTheme(event.target.value)
                                                        }}
                                                        value="default"
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': 'Default' }}
                                                    />
                                            }/>
                                        <FormControlLabel
                                            label="Small" 
                                            control={<Radio
                                                        checked={selectedTheme === 'small'}
                                                        onChange={(event) => {
                                                            setSelectedTheme(event.target.value);
                                                            setTheme(event.target.value)
                                                        }}
                                                        value="small"
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': 'Small' }}
                                                    />
                                            }/>
                                    </RadioGroup>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}
 