import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Divider, Table, TableBody, TableRow, ListItemText, TablePagination, useTheme } from "@material-ui/core";
import {red, blue} from '@material-ui/core/colors';
import { ProductService } from "../../Services/ProductService";
import moment from "moment";
import ItemSelection from '../../components/ItemSelection';
import EnhancedTableHead from "../../components/EnhancedTableHead";
import EnhancedTableToolbar from "../../components/EnhancedTableToolbar";
import {toInt, format, BootstrapTooltip, StyledTableCell} from "../../Utils";
import XLSX from 'xlsx';
import { RowCountOptions, TABLE_COUNTS } from "../../Constants";
import { Link as RouterLink} from 'react-router-dom';
import DateRangePickerCustom from "../../components/DateRangePickerCustom";
import { ReportService } from "../../Services/ReportService";
import { AppContext } from "../../AppContextProvider";

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function TopCustomers(props){

    const {setTitle: setTitles, setLinearLoading, showSnackBar} = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();
    const [asin, setASIN] = React.useState(null);
    const [asinList, setASINList] = React.useState([]);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').add(-30, 'days'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('OrderCount');
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(true);
    const [sortingAction, setSortingAction] = React.useState(false);

    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: false, label: 'Sl. No.', sortable: false },
        { id: 'BuyerEmail', align: 'left', numeric: false, disablePadding: true, label: 'Buyer Email', sortable: true },
        { id: 'OrderCount', align: 'left', numeric: true, disablePadding: true, label: 'No of Orders', sortable: true },
        { id: 'TotalUnits', align: 'left', numeric: true, disablePadding: true, label: 'Total Units', sortable: true },
        { id: 'TotalAmount', align: 'left', numeric: true, disablePadding: true, label: 'Total Amount', sortable: true },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.top_customer_tab_count_key) ? toInt(localStorage.getItem(TABLE_COUNTS.top_customer_tab_count_key)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);
    const [title, setTitle] = React.useState('');

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.top_customer_tab_count_key, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitles("Top Customers");

        ProductService.listProductNames()
        .then(data => {
            let list = data.map(d => ({...d, label : `[${d.asin}][${d.seller_sku}] ${d.name.substring(0, 120)}${d.name.length > 120 ? '...' : ''}`}));
            let allASIN = {asin: '-1', label: 'All ASIN'};
            list.splice(0, 0, allASIN)
            setASINList(list);
            setASIN(allASIN);
            if(asin){
                let a = list.filter(l => l.asin === asin);
                setASIN(a.length > 0 ? a[0] : null);
            }
        })
        .catch(error => console.log(error));

    }, [true]);

    React.useEffect(() => {
        if(generate && asin){
            if(!sortingAction){
                setCurrentRows([]);
            }
            setSortingAction(false);
            setLinearLoading(true);
            let selASIN = asin.asin !== '-1' ? asin.asin : null;
            setTitle(selASIN ? asin.name : 'All ASIN');
            setCurrentPageNumber(0);
            setTotalItems(0);
            ReportService.fetchTopCustomerCount(selASIN, startdate, enddate)
            .then(data => {
                setTotalItems(toInt(data.count));
                setFetchPage(true);
                if(toInt(data.count) === 0){
                    setCurrentRows([]);
                }
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
            });
            setGenerate(false);
        }
    }, [generate, asin, order, orderBy]);

    React.useEffect(() => {
        let rows = [];
        if(fetchPage && asin && totalItems > 0){
            setLinearLoading(true);
            let selASIN = asin.asin !== '-1' ? asin.asin : null;
            ReportService.fetchTopCustomers(selASIN, startdate, enddate, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                // console.log(data);
                data.forEach( (element, index) => {
                    rows.push(
                        <TableRow key={index} hover>
                            <StyledTableCell scope="row" align="center" padding='default' style={{width: 130}}>
                                {(currentPageNumber * numItemsPerPage) + index + 1}
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="left" padding='default'>
                                {element.BuyerEmail}
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="right" padding='default'>
                                <BootstrapTooltip title="Click to view orders">
                                    <RouterLink  style={{cursor: 'pointer', color: theme.palette.text.link, textDecoration: 'none'}} to={`/performance/orders?buyer=${element.BuyerEmail}&startdate=${startdate.format(format)}&enddate=${enddate.format(format)}`}>
                                        Ordered {element.OrderCount} times
                                    </RouterLink>
                                </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="right" padding='default'>
                                {element.TotalUnits}
                            </StyledTableCell>
                            <StyledTableCell align="right" padding='default'>
                                {element.TotalAmount}
                            </StyledTableCell>
                        </TableRow>);
                });
                setCurrentRows(rows);
                setLinearLoading(false);
            }).catch((error) => {
                console.log(error);
                setLinearLoading(false);
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setSortingAction(true);
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const exportReport = e => {
        setLinearLoading(true);
        let row = [];
        let filename = `Inventory Report ${asin.asin}`;
        let selASIN = asin.asin !== '-1' ? asin.asin : null;
        ReportService.fetchTopCustomers(selASIN, startdate, enddate, orderBy, order, 0, totalItems)
        .then((data) => {
            if(data.length > 0){
                row.push(columns.map(c => c.label));
                data.forEach((d, index) => {
                    row.push([index+1, d.BuyerEmail, toInt(d.OrderCount), toInt(d.TotalUnits), parseFloat(d.TotalAmount)]);
                });
                const wb = XLSX.utils.book_new();
                const wsAll = XLSX.utils.aoa_to_sheet(row);
                XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
                XLSX.writeFile(wb, filename + ".xlsx");
            } else {
                showSnackBar("No data to export" , 'warning');
            }
            setLinearLoading(false);
        })
        .catch(error => {
            console.log(error);
            showSnackBar("Failed to export. Please try again." , 'error');
            setLinearLoading(false);
        });
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={9} lg={11} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item xs={12} lg={4}>
                                    <ItemSelection clearable={true} required label='Select ASIN' value={asin} optionLabel='label' options={asinList} selected={setASIN}/>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    {/* <Timeline startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/> */}
                                    <DateRangePickerCustom startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Button variant="contained" className={classes.button} color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                        Generate Report
                                    </Button>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 10, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <EnhancedTableToolbar 
                                            title={asin && 
                                                <ListItemText 
                                                    primaryTypographyProps={{align: 'left', variant: 'subtitle2'}} 
                                                    secondaryTypographyProps={{align: 'left'}}  
                                                    primary={title} 
                                                    secondary={`${totalItems} Customers`}/>} 
                                            exportReport={exportReport}/>
                                    <div style={{minHeight: 100, maxHeight: 400, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows ={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Data</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};