import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Divider, Switch, IconButton, Button } from "@material-ui/core";
import { MasterService } from "../Services/MasterService";
import RefreshIcon from '@material-ui/icons/Refresh';
import { getTimeInIST, format_fancy } from "../Utils";
import { Skeleton } from "@material-ui/lab";
import SyncIcon from '@material-ui/icons/Sync';
import { ProductService } from "../Services/ProductService";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(3),
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    header: {
        fontSize: 16,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize: 14,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    divider: {
        marginTop: 3,
        marginBottom: 3
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function JobSettings(props) {
    const classes = useStyles();
    const {setLinearLoading, showConfirmDialog, closeConfirmDialog, showSnackBar, setTourSteps} = useContext(AppContext);

    const [jobs, setJobs] = React.useState([]);    
    const [refresh, setRefresh] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [merchantListingRun, setMerchantListingRun] = React.useState(undefined);

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="jobs"]',
                content: `Jobs on the platform`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour="jobsenable"]',
                content: `Enable/Disable Jobs`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour="jobsstatus"]',
                content: `Current Job Running Status`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour="merchant"]',
                content: `Resync Merchant Listing from Amazon`,
                style: {
                    backgroundColor: '#fff',
                },
            },

        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    React.useEffect(() => {
        fetchJobs();
        fetchMerchantListing();

        return () => {
            setLinearLoading(false);
            setRefresh(false);
        }
    }, [true]);

    function Rows(props){
        const job = props.job;
        const showDivider = props.showDivider;
        const [running, setRunning] = React.useState(null);
        const [ui, setUI] = React.useState(null);

        React.useEffect(() => {
            if(job.name){
                fetchRunningStatus(job.name);
            }
        }, [true]);

        React.useEffect(() => {
            if(running){
                switch(running.type){
                    case 'ORDERITEM':
                        let s = running.stats.map(r => r.status);
                        if(s.length === 1 && s.includes('FINISHED')){
                            setUI(  <Typography key={'PENDING'} variant="subtitle2"  align="left"  display="block" style={{fontSize: 12}}>
                                        Pending: <b>0</b>
                                    </Typography>);
                        } else {
                            setUI(
                                <div>
                                    { running.stats.length > 0 && 
                                        running.stats.filter(r => r.status !== 'FINISHED').map((d, i) => {
                                            return (
                                                <Typography key={i} variant="subtitle2"  align="left"  display="block" style={{fontSize: 12}}>
                                                    {d.status}: <b>{d.count}</b>
                                                </Typography>
                                            )
                                        })
                                    }
                                </div>);
                        }
                        break;
                    case 'PRODUCT_STATS':
                        setUI(
                            <div>
                                <Typography variant="subtitle2" align="left" display="block" style={{fontSize: 12}}>
                                    Status: <b>{running.status}</b>
                                </Typography>
                                { running.details.length > 0 && 
                                    running.details.map((d, i) => {
                                        return (
                                            <Typography key={i} variant="subtitle2"  align="left"  display="block" style={{fontSize: 12}}>
                                                {d.status}: <b>{d.count}</b>
                                            </Typography>
                                        )
                                    })
                                }
                                { running.comments && (
                                    <Typography variant="subtitle2" align="left" display="block" style={{fontSize: 12}}>
                                        Comments: <b>{running.comments}</b>
                                    </Typography>
                                )}
                                <Typography variant="subtitle2"  align="left" display="block" style={{fontSize: 12}}>
                                    Last Started at: <b>{running.starttime ? getTimeInIST(running.starttime).format(format_fancy): "-"}</b>
                                </Typography>
                                <Typography variant="subtitle2"   align="left" display="block" style={{fontSize: 12}}>
                                    Last Finished at: <b>{running.endtime ? getTimeInIST(running.endtime).format(format_fancy): "-"}</b>
                                </Typography>
                            </div>);
                        break;
                    case 'DAILY_INVENTORY':
                    case 'FEEDBACK':
                    case 'ORDER':
                    default:
                        setUI(
                            <div>
                                <Typography variant="subtitle2" align="left" display="block" style={{fontSize: 12}}>
                                    Status: <b>{running.status}</b>
                                </Typography>
                                <Typography variant="subtitle2"  align="left"  display="block" style={{fontSize: 12}}>
                                    Inserted Count: <b>{running.inserted}/{running.actualCount}</b>
                                </Typography>
                                { running.comments && (
                                    <Typography variant="subtitle2" align="left" display="block" style={{fontSize: 12}}>
                                        Comments: <b>{running.comments}</b>
                                    </Typography>
                                )}
                                <Typography variant="subtitle2"  align="left" display="block" style={{fontSize: 12}}>
                                    Last Started at: <b>{running.starttime ? getTimeInIST(running.starttime).format(format_fancy): "-"}</b>
                                </Typography>
                                <Typography variant="subtitle2"   align="left" display="block" style={{fontSize: 12}}>
                                    Last Finished at: <b>{running.endtime ? getTimeInIST(running.endtime).format(format_fancy): "-"}</b>
                                </Typography>
                            </div>);
                        break;
                }
            }
        }, [running])

        const fetchRunningStatus = (jobName) => {
            setUI( <Skeleton variant="rect" width="100%" height="100%" animation="wave"/>)
            let headers = {
                type: jobName
            }
            MasterService.fetchBackgroundJobsStatus(headers)
            .then(data => {
                // console.log(data);
                setRunning(data);
            })
            .catch((error) => {
                console.log('error fetching status');
            });
        }

        return (
                <Grid container item xs={12} lg={12} alignContent={"flex-start"} justify={"center"}>
                        <Grid item xs={12} lg={4}>
                            <Typography align="left" variant="subtitle2" style={{paddingLeft: 16}}>{job.description}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography component="div" className={classes.subtitle}>
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>Off</Grid>
                                    <Grid item>
                                        <Switch
                                            checked={job.enabled}
                                            onChange={props.handleChange(job.id)}
                                            color="primary"
                                            name="job_status"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </Grid>
                                    <Grid item>On</Grid>
                                </Grid>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            {ui}
                        </Grid>
                        <Grid item xs={12} lg={1}>
                            <IconButton color="primary" aria-label="upload picture" component="span" onClick={(e) => fetchRunningStatus(job.name)}>
                                <RefreshIcon/>
                            </IconButton>
                        </Grid>
                        {
                            showDivider && (
                                <Grid item xs={12} lg={12}>
                                    <Divider className={classes.divider}/>
                                </Grid>
                        )}
                        
                    </Grid>
        )
    }

    React.useEffect(() => {
        if(refresh && jobs.length > 0){
            setRefresh(false);
            let rows = [];
            jobs.forEach((job, i) => {
                rows.push(<Rows key={i} showDivider={i !== jobs.length -1} job={job} handleChange={handleChange}/>);
            });
            setRows(rows);
            setLinearLoading(false);
        }
        return () => {
            setRefresh(false);
        }
    }, [refresh])

    const fetchJobs = (jobId = null) => {
        setLinearLoading(true);
        // Fetch Job List
        MasterService.fetchBackgroundJobs()
        .then(data => {
            // console.log(data);
            setJobs(data);
            setRefresh(true);
        })
    }

    const handleChange = jobId => e => {
        let enabled = e.target.checked;
        showConfirmDialog("Confirm change status?", undefined, () => {
            confirmChange(jobId, enabled);
        }, "Cancel", "Yes");
    
    }

    const confirmChange = (jobId, enabled) => {
        setLinearLoading(true);
        MasterService.enableJob(jobId, enabled)
        .then((data) => {
            // console.log(data);
            fetchJobs(jobId);
            if(data.success){
                showSnackBar(data.message, "success");
            } else {
                showSnackBar(data.message, "error");
            }
            closeConfirmDialog();
            setLinearLoading(false);
        }).catch((error) => {
            showSnackBar("Something went wrong. Try again later.", "error");
            closeConfirmDialog();
            setLinearLoading(false);
        });
    }

    const fetchMerchantListing = () => {
        setMerchantListingRun(<Skeleton variant="rect" width={310} height={88} animation="wave"/>);
        let headers = {
            type: 'MERCHANT_LISTING'
        }
        MasterService.fetchBackgroundJobsStatus(headers)
        .then(running => {
            
            setMerchantListingRun(running.starttime ?
                (<div>
                    <Typography variant="subtitle2" align="left" display="block" style={{fontSize: 12}}>
                        Status: <b>{running.status}</b>
                    </Typography>
                    <Typography variant="subtitle2"  align="left"  display="block" style={{fontSize: 12}}>
                        Inserted Count: <b>{running.inserted}/{running.actualCount}</b>
                    </Typography>
                    { running.comments && (
                        <Typography variant="subtitle2"  color="textPrimary" align="left" display="block" style={{fontSize: 12}}>
                            Comments: <b>{running.comments}</b>
                        </Typography>
                    )}
                    <Typography variant="subtitle2"  align="left" display="block" style={{fontSize: 12}}>
                        Last Started at: <b>{running.starttime ? getTimeInIST(running.starttime).format(format_fancy): "-"}</b>
                    </Typography>
                    <Typography variant="subtitle2"   align="left" display="block" style={{fontSize: 12}}>
                        Last Finished at: <b>{running.endtime ? getTimeInIST(running.endtime).format(format_fancy) : '-'}</b>
                    </Typography>
                </div>)
                :
                    '-');
            // setMerchantListingRun(data.starttime ? getTimeInIST(data.starttime).format(format_fancy) : );
        })
        .catch((error) => {
            console.log('error fetching status');
        });
    }

    const refetchMerchantListing = () => {
        showConfirmDialog('Confirm Request Merchant Listing? It may take sometime for the job to complete.', '', () => {
            closeConfirmDialog();
            let headers = {
                type: 'MERCHANT_LISTING'
            }
            ProductService.executeReportJob(headers)
            .then(response => {
                if (response.status === 500 || response.status === 503 || response.status === 201) {
                    return response.json()
                }
            })
            .then(data => {
                    showSnackBar(data.message, 'info');
            })
            .catch((error) => {
                console.log('error fetching status');
            });
        });
    }

    return (
        <div >
            <Grid container direction="row" justify="center" spacing={4}>
                <Grid item xs={12} lg={12}>
                    <Paper className={classes.section}>
                        <Grid container spacin={1} alignItems="flex-start" justify={"space-evenly"}>
                            <Grid container item xs={12} lg={12}>
                                <Grid item xs={12} lg={4} data-tour="jobs">
                                    <Typography  align="left" className={classes.header} variant="subtitle2">Job</Typography>
                                </Grid>
                                <Grid item xs={12} lg={3} data-tour="jobsenable">
                                    <Typography  align="left" className={classes.header} variant="subtitle2">Enabled</Typography>
                                </Grid>
                                {/* <Grid item xs={12} lg={2}>
                                    <Typography  align="left" className={classes.header} variant="subtitle2">Refresh</Typography>
                                </Grid> */}
                                <Grid item xs={12} lg={5} data-tour="jobsstatus">
                                    <Typography  align="left" className={classes.header} variant="subtitle2">Status</Typography>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider className={classes.divider}/>
                                </Grid>
                            </Grid>
                            {rows.length === 0 ? Array.from(Array(5), (_, i) => <Skeleton key={i} width={"100%"} height={50}/>) : rows}
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={12}>
                    {/* <Typography variant="subtitle2" style={{fontSize: 16, marginTop: 24}}>Products</Typography> */}
                    <Paper style={{marginTop: 24, paddingTop: 16, paddingBottom: 16}}>
                        <Grid container item xs={12} lg={12}justify="flex-start" alignItems="center">
                            <Grid item xs={12} lg={3}>
                                <Typography  align="left" className={classes.header} style={{paddingLeft: 32}} variant="subtitle2">Merchant Listing</Typography>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Button
                                    data-tour="merchant"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.button}
                                    startIcon={<SyncIcon />}
                                    onClick={(e) => refetchMerchantListing()}
                                >
                                    Re-Sync
                                </Button>
                            </Grid>
                            {/* <Grid item xs={12} lg={2}>
                                <Typography  align="left" className={classes.header} variant="subtitle2">Refresh</Typography>
                            </Grid> */}
                            <Grid item xs={12} lg={4}>
                                {merchantListingRun}
                            </Grid>
                            <Grid item xs={12} lg={1}>
                                <IconButton color="primary" aria-label="refresh" component="span" onClick={(e) => fetchMerchantListing()}>
                                    <RefreshIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
 