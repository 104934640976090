import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Table, TableBody, TableRow, ListItemText, useTheme } from "@material-ui/core";
import { ReportService } from "../../Services/ReportService";
import {red, blue} from '@material-ui/core/colors';
import moment from "moment";
import ItemSelection from '../../components/ItemSelection';
// import Timeline from "../../components/Timeline";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import EnhancedTableToolbar from "../../components/EnhancedTableToolbar";
import { Doughnut } from "react-chartjs-2";
import { colorGenerator, toInt, toPercentage, formatNumber, StyledTableCell } from "../../Utils";
import queryString from 'query-string';
import XLSX from 'xlsx';
import { LocationService } from "../../Services/LocationService";
import { Link as RouterLink} from 'react-router-dom';
import DateRangePickerCustom from "../../components/DateRangePickerCustom";
import { AppContext } from "../../AppContextProvider";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    section: {
        width: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    StyledTableCell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export default function GeoAnalyseByLocation(props) {

    const {setLinearLoading, setTourSteps, setTitle, showProgressDialog, closeProgressDialog} = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();
    
    const [selectedMapping, setSelectedMapping] = React.useState(null);
    const [mapping, setMapping] = React.useState([]);
    const [stateData, setStateData] = React.useState([]);
    const [cityRawData, setCityRawData] = React.useState([]);
    const [stateTotalCount, setStateTotalCount] = React.useState(0);
    const [stateTotalAmount, setStateTotalAmount] = React.useState(0);
    const [cityTotalCount, setCityTotalCount] = React.useState(0);
    const [cityTotalAmount, setCityTotalAmount] = React.useState(0);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').add(-30, 'd'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [regionData, setRegionData] = React.useState({});
    const [cityData, setCityData] = React.useState({});
    const order = 'desc';
    const orderBy = 'Title';
    const [stateRows, setStateRows] = React.useState([]);
    const [cityRows, setCityRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);

    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No', sortable: false },
        { id: 'ASIN', align: 'left', numeric: false, disablePadding: true, label: 'ASIN', sortable: false },
        { id: 'SellerSKU', align: 'left', numeric: false, disablePadding: true, label: 'Seller SKU', sortable: false },
        // { id: 'Title', align: 'left', numeric: false, disablePadding: true, label: 'Product', sortable: false },
        { id: 'TotalCount', numeric: true, disablePadding: true, label: 'Units', sortable: false  },
        { id: 'Percentage', align: 'left', numeric: true, disablePadding: true, label: 'Percentage', sortable: false },
        { id: 'TotalAmount', numeric: true, disablePadding: true, label: 'Total Amount', sortable: false  }
    ];

    const regionOptions = {
        // responsive : true,
        elements: {
            arc: {
                borderWidth: 5,
                borderColor: theme.palette.white,
                backgroundColor: theme.palette.white
            },
        },
        maintainAspectRatio: true, 
        onClick: null,
        cutoutPercentage: 80,
        legend: {
            display: false,
            align: 'end',
            position: 'bottom',
            fullWidth: true,
            onClick: () => {},
            labels: {
                fontSize: 10
            }
        }, 
        tooltips: {
            intersect: false,
            caretSize: 10,
            yPadding: 20,
            xPadding: 20,
            borderWidth: 1,
            titleFontSize: 10,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.black,
            titleFontColor: theme.palette.white,
            bodyFontColor: theme.palette.white,
            footerFontColor: theme.palette.white,
            callbacks: {
                label: function(tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue/total*100).toFixed(1));
                    // return " Count: " + currentValue + ' (' + percentage + '%)';
                    return ` ${currentValue}units (${percentage}%)`;
                },
                title: function(tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    };

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="selectlocation"]',
                content: `Filter by State/City`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selecttimeline]',
                content: `Filter by Date Range`,
                style: {
                    backgroundColor: '#fff',
                },
            }
        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    React.useEffect(() => {
        setTitle("Analyze by Location");
        let qMapping = null;
        if(props.location.search){
            let qString = queryString.parse(props.location.search);
            qMapping = {};
            if(qString.state){
                qMapping.state = qString.state;
                if(qString.city)
                    qMapping.city = qString.city;
                else 
                    qMapping.city = 'All Cities';
                if(qString.startdate)
                    setStartDate(moment(qString.startdate));
                if(qString.enddate)
                    setEndDate(moment(qString.enddate));
                qMapping.label = `${qMapping.state} - ${qMapping.city}`;
            }
        }

        LocationService.stateMapping()
        .then(data => {
            let states = new Set(data.map(d => d.state));
            let mapping = [{state: 'All States', city: 'All Cities'}, ...data];
            states.forEach(s => mapping.push({state: s, city: 'All Cities'}));
            mapping = mapping.map(m => ({...m, label: `${m.state} - ${m.city}`}));
            mapping.sort((a, b) => (a.label > b.label) ? 1 : -1);
            setMapping(mapping);
            if(qMapping === null){
                setSelectedMapping(mapping[0]);
                setGenerate(true);
            } else {
                setSelectedMapping(qMapping);
                setGenerate(true);
            }
        })
        .catch(error => console.log(error));

    }, [true]);

    React.useEffect(() => {
        if(mapping.length > 0 && generate && selectedMapping){
            if(selectedMapping.city !== 'All Cities'){
                fetchCityWiseData();
            }
            fetchStateWiseData();
            setGenerate(false);
        }
    }, [selectedMapping, generate, mapping]);

    const fetchStateWiseData = () => {
        setRegionData({});
        setCityData({});
        setLinearLoading(true);
        showProgressDialog();
        ReportService.fetchRelatedProductsFromLocation(selectedMapping.state !== 'All States' ? selectedMapping.state : null, null, startdate, enddate)
        .then(data => {
            let labels = [], count = [];
            let srow = [];
            let accumulator = 0, totalAmount = 0;
            let total = data.reduce(function(accumulator, currentValue) {
                return accumulator + parseFloat(currentValue.TotalCount);
            }, accumulator);
            setStateTotalCount(total);
            totalAmount = data.reduce(function(accumulator, currentValue) {
                return accumulator + parseFloat(currentValue.TotalAmount || 0);
            }, totalAmount);
            setStateTotalAmount(totalAmount);
            setStateData(data);
            data.forEach((element, index) => {
                labels.push(`${element.ASIN}\n${element.SellerSKU}`);
                count.push(toInt(element.TotalCount));
                srow.push(
                    <TableRow key={index} hover>
                        <StyledTableCell scope="row" align="center" padding='default' style={{width: 50}}>
                            {index + 1}
                        </StyledTableCell>
                        <StyledTableCell scope="row" align="justify" padding='default'>
                            <RouterLink  style={{cursor: 'pointer', textDecoration: 'none', color: theme.palette.text.link}} to={`/geo/analyze/asin?asin=${element.ASIN}`}>
                                {element.ASIN}
                            </RouterLink>
                        </StyledTableCell>
                        <StyledTableCell scope="row" align="justify" padding='default'>
                            {element.SellerSKU}
                        </StyledTableCell>
                        {/* <StyledTableCell align="justify" padding='default'>
                            <Typography variant="subtitle2">
                                {element.Title}
                            </Typography>
                        </StyledTableCell> */}
                        <StyledTableCell align="right" padding='default'>
                            {element.TotalCount}
                        </StyledTableCell>
                        <StyledTableCell align="right" padding='default'>
                            {toPercentage(element.TotalCount, total)}
                        </StyledTableCell>
                        <StyledTableCell align="right" padding='default' style={{paddingRight: 16}}>
                            {formatNumber(element.TotalAmount)}
                        </StyledTableCell>
                    </TableRow>)
                });
            let rd = {
                labels: labels,
                datasets: [{
                    label: "State Wise",
                    data: count,
                    backgroundColor: colorGenerator(count.length)
                }]
            };
            setRegionData(rd);
            setStateRows(srow);
            setLinearLoading(false);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false); 
            closeProgressDialog();
        });
    };

    const fetchCityWiseData = (state) => {
        setLinearLoading(true);
        ReportService.fetchRelatedProductsFromLocation(selectedMapping.state, selectedMapping.city, startdate, enddate)
        .then(data => {
            let labels = [], count = [];
            let crow = [], total = 0, totalAmount = 0;
            total = data.reduce(function(accumulator, currentValue) {
                return accumulator + parseFloat(currentValue.TotalCount || 0);
            }, total);
            setCityTotalCount(total);
            totalAmount = data.reduce(function(accumulator, currentValue) {
                return accumulator + parseFloat(currentValue.TotalAmount || 0);
            }, totalAmount);
            setCityTotalAmount(totalAmount);
            setCityRawData(data);
            data.forEach((element, index) => {
                labels.push(`${element.ASIN}\n${element.SellerSKU}`);
                count.push(toInt(element.TotalCount));
                crow.push(
                    <TableRow key={index} hover>
                        <StyledTableCell scope="row" align="center" padding='default' style={{width: 50}}>
                            {index + 1}
                        </StyledTableCell>
                        <StyledTableCell scope="row" align="justify" padding='default'>
                            <RouterLink  style={{cursor: 'pointer', textDecoration: 'none', color: theme.palette.text.link}} to={`/geo/analyze/asin?asin=${element.ASIN}`}>
                                {element.ASIN}
                            </RouterLink>
                        </StyledTableCell>
                        <StyledTableCell scope="row" align="justify" padding='default'>
                            {element.SellerSKU}
                        </StyledTableCell>
                        {/* <StyledTableCell align="justify" padding='default'>
                            <Typography variant="subtitle2">
                                {element.Title}
                            </Typography>
                        </StyledTableCell> */}
                        <StyledTableCell align="right" padding='default'>
                            {element.TotalCount}
                        </StyledTableCell>
                        <StyledTableCell align="right" padding='default'>
                            {toPercentage(element.TotalCount, total)}
                        </StyledTableCell>
                        <StyledTableCell align="right" padding='default' style={{paddingRight: 16}}>
                            {formatNumber(element.TotalAmount)}
                        </StyledTableCell>
                    </TableRow>);
            });
            let rd = {
                labels: labels,
                datasets: [{
                    label: "City Wise",
                    data: count,
                    backgroundColor: colorGenerator(count.length)
                }]
            };
            setCityData(rd);
            setCityRows(crow);
            setLinearLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false); 
        });
    }

    const exportReport = type => e => {
        setLinearLoading(true);
        let row = [];
        let filename = selectedMapping.state;
        row.push(columns.map(c => c.label));
        if(type === 'state') {
            stateData.forEach((d, index) => {
                row.push([index+1, d.ASIN, d.SellerSKU, d.Title, toInt(d.TotalCount), toPercentage(d.TotalCount, stateTotalCount), parseFloat(d.TotalAmount)]);
            });
            filename += ' - State wise Report';
        } else if(type === 'city') {
            cityRawData.forEach((d, index) => {
                row.push([index+1, d.ASIN, d.SellerSKU, d.Title, toInt(d.TotalCount), toPercentage(d.TotalCount, stateTotalCount), parseFloat(d.TotalAmount)]);
            });
            filename += ' - ' + selectedMapping.city + " City wise Report";
        }

        const wb = XLSX.utils.book_new();
        const wsAll = XLSX.utils.aoa_to_sheet(row);
        XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
        XLSX.writeFile(wb, filename + ".xlsx");

        setLinearLoading(false);
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={2} justify="center">
                    <Grid item xl={8} lg={10} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item xs={12} lg={3} data-tour="selectlocation">
                                    <ItemSelection 
                                        clearable={true} 
                                        required 
                                        label='Search by State / City' 
                                        value={selectedMapping} 
                                        optionLabel="label" 
                                        options={mapping} 
                                        selected={setSelectedMapping}/>
                                </Grid>
                                <Grid item xs={12} lg={3} data-tour="selecttimeline">
                                    {/* <Timeline singleline startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/> */}
                                    <DateRangePickerCustom startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Button disabled={!selectedMapping} variant="contained" className={classes.button} color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                        Generate Report
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                        { regionData.labels && regionData.labels.length === 0 &&
                            <div style={{marginTop: 50}}>
                                <Typography align="center" variant="subtitle2" gutterBottom>No Data Available</Typography>
                            </div>
                        }
                        { cityData.labels && cityData.labels.length > 0 && cityData.datasets && 
                            (<Paper className={classes.section}>
                                <Grid container spacin={1} alignItems="center">
                                    <Grid item xs={12} lg={3}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} lg={12}>
                                                {/* (selectedMapping && selectedMapping.city)  || ''*/}
                                                <Typography align="left" variant="subtitle2" className={classes.title} gutterBottom>{'City'} Sales</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={12}>
                                                <Doughnut 
                                                    width={50} 
                                                    height={40} 
                                                    options={regionOptions} 
                                                    data={cityData} 
                                                    style={{float: "left"}}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    { cityRows.length > 0 && 
                                        (<Grid item xs={12} lg={9}>
                                            <EnhancedTableToolbar 
                                                title={
                                                    <ListItemText primaryTypographyProps={{align: 'left', variant: 'h6'}} secondaryTypographyProps={{align: 'left'}}  primary={`Total Count: ${cityTotalCount}`} secondary={`Total Amount: ${formatNumber(cityTotalAmount)}`}/>} 
                                                exportReport={exportReport('city')}/>
                                            <div style={{maxHeight: 500, overflowY: 'auto', display: 'block'}}>
                                                <Table className={classes.table} stickyHeader>
                                                    <EnhancedTableHead
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={() => {}}
                                                        rows ={columns} />
                                                    <TableBody>
                                                        {cityRows}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Paper>
                        )}
                        { regionData.labels && regionData.labels.length > 0 && (
                            <Paper className={classes.section}>
                                <Grid container spacin={1} alignItems="center">
                                    <Grid item xs={12} lg={3}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} lg={12}>
                                                {/* (selectedMapping && selectedMapping.state) || '' */}
                                                <Typography align="left" variant="subtitle2" className={classes.title} gutterBottom>{'State'} Sales</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={12}>
                                                <Doughnut 
                                                    width={50} 
                                                    height={40} 
                                                    options={regionOptions} 
                                                    data={regionData}
                                                    style={{float: "left"}}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    { stateRows.length > 0 && 
                                        (<Grid item xs={12} lg={9}>
                                            <EnhancedTableToolbar 
                                                title={
                                                    <ListItemText primaryTypographyProps={{align: 'left', variant: 'h6'}} secondaryTypographyProps={{align: 'left'}}  primary={`Total Count: ${stateTotalCount}`} secondary={`Total Amount: ${formatNumber(stateTotalAmount)}`}/>} 
                                                exportReport={exportReport('state')}/>
                                            <div style={{maxHeight: 500, overflowY: 'auto', display: 'block'}}>
                                                <Table className={classes.table} stickyHeader size="small" padding="none">
                                                    <EnhancedTableHead
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={() => {}}
                                                        rows ={columns} />
                                                    <TableBody>
                                                        {stateRows}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Paper>
                            )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
 