import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Button, Divider, Table, TableBody, TableRow } from "@material-ui/core";
import { ReportService } from "../../Services/ReportService";
import {red, blue} from '@material-ui/core/colors';
import { ProductService } from "../../Services/ProductService";
import moment from "moment";
import ItemSelection from '../../components/ItemSelection';
import EnhancedTableHead from "../../components/EnhancedTableHead";
import EnhancedTableToolbar from "../../components/EnhancedTableToolbar";
import { formatNumber, format_display_fancy_notime, formatQty, getTimeInIST, BootstrapTooltip, StyledTableCell } from "../../Utils";
import queryString from 'query-string';
import XLSX from 'xlsx';
import { AppContext } from "../../AppContextProvider";
import { MasterService } from "../../Services/MasterService";
import { LocationService } from "../../Services/LocationService";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    StyledTableCell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export default function SalesVelocity(props) {

    const {setTitle, setTourSteps, setLinearLoading, showProgressDialog, closeProgressDialog, updateProgressMessage} = useContext(AppContext);
    const classes = useStyles();

    const [selectedMapping, setSelectedMapping] = React.useState(null);
    const [mapping, setMapping] = React.useState([]);
    const [asin, setASIN] = React.useState([]);
    const [asinList, setASINList] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [loadingData, setLoadingData] = React.useState([]);
    const order = 'desc';
    const orderBy = 'Title';
    const [generate, setGenerate] = React.useState(false);
    const [selectedTag, setSelectedTag] = React.useState(null);
    const [tags, setTags] = React.useState(null);

    const columns = [
        // { id: 'index', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No', sortable: false },
        { id: 'asin', align: 'left', numeric: false, disablePadding: true, label: 'Product', sortable: false },
        { id: 'today', align: 'center', numeric: false, disablePadding: true, label: 'Today', sortable: false },
        { id: 'yesterday', align: 'center', numeric: false, disablePadding: true, label: 'Yesterday', sortable: false },
        { id: 'this_week', align: 'center', numeric: false, disablePadding: true, label: 'This Week', sortable: false },
        { id: 'this_month', align: 'center', numeric: false, disablePadding: true, label: 'This Month', sortable: false },
        { id: 'last_month', align: 'center', numeric: false, disablePadding: true, label: 'Last Month', sortable: false },
        { id: 'this_year', align: 'center', numeric: false, disablePadding: true, label: 'This Year', sortable: false },
        { id: 'last_year', align: 'center', numeric: false, disablePadding: true, label: 'Last Year', sortable: false },
        { id: 'forever', align: 'center', numeric: false, disablePadding: true, label: 'Forever', sortable: false },
        { id: 'last_sale_date', align: 'center', numeric: false, disablePadding: true, label: 'Last Sale Date', sortable: false },
        { id: 'days_last_sale', align: 'center', numeric: false, disablePadding: true, label: 'Days Since Last Sale', sortable: false },
    ];

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="selectasin"]',
                content: `Filter by ASIN`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selecttag]',
                content: `Filter by Tag`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selectlocation]',
                content: `Filter by Location`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            
        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    React.useEffect(() => {
        setTitle("Sales Velocity");
        fetchTags();
        let asin = null;
        if(props.location.search){
            let qString = queryString.parse(props.location.search);
            if(qString.asin){
                asin = qString.asin;
                setLinearLoading(true);    
                setGenerate(true);
            }
            // setASIN(props.location.query.asin);
        }

        ProductService.listProductNames()
        .then(data => {
            let list = data.map(d => ({...d, label : `[${d.asin}][${d.seller_sku}] ${d.name.substring(0, 120)}${d.name.length > 120 ? '...' : ''}`}));
            setASINList(list);
            if(asin){
                let a = list.filter(l => l.asin === asin);
                setASIN(a.length > 0 ? a : null);
                // if(a.length > 0) {
                //     generateReport();
                // }
            }
        })
        .catch(error => console.log(error));

        Promise.all([LocationService.stateMapping(), LocationService.regionMapping()])
        .then(datas => {
            let mapping = [];
            if(datas[1].length > 0){
                datas[1].forEach(d => {
                    mapping.push({...d, label: `${d.region_label} - ${d.zone} Zone`, type: 'Region'})
                })
            }
            if(datas[0].length > 0){
                let data = datas[0];
                data = data.map(d => ({...d}));
                let states = new Set(data.map(d => d.state));
                let m = [{state: 'All States', city: 'All Cities'}, ...data];
                states.forEach(s => m.push({state: s, city: 'All Cities'}));
                m = m.map(m => ({...m, label: `${m.state} - ${m.city}`, type: 'Location'}));
                m.sort((a, b) => (a.label > b.label) ? 1 : -1);
                mapping.push(...m);
                setSelectedMapping(m[0]);
            }
            setMapping(mapping);
            // setGenerate(true);
        })
        .catch(error => console.log(error));

    }, [true]);

    const fetchTags = () => {
        MasterService.getTags()
        .then(data => {
            // console.log(data);
            let allTags = {label: 'All Tags', tag: '-1'};
            setTags([allTags, ...data]);
            setSelectedTag(allTags);
        })
        .catch(error => console.log(error));
    }

    React.useEffect(() => {
        if(generate && (asin.length > 0 || selectedTag) ){
            let rASIN = [];
            if (asin.length > 0){
                rASIN = [...asin];
            } else if(selectedTag.tag === '-1'){
                rASIN = [...asinList];
            } else if(selectedTag.tag !== -1){
                rASIN = [...asinList.filter(a => a.tags.includes(selectedTag.tag))];
            }

            generateReport(rASIN);
            setGenerate(false);
        }
    }, [generate, asin, selectedTag]);

    const generateReport = (rASIN) => {
        setLinearLoading(true);
        showProgressDialog(`Loaded Stats for 0/${rASIN.length}`);
        setLoadingData(Array.from(Array(rASIN.length), (_, i) => (<Skeleton key={i} width={'98.3vw'} height={100} />)));
        setData([]);
        let locationFilter = null;
        let locationType = null;
        if(selectedMapping && selectedMapping.type === 'Region'){
            locationType = 'region';
            locationFilter = selectedMapping.region;
        } else if(selectedMapping && selectedMapping.type === 'Location'){
            if(selectedMapping.state !== 'All States'){
                locationType = 'location';
                locationFilter = selectedMapping.state;
                if(selectedMapping.city !== 'All Cities'){
                    locationFilter = `${locationFilter}|${selectedMapping.city}`;
                }
            }
        } 
        let loaded = 0;
        rASIN.forEach((asin, index) => {
            ReportService.fetchSalesStats(asin.asin, 't,y,tw,tm,lm,ty,ly,full,lastsaledate', locationType, locationFilter)
            .then(d => {
                setData(prevData => ([...prevData, {...asin, ...d.sales}]));
                setLoadingData(prevLoadingData => {
                    prevLoadingData.shift()
                    return ([...prevLoadingData])
                })
                updateProgressMessage(`Loaded Stats for ${loaded++}/${rASIN.length}`);
                if(index === rASIN.length - 1){
                    closeProgressDialog();
                    setLinearLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
            });
        })
        // fetchCityWiseData(null);
    };

    const exportReport = e => {
        setLinearLoading(true);
        let row = [];
        let filename = 'Sales Velocity Report';
        row.push(['ASIN', 'SKU', ...columns.map(c => c.label)]);
        data.forEach((d) => {
            row.push([
                        d.asin, 
                        d.seller_sku, 
                        d.name, 
                        d.today ? parseFloat(d.today.totalAmount || 0) : 0, 
                        d.yesterday ? parseFloat(d.yesterday.totalAmount || 0) : 0, 
                        d.this_week ? parseFloat(d.this_week.totalAmount || 0) : 0, 
                        d.current_month ? parseFloat(d.current_month.totalAmount || 0) : 0, 
                        d.last_month ? parseFloat(d.last_month.totalAmount || 0) : 0,
                        d.current_year ? parseFloat(d.current_year.totalAmount || 0) : 0,
                        d.last_year ? parseFloat(d.last_year.totalAmount || 0) : 0,
                        d.forever ? parseFloat(d.forever.totalAmount || 0) : 0,
                        d.last_sale_date ? getTimeInIST(d.last_sale_date.PurchaseDate).format(format_display_fancy_notime) : '-',
                        d.last_sale_date ? moment().diff(getTimeInIST(d.last_sale_date.PurchaseDate), 'days') : '',
                    ]);
        });
        const wb = XLSX.utils.book_new();
        const wsAll = XLSX.utils.aoa_to_sheet(row);
        XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
        XLSX.writeFile(wb, filename + ".xlsx");
        setLinearLoading(false);
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" justify="center" spacing={2}>
                    <Grid item xl={12} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item xs={6} lg={3} data-tour="selectasin">
                                    <ItemSelection 
                                        multiple
                                        clearable={true}
                                        label='Select ASIN' 
                                        value={asin} 
                                        optionLabel='label' 
                                        options={asinList} 
                                        selected={setASIN}
                                        limitTags={2}
                                        disabled={selectedTag && selectedTag.tag !== '-1'}/>
                                </Grid>
                                <Grid item xs={6} lg={2} data-tour="selecttag">
                                    <ItemSelection 
                                        label='Select Tags' 
                                        clearable={true}
                                        value={selectedTag} 
                                        optionLabel='label' 
                                        options={tags} 
                                        disabled={asin && asin.length > 0}
                                        selected={(tag) => {
                                            setSelectedTag(tag);
                                        }}/>
                                </Grid>
                                <Grid item xs={12} lg={4} data-tour="selectlocation">
                                    <ItemSelection 
                                        clearable={true} 
                                        required 
                                        label='Search by Region / State / City' 
                                        value={selectedMapping} 
                                        optionLabel="label" 
                                        options={mapping} 
                                        selected={setSelectedMapping}
                                        groupBy={(option) => option.type}/>
                                </Grid>
                                {/* <Grid item xs={12} lg={3}>
                                </Grid> */}
                                <Grid item xs={12} lg={3}>
                                    <Button variant="contained" disabled={!asin} className={classes.button} color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                        Generate Report
                                    </Button>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 10, marginTop: 10}}/>
                                </Grid>
                                {/* { data.length === 0 &&
                                    <Grid item xs={12} lg={12}>
                                        <Typography align="center" variant="subtitle2" gutterBottom>No Data Available</Typography>
                                    </Grid>
                                } */}
                                <Grid container alignItems={'flex-start'} item xs={12} lg={12}>
                                    <Grid item xs={12} xl={12} lg={12}>
                                        <EnhancedTableToolbar exportReport={exportReport}/>
                                        <div style={{overflow: 'auto', display: 'block'}}>
                                            <Table className={classes.table} stickyHeader>
                                                <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={() => {}}
                                                    rows ={columns} />
                                                <TableBody>
                                                    {data.map((element, index) => (
                                                            <TableRow key={index} hover>
                                                                <StyledTableCell scope="row" align="left" padding='default' style={{width: '28vw', paddingLeft: 8, paddingRight: 8}}>
                                                                    <BootstrapTooltip title={element.name}>
                                                                        <div>
                                                                            <span>{`${element.name.substring(0, 125)}${element.name.length > 125 ? '...' : ''}`}</span><br/>
                                                                            <b>{'ASIN: '}</b>{element.asin}<br/>
                                                                            <b>{'SKU: '}</b>{element.seller_sku}
                                                                        </div>
                                                                    </BootstrapTooltip>
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="right" padding='default' style={{padding: 5}}>
                                                                    <BootstrapTooltip title={`Units: ${formatQty(element.today ? (element.today.quantity || 0): 0)}`}>
                                                                        <div>{formatNumber(element.today ? (element.today.totalAmount || 0) : 0)}</div>
                                                                    </BootstrapTooltip>
                                                                </StyledTableCell>
                                                                <StyledTableCell scope="row" align="right" padding='default' style={{padding: 5}}>
                                                                    <BootstrapTooltip title={`Units: ${formatQty(element.yesterday? (element.yesterday.quantity || 0): 0)}`}>
                                                                        <div>{formatNumber(element.yesterday ? (element.yesterday.totalAmount || 0): 0)}</div>
                                                                    </BootstrapTooltip>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="right" padding='default' style={{padding: 5}}>
                                                                    <BootstrapTooltip title={`Units: ${formatQty(element.this_week ? (element.this_week.quantity || 0): 0)}`}>
                                                                        <div>{formatNumber(element.this_week ? (element.this_week.totalAmount || 0) : 0)}</div>
                                                                    </BootstrapTooltip>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="right" padding='default' style={{padding: 5}}>
                                                                    <BootstrapTooltip title={`Units: ${formatQty(element.current_month ? (element.current_month.quantity || 0) : 0)}`}>
                                                                        <div>{formatNumber(element.current_month? (element.current_month.totalAmount || 0) : 0)}</div>
                                                                    </BootstrapTooltip>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="right" padding='default' style={{padding: 5}}>
                                                                    <BootstrapTooltip title={`Units: ${formatQty(element.last_month ? (element.last_month.quantity || 0) : 0)}`}>
                                                                        <div>{formatNumber(element.last_month ? (element.last_month.totalAmount || 0) : 0)}</div>
                                                                    </BootstrapTooltip>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="right" padding='default' style={{padding: 5}}>
                                                                    <BootstrapTooltip title={`Units: ${formatQty(element.current_year ? (element.current_year.quantity || 0) : 0)}`}>
                                                                        <div>{formatNumber(element.current_year ? (element.current_year.totalAmount || 0) : 0)}</div>
                                                                    </BootstrapTooltip>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="right" padding='default' style={{padding: 5}}>
                                                                    <BootstrapTooltip title={`Units: ${formatQty(element.last_year ? (element.last_year.quantity || 0) : 0)}`}>
                                                                        <div>{formatNumber(element.last_year ? (element.last_year.totalAmount || 0) : 0)}</div>
                                                                    </BootstrapTooltip>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="right" padding='default' style={{padding: 5}}>
                                                                    <BootstrapTooltip title={`Units: ${formatQty(element.full ? (element.full.quantity || 0) : 0)}`}>
                                                                        <div>{formatNumber(element.full ? (element.full.totalAmount || 0) : 0)}</div>
                                                                    </BootstrapTooltip>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" padding='default' style={{padding: 5}}>
                                                                    <BootstrapTooltip title={`Units: ${formatQty(element.last_sale_date ? element.last_sale_date.quantity : 0)}`}>
                                                                        <div>
                                                                            { element.last_sale_date.PurchaseDate ? getTimeInIST(element.last_sale_date.PurchaseDate).format(format_display_fancy_notime) : '-'}
                                                                        </div>
                                                                    </BootstrapTooltip>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" padding='default' style={{paddingRight: 16, width: '8vw'}}>
                                                                    <BootstrapTooltip title={`Units: ${formatQty(element.last_sale_date.quantity)}`}>
                                                                        <div>
                                                                            { element.last_sale_date.PurchaseDate ? `${moment().diff(getTimeInIST(element.last_sale_date.PurchaseDate), 'days')} days` : '-'}
                                                                        </div>
                                                                    </BootstrapTooltip>
                                                                </StyledTableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                            {loadingData}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
 