import React, { useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { formatNumber } from '../Utils';
import { Grid, Fade, LinearProgress, Divider, Chip, IconButton } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { ProductService } from '../Services/ProductService';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PropTypes from 'prop-types'
import BSRGraph from './BSRGraph';
import ClearIcon from '@material-ui/icons/Clear';
import { green, red } from '@material-ui/core/colors';
import { ReportService } from '../Services/ReportService';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
    },
    subheading: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 500,
        textAlign: "left"
    },
    dialogContent: {
        paddingTop: 0
    },
    green: {
        color: green[700],
    },
    red: {
        color: red[500],
    },
}));

export default function ASINDetails(props) {
    const [loading, setLoading] = React.useState(false); 
    const [generate, setGenerate] = React.useState(false);
    const [details, setDetails] = React.useState(null)
    const [salesl7, setSalesl7] = React.useState(null)
    const [salesl30, setSalesl30] = React.useState(null)
    const [salestm, setSalestm] = React.useState(null)
    const [saleslm, setSaleslm] = React.useState(null)
    const [salesty, setSalesty] = React.useState(null)
    const [salesly, setSalesly] = React.useState(null)
    const [salesfull, setSalesfull] = React.useState(null)
    const [asin, setASIN] = React.useState(null);
    const classes = useStyles();
    
    const componentIsMounted = useRef(true);
    React.useEffect(() => {
        return () => {
            componentIsMounted.current = false
        }
    }, []);

    React.useEffect(() => {
        if(componentIsMounted.current && props.show){
            setGenerate(true);
            setASIN(props.asin);
        }
        return () => {
            setASIN(null);
            setGenerate(false);
            setDetails(null);
        }
    }, [true])

    React.useEffect(() => {
        if(componentIsMounted.current && generate && asin){
            setLoading(true);
            setGenerate(false);

            ProductService.listMetricsByASIN(props.asin, false)
            .then((data) => {
                if(data.length > 0){
                    if(componentIsMounted.current){
                        setDetails(data[0]);
                        setLoading(false);
                    }
                }
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });

            ReportService.fetchSalesStats(props.asin, 'l7')
            .then(data => {
                if(componentIsMounted.current){
                    setSalesl7(data.sales);
                }
            })
            .catch(error => console.log(error));
            ReportService.fetchSalesStats(props.asin, 'l30')
            .then(data => {
                if(componentIsMounted.current){
                    setSalesl30(data.sales);
                }
            })
            .catch(error => console.log(error));
            ReportService.fetchSalesStats(props.asin, 'tm')
            .then(data => {
                if(componentIsMounted.current){
                    setSalestm(data.sales);
                }
            })
            .catch(error => console.log(error));
            ReportService.fetchSalesStats(props.asin, 'lm')
            .then(data => {
                if(componentIsMounted.current){
                    setSaleslm(data.sales);
                }
            })
            .catch(error => console.log(error));
            ReportService.fetchSalesStats(props.asin, 'ty')
            .then(data => {
                if(componentIsMounted.current){
                    setSalesty(data.sales);
                }
            })
            .catch(error => console.log(error));
            ReportService.fetchSalesStats(props.asin, 'ly')
            .then(data => {
                if(componentIsMounted.current){
                    setSalesly(data.sales);
                }
            })
            .catch(error => console.log(error));
            ReportService.fetchSalesStats(props.asin, 'full')
            .then(data => {
                if(componentIsMounted.current){
                    setSalesfull(data.sales);
                }
            })
            .catch(error => console.log(error));
        }
        return () => {

        }
    }, [generate]);

    return (
        <Grid container justify="flex-start" alignItems="center" spacing={0}>
            <Grid item xs={12} lg={12}>
                <Fade
                    in={loading}
                    style={{
                        transitionDelay: loading ? '10ms' : '0ms'
                    }}
                    unmountOnExit
                    >
                    <LinearProgress style={{height: 5, width: "100%"}} color="secondary"/>
                </Fade>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Grid container justify="center" alignItems="flex-start" spacing={2} style={{marginTop: 8}}>
                    <Grid item xs={12} lg={4}>
                        { 
                            details ? 
                                <img src={details.image_url ? details.image_url.replace('_SL75_', '_SL225_') : null} height="" width="" alt="" style={{marginRight: 15, marginLeft: 24}}/>
                            :
                                <Skeleton width={200} height={300} />
                        }
                    </Grid>
                    <Grid item container xs={12} lg={8} alignItems="center">
                    {   
                        !details ?
                        <Grid item xs={6} lg={8}>
                            <Skeleton width="80%" height={30} />
                        </Grid>
                        :
                        <Grid item xs={12} lg={8}>
                            <span style={{marginRight: 3}}>
                                ASIN: 
                            </span>
                            <span style={{marignLeft: 8}}>
                                {details && details.asin}
                            </span>
                            {   
                                details && (
                                    details.status ? 
                                        details.status === 'ACTIVE' ?  
                                            <IconButton component="span" className={classes.green} style={{padding: 0, paddingLeft: 12}}>
                                                <FiberManualRecordIcon />
                                            </IconButton>
                                        : 
                                            <IconButton className={classes.red} component="span" style={{padding: 0, paddingLeft: 12}}>
                                                <FiberManualRecordIcon />
                                            </IconButton>
                                    : 
                                        '-'
                                )
                            }
                        </Grid>
                    }
                    {   
                        !details ?
                        <Grid item xs={12} lg={4}>
                            <Skeleton width="80%" height={30} />
                        </Grid>
                        :
                        <Grid item xs={12} lg={4}>
                            <span style={{marginRight: 3}}>
                                Top Category: 
                            </span>
                            <span style={{marignLeft: 8}}>
                                {details && details.categories.top_category_name}
                            </span>
                        </Grid>
                    }
                    {   
                        !details ?
                        <Grid item xs={12} lg={8}>
                            <Skeleton width="80%" height={30} />
                        </Grid>
                        :
                        <Grid item xs={12} lg={8}>
                            <span style={{marginRight: 3}}>
                                SKU: 
                            </span>
                            <span style={{marignLeft: 8}}>
                                {details && details.seller_sku}
                            </span>
                        </Grid>
                    }
                    {   
                        !details ?
                        <Grid item xs={12} lg={4}>
                            <Skeleton width="80%" height={30} />
                        </Grid>
                        :
                        <Grid item xs={12} lg={4}>
                            <span style={{marginRight: 3}}>
                                Node Category:
                            </span>
                            <span style={{marignLeft: 8}}>
                                {details && details.categories.category_name}
                            </span>
                        </Grid>
                    }
                        <Grid item xs={12} lg={12}>
                            <Divider style={{marginTop: 10, marginBottom: 10}}/>
                        </Grid>
                        <Grid item container alignItems="center" justify="flex-start" xs={12} lg={6}>
                            <Grid item xs={6} lg={6}>
                                <Typography align="left" variant="body2" className={classes.subheading}>
                                    MRP
                                </Typography>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <Typography variant="body2" align="justify" style={{marginRight: 3}}>
                                    {details ? formatNumber(details.maximum_retail_price || 0) : <Skeleton width={'70%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <Typography align="left" variant="body2" className={classes.subheading}>
                                    Price
                                </Typography>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <Typography variant="body2" align="justify" style={{marginRight: 3}}>
                                    {details ? formatNumber(details.price || 0) : <Skeleton width={'70%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <Typography align="left" variant="body2" className={classes.subheading}>
                                    Competitive Pricing
                                </Typography>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <Typography variant="body2" align="justify" style={{marginRight: 3}}>
                                    {details ? (details.competitive_pricing < 0 ? 'NA' : formatNumber(details.competitive_pricing || 0))  : <Skeleton width={'70%'} height={'100%'}/>} 
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <Typography align="left" variant="body2" className={classes.subheading}>
                                    No of Listings
                                </Typography>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                { details ? details.no_of_listings > 0 ?
                                        <Typography variant="body2" color={details.no_of_listings > 1 ? 'secondary' : 'primary'}>{details.no_of_listings}</Typography>
                                        :
                                        <IconButton className={classes.red} component="span" style={{padding: 0}}>
                                            <ClearIcon />
                                        </IconButton>
                                    :
                                        <Skeleton width={'70%'} height={'100%'}/>
                                }
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <Typography align="left" variant="body2" className={classes.subheading}>
                                    Tags
                                </Typography>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                {
                                    details ? details.tags.map(tag => <Chip key={tag} label={tag} size="small" color="primary" style={{margin: 5, fontSize: 12}}/>) : <Skeleton width={'70%'} height={'100%'}/>
                                }
                            </Grid>
                        </Grid>
                        <Grid item container justify="flex-start" xs={12} lg={6}>
                            <Grid item xs={12} lg={12}>
                                <Typography variant="h6" component="span" style={{marginRight: 3}}>
                                    Sales Figures
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}/>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="subtitle2" align="right" style={{marginRight: 3}}>
                                    Quantity
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="subtitle2" align="right" style={{marginRight: 3}}>
                                    Amount
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" className={classes.subheading}>
                                    Last 7 days
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {salesl7 ? salesl7.last_7.quantity : <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {salesl7 ? formatNumber(salesl7.last_7.totalAmount) : <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" className={classes.subheading}>
                                    Last 30 days
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {salesl30 ? salesl30.last_30.quantity : <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {salesl30 ? formatNumber(salesl30.last_30.totalAmount) : <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" className={classes.subheading}>
                                    Current Month
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {salestm ? salestm.current_month.quantity : <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {salestm ? formatNumber(salestm.current_month.totalAmount) : <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" className={classes.subheading}>
                                    Last Month
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {saleslm ? saleslm.last_month.quantity : <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {saleslm ? formatNumber(saleslm.last_month.totalAmount): <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" className={classes.subheading}>
                                    Current Year
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {salesty ? salesty.current_year.quantity: <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {salesty ? formatNumber(salesty.current_year.totalAmount): <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" className={classes.subheading}>
                                    Last Year
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {salesly ? salesly.last_year.quantity: <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {salesly ? formatNumber(salesly.last_year.totalAmount): <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" className={classes.subheading}>
                                    Till Date
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {salesfull ? salesfull.full.quantity: <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <Typography variant="body2" align="right" style={{marginRight: 3}}>
                                    {salesfull ? formatNumber(salesfull.full.totalAmount): <Skeleton width={'100%'} height={'100%'}/>}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Divider style={{marginTop: 10}}/>
            </Grid>
            <Grid item xs={12} lg={12}>
                {
                    details && 
                        <BSRGraph
                            height={300}
                            asin={asin}
                            details={details}
                            showDetails={false}
                            open={props.show}
                            />
                }
            </Grid>
        </Grid>
  );
}

ASINDetails.prototype = {
    show: PropTypes.bool.isRequired,
    asin: PropTypes.string.isRequired,
};