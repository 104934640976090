import React, { useContext, useState } from "react";
import "../css/home.css";
import {formatQty, formatNumber, getTimeInIST} from '../Utils';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import {
    Typography,
	Grid,
	Icon,
	IconButton,
} from '@material-ui/core';
import Config from "../Config";
import { AppContext } from "../AppContextProvider";
import CardViewBlock from "../components/CardViewBlock";
import { ReportService } from "../Services/ReportService";
import CardViewLoader from "../components/CardViewLoader";
import { MasterService } from "../Services/MasterService";
import VisibilityIcon from '@material-ui/icons/Visibility';

// import { ListManager } from "react-beautiful-dnd-grid";
import { DashboardContext } from "../DashboardContext";
import EmptyBlock from "./EmptyBlock";
import DragItem from "../DragItem";
import DashboardPopover from "./DashboardPopover";
import TopRankingProducts from "./TopRankingProducts";
import TopProducts from "./TopProducts";
import TopRankingStates from "./TopRankingStates";
import TopRankingCities from "./TopRankingCities";
import ProductsOutOfStock from "./ProductsOutOfStock";
import FCStock from "./FCStock";
import LatestFeedBack from "./LatestFeedBack";

// const myStyles = makeStyles(theme =>({
// 	fab: {
// 		margin: theme.spacing(1),
// 		position: 'fixed',
// 		bottom: theme.spacing(2),
// 		right: theme.spacing(2),
// 	},
// 	root: {
// 		width: '100%',
// 		marginTop: theme.spacing(3),
// 		paddingTop : theme.spacing(1), 
// 		paddingLeft: theme.spacing(1), 
// 		// paddingRight: theme.spacing(1),
// 		paddingBottom: theme.spacing(1)
// 	},
// 	section: {
// 		width: '100%',
// 		height: 280,
// 		overflow: 'auto',
		
// 		overflowX: 'auto',
// 		paddingTop : theme.spacing(1), 
// 		paddingLeft: theme.spacing(1), 
// 		// paddingRight: theme.spacing(1),
// 		paddingBottom: theme.spacing(1)
// 	},
// 	tablecell: {
// 		fontSize: '12pt',
// 		padding: theme.spacing(1),
		
// 	},
// 	paper: {
// 		position: 'absolute',
// 		backgroundColor: theme.palette.background.paper,
// 		boxShadow: theme.shadows[5],
// 		padding: theme.spacing(4),
// 		width: "80%",
// 		outline: 'none',
// 		textAlign: 'center',
// 		color: theme.palette.text.secondary,
// 		marginBottom : '20px',
// 	},
// 	table: {
// 			display: 'inline-block',  
// 			[theme.breakpoints.up('md')]: {
// 				minWidth: 500,
// 				display: 'inline-table',  
// 			},
// 			overflow: 'auto'
// 	},
// 	list: {
// 		width: '100%',
// 		backgroundColor: theme.palette.background.paper
// 	},
// 	grid: {
// 		[theme.breakpoints.up('md')]: {
// 			marginTop: '10px',
// 		},
// 		marginTop: '50px',
// 		marginBottom: '5px'
// 	},
// 	textField: {
// 		marginLeft: theme.spacing(1),
// 		marginRight: theme.spacing(1),
// 	},
// 	qtyField: {
// 		width: "40%",
// 		position: "relative",
// 		[theme.breakpoints.up('md')]: {
// 			right: '100px',
// 			width: "30%",
// 		},
// 		right: "22px"
// 	},
// 	icon: {
// 		padding: '0px',
// 		[theme.breakpoints.up('md')]: {
// 			padding: '12px',
// 		},
// 	},
// 	itemQtySection: {
// 		width: '100%',
// 		[theme.breakpoints.up('md')]: {
// 			width: '40%',
// 		},
// 	},
// 	formControl: {
// 		margin: theme.spacing(1),
// 		marginTop: theme.spacing(3),
// 		minWidth: 200,
// 		marginLeft: theme.spacing(2),
// 		[theme.breakpoints.up('md')]: {
// 			marginLeft: theme.spacing(0),
// 		},
// 	},
// 	button: {
// 		marginTop: theme.spacing(3),
// 		height: theme.spacing(7),
// 		float: 'right',
// 		marginLeft: theme.spacing(2),
// 	},
// 	message: {
// 		padding: 10,
// 		color: 'red'
// 	}
// }));

// function sortList1(list) {
// 	return list.slice().sort((first, second) => first.display_order - second.display_order);
// }

function Home(props){

	const {state, setTitle, setTourSteps} = useContext(AppContext);
	const { reportBlocks, setReportBlocks, moveItem } = useContext(DashboardContext);
	// const classes = myStyles();
	const [lastRefreshed, setLastRefreshed] = useState(moment());
	const [totalShippedOrders, setTotalShippedOrders] = React.useState(0);
	const [totalCanceledOrders, setTotalCanceledOrders] = React.useState(0);
	const [totalPendingOrders, setTotalPendingOrders] = React.useState(0);
	const [totalSales, setTotalSales] = React.useState(0);
	const [yesterdayTotalSales, setYesterdayTotalSales] = React.useState(0);
	const [salesDiffPer, setSalesDiffPer] = React.useState(0);
	const [intervalID, setIntervalID] = useState(null);
	const [timedIntervalID, setTimedIntervalID] = useState(null);
	const [lastRefreshedString, setLastRefreshedString] = useState('');
	const [ordersLastRun, setOrdersLastRun] = useState('');
	const [showBlockLoader, setShowBlockLoader] = useState(false);
	const dashboardConfigRef = React.useRef(null);
	const [showDashBoardConfig, setShowDashBoardConfig] = React.useState(false);

	React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="refresh"]',
                content: `Refresh the Dashboard`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour="eye"]',
                content: `Show/Hide Grids on the Dashboard`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour="today"]',
                content: `Today's Figures`,
                style: {
                    backgroundColor: '#fff',
                },
            },

        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

	React.useEffect(() => {
		if(state.isAuthenticated){
			fetchDashboard();
			setTitle('Dashboard');
			refresh();
			timedEvents();
			ReportService.fetchSalesStatsReport(moment().startOf('day').add(-1, 'day'), moment().endOf('day').add(-1, 'day'), true, "Shipped")
			.then(data => {
				let sales = data.reduce((sum, next) => sum + (next.TotalAmount || 0), 0);
				setYesterdayTotalSales(sales);
			});
	
			setIntervalID(setInterval(refresh, Config.REFRESH_INTERVAL));
			setTimedIntervalID(setInterval(timedEvents, Config.SHORT_REFRESH_INTERVAL));
			
			return () => {
				clearInterval(intervalID);
				clearInterval(timedIntervalID);
				setIntervalID(null);
				setTimedIntervalID(null);
			}
		}
	}, [state.isAuthenticated, true]);

	React.useEffect(() => {
		if(yesterdayTotalSales > 0){
			refresh();
		}
	}, [yesterdayTotalSales]);

	const handleDashboardClose = () => {
		setShowDashBoardConfig(false);
	}

	const timedEvents = () => {
		setLastRefreshedString(lastRefreshed.fromNow());
	}

	const refresh = () => {
		setShowBlockLoader(true);
		ReportService.fetchSalesStatsReport(moment().startOf('day'), moment().endOf('day'), true, "Shipped")
		.then(data => {
			let currentSales = data.reduce((sum, next) => sum + (next.TotalAmount || 0), 0);
			setTotalShippedOrders(data.reduce((sum, next) => sum + next.OrderCount, 0));
			// setTotalUnits(data.reduce((sum, next) => sum + next.TotalCount, 0));
			setTotalSales(currentSales);
			if(yesterdayTotalSales > 0){
				setSalesDiffPer(((currentSales - yesterdayTotalSales) / yesterdayTotalSales) * 100);
			} else {
				setSalesDiffPer(0);
			}
			setShowBlockLoader(false);
			timedEvents();
		})
		.catch(error => {
			setShowBlockLoader(false);
			console.log(error);
		});

		ReportService.fetchSalesStatsReport(moment().startOf('day'), moment().endOf('day'), true, "Canceled")
		.then(data => {
			setTotalCanceledOrders(data.reduce((sum, next) => sum + next.OrderCount, 0));
			setShowBlockLoader(false);
		})
		.catch(error => {
			setShowBlockLoader(false);
			console.log(error);
		});
		
		ReportService.fetchSalesStatsReport(moment().startOf('day'), moment().endOf('day'), true, "Pending")
		.then(data => {
			setTotalPendingOrders(data.reduce((sum, next) => sum + next.OrderCount, 0));
			setShowBlockLoader(false);
		})
		.catch(error => {
			setShowBlockLoader(false);
			console.log(error);
		});

		MasterService.fetchBackgroundJobsStatus({type: 'ORDER'})
		.then(data => {
			if(data.endtime){
				setOrdersLastRun(getTimeInIST(data.endtime).fromNow());
			}
			// setRunning(data);
		})
		.catch((error) => {
			console.log('error fetching status');
		});
		
		setLastRefreshed(moment());
	}

	const fetchDashboard = () => {
		MasterService.getDashboard()
		.then(data => {
			setReportBlocks(data);
		})
		.catch(error => {
			console.log(error);
		})
	}
	  
	// const sortList = () => {
	// 	setReportBlocks(sortList1(reportBlocks));
	// }
	
	// const reorderList = (sourceIndex, destinationIndex) => {
	// 	if (destinationIndex === sourceIndex) {
	// 		return;
	// 	}
	// 	const list = reportBlocks;
	// 	if (destinationIndex === 0) {
	// 		list[sourceIndex].display_order = list[0].display_order - 1;
	// 		sortList();
	// 		return;
	// 	}
	// 	if (destinationIndex === list.length - 1) {
	// 		list[sourceIndex].display_order = list[list.length - 1].display_order + 1;
	// 		sortList();
	// 		return;
	// 	}
	// 	if (destinationIndex < sourceIndex) {
	// 		list[sourceIndex].display_order = (list[destinationIndex].display_order + list[destinationIndex - 1].display_order) / 2;
	// 		sortList();
	// 		return;
	// 	}
	// 	list[sourceIndex].display_order = (list[destinationIndex].display_order + list[destinationIndex + 1].display_order) / 2;
	// 	sortList();
	// }

	const getReportView = (report) => {
		switch(report.id){
			case 'latest_seller_feedback':
				return (<LatestFeedBack report={report} />);
			case 'fc_inventory':
				return (<FCStock report={report} />);
			case 'out_of_stock':
				return (<ProductsOutOfStock report={report} />);
			case 'top_states':
				return (<TopRankingStates report={report} />);
			case 'top_cities':
				return (<TopRankingCities report={report} />);
			case 'top_ranking_products':
				return (<TopRankingProducts report={report} />);
			case 'top_asins':
				return (<TopProducts report={report} />);
			default: 
			return (<EmptyBlock report={report} />);
		}
	}

	return (
		<div className="Home">
			<div className="lander">
				<div>
					<Grid container direction="row" spacing={1} justify={"center"}>
						<Grid container item xl={9} lg={11} xs={12} spacing={0} justify={"flex-start"}>
							<Grid item xl={12} lg={12} xs={12}>
								<div style={{float: "right"}}>
									<IconButton ref={dashboardConfigRef} data-tour="eye" onClick={(e) => setShowDashBoardConfig(true)}>
										<VisibilityIcon />
									</IconButton>
								</div>
								<div style={{float: "right"}}>
									<Typography variant="caption">
										Last Refreshed: 
										{
											lastRefreshed && lastRefreshedString
										}
									</Typography>
									<IconButton aria-label="close" data-tour="refresh" onClick={refresh}>
										<Icon>refresh</Icon>
									</IconButton>
								</div>
								<div style={{float: "right"}}>
									Orders Last synced from MWS {ordersLastRun}
									<IconButton aria-label="close">
										<Icon>schedule</Icon>
									</IconButton>
								</div>
							</Grid>
							<Grid container item data-tour="today" xs={12} lg={12} xl={12}>
								<Grid item lg={2} xs={12}>
									<CardViewBlock title={''} subtitle={'Today'} />
								</Grid>
								<Grid item lg={2} xs={6}>
									{
										showBlockLoader ?
											<CardViewLoader />
										:
											<CardViewBlock title={'Orders Shipped'} subtitle={formatQty(totalShippedOrders || 0)} />
									}
								</Grid>
								<Grid item lg={2} xs={6}>
									{
										showBlockLoader ?
											<CardViewLoader />
										:
											<CardViewBlock color="red" title={'Orders Canceled'} subtitle={formatQty(totalCanceledOrders || 0)} />
									}
								</Grid>
								<Grid item lg={2} xs={6}>
									{
										showBlockLoader ?
											<CardViewLoader />
										:
											<CardViewBlock color="yellow" title={'Orders Pending'} subtitle={formatQty(totalPendingOrders || 0)} />
									}
								</Grid>
								<Grid item lg={4} xs={6}>
									{
										showBlockLoader ?
											<CardViewLoader />
										:
										<CardViewBlock title={'Shipped Order Sales'} subtitle={formatNumber(totalSales || 0)} difference={salesDiffPer} currency="₹"/>
									}
								</Grid>
							</Grid>
							{/* <Grid container item spacing={1} style={{marginTop: 30}} lg={12} xs={12}>
								<ListManager
									items={reportBlocks}
									direction="horizontal"
									maxItems={3}
									render={report =>  {
										if(report.visible === '1'){
											return (
												<Grid item>
													{getReportView(report)}
												</Grid>
											)
										} else {
											return null;
										}
									}}
									onDragEnd={reorderList}
								/>
							</Grid> */}
						</Grid>
						<Grid container item xl={9} lg={11} xs={12} spacing={0} justify={"flex-start"}>
							{
								reportBlocks.filter(r => r.visible === '1').map(report => (
									<DragItem key={report.id} id={report.id} onMoveItem={moveItem}>
										{getReportView(report)}
									</DragItem>
								))
							}
						</Grid>
					</Grid>
				</div>
			</div>
			<DashboardPopover
				anchorEl={dashboardConfigRef.current}
				onClose={handleDashboardClose}
				open={showDashBoardConfig}
			/>
		</div>
	);
}

export default Home;