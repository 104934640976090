import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors, Grid } from '@material-ui/core';

// import Header from './Header';
import JobSettings from './JobSettings';
import Settings from './Settings';
import MWSSettings from './MWSSettings';
import Notifications from './Notifications';
import { AppContext } from '../AppContextProvider';
import SPAPISettings from './SPAPISettings';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const AccountManagement = props => {
  const { match, history } = props;
  const classes = useStyles();
  const tab = match.params.tab || 'general';

  const {setTitle} = useContext(AppContext);

//   console.log(tab);

  React.useEffect(() => {
      setTitle('Settings');
  }, [true]);

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'general', label: 'General' },
    // { value: 'mws', label: 'MWS Credentials' },
    { value: 'spapi', label: 'Credentials' },
    { value: 'notifications', label: 'Notifications' },
    { value: 'jobs', label: 'Jobs' }
  ];

  if (!tab) {
    return <Redirect to="/account/management/general" />;
  }

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  return (
    <div className="Home">
        <div className="lander">
            {/* <Header /> */}
            <Grid container justify="center" alignItems="flex-start">
                <Grid item xs={12} lg={10} xl={10}>
                    <Tabs
                        className={classes.tabs}
                        onChange={handleTabsChange}
                        scrollButtons="auto"
                        value={tab}
                        variant="scrollable"
                    >
                        {tabs.map(tab => (
                        <Tab
                            key={tab.value}
                            label={tab.label}
                            value={tab.value}
                        />
                        ))}
                    </Tabs>
                    <Divider className={classes.divider} />
                    <div className={classes.content}>
                        {tab === 'general' && <Settings {...props}/>}
                        {tab === 'mws' && <MWSSettings {...props}/>}
                        {tab === 'spapi' && <SPAPISettings {...props}/>}
                        {tab === 'notifications' && <Notifications {...props}/>}
                        {tab === 'jobs' && <JobSettings {...props}/>}
                    </div>
                </Grid>
            </Grid>
        </div>
    </div>
  );
};

AccountManagement.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default AccountManagement;
