import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.caption,
    padding: 8,
    borderBottom: `1px solid ${palette.divider}`,
    borderLeft: `1px solid ${palette.divider}`,
    borderRight: `1px solid ${palette.divider}`,
    "&:last-child": {
      paddingRight: 32,
      borderRight: `0px`,
    },
    "&:first-child": {
      paddingRight: 32,
      borderLeft: `0px`,
    },
  }
};
