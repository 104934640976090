import React, { useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, Button, CardActions, List, ListItem, Typography, ListItemText, Grid, CircularProgress } from "@material-ui/core";
import { HtmlTooltip } from "../Utils";
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PropTypes from "prop-types";
import clsx from 'clsx';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { InventoryService } from "../Services/InventoryService";
import MyDialog from "../components/MyDialog";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5, 
        minHeight: 190,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
        minHeight: 200,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8
    },
    griditem: {
        cursor: 'move'
    },
    loader: {
        marginTop: 40
    },
    nodata: {
        position: 'relative',
        top: 40
    }
}));

const ProductsOutOfStock = ({ forwardedRef, ...props }) => {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const {report, className} = props;
    // const [isShown, setIsShown] = useState(false);
    const [data, setData] = useState([]);

    // const changeVisibility = e => {
    //     if(report.visible === '1'){
    //         hideReport(report.id);
    //     } else {
    //         showReport(report.id);
    //     }
    // }

    //   { isShown && (
    //                     <BootstrapTooltip title={report.visible === '1' ? 'Click to Hide' : 'Click to Show' }>
    //                         <IconButton size="small" className={classes.icon} onClick={changeVisibility}>
    //                             {report.visible === '1' ? <VisibilityOffIcon /> : <VisibilityIcon /> }
    //                         </IconButton>
    //                     </BootstrapTooltip>
    //                 ) }
    React.useEffect(() => {
        setLoading(true);
        InventoryService.getOutOfStockList()
        .then(data => {
            setData(data);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        })
    }, [true]);

    const onClick = (data) => {
        let asin = data.asin;
        history.push(`/inventory/report?asin=${asin}`);
    }

    return (
        <Grid item xl={4} lg={4} xs={12}>
            <Card 
                ref={forwardedRef}
                className={clsx(classes.root, className)}
                //onMouseEnter={() => setIsShown(true)}
                //onMouseLeave={() => setIsShown(false)}
                >
                <CardHeader 
                    title={report.name}
                    className={classes.griditem}
                    titleTypographyProps={{align: 'left'}}/>
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress/>
                            </div>
                        :
                        data.length > 0 ? (
                            <List>
                                {data.slice(0, 8).map(data => (
                                    <ListItem
                                        classes={{ divider: classes.itemDivider }}
                                        divider
                                        dense
                                        key={data.asin}
                                    >
                                        <HtmlTooltip arrow title={
                                            <div>
                                                <Typography variant="caption" component="span" color="inherit">
                                                    <span>{data.name}</span><br/>
                                                    <b>{'ASIN: '}</b>{data.asin}<br/>
                                                </Typography>
                                            </div>
                                        }>
                                            <ListItemText
                                                primary={data.sku}
                                                primaryTypographyProps={{ color: 'inherit', variant: 'subtitle2' }}
                                                onClick={(e) => onClick(data)}
                                            />
                                        </HtmlTooltip>
                                    </ListItem>
                                ))}
                            </List>
                        )
                        : (
                            <div className={classes.nodata}>
                                No Products
                            </div>
                        )
                    }
                </CardContent>
                {
                    data.length > 8 && (
                        <CardActions className={classes.actions}>
                            <Button
                                color="inherit"
                                onClick={(e) => setOpen(true)}
                                size="small"
                                variant="text"
                            >
                                See all
                                <ArrowForwardIcon className={classes.arrowForwardIcon} />
                            </Button>
                        </CardActions>
                    )
                }
            </Card>
            <MyDialog
                maxWidth={"sm"}
                content={
                        data.length > 0 ? (
                            <List>
                                {data.map(data => (
                                    <ListItem
                                        classes={{ divider: classes.itemDivider }}
                                        divider
                                        dense
                                        key={data.asin}
                                    >
                                        <HtmlTooltip arrow title={
                                            <div>
                                                <Typography variant="caption" component="span" color="inherit">
                                                    <span>{data.name}</span><br/>
                                                    <b>{'ASIN: '}</b>{data.asin}<br/>
                                                </Typography>
                                            </div>
                                        }>
                                            <ListItemText
                                                primary={data.sku}
                                                primaryTypographyProps={{ color: 'inherit', variant: 'subtitle2' }}
                                            />
                                        </HtmlTooltip>
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <div className={classes.nodata}>
                                No Products
                            </div>
                        )
                    }
                title={`Products out of Stock (${data.length})`}
                open={open}
                close={() => {
                    setOpen(false);
                }}/>
        </Grid>
    );
};

ProductsOutOfStock.propTypes = {
    report: PropTypes.object.isRequired,
};

export default ProductsOutOfStock;