export const RowCountOptions = [10, 25, 50, 100];

export const TABLE_COUNTS = {
    inv_tab_count_key: "asinwatchdog.inv_tab_count",
    top_customer_tab_count_key: "asinwatchdog.top_customer_tab_count",
    advance_search_customer_tab_count_key: "advance_search_customer_tab_count",
    sales_order_tab_count_key: "asinwatchdog.sales_order_tab_count",
    asin_metrics_tab_count_key: "asinwatchdog.asin_metrics_tab_count",
    seller_feedback_tab_count_key: "asinwatchdog.seller_feedback_tab_count",
    product_review_tab_count_key: "asinwatchdog.product_review_tab_count",
}