import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Table, TableBody, TableRow, Tooltip, IconButton, Icon, TablePagination } from "@material-ui/core";
import {red, blue} from '@material-ui/core/colors';
import moment from "moment";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import {toInt, StyledTableCell, format_display_fancy_notime, openLink, BootstrapTooltip} from "../../Utils";
import XLSX from 'xlsx';
import DateRangePickerCustom from "../../components/DateRangePickerCustom";
import { AppContext } from "../../AppContextProvider";
import { FeedbackService } from "../../Services/FeedbackService";
import { TABLE_COUNTS, RowCountOptions } from "../../Constants";
import { Rating } from "@material-ui/lab";
import Config from "../../Config";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    section: {
        width: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    StyledTableCell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        float: 'left',
        // height: theme.spacing(7),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export default function SellerFeedback(props) {

    const {setTitle, setLinearLoading, showProgressDialog, closeProgressDialog} = useContext(AppContext);
    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [currentRows, setCurrentRows] = React.useState([]);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').add(-30, 'd'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [generate, setGenerate] = React.useState(true);

    const columns = [
        { id: 'Date', align: 'left', numeric: false, disablePadding: true, label: 'Date', sortable: true },
        { id: 'Rating', align: 'left', numeric: false, disablePadding: true, label: 'Rating', sortable: true },
        { id: 'Comments', align: 'left', numeric: false, disablePadding: true, label: 'Comments', sortable: false },
        { id: 'OrderID', align: 'center', numeric: false, disablePadding: true, label: 'Order ID', sortable: true },
    ];

    React.useEffect(() => {
        setTitle("Seller Feedback");
    }, [true]);

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.seller_feedback_tab_count_key) ? toInt(localStorage.getItem(TABLE_COUNTS.seller_feedback_tab_count_key)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Date');

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.seller_feedback_tab_count_key, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        if(generate){
            setLinearLoading(true);
            showProgressDialog();
            setTotalItems(0);
            setCurrentPageNumber(0);
            FeedbackService.fetchFeedbacksCount(startdate, enddate)
            .then(data => {
                setTotalItems(toInt(data.count));
                setFetchPage(true);
                if(toInt(data.count) === 0){
                    setCurrentRows([]);
                }
                setLinearLoading(false);
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
            setGenerate(false);
        }
    }, [generate, order, orderBy]);

    React.useEffect(() => {
        if(fetchPage && totalItems > 0){
            fetchReport();
        }
        setFetchPage(false);
    }, [fetchPage]);


    const fetchReport = () => {
        setLinearLoading(true);
        showProgressDialog();
        FeedbackService.fetchFeedbacks(startdate, enddate, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
        .then(data => {
            let rows = [];
            setData(data);
            data.forEach((element, index) => {
                rows.push(
                    <TableRow key={index} hover>
                        <StyledTableCell align="right" padding='default' style={{width: '12vw'}}>
                            {moment(element.Date).format(format_display_fancy_notime)}
                        </StyledTableCell>
                        <StyledTableCell align="justify" padding='default' style={{width: '10vw'}}>
                            <Rating name="size-small" style={{flex: 1}} value={element.Rating} component="div" size="small" readOnly/>
                        </StyledTableCell>
                        <StyledTableCell align="justify" padding='default'>
                            {element.Comments}
                        </StyledTableCell>
                        <StyledTableCell align="center" padding='default' style={{width: '20vw', cursor: 'pointer'}}>
                            <BootstrapTooltip title={`Click to view order in Seller Central`}>
                                <div
                                    onClick={(e) => {
                                        openLink(Config.seller_central_order_url.replace('{ORDERID}', element.OrderID))
                                    }} >
                                    {element.OrderID}
                                </div>
                            </BootstrapTooltip>   
                        </StyledTableCell>
                    </TableRow>)
                });
            setCurrentRows(rows);
            setLinearLoading(false);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false);
            closeProgressDialog();
        });
    };

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const exportReport = e => {
        setLinearLoading(true);
        let row = [];
        let filename = 'Seller Feedback Report';
        FeedbackService.fetchFeedbacks(startdate, enddate, orderBy, order, 0, totalItems)
        .then(data => {
            row.push(columns.map(c => c.label));
            data.forEach((d, index) => {
                row.push([moment(d.Date).format(format_display_fancy_notime), Array.from(Array(toInt(d.Rating)), (_, i) => '⭐️').join(''), d.Comments, d.OrderID]);
            });
            const wb = XLSX.utils.book_new();
            const wsAll = XLSX.utils.aoa_to_sheet(row);
            XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
            XLSX.writeFile(wb, filename + ".xlsx");
            setLinearLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false);
        });
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" justify="center" spacing={2}>
                    <Grid item xl={9} lg={11} md={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center" justify="flex-start">
                                <Grid item xs={12} lg={3}>
                                    {/* <Timeline singleline startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/> */}
                                    <DateRangePickerCustom startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/>
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <Button variant="contained" className={classes.button} color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                        Generate Report
                                    </Button>
                                </Grid>
                                <Grid item xs={3} lg={2}>
                                    <Tooltip title="Export">
                                        <span>
                                            <IconButton className={classes.icon} aria-label="Export" disabled={data.length === 0} onClick={(e) => exportReport()}>
                                                <Icon>save_alt</Icon>
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Paper>
                        { currentRows.length === 0 &&
                            <div style={{marginTop: 50}}>
                                <Typography align="center" variant="subtitle2" gutterBottom>No Data Available</Typography>
                            </div>
                        }
                        { currentRows.length > 0 && (
                            <Paper className={classes.section}>
                                <Grid container spacin={1} alignItems="center">
                                        <Grid item xs={12} lg={12}>
                                            <div style={{height: 500, overflowY: 'auto', display: 'block'}}>
                                                <Table className={classes.table} stickyHeader size="small" padding="none">
                                                    <EnhancedTableHead
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={sortBy}
                                                        rows ={columns} />
                                                    <TableBody>
                                                        {currentRows}
                                                    </TableBody>
                                                </Table>
                                                {
                                                    totalItems === 0 && 
                                                        <Typography color="error" variant="subtitle2">No Data</Typography>
                                                }
                                            </div>
                                            <TablePagination
                                                    rowsPerPageOptions={RowCountOptions}
                                                    component="div"
                                                    count={totalItems}
                                                    rowsPerPage={numItemsPerPage}
                                                    page={currentPageNumber}
                                                    backIconButtonProps={{
                                                        'aria-label': 'Previous Page',
                                                    }}
                                                    nextIconButtonProps={{
                                                        'aria-label': 'Next Page',
                                                    }}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    classes={{ selectRoot : classes.selectRoot}}/>
                                        </Grid>
                                </Grid>
                            </Paper>
                            )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
 