import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Divider, Table, TableBody, TableRow, IconButton, Tooltip, useTheme } from "@material-ui/core";
import {red, blue, green} from '@material-ui/core/colors';
import { ProductService } from "../../Services/ProductService";
// import moment from "moment";
import ItemSelection from '../../components/ItemSelection';
import EnhancedTableHead from "../../components/EnhancedTableHead";
import { getTimeInIST, StyledTableCell, BootstrapTooltip, HtmlTooltip, openLink, formatNumber } from "../../Utils";
import queryString from 'query-string';
import BSRGraph from "../../components/BSRGraph";
import AddASIN from "../../components/AddASIN";
import DeleteIcon from '@material-ui/icons/Delete';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ClearIcon from '@material-ui/icons/Clear';
import MyDialog from "../../components/MyDialog";
import { AppContext } from "../../AppContextProvider";
import Config from '../../Config';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    green: {
        color: green[700],
    },
    red: {
        color: red[500],
    }
}));

export default function ASINCompetitor(props) {

    const {state, setTitle, setLinearLoading, setTourSteps, showSnackBar, closeConfirmDialog, showProgressDialog, closeProgressDialog, showConfirmDialog} = useContext(AppContext);

    const competitorLimit = state.user.competitor_limit;
    const classes = useStyles();
    const theme = useTheme();
    const [asin, setASIN] = React.useState(null);
    const [asinList, setASINList] = React.useState([]);
    // const [startdate, setStartDate] = React.useState(moment().startOf('day').add(-30, 'd'));
    // const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const order = 'asc';
    const orderBy = 'asin';
    const [generate, setGenerate] = React.useState(false);
    const [step2, setStep2] = React.useState(false);
    const [competitors, setCompetitors] = React.useState([]);
    const [currentRows, setCurrentRows] = React.useState([]);
    const [showDialog, setShowDialog] = React.useState(false);
    const [dialogAsin, setDialogASIN] = React.useState(null);
    const [dialogDetails, setDialogDetails] = React.useState(null);
    const [addASINOpen, setAddASINOpen] = React.useState(false);
    const [dialogDetailsTitle, setDialogDetailsTitle] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [showImageDialog, setShowImageDialog] = React.useState(false);
    const [dialogDetailsURL, setDialogDetailsURL] = React.useState(null);

    const columns = [
        { id: 'name', align: 'left', numeric: false, disablePadding: false, label: 'Product', sortable: false },
        { id: 'asin', align: 'left', numeric: false, disablePadding: true, label: 'ASIN', sortable: false },
        { id: 'seller_sku', align: 'left', numeric: false, disablePadding: true, label: 'SKU', sortable: false },
        { id: 'bsr', align: 'center', numeric: false, disablePadding: true, label: 'BSR', sortable: false, tooltip: 'Best Seller Ranking' },
        { id: 'top_level_category', align: 'center', numeric: false, disablePadding: true, label: 'Category', sortable: false, tooltip: 'Top Level Category'},
        { id: 'competitive_pricing', align: 'center', numeric: false, disablePadding: true, label: 'Price', sortable: false },
        { id: 'no_of_listings', align: 'center', numeric: false, disablePadding: true, label: 'Listings', sortable: false, tooltip: 'No. of Listing' },
        { id: 'status', align: 'center', numeric: false, disablePadding: true, label: 'Status', sortable: false },
        { id: 'action', align: 'center', numeric: false, disablePadding: true, label: 'Actions', sortable: false },
    ];

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="selectasin"]',
                content: `Select ASIN to view Competitor Stats`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=addcompetitor]',
                content: `Click to Add Competitor`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=track]',
                content: `Click to Show Stats`,
                style: {
                    backgroundColor: '#fff',
                },
            }
        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    React.useEffect(() => {
        setTitle("Track Competitor");
        let asin = null;
        if(props.location.search){
            let qString = queryString.parse(props.location.search);
            if(qString.asin){
                asin = qString.asin;
                // if(qString.startdate)
                //     setStartDate(moment(qString.startdate));
                // if(qString.enddate)
                //     setEndDate(moment(qString.enddate));
                setGenerate(true);
            }
            // setASIN(props.location.query.asin);
        }

        ProductService.listProductNames()
        .then(data => {
            let list = data.map(d => ({...d, label : `[${d.asin}][${d.seller_sku}] ${d.name.substring(0, 120)}${d.name.length > 120 ? '...' : ''}`}));
            setASINList(list);
            if(asin){
                let a = list.filter(l => l.asin === asin);
                setASIN(a.length > 0 ? a[0] : null);
                // if(a.length > 0) {
                //     generateReport();
                // }
            }
        })
        .catch(error => console.log(error));

    }, [true]);

    React.useEffect(() => {
        if(generate && asin && asin.asin){
            generateReport();
            setGenerate(false);
        }
    }, [generate, asin]);

    const generateReport = () => {
        setLinearLoading(true);

        ProductService.getCompetitorsForASIN(asin.asin)
        .then((data) => {
            console.log(data);
            setCompetitors(data);
            setStep2(true);
            setLinearLoading(false);
        });
    }

    const saveMapping = (externalASIN) => {
        ProductService.saveCompetitorsForASIN(asin.asin, externalASIN)
        .then((data) => {
            if(data.status === 'success'){
                setGenerate(true);
            } else {
                showSnackBar('Unable to Add. Please try again later', 'error');
            }
        });
    }

    const removeMapping = (externalASIN) => {
        showConfirmDialog('Confirm removal of Competitor (All tracking data and metric will be lost)?', undefined, () => {
            closeConfirmDialog();
            showProgressDialog('Removing Competitor...');
            ProductService.removeCompetitorsForASIN(asin.asin, externalASIN)
            .then((response) => {
                closeProgressDialog();
                setGenerate(true);
            })
            .catch(error => {
                console.log(error);
                closeProgressDialog();
            });
        })
    }

    React.useEffect(() => {
        if(step2){
            showProgressDialog('Fetching Stats...');
            setStep2(false);
            let rows = [];
            let asins = [asin.asin, ...competitors].join(',');
            ProductService.listMetricsByASIN(asins)
            .then((data) => {
                console.log(data);
                let internal = data.findIndex(d => d.asin === asin.asin);
                let track = data;
                if(internal !== 0){
                    let first = data[internal];
                    data.splice(internal, 1);
                    track = [first, ...data];
                }
                setData(track);
                track.forEach((element, index) => {
                    let bsr1 = (element.bsr.length > 0 && element.bsr[0].length > 0) ? element.bsr[0] : [];
                    let bsr2 = (element.bsr.length === 2 && element.bsr[1].length > 0) ? element.bsr[1] : [];
                    bsr1.sort((a, b) => (getTimeInIST(a.modified_date).isAfter(getTimeInIST(b.modified_date)) ? -1 : 1));
                    bsr2.sort((a, b) => (getTimeInIST(a.modified_date).isAfter(getTimeInIST(b.modified_date)) ? -1 : 1));

                    let f1 = ((bsr1[1] && bsr1[1].rank) || 0);
                    let diff1 = (f1 === 0 ? -1 : 1 )*(f1 - ((bsr1[0] && bsr1[0].rank) || 0));
                    let f2 = ((bsr2[1] && bsr2[1].rank) || 0);
                    let diff2 = (f2 === 0 ? -1 : 1 )*(f2 - ((bsr2[0] && bsr2[0].rank) || 0));
                    rows.push(
                        <TableRow key={index} hover style={element.status !== 'ACTIVE' ? {backgroundColor: red[100]} : {}} >
                            <StyledTableCell scope="row" align="left" padding='default' style={{ paddingLeft:8, paddingRight:8, width: '30vw', paddingTop: 4, paddingBottom: 4}}>
                                <div align="left" style={{verticalAlign: 'middle', display: 'inline-flex'}}>
                                    <BootstrapTooltip title={element.image_url ? "Click to enlarge" : ""}>
                                        <div 
                                            style={{marginRight: 15, maxWidth: 110}}
                                            onClick={(e) => {
                                                        if(element.image_url){
                                                            setShowImageDialog(true);
                                                            setDialogDetailsTitle(element.seller_sku);
                                                            setDialogDetailsURL(element.image_url.replace('_SL75_', '_SL300_'));
                                                        }
                                                    }}>
                                            <img src={element.image_url ? element.image_url : null} height="50" width="50" alt=""/>
                                        </div>
                                    </BootstrapTooltip>
                                    <BootstrapTooltip title={element.name}>
                                        <span>{`${element.name.substring(0, 125)}${element.name.length > 125 ? '...' : ''}`}</span>
                                    </BootstrapTooltip>
                                </div>
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="center" padding='default' style={{width: '8vw', paddingLeft: 8, paddingRight:8}}>
                                <BootstrapTooltip title={`View in Amazon`}>
                                    <div 
                                        style={{color: theme.palette.text.link, cursor: 'pointer', textDecoration: 'none'}}
                                        onClick={(e) => {
                                                openLink(Config.amazon_asin_url.replace('{ASIN}', element.asin))
                                            }}>
                                        {element.asin}
                                    </div>
                                </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="justify" padding='default' style={{width: '28vw', paddingLeft: 8, paddingRight:8}}>
                                {element.seller_sku}
                            </StyledTableCell>
                            <StyledTableCell data-tour="bsr" align="center" padding='default' style={{width: '10vw', paddingRight:4, paddingLeft: 4}}>
                                {
                                    bsr1.length === 0 && bsr2.length === 0 ? 
                                        '-'
                                    :
                                        <div 
                                        style={{cursor: "pointer"}}
                                        onClick={(e) => {
                                            console.log(track[0].asin, track[0].categories.top_category_name);
                                            setDialogASIN(track[0].asin);
                                            setDialogDetailsTitle(track[0].name);
                                            setDialogDetails(track[0]);
                                            setShowDialog(true);
                                        }}>
                                                <HtmlTooltip arrow 
                                                    title= {<React.Fragment>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><b>{element.categories.top_category_name}</b></td>
                                                                            <td align="right">{(bsr2[0] && bsr2[0].rank) || 0}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><b>{element.categories.category_name}</b></td>
                                                                            <td align="right">{(bsr1[0] && bsr1[0].rank) || 0}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </React.Fragment>}>
                                                    <div>
                                                    {
                                                        bsr2.length > 0 &&
                                                            <div>
                                                                {(bsr2[0] && bsr2[0].rank) || 0}   (<span className={(diff2 > 0 ? classes.green: classes.red)}>{diff2 > 0 ? `+${diff2}` : `${diff2}`}</span>)
                                                            </div>
                                                    }
                                                    {
                                                        bsr1.length > 0 &&
                                                            <div>
                                                                {(bsr1[0] && bsr1[0].rank) || 0}   (<span className={(diff1 > 0 ? classes.green: classes.red)}>{diff1 > 0 ? `+${diff1}` : `${diff1}`}</span>)
                                                            </div>
                                                    }
                                                    </div>
                                                </HtmlTooltip>
                                        </div>
                                }
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="center" padding='default'>
                                {(element.categories && element.categories.top_category_name) || '-'}
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="center" padding='default' style={{width: '7vw'}}>
                                {element.competitive_pricing > 0 ? formatNumber(element.competitive_pricing) : '-'}
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="center" padding='default' style={{width: '2vw'}}>
                                { element.no_of_listings > 0 ?
                                    <div className={element.no_of_listings > 1 ? classes.red: classes.blue}>{element.no_of_listings}</div>
                                    :
                                    <IconButton className={classes.red} component="span">
                                        <ClearIcon />
                                    </IconButton>
                                }
                            </StyledTableCell>
                            <StyledTableCell  scope="row" align="center" padding='default' style={{width: '2vw', paddingLeft: 8, paddingRight:8}}>
                                {
                                    element.status ? 
                                        element.status === 'ACTIVE' ?  
                                            <IconButton component="span" className={classes.green}>
                                                <FiberManualRecordIcon />
                                            </IconButton>
                                        : 
                                            <IconButton className={classes.red} component="span">
                                                <FiberManualRecordIcon />
                                            </IconButton>
                                    : 
                                        '-'
                                }
                            </StyledTableCell>
                            <StyledTableCell scope="row" padding='default' align="center" style={{maxWidth: '3vw', paddingLeft: 8, paddingRight:8}}>
                                {   element.asin !== asin.asin &&
                                    (<IconButton onClick={(e) => removeMapping(element.asin)}><DeleteIcon /></IconButton>)
                                }
                            </StyledTableCell>
                        </TableRow>);
                });
                setCurrentRows(rows);
                setLinearLoading(false);
                closeProgressDialog();
            }).catch((error) => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
        }

    }, [step2]);
    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={2} justify="center">
                    <Grid item xl={10} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center" style={{marginLeft: 12}}>
                                <Grid data-tour="selectasin" item xs={12} lg={4}>
                                    <ItemSelection clearable={true} disabled={asinList.length === 0} required label='Select ASIN' value={asin} optionLabel='label' options={asinList} selected={setASIN}/>
                                </Grid>
                                <Grid item xs={4} lg={1}>
                                    <Button data-tour="track"  variant="contained" disabled={!asin} className={classes.button} color="primary" onClick={generateReport}>
                                        Track
                                    </Button>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    {/* <Timeline startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/> */}
                                    {/* <DateRangePickerCustom style={{marginTop: 12}} startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/> */}
                                </Grid>
                                <Grid item xs={8} lg={3}>
                                    <Tooltip title={competitors.length >= competitorLimit ? `Max ${competitorLimit} competitors allowed` : ''}>
                                        <Button 
                                            data-tour="addcompetitor"
                                            variant="outlined" 
                                            disabled={competitors.length >= competitorLimit || !asin || currentRows.length === 0} 
                                            className={classes.button} 
                                            color="primary" 
                                            onClick={(e) => setAddASINOpen(true)}>
                                            Add Competitor
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 10, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                            <Table className={classes.table} stickyHeader>
                                                <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={() => {}}
                                                    rows={columns} />
                                                <TableBody>
                                                    {currentRows}
                                                </TableBody>
                                            </Table>
                                    </div>
                                    {
                                        currentRows.length === 1 && 
                                            <Typography color="error" variant="subtitle2">No Competitors Mapped</Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <AddASIN
                    {...props} 
                    onError={() => {
                        setAddASINOpen(false);
                    }} 
                    onSuccess={(externalASIN) => {
                        setAddASINOpen(false);
                        saveMapping(externalASIN);
                    }} 
                    onCancel={() => setAddASINOpen(false)} 
                    open={addASINOpen} 
                    title="Add Competitor ASIN" 
                    placeholder="ASIN"
                    internal={false}
                    excludeList={[(asin && asin.asin) || null, ...competitors]} />
                <MyDialog
                    content={
                        <BSRGraph 
                            showDetails
                            competitors={competitors}
                            asinFullDetails={data}
                            asin={dialogAsin}
                            details={dialogDetails}
                            open={showDialog} />
                        }
                    title={dialogDetailsTitle}
                    open={showDialog}
                    close={() => {
                        setShowDialog(false);
                        setDialogASIN(null);
                        setDialogDetails(null);
                    }}/>
                <MyDialog
                    maxWidth={"sm"}
                    content={
                            <img 
                                src={dialogDetailsURL} 
                                height="300" width="300" 
                                alt=""/>
                        }
                    title={dialogDetailsTitle}
                    open={showImageDialog}
                    close={() => {
                        setShowImageDialog(false);
                        setDialogDetailsURL(null);
                        setDialogDetailsTitle(null);
                    }}/>
            </div>
        </div>
    );
}
 