import React from 'react';
import { withRouter, useHistory } from "react-router-dom";
// import logo from './logo.svg';
import './css/App.css';
import Routes from "./Routes/routes";
import { UserService } from './Services/UserService';
import MySnackbarContent from './components/SnackBar';
import ProgressDialog from './components/ProgressDialog';

import {
	Snackbar,
	Dialog as DialogMUI,
	DialogActions,
	DialogContent,
	DialogContentText,
	Button,
	DialogTitle,
	CssBaseline,
	Fade,
	LinearProgress,
	FormControl,
	OutlinedInput,
	ThemeProvider,
	makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import * as Chart from "chart.js";
import AppDrawer from './Routes/AppDrawer';
import MyAppBar from './Routes/MyAppBar';
import { AppContext } from './AppContextProvider';
import { useContext } from 'react';
import { DashboardContextProvider } from './DashboardContext';

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

Chart.Tooltip.positioners.custom = function (elements, position) {
	if (!elements.length) {
		return false;
	}
	var offset = 0;
	//adjust the offset left or right depending on the event position
	if (elements[0]._chart.width / 2 > position.x) {
		offset = 20;
	} else {
		offset = -120;
	}

	var offsetY = 0;
	if (elements[0]._chart.height / 2 > position.y) {
		offsetY = 20;
	} else {
		offsetY = -200;
	}
	return {
		x: position.x + offset,
		y: position.y + offsetY
	}
}

Chart.Tooltip.positioners.sales = function (elements, position) {
	// if (!elements.length) {
	//   return false;
	// }
	// var offset = 0;
	// //adjust the offset left or right depending on the event position
	// if (elements[0]._chart.width / 2 > position.x) {
	//   offset = 20;
	// } else {
	//   offset = -120;
	// }

	// var offsetY = 0;
	// if (elements[0]._chart.height / 2 > position.y) {
	// 	offsetY = 20;
	// } else {
	// 	offsetY = -200;
	// }
	var tooltip = this;

	if (!elements.length) {
		return false;
	}
	var offset = 0;
	//adjust the offset left or right depending on the event position
	if (elements[0]._chart.width / 2 > position.x) {
		offset = 20;
	} else {
		offset = -(tooltip._view.width + 20);
	}

	var offsetY = 0;
	if (elements[0]._chart.height / 2 > position.y) {
		offsetY = 20;
	} else {
		offsetY = -140;
	}

	return {
		x: position.x + offset,
		y: position.y + offsetY
	}
}

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	sectionMobile: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	accountCircle: {
		marginRight: theme.spacing(3)
	},
}));


function App(props) {

	const classes = useStyles();
	const { state,
		initializeTheme,
		handleSBClose,
		showSnackBar,
		closeConfirmDialog,
		handleConnectionChange,
		userHasAuthenticated,
		handleLogout,
		// requestToUpdateMWSCredentials,
		requestToUpdateSPAPICredentials,
		// setHasMWSCredentials,
		setHasSPAPICredentials,
		hasProductsFetched,
		updateUser,
		setAuthenticating,
		showProgressDialog,
		closeProgressDialog,
		setDialogFieldText } = useContext(AppContext);
	const history = useHistory();

	React.useEffect(() => {
		handleConnectionChange();
		window.addEventListener('online', handleConnectionChange());
		window.addEventListener('offline', handleConnectionChange());
		initializeTheme();
		if (props.location.pathname === '/reset/password') {
			history.push(props.location.pathname);
		} else if (state.user === null) {
			history.push("/login");
		}
		try {
			// if (state.user !== null) {
			// 	userHasAuthenticated(true);
			// }
			if (state.user) {
				showProgressDialog('Logging in...');
				let body = { email: state.user.email, password: state.user.password }
				UserService.authenticate(body)
					.then((data) => {
						if (data.id && data.status === '1' && moment(data.licence_expiry_date).isAfter(moment())) {
							data.password = state.user.password;
							updateUser(data);
							localStorage.setItem('asinwatchdog.user', JSON.stringify(data));
							localStorage.setItem('asinwatchdog.session', data['sysbrew-x-token']);
							localStorage.setItem('asinwatchdog.mapping', data['db_mapping']);
							userHasAuthenticated(true);
							// let hasMWSCredentials = data.seller_id !== null && data.aws_access_key_id !== null && data.secret_key !== null && data.market_place_id !== null;
							const hasSPAPICredentials = data.lwa_client_id !== null && data.lwa_client_secret !== null
								&& data.lwa_refresh_token !== null && data.aws_access_key !== null 
								&& data.aws_secret_key !== null && data.market_place_id !== null;
							// setHasMWSCredentials(hasMWSCredentials);
							setHasSPAPICredentials(hasSPAPICredentials);
							if (hasSPAPICredentials) {
								hasProductsFetched();
								history.push(props.location.pathname);
							} else {
								// requestToUpdateMWSCredentials();
								requestToUpdateSPAPICredentials();
							}
						} else if (data.licence_expiry_date && moment(data.licence_expiry_date).isBefore(moment())) {
							showSnackBar("Your License has expired. Please Contact Admin", 'error');
							handleLogout();
						} else {
							handleLogout();
							history.push("/login");
						}
						closeProgressDialog();
						setAuthenticating(false);
					})
					.catch((error) => {
						handleLogout();
						closeProgressDialog();
						setAuthenticating(false);
					});
			}
		} catch (e) {
			if (e !== 'No current user') {
				alert(e);
			}
			closeProgressDialog();
			setAuthenticating(false);
		}

		return () => {
			window.removeEventListener('online', handleConnectionChange);
			window.removeEventListener('offline', handleConnectionChange);
		}
	}, [true]);

	return (
		<ThemeProvider theme={state.theme}>
			<DndProvider backend={HTML5Backend}>
				<DashboardContextProvider>
					<div className="App container">
						<div className={classes.root}>
							<Fade
								in={state.linearLoading}
								style={{
									transitionDelay: state.linearLoading ? '50ms' : '0ms',
								}}
								unmountOnExit
							>
								<LinearProgress
									id="test"
									style={{ height: 5, position: "fixed", top: 64, width: "98.5%" }} />
							</Fade>
							<CssBaseline />
							<MyAppBar />
							{state.isAuthenticated &&
								<AppDrawer />
							}
						</div>
						<Routes style={{ width: "50%" }} />
						<Snackbar
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={state.sb.open}
							autoHideDuration={state.sb.autohide}
							onClose={handleSBClose}>
							<MySnackbarContent
								onClose={handleSBClose}
								variant={state.sb.varient}
								message={state.sb.message}
							/>
						</Snackbar>
						<ProgressDialog open={state.progressOpen} message={state.progressMessage} />
						<DialogMUI
							disableEnforceFocus
							open={state.openDialog}
							onClose={closeConfirmDialog}
							fullWidth={true}
							maxWidth='sm'
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description">
							{state.dialog_title ? <DialogTitle id="alert-dialog-title">{state.dialog_title}</DialogTitle> : ''}
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									{state.dialog_message}
								</DialogContentText>
								{
									state.showfield &&
									<div>
										<FormControl variant="outlined" key="field" fullWidth>
											<OutlinedInput
												fullWidth
												placeholder={state.fieldHint}
												defaultValue={state.fieldValue}
												id="outlined-adornment-field"
												onChange={(e) => {
													e.target.value && e.target.value.length > 0 && setDialogFieldText(e.target.value)
												}}
												aria-describedby="outlined-weight-helper-text"
												inputProps={{
													'aria-label': 'rate',
												}}
												labelWidth={0}
												multiline={false}
											/>
										</FormControl>
									</div>
								}
							</DialogContent>
							<DialogActions>
								<Button onClick={closeConfirmDialog} color="primary">
									{state.confirmAction ? state.cancel_title : "Ok"}
								</Button>
								{
									state.confirmAction &&
									<Button onClick={(e) =>
										state.showfield ?
											state.confirmAction(state.dialog_field_text)
											:
											state.confirmAction()
									}
										color="primary"
										autoFocus>
										{state.accept_title}
									</Button>
								}
							</DialogActions>
						</DialogMUI>
					</div>
				</DashboardContextProvider>
			</DndProvider>
		</ThemeProvider>
	);
}

export default withRouter(App);
