import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Divider, Table, TableBody, TableRow, TablePagination, TextField, Chip, InputAdornment, IconButton, Popover } from "@material-ui/core";
import {red, blue} from '@material-ui/core/colors';
import moment from "moment";
import ItemSelection from '../../components/ItemSelection';
import EnhancedTableHead from "../../components/EnhancedTableHead";
import {toInt, format_order_date, format, StyledTableCell, BootstrapTooltip, getTimeInIST} from "../../Utils";
// import XLSX from 'xlsx';
import { RowCountOptions, TABLE_COUNTS } from "../../Constants";
import { MasterService } from "../../Services/MasterService";
import { ReportService } from "../../Services/ReportService";
import queryString from 'query-string';
import SearchIcon from '@material-ui/icons/Search';
import DateRangePickerCustom from "../../components/DateRangePickerCustom";
import { AppContext } from "../../AppContextProvider";
import { ProductService } from "../../Services/ProductService";
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function CustomerAdvanceSearch(props){
    
    const {setTitle: setTitles, setTourSteps, setLinearLoading, closeProgressDialog, showProgressDialog} = useContext(AppContext);
    const classes = useStyles();
    const [selectedStatus, setSelectedStatus] = React.useState(null);
    const [search, setSearch] = React.useState('');
    // const [statusList, setStatusList] = React.useState([]);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').add(-30, 'days'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('last_purchase_date');
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [sortingAction, setSortingAction] = React.useState(false);
    const [asin, setASIN] = React.useState([]);
    const [asinList, setASINList] = React.useState([]);

    //AdvanceSearch Criteria
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [searchCity, setSearchCity] = React.useState('');
    const [searchState, setSearchState] = React.useState('');
    const [searchCounty, setSearchCounty] = React.useState('');
    const [searchMunicipality, setSearchMunicipality] = React.useState('');
    const [searchPostalCode, setSearchPostalCode] = React.useState('');
    const [appliedAdvanceFilter, setAppliedAdvanceFilter] = React.useState(false);

    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: false, label: 'S.No.', sortable: false },
        { id: 'BuyerEmail', align: 'left', numeric: false, disablePadding: false, label: 'Buyer Email', sortable: true },
        { id: 'ShippingAddressCity', align: 'left', numeric: false, disablePadding: true, label: 'City', sortable: true },
        { id: 'ShippingAddressStateOrRegion', align: 'left', numeric: false, disablePadding: true, label: 'State', sortable: true },
        { id: 'last_purchase_date', align: 'center', numeric: false, disablePadding: true, label: 'Last Purchased Date', sortable: true },
        { id: 'total_qty', align: 'center', numeric: false, disablePadding: true, label: 'Total Qty', sortable: true },
        { id: 'order_count', align: 'center', numeric: false, disablePadding: true, label: 'Total Orders', sortable: true },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.advance_search_customer_tab_count_key) ? toInt(localStorage.getItem(TABLE_COUNTS.advance_search_customer_tab_count_key)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.advance_search_customer_tab_count_key, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="searchbuyeremail"]',
                content: `Search for Orders by Buyer Email ID`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selectdaterange]',
                content: `Filter by Date`,
                style: {
                    backgroundColor: '#fff',
                },
            }
        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    React.useEffect(() => {
        setTitles("Advanced Customer Search");
        let asin = null;
        if(props.location.search){
            let qString = queryString.parse(props.location.search);
            if(qString.asin){
                asin = qString.asin;
                if(qString.startdate)
                    setStartDate(moment(qString.startdate));
                if(qString.enddate)
                    setEndDate(moment(qString.enddate));
                setLinearLoading(true);
            }
        }

        ProductService.listProductNames()
        .then(data => {
            let list = data.map(d => ({...d, label : `[${d.asin}][${d.seller_sku}] ${d.name.substring(0, 120)}${d.name.length > 120 ? '...' : ''}`}));
            setASINList(list);
            if(asin){
                let a = list.find(l => l.asin === asin);
                setASIN(a);
                // if(a.length > 0) {
                //     generateReport();
                // }
            }
        })
        .catch(error => console.log(error));

        MasterService.getOrderStatuses()
        .then(data => {
            let list = data;
            let allStatus = {name: 'All Status', value: '-1'};
            list.splice(0, 0, allStatus);
            // setStatusList(list);
            setSelectedStatus(data.find(d => d.value === 'Shipped'));
            setGenerate(false);
        })
        .catch(error => console.log(error));

    }, [true]);

    React.useEffect(() => {
        if(generate){
            if(asin.length > 0) {
                if(!sortingAction){
                    setCurrentRows([]);
                }
                setSortingAction(false);
                setLinearLoading(true);
                showProgressDialog();
                let selStatus = selectedStatus && selectedStatus.value !== '-1' ? selectedStatus.value : null;
                let city, state, postalcode, muncipality, county;
                if(appliedAdvanceFilter){
                    city = searchCity;
                    state = searchState;
                    postalcode = searchPostalCode; 
                    muncipality = searchMunicipality;
                    county = searchCounty;
                } else {
                    city = search;
                    state = search;
                    postalcode = search; 
                    muncipality = search;
                    county = search;
                }
                let rASIN = [];
                if (asin.length > 0){
                    rASIN = [...asin].map(a => a.asin);
                }
                setTotalItems(0);
                setCurrentPageNumber(0);
                ReportService.fetchAdvanceCustomerSearchCount(rASIN.join(','), selStatus, startdate, enddate, city, state, postalcode, muncipality, county)
                .then(data => {
                    setTotalItems(toInt(data.count));
                    setFetchPage(true);
                    if(toInt(data.count) === 0){
                        setCurrentRows([]);
                    }
                    setLinearLoading(false);
                    closeProgressDialog();
                })
                .catch(error => {
                    console.log(error);
                    setLinearLoading(false);
                    closeProgressDialog();
                });
            }
            setGenerate(false);
        }
    }, [generate, search, selectedStatus, order, orderBy]);

    const onClick = (email) => e => {
        if(email != null){
            let url = `/performance/orders?buyer=${email}&startdate=${startdate.format(format)}&enddate=${enddate.format(format)}`;
            // history.push(url);
            window.open(url, "_blank")
        }
    }

    React.useEffect(() => {
        let rows = [];
        if(fetchPage && totalItems > 0){
            setLinearLoading(true);
            let selStatus = selectedStatus && selectedStatus.value !== '-1' ? selectedStatus.value : null;
            let city, state, postalcode, muncipality, county;
            if(appliedAdvanceFilter){
                city = searchCity;
                state = searchState;
                postalcode = searchPostalCode; 
                muncipality = searchMunicipality;
                county = searchCounty;
            } else {
                city = search;
                state = search;
                postalcode = search; 
                muncipality = search;
                county = search;
            }
            let rASIN = [];
            if (asin.length > 0){
                rASIN = [...asin].map(a => a.asin);
            }
            ReportService.fetchAdvanceCustomerSearch(rASIN.join(','), selStatus, startdate, enddate, city, state, postalcode, muncipality, county, currentPageNumber * numItemsPerPage, numItemsPerPage, orderBy, order)
            .then((data) => {
                // console.log(data);
                data.forEach( (element, index) => {
                    rows.push(
                        <TableRow key={index} style={{paddingBottom: 10, paddingTop: 10}} hover>
                            <StyledTableCell scope="row" align="center" padding='none' style={{width: 50}}>
                                <Typography variant="subtitle2">{(currentPageNumber * numItemsPerPage) + index + 1}</Typography>
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="center" padding='none' style={{width: 50}}>
                                <BootstrapTooltip title={element.BuyerEmail !== null ? "Click to view more orders by Buyer" : ""}>
                                    <Typography variant="subtitle2" style={{cursor: 'pointer'}} onClick={onClick(element.BuyerEmail)}>{element.BuyerEmail || '-'}</Typography>
                                </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="center" padding='none' style={{width: 50}}>
                                <Typography variant="subtitle2">{element.ShippingAddressCity || '-'}</Typography>
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="center" padding='none' style={{width: 50}}>
                                <Typography variant="subtitle2">{element.ShippingAddressStateOrRegion || '-'}</Typography>
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="left" padding='none' style={{width: 110, padding: 12}}>
                                {getTimeInIST(element.last_purchase_date).format(format_order_date)}
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="left" padding='none' style={{width: 110, padding: 12}}>
                                {element.total_qty}
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="left" padding='none' style={{width: 110, padding: 12}}>
                                {element.order_count}
                            </StyledTableCell>
                        </TableRow>);
                });
                setCurrentRows(rows);
                setLinearLoading(false);
            }).catch((error) => {
                console.log(error);
                setLinearLoading(false);
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    // const exportReport = e => {
        // setLinearLoading(true);
        // let row = [];
        // let filename = `Inventory Report ${asin.asin}`;
        // let fc = selectedFC.value !== '-1' ? selectedFC.value : null;
        // let selASIN = asin.asin !== '-1' ? asin.asin : null;
        // InventoryService.fetchInventoryTxns(selASIN, startdate, enddate, fc, selectedDisposition.value, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
        // .then((data) => {
        //     if(data.length > 0){
        //         let header = columns.map(c => c.label);
        //         header.push('Disposition');
        //         row.push(header);
        //         data.forEach((d, index) => {
        //             row.push([getTimeInIST(d.snapshot_date).format(format_display_fancy_notime), d.asin, d.fnsku, d.sku, toInt(d.quantity), d.fulfillment_center_id, d.detailed_disposition]);
        //         });
        //         const wb = XLSX.utils.book_new();
        //         const wsAll = XLSX.utils.aoa_to_sheet(row);
        //         XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
        //         XLSX.writeFile(wb, filename + ".xlsx");
        //     } else {
        //         showSnackBar("No data to export" , 'warning');
        //     }
        //     setLinearLoading(false);
        // })
        // .catch(error => {
        //     console.log(error);
        //     showSnackBar("Failed to export. Please try again." , 'error');
        //     setLinearLoading(false);
        // });
    // }

    const handleDelete = type => e => {
        switch(type){
            case 'city': setSearchCity(''); break;
            case 'state': setSearchState(''); break;
            case 'municipality': setSearchMunicipality(''); break;
            case 'county': setSearchCounty(''); break;
            case 'postal': setSearchPostalCode(''); break;
            default: break;
        }
    }

    const applyFilter = () => {
        if(searchCity || searchState || searchCounty || searchMunicipality || searchPostalCode){
            setAppliedAdvanceFilter(true);
            handleClose();
        } else {
            cancelFilter();
        }
    }

    const cancelFilter = () => {
        setAppliedAdvanceFilter(false);
        setSearchCity('');
        setSearchState(''); 
        setSearchMunicipality('');
        setSearchCounty('');
        setSearchPostalCode('');
        handleClose();
    }



    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={10} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={0} alignItems="center" justify="center">
                                <Grid item xs={6} lg={3} data-tour="selectasin">
                                    <ItemSelection 
                                        multiple
                                        clearable={true}
                                        label='Select ASIN' 
                                        value={asin} 
                                        optionLabel='label' 
                                        options={asinList} 
                                        selected={setASIN}
                                        limitTags={2} />
                                </Grid>
                                {/* <Grid item xs={12} lg={2} data-tour="selectstatus">
                                    <ItemSelection required label='Status' value={selectedStatus} optionLabel='name' options={statusList} selected={setSelectedStatus}/>
                                </Grid> */}
                                <Grid item xs={12} lg={3} data-tour="selectdaterange">
                                    {/* <Timeline startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/> */}
                                    <DateRangePickerCustom startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    {
                                        (appliedAdvanceFilter && (searchCity || searchState || searchCounty || searchMunicipality || searchPostalCode)) ? 
                                        (
                                            <div style={{textAlign: 'left'}}>
                                                {searchCity && <Chip size="small" style={{margin: 2}} label={`City: ${searchCity}`} onDelete={handleDelete('city')} color="inherit" />}
                                                {searchState && <Chip size="small" style={{margin: 2}} label={`State: ${searchState}`} onDelete={handleDelete('state')} color="inherit" />}
                                                {searchCounty && <Chip size="small" style={{margin: 2}} label={`County: ${searchCounty}`} onDelete={handleDelete('county')} color="inherit" />}
                                                {searchMunicipality && <Chip size="small" style={{margin: 2}} label={`Municipality: ${searchMunicipality}`} onDelete={handleDelete('municipality')} color="inherit" />}
                                                {searchPostalCode && <Chip size="small" style={{margin: 2}} label={`PostalCode: ${searchPostalCode}`} onDelete={handleDelete('postal')} color="inherit" />}
                                            </div>
                                        ) :
                                        (
                                            <TextField
                                                margin="dense"
                                                data-tour="search"
                                                fullWidth
                                                style={{marginTop: 12, marginLeft: 10, marginRight: 20, paddingRight: 20}}
                                                id="outlined-required"
                                                label="Search All Fields"
                                                variant="outlined"
                                                value={search || ''}
                                                type="search"
                                                onChange={(e) => setSearch(e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon/>
                                                        </InputAdornment>
                                                        )
                                                }}
                                            />
                                        )
                                    }
                                </Grid>
                                <Grid item>
                                    <IconButton style={{marginTop: 4}} aria-describedby={id} color="inherit" aria-label="advance search" component="span" onClick={handleClick}>
                                        <SettingsIcon />
                                    </IconButton>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <div style={{padding: 16, minWidth: 200, maxWidth: 350}}>
                                            <Grid container justify="center" alignItems="flex-start">
                                                <Grid item xs={12} lg={12}>
                                                    <Typography variant="caption">Advance Filter</Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={12}>
                                                    <Divider style={{marginTop: 5, marginBottom: 5}}/>
                                                </Grid>
                                                <Grid item xs={12} lg={12}>
                                                    <TextField
                                                        margin="dense"
                                                        autoFocus="true"
                                                        fullWidth
                                                        id="outlined-required"
                                                        label="Search by City"
                                                        variant="outlined"
                                                        value={searchCity || ''}
                                                        type="search"
                                                        onFo
                                                        onChange={(e) => setSearchCity(e.target.value)}
                                                        
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12}>
                                                    <TextField
                                                        margin="dense"
                                                        
                                                        fullWidth
                                                        id="outlined-required"
                                                        label="Search by State"
                                                        variant="outlined"
                                                        value={searchState || ''}
                                                        type="search"
                                                        onChange={(e) => setSearchState(e.target.value)}
                                                        
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12}>
                                                    <TextField
                                                        margin="dense"
                                                        fullWidth
                                                        id="outlined-required"
                                                        label="Search by County"
                                                        variant="outlined"
                                                        value={searchCounty || ''}
                                                        type="search"
                                                        onChange={(e) => setSearchCounty(e.target.value)}
                                                        
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12}>
                                                    <TextField
                                                        margin="dense"
                                                        
                                                        fullWidth
                                                        id="outlined-required"
                                                        label="Search by Municipality"
                                                        variant="outlined"
                                                        value={searchMunicipality || ''}
                                                        type="search"
                                                        onChange={(e) => setSearchMunicipality(e.target.value)}
                                                        
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12}>
                                                    <TextField
                                                        margin="dense"
                                                        
                                                        fullWidth
                                                        id="outlined-required"
                                                        label="Search by Postal Code"
                                                        variant="outlined"
                                                        value={searchPostalCode || ''}
                                                        type="search"
                                                        onChange={(e) => setSearchPostalCode(e.target.value)}
                                                        
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12}>
                                                    <div style={{marginTop: 8}}>
                                                        <Button variant="contained" color="primary" onClick={applyFilter}>Apply</Button>
                                                        <Button variant="text" style={{marginLeft: 8, color: 'red'}} onClick={cancelFilter}>Cancel</Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Popover>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <Button variant="contained" className={classes.button} color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                        Generate Report
                                    </Button>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 10, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{overflow: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows ={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" style={{marginTop: 40}} variant="subtitle2">No Data</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );

};