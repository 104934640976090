import Config from '../Config';
import {RestService} from './RestService';
import { format } from '../Utils';
import moment from 'moment';

export const ReportService = {
    fetchGeoAnalysisState: async function(asin = null, startdate = null, enddate = null, headers = {}){
        return RestService.get(`${Config.fetch_geo_analysis_state}${asin ? `asin=${asin}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).utc().format(format)}`: ''}`, headers)
                .then(response => response.json());
    },
    fetchGeoAnalysisCity: async function(asin = null, state = null, startdate = null, enddate = null, headers = {}){
        return RestService.get(`${Config.fetch_geo_analysis_city}${asin ? `asin=${asin}`: ''}${state ? `&state=${state}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).add(1, 'd').utc().format(format)}`: ''}`, headers)
                .then(response => response.json());
    },
    fetchRelatedProductsFromLocation: async function(state = null, city = null, startdate = null, enddate = null, headers = {}){
        return RestService.get(`${Config.fetch_relative_products_from_geo}${state ? `state=${state}`: ''}${city ? `&city=${city}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).add(1, 'd').utc().format(format)}`: ''}`, headers)
                .then(response => response.json());
    },
    fetchTopProducts: async function(startdate = null, enddate = null, headers = {}){
        return RestService.get(`${Config.fetch_top_performing_products}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).add(1, 'd').utc().format(format)}`: ''}`, headers)
                .then(response => response.json());
    },
    fetchASINDailySales: async function(asin = null, startdate = null, enddate = null, headers = {}){
        return RestService.get(`${Config.fetch_asin_daily_sales}${asin ? `asin=${asin}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).add(1, 'd').utc().format(format)}`: ''}`, headers)
                .then(response => response.json());
    },
    fetchTopCustomerCount: async function(asin = null, startdate = null, enddate = null, headers = {}){
        return RestService.get(`${Config.fetch_top_customers}${asin ? `asin=${asin}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).add(1, 'd').utc().format(format)}`: ''}&count=true`, headers)
                .then(response => response.json());
    },
    fetchTopCustomers: async function(asin = null, startdate = null, enddate = null, sort, direction, from, offset, headers = {}){
        return RestService.get(`${Config.fetch_top_customers}${asin ? `asin=${asin}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).add(1, 'd').utc().format(format)}`: ''}${sort ? `&sort=${sort}`: ''}${direction ? `&direction=${direction}`: ''}${from ? `&from=${from}`: ''}${offset ? `&offset=${offset}`: ''}`, headers)
                .then(response => response.json());
    },
    fetchSalesOrderCount: async function(filter = null, filterType = 'email', status = null, startdate = null, enddate = null, headers = {}){
        return RestService.get(`${Config.fetch_sales_orders}${status ? `statuses=${status}`: ''}${filter ? `&filter=${filter}`: ''}${filterType ? `&filter_type=${filterType}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).add(1, 'd').utc().format(format)}`: ''}&count=true`, headers)
                .then(response => response.json());
    },
    fetchSalesOrder: async function(filter = null, filterType = 'email', status = null, startdate = null, enddate = null, sort, direction, from, offset, headers = {}){
        return RestService.get(`${Config.fetch_sales_orders}${status ? `statuses=${status}`: ''}${filter ? `&filter=${filter}`: ''}${filterType ? `&filter_type=${filterType}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).add(1, 'd').utc().format(format)}`: ''}${sort ? `&sort=${sort}`: ''}${direction ? `&direction=${direction}`: ''}${from ? `&from=${from}`: ''}${offset ? `&offset=${offset}`: ''}`, headers)
                .then(response => response.json());
    },
    fetchSalesOrderExport: async function(filter = null, filterType = 'email', status = null, startdate = null, enddate = null, sort, direction, from, offset, headers = {}){
        return RestService.get(`${Config.fetch_sales_orders}${status ? `statuses=${status}`: ''}${filter ? `&filter=${filter}`: ''}${filterType ? `&filter_type=${filterType}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).add(1, 'd').utc().format(format)}`: ''}${sort ? `&sort=${sort}`: ''}${direction ? `&direction=${direction}`: ''}${from ? `&from=${from}`: ''}${offset ? `&offset=${offset}`: ''}&export=true`, headers)
                .then(response => response.blob());
    },
    /** ranges available
     * ----------------------------------------------------------
     * Code                 |           Description
     * ----------------------------------------------------------
     * t                    |           Today
     * y                    |           Yesterday
     * l7                   |           Last 7 days
     * tw                   |           This week
     * tm                   |           This Month
     * lm                   |           Last Month
     * ty                   |           This Year
     * ly                   |           Last Year
     * full                 |           LifeTime
     * lastsaledate         |           Last sale Date
     * ----------------------------------------------------------
     */
    fetchSalesStats: async function(asin = null, ranges = null, locationType = null, locationFilter = null, headers = {}){
        return RestService.get(`${Config.fetch_sales_stats_for_asin}${asin ? `asin=${asin}`: ''}${ranges ? `&ranges=${ranges}`: ''}${locationType ? `&locationType=${locationType}`: ''}${locationFilter ? `&locationFilter=${locationFilter}`: ''}`, headers)
                .then(response => response.json());
    },
    fetchZonalReport: async function(zones = null, tag = null, startdate = null, enddate = null, headers = {}){
        return RestService.get(`${Config.fetch_zonal_stats}${zones ? `zones=${zones}`: ''}${tag ? `&tag=${tag}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).add(1, 'd').utc().format(format)}`: ''}`, headers)
                .then(response => response.json());
    },
    fetchSalesStatsReport: async function(startdate = null, enddate = null, trimToDay = false, statuses = "Shipped", asins = null, headers = {}){
        return RestService.get(`${Config.fetch_sales_stats}${trimToDay ? `&trimToDay=${trimToDay}`: ''}${statuses ? `&statuses=${statuses}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).utc().format(format)}`: ''}${asins ? `&asins=${asins}`: ''}`, headers)
                .then(response => response.json());
    },
    fetchSalesProductStatsReport: async function(startdate = null, enddate = null, statuses = "Shipped", asins = null, headers = {}){
        return RestService.get(`${Config.fetch_sales_product_stats}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).utc().format(format)}`: ''}${statuses ? `&statuses=${statuses}`: ''}${asins ? `&asins=${asins}`: ''}`, headers)
                .then(response => response.json());
    },
    fetchSalesMinDate: async function(headers = {}){
        return RestService.get(Config.fetch_sales_min_date, headers)
        .then(response => response.json());
    },
    getTopLocations: async function(count = 5, type = null, measure = null , startdate = null, enddate = null, headers = {}){
        return RestService.get(`${Config.top_ranking_locations_url}count=${count}${type ? `&type=${type}`: ''}${measure ? `&measure=${measure}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).utc().format(format)}`: ''}`, headers)
        .then(response => response.json());
    },
    fetchAdvanceCustomerSearchCount: async function(asins = null, status = null, startdate = null, enddate = null, city = null, state = null, postalcode = null, muncipality = null, county = null,  headers = {}){
        return RestService.get(`${Config.fetch_advance_customer_search_report}${asins ? `asins=${asins}`: ''}${status ? `&statuses=${status}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).add(1, 'd').utc().format(format)}`: ''}${city ? `&ShippingAddressCity=${city}`: ''}${state ? `&ShippingAddressStateOrRegion=${state}`: ''}${county ? `&ShippingAddressCounty=${county}`: ''}${muncipality ? `&ShippingAddressMunicipality=${muncipality}`: ''}${postalcode ? `&ShippingAddressPostalCode=${postalcode}`: ''}&count=true`, headers)
                .then(response => response.json());
    },
    fetchAdvanceCustomerSearch: async function(asins = null, status = null, startdate = null, enddate = null, city = null, state = null, postalcode = null, muncipality = null, county = null, from, offset, sort, direction, headers = {}){
        return RestService.get(`${Config.fetch_advance_customer_search_report}${asins ? `asins=${asins}`: ''}${status ? `&statuses=${status}`: ''}${startdate ? `&startdate=${moment(startdate).utc().format(format)}`: ''}${enddate ? `&enddate=${moment(enddate).add(1, 'd').utc().format(format)}`: ''}${from ? `&from=${from}`: ''}${offset ? `&offset=${offset}`: ''}${city ? `&ShippingAddressCity=${city}`: ''}${state ? `&ShippingAddressStateOrRegion=${state}`: ''}${county ? `&ShippingAddressCounty=${county}`: ''}${muncipality ? `&ShippingAddressMunicipality=${muncipality}`: ''}${postalcode ? `&ShippingAddressPostalCode=${postalcode}`: ''}${sort ? `&sort=${sort}`: ''}${direction ? `&direction=${direction}`: ''}`, headers)
                .then(response => response.json());
    },
};