import React, { useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, Button, CardActions, List, ListItem, Typography, ListItemText, Grid, CircularProgress } from "@material-ui/core";
import { format_display_fancy_notime, openLink, BootstrapTooltip } from "../Utils";
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from "prop-types";
import clsx from 'clsx';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { FeedbackService } from "../Services/FeedbackService";
import moment from "moment";
import { Rating } from "@material-ui/lab";
import Config from "../Config";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5, 
        minHeight: 190,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 200,
        paddingLeft: 0,
        paddingRight: 0,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8
    },
    griditem: {
        cursor: 'move'
    },
    loader: {
        marginTop: 40
    },
    inner: {
        overflow: 'auto',
    },
    comments: {
        display: 'flex',
        alignItems: 'center'
    },
    tableHead: {
        fontSize: 16,
        color: '#263238',
        fontWeight: 500,
        lineHeight: '1.5rem'
    },
    tableCell: {
        fontSize: '0.875rem',
        color: '#263238',
        fontWeight: 400,
        lineHeight: '1.43'
    }
}));

const LatestFeedBack = ({ forwardedRef, ...props }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const {report, className} = props;
    const count = 5;
    // const [isShown, setIsShown] = useState(false);
    const [data, setData] = useState([]);

    // const changeVisibility = e => {
    //     if(report.visible === '1'){
    //         hideReport(report.id);
    //     } else {
    //         showReport(report.id);
    //     }
    // }

    //   { isShown && (
    //                     <BootstrapTooltip title={report.visible === '1' ? 'Click to Hide' : 'Click to Show' }>
    //                         <IconButton size="small" className={classes.icon} onClick={changeVisibility}>
    //                             {report.visible === '1' ? <VisibilityOffIcon /> : <VisibilityIcon /> }
    //                         </IconButton>
    //                     </BootstrapTooltip>
    //                 ) }
    React.useEffect(() => {
        setLoading(true);
        FeedbackService.fetchLatestFeedbacks(count)
        .then(data => {
            setData(data);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        })
    }, [true]);

    return (
        <Grid item xl={6} lg={8} xs={12}>
            <Card 
                ref={forwardedRef}
                className={clsx(classes.root, className)}
                //onMouseEnter={() => setIsShown(true)}
                //onMouseLeave={() => setIsShown(false)}
                >
                <CardHeader 
                    title={report.name}
                    className={classes.griditem}
                    titleTypographyProps={{align: 'left'}}/>
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress/>
                            </div>
                        :
                        <div className={classes.inner}>
                            <List>
                                {data.map(feedback => (
                                    <ListItem
                                        classes={{ divider: classes.itemDivider }}
                                        style={{paddingLeft: 10, paddingRight: 10}}
                                        divider
                                        dense
                                        key={feedback.id}
                                    >
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography align="left" component="legend"><b>{`Comment: `}</b>{feedback.Comments}</Typography>
                                            }
                                            secondary={
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <Rating name="size-small" style={{flex: 1}} value={feedback.Rating} component="div" size="small" readOnly/>
                                                    <BootstrapTooltip title={`Click to view order in Seller Central`}>
                                                        <Typography 
                                                            variant="caption" 
                                                            style={{marginRight: 8, cursor: 'pointer'}} 
                                                            align="right" 
                                                            component="div"
                                                            onClick={(e) => {
                                                                openLink(Config.seller_central_order_url.replace('{ORDERID}', feedback.OrderID))
                                                            }} >
                                                            {`Order ID: ${feedback.OrderID}`}
                                                        </Typography>
                                                    </BootstrapTooltip>
                                                    <Typography variant="caption" align="right" component="div">
                                                        {moment(feedback.Date).format(format_display_fancy_notime)}
                                                    </Typography>
                                                </div>
                                            }/>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    }
                </CardContent>
                <CardActions className={classes.actions}>
                    <Button
                        color="inherit"
                        component={RouterLink}
                        size="small"
                        variant="text"
                        to="/seller/feedback"
                    >
                        See all
                        <ArrowForwardIcon className={classes.arrowForwardIcon} />
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

LatestFeedBack.propTypes = {
    report: PropTypes.object.isRequired,
};

export default LatestFeedBack;