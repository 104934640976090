import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { format_display_fancy_notime, getTimeInIST, format_without_time, formatNumber, BootstrapTooltip, colorGenerator } from '../Utils';
import { Grid, Fade, LinearProgress, useTheme } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { ProductService } from '../Services/ProductService';
import PropTypes from 'prop-types';
import DateRangePickerCustom from './DateRangePickerCustom';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
    },
    subheading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "left"
    },
    dialogContent: {
        paddingTop: 0
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'left',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
}));

export default function BSRGraph(props) {

    const classes = useStyles();
    const theme = useTheme();
    
    const [loading, setLoading] = React.useState(false);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').add(-30, 'd'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [lineData, setLineData] = React.useState({}); 
    const [generate, setGenerate] = React.useState(false);
    const [details, setDetails] = React.useState(null);

    const asinFullDetails = props.asinFullDetails;
    const [colorMap, setColorMap] = React.useState(new Map());
    const updateMap = (k,v) => {
        setColorMap(colorMap.set(k,v));
        // setColorMap(new Map(colorMap.set(k,v)));
    }

    const componentIsMounted = React.useRef(true);
    React.useEffect(() => {
        let genColors = colorGenerator(1 + ((props.competitors && props.competitors.length) || 0));
        let color = genColors[0];
        updateMap(props.asin, color);
        if(props.competitors && props.competitors.length > 0){
            props.competitors.forEach((c, index) => {
                do {
                    color = genColors[index+1];
                    if([...colorMap.values()].indexOf(color) === -1){
                        updateMap(c, color);
                        break;
                    }
                } while(true);
            })
        }

        return () => {
            componentIsMounted.current = false
        }
    }, []);
    
    const options = {
        layout: {
            padding: 32
        },
        type: "invertedLinear",
        responsive : true, 
        maintainAspectRatio: false,
        tooltips: {
            position: 'custom',
            // Disable the on-canvas tooltip
            enabled: false,
            custom: function(tooltipModel, data) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<thead>';

                    titleLines.forEach(function(title) {
                        innerHtml += '<tr><th>' + getTimeInIST(title).format(format_display_fancy_notime) + '</th></tr>';
                    });
                    innerHtml += '</thead><tbody>';

                    let dataIndex = tooltipModel.dataPoints[0].datasetIndex;
                    let index = tooltipModel.dataPoints[0].index;
                    let otherData = lineData.datasets[dataIndex].otherData[index];


                    bodyLines.forEach(function(body, i) {
                        var colors = tooltipModel.labelColors[i];
                        var style = 'background:' + colors.backgroundColor;
                        style += '; border-color:' + colors.borderColor;
                        style += '; border-width: 2px';
                        var span = '<span style="' + style + '"></span>';
                        if(otherData){
                            innerHtml += '<tr><td colspan="2">' + span + '<b>' + otherData.asin + '</b></td></tr>';
                        }
                        innerHtml += '<tr><td colspan="2">' + span + '<hr style="margin-top: 0px;margin-bottom: 0px;border: 1px solid white;"/></td></tr>';
                        if(otherData){
                            innerHtml += '<tr><td>' + span + '<b>' + props.details.categories.top_category_name + '</b></td><td align="right">' + span + (otherData.top_rank || 'NA') + '</td></tr>';
                            innerHtml += '<tr><td>' + span + '<b>' + props.details.categories.category_name + '</b></td><td align="right">' + span + (otherData.node_rank || 'NA') + '</td></tr>';
                            innerHtml += '<tr><td>' + span + '<b>Competitive Pricing</b></td><td align="right">' + span + (otherData.competitive_pricing > 0 ? formatNumber(otherData.competitive_pricing) : 'NA') + '</td></tr>';
                        }
                    });
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.backgroundColor = theme.palette.black;
                tooltipEl.style.color = theme.palette.white;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                tooltipEl.style.pointerEvents = 'none';
                tooltipEl.style.zIndex = 2000;
            },
        },
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            week : "ll",
                            month: "MMM YYYY"
                        },
                        //unitStepSize: daily_sales_option === 0 ? 10  : undefined
                    },
                    display: true,
                    gridLines: {display: true}
                }
            ],
            yAxes: [{
                ticks: {
                    reverse: true,
                    callback: function(label, index, labels) {
                        return label
                    }
                }
            }]
        }
    };

    React.useEffect(() => {
        if(props.open){
            setGenerate(true);
        }

        return () => {
            setLineData({});
            setDetails(null);
            setGenerate(false);
        }
    }, [props.open])

    React.useEffect(() => {
        if(generate && props.asin && props.details){
            setLoading(true);
            setGenerate(false);
            let show = props.showDetails !== null ? props.showDetails : true;
            if(show && props.details){
                setDetails(
                    <Grid container justify="flex-start" alignItems="flex-start" spacing={0}>
                        <Grid item xs={3} lg={3}>
                            <Typography variant="subtitle2" component="span">
                                ASIN:
                            </Typography>
                        </Grid>
                        <Grid item xs={9} lg={9}>
                            <Typography variant="subtitle2" component="span" style={{marignLeft: 8}}>
                                {props.asin}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} lg={3}>
                            <Typography variant="subtitle2" component="span" style={{marignRight: 3}}>
                                SKU: 
                            </Typography>
                        </Grid>
                        <Grid item xs={9} lg={9}>
                            <Typography variant="subtitle2" component="span" style={{marignLeft: 8}}>
                                {props.details.seller_sku}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} lg={3}>
                            <Typography variant="subtitle2" component="span" style={{marignRight: 3}}>
                                Top Category: 
                            </Typography>
                        </Grid>
                        <Grid item xs={9} lg={9}>
                            <Typography variant="subtitle2" component="span" style={{marignLeft: 8}}>
                                {props.details.categories.top_category_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} lg={3}>
                            <Typography variant="subtitle2" component="span" style={{marignRight: 3}}>
                                Node Category:
                            </Typography>
                        </Grid>
                        <Grid item xs={9} lg={9}>
                            <Typography variant="subtitle2" component="span" style={{marignLeft: 8}}>
                                {props.details.categories.category_name}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }
            
            let asins = [props.asin];
            let pList = [ProductService.asinBSRData(props.asin, props.details.categories.top_category_name, props.details.categories.category_id, startdate, enddate)];
            if(props.competitors && props.competitors.length > 0){
                props.competitors.forEach(competitor => {
                    pList.push(ProductService.asinBSRData(competitor, null, null, startdate, enddate))
                    asins.push(competitor);
                });
            }
            Promise.all(pList)
            .then(datas => {
                let labels = [];
                if(componentIsMounted.current){
                    let datasets = [];
                    datas.forEach((data, index) => {
                        let count = [], otherData = [];
                        let topCatData = data;
                        let minDate = topCatData.length > 0 ? moment(topCatData[0].modified_date).startOf('d') : null;
                        if(minDate){
                            let topCat = null;
                            do {
                                let x = getTopData(topCatData, minDate);
                                topCat = x.length === 1 ? x[0] : topCat;
                                if(index === 0){
                                    labels.push(minDate.format(format_without_time));
                                }
                                count.push(topCat.top_rank);
                                otherData.push({asin: asins[index], node_rank: topCat.node_rank, competitive_pricing: topCat.competitive_pricing, top_rank: topCat.top_rank});
                                minDate = minDate.add(1, 'day');
                                // console.log(minDate.format(format_display_fancy_notime))
                                if(minDate.isAfter(moment(topCatData[topCatData.length - 1].modified_date))){
                                    break;
                                }
                            } while(true);
                        }
                        datasets.push({
                            label: asins[index],
                            data: count,
                            otherData: otherData,
                            backgroundColor: '#FFF',
                            borderColor: colorMap.get(asins[index]),
                            borderWidth: 3,
                            fill: false,
                            pointRadius: 5,
                        })
                    });

                    function getTopData(data, search){
                        return data.filter(t => moment(t.modified_date).isSame(search));
                    }
                    let rd = {
                        labels: labels,
                        datasets: datasets
                    };
                    setLineData(rd);
                    setLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
        }
    }, [generate]);

    return (
        <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} lg={12}>
                <Fade
                    in={loading}
                    style={{
                        transitionDelay: loading ? '10ms' : '0ms'
                    }}
                    unmountOnExit
                    >
                    <LinearProgress style={{height: 5, width: "100%"}} color="secondary"/>
                </Fade>
            </Grid>
            {
                details && (
                    <Grid item xs={12} lg={6}>
                        {details}
                    </Grid>
                )
            }
            { 
                props.competitors && props.competitors.length > 0 &&

                <Grid container item xs={12} lg={6} justify="flex-start" alignItems="flex-start" style={{marginBottom: 16}}>
                    {
                        props.details && 
                        <Grid item key={props.asin}>
                            <BootstrapTooltip title={props.details.name}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <svg width="40" height="15">
                                        <rect width="40" height="15" style={{fill: colorMap.get(props.asin), strokeWidth:1, stroke: 'rgb(255,255,255, 0.1)'}} />
                                    </svg>
                                    <span style={{marginLeft: 8}}>{`[${props.asin}] ${props.details.name.substring(0, 80)}${props.details.name.length > 80 ? '...' : ''}`}</span>
                                </Typography>
                            </BootstrapTooltip>
                        </Grid>
                    }
                    {
                        props.competitors && props.competitors.length > 0 && 
                        props.competitors.map(comp => {

                            let det = asinFullDetails.filter(a => a.asin === comp)[0];

                            return (
                                <Grid item key={comp}>
                                    <BootstrapTooltip title={det.name}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            <svg width="40" height="15">
                                                <rect width="40" height="15" style={{fill: colorMap.get(comp), strokeWidth:1, stroke: 'rgb(255,255,255, 0.1)'}} />
                                            </svg>
                                            <span style={{marginLeft: 8}}>{`[${comp}] ${det.name.substring(0, 80)}${det.name.length > 80 ? '...' : ''}`}</span>
                                        </Typography>
                                    </BootstrapTooltip>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }
            <Grid item>
                {/* <Timeline singleline showSummary={false} startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/> */}
                <DateRangePickerCustom startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/>
            </Grid>
            <Grid item style={{marginTop: 4}}>
                <Button variant="contained" className={classes.button} color="primary" onClick={() => {!generate && setGenerate(true)}}>
                    Refresh
                </Button>
            </Grid>
            <Grid item xs={12} lg={12} style={{minHeight: (props.height ? props.height : '55vh')}}>
                <Line options={options} data={lineData}/>
            </Grid>
        </Grid>
  );
}

BSRGraph.prototype = {
    open: PropTypes.bool.isRequired,
    showDetails: PropTypes.bool,
    asin: PropTypes.string.isRequired,
    competitors: PropTypes.arrayOf(PropTypes.string),
    details: PropTypes.object.isRequired,
    height: PropTypes.any,
};