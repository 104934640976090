import React, { createContext, useState } from "react";
import theme from "./theme";
import { useHistory } from "react-router-dom";
import { ProductService } from "./Services/ProductService";
import { MasterService } from "./Services/MasterService";
import { useMediaQuery } from "@material-ui/core";

const AppContext = createContext(null);

const defaultTheme = theme;
const smallTheme = theme;
// const smallTheme = createMuiTheme({
//     palette: {
//       type: 'light',
//     },
//     typography: {
//         subtitle1: {
//             fontSize: '0.8rem',
//         },
//         subtitle2: {
//             fontSize: '0.8rem',
//         },
//         // body1: {
//         //     fontWeight: 500,
//         // },
//         // button: {
//         //     fontStyle: 'italic',
//         // },
//     },
// });

let user = JSON.parse(localStorage.getItem("asinwatchdog.user"));

const AppContextProvider = ({ props, children }) => {
  const history = useHistory();
  let [state, setState] = useState({
    isAuthenticated: false,
    isAuthenticating: true,
    user: user,
    loading: false,
    linearLoading: false,
    show: false,
    sb: {
      open: false,
      autohide: 6000,
    },
    openDialog: false,
    open: false,
    title: "",
    progressOpen: false,
    progressMessage: null,
    theme: defaultTheme,
    isDisconnected: false,
    openNotifications: false,
    notifications: [],
    userIsTouring: false,
    tourSteps: [],
  });

  const setAuthenticating = (isAuthenticating) => {
    setState((prevState) => ({
      ...prevState,
      isAuthenticating: isAuthenticating,
    }));
  };

  const setTheme = (theme) => {
    localStorage.setItem("asinwatchdog.theme", theme);
    initializeTheme();
  };

  const getTheme = () => {
    return localStorage.getItem("asinwatchdog.theme");
  };

  const initializeTheme = () => {
    let theme = defaultTheme;
    if (localStorage.getItem("asinwatchdog.theme") !== null) {
      switch (localStorage.getItem("asinwatchdog.theme") || "default") {
        case "small":
          theme = smallTheme;
          break;
        case "default":
          theme = defaultTheme;
          break;
        default:
          theme = defaultTheme;
          break;
      }
      setState((prevState) => ({
        ...prevState,
        theme: theme,
      }));
    }
  };

  const setLinearLoading = (loading) => {
    setState((prevState) => ({
      ...prevState,
      linearLoading: loading,
    }));
  };

  const handleDrawerOpen = () => {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));
  };

  const handleDrawerClose = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const closeConfirmDialog = () => {
    setState((prevState) => ({
      ...prevState,
      openDialog: false,
      showfield: false,
      fieldHint: "",
      fieldValue: "",
      dialog_field_text: undefined,
    }));
  };

  const handleSBClose = () => {
    setState((prevState) => ({
      ...prevState,
      sb: {
        ...prevState.sb,
        open: false,
        message: "",
        varient: "info",
      },
    }));
  };

  const showSnackBar = (message, variant) => {
    setState((prevState) => ({
      ...prevState,
      sb: {
        ...prevState.sb,
        open: true,
        message: message,
        varient: variant,
      },
    }));
  };

  const showConfirmDialog = (
    dialog_message,
    dialog_title,
    confirmAction,
    cancel_title = "Cancel",
    accept_title = "Confirm",
    showfield = false,
    fieldHint = "",
    fieldValue = ""
  ) => {
    setState((prevState) => ({
      ...prevState,
      dialog_message: dialog_message,
      dialog_title: dialog_title,
      openDialog: true,
      confirmAction: confirmAction,
      cancel_title: cancel_title,
      accept_title: accept_title,
      showfield: showfield,
      fieldHint: fieldHint,
      fieldValue: fieldValue,
      dialog_field_text: fieldValue,
    }));
  };

  const setDialogFieldText = (text) => {
    setState((prevState) => ({
      ...prevState,
      dialog_field_text: text,
    }));
  };

  const setTitle = (title) => {
    setState((prevState) => ({
      ...prevState,
      title: title,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  const handleShow = () => {
    setState((prevState) => ({
      ...prevState,
      show: true,
    }));
  };

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("//google.com", {
          mode: "no-cors",
        })
          .then(() => {
            setState((prevState) => ({
              ...prevState,
              isDisconnected: false,
            }));
            clearInterval(webPing);
          })
          .catch(() => {
            setState((prevState) => ({
              ...prevState,
              isDisconnected: true,
            }));
          });
      }, 2000);
      return;
    }
    setState((prevState) => ({
      ...prevState,
      isDisconnected: true,
    }));
  };

  const isLoading = (loading) => {
    setState((prevState) => ({
      ...prevState,
      loading: loading,
    }));
  };

  const userHasAuthenticated = (authenticated) => {
    setState((prevState) => ({
      ...prevState,
      isAuthenticated: authenticated,
    }));
  };

  const handleLogout = (event) => {
    // let user = JSON.parse(localStorage.getItem('user'));
    userHasAuthenticated(false);
    localStorage.removeItem("asinwatchdog.user");
    localStorage.removeItem("asinwatchdog.session");
    localStorage.removeItem("asinwatchdog.mapping");
    history.push("/login");
  };

  const requestToUpdateMWSCredentials = () => {
    showConfirmDialog(
      "Please update your MWS credentials to proceed with this App",
      "",
      () => {
        history.push("/account/management/mws");
        closeConfirmDialog();
      },
      "Later",
      "Proceed"
    );
  };
  
  const requestToUpdateSPAPICredentials = () => {
    showConfirmDialog(
      "Please update your credentials to proceed with this App",
      "",
      () => {
        history.push("/account/management/mws");
        closeConfirmDialog();
      },
      "Later",
      "Proceed"
    );
  };

  const setHasMWSCredentials = (hasCredentials) => {
    setState((prevState) => ({
      ...prevState,
      hasMWSCredentials: hasCredentials,
    }));
  };

  const hasMWSCredentials = () => {
    return state.hasMWSCredentials;
  };
  
  const setHasSPAPICredentials = (hasCredentials) => {
    setState((prevState) => ({
      ...prevState,
      hasSPAPICredentials: hasCredentials,
    }));
  };

  const hasSPAPICredentials = () => {
    return state.hasSPAPICredentials;
  };

  const showProgressDialog = (message = null) => {
    setState((prevState) => ({
      ...prevState,
      progressOpen: true,
      progressMessage: message,
    }));
  };

  const updateProgressMessage = (message = null) => {
    setState((prevState) => ({
      ...prevState,
      progressMessage: message,
    }));
  };

  const closeProgressDialog = () => {
    setState((prevState) => ({
      ...prevState,
      progressOpen: false,
      progressMessage: null,
    }));
  };

  const hasProductsFetched = () => {
    if (hasMWSCredentials()) {
      ProductService.listASINs()
        .then((data) => {
          if (data.length === 0) {
            let headers = { type: "MERCHANT_LISTING" };
            MasterService.fetchBackgroundJobsStatus(headers).then((data) => {
              if (!data || Array.isArray(data)) {
                showConfirmDialog(
                  "Request Merchant Listing to Proceed using this App",
                  "",
                  () => {
                    let h = { type: "MERCHANT_LISTING" };
                    ProductService.executeReportJob(h)
                      .then((response) => {
                        if (response.status === 201) {
                          return response.json();
                        } else if (
                          response.status === 500 ||
                          response.status === 503
                        ) {
                          return response.json();
                        }
                      })
                      .then((data) => {
                        showSnackBar(data.message, "info");
                      })
                      .catch((error) => {
                        showSnackBar(error.message, "error");
                        console.log("Failed to Request Merchant Listing");
                      });
                  },
                  "Later",
                  "Proceed"
                );
              }
            });
          }
        })
        .catch((error) => console.log("Failed to fetch Product line"));
    }
  };

  const requestMerchantListing = () => {
    showConfirmDialog(
      "Please update your MWS credentials to proceed with this App",
      "",
      () => {
        history.push("/account/management/jobs");
        closeConfirmDialog();
      },
      "Later",
      "Proceed"
    );
  };

  const updateMWSCredentials = (credentials) => {
    setState((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        seller_id: credentials.sellerId,
        aws_access_key_id: credentials.awsAccessKeyId,
        secret_key: credentials.secretKey,
        market_place_id: credentials.marketPlaceId,
      },
    }));
  };
  
  const updateSPAPICredentials = (credentials) => {
    setState((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        lwa_client_id: credentials.lwa_client_id,
        lwa_client_secret: credentials.lwa_client_secret,
        lwa_refresh_token: credentials.lwa_refresh_token,
        aws_access_key: credentials.aws_access_key,
        aws_secret_key: credentials.aws_secret_key,
        market_place_id: credentials.marketPlaceId,
      },
    }));
  };

  const updateUser = (user) => {
    setState((prevState) => ({
      ...prevState,
      user: user,
    }));
  };

  const setNotifications = (notifications) => {
    setState((prevState) => ({
      ...prevState,
      notifications,
    }));
  };

  const handleNotificationsClose = () => {
    setState((prevState) => ({
      ...prevState,
      openNotifications: false,
    }));
  };

  const showNotifications = (e) => {
    setState((prevState) => ({
      ...prevState,
      openNotifications: true,
    }));
  };

  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  // return useMediaQuery('(max-width:750px)');
  // return useMediaQuery(theme.breakpoints.down('md'));

  const userWantsToTour = (tour) => {
    setState((prevState) => ({
      ...prevState,
      userIsTouring: tour,
    }));
  };

  const setTourSteps = (steps) => {
    setState((prevState) => ({
      ...prevState,
      tourSteps: steps,
    }));
  };

  const tourAvailable = state.tourSteps.length > 0;

  return (
    <AppContext.Provider
      value={{
        state,
        setTheme,
        getTheme,
        initializeTheme,
        handleClose,
        handleSBClose,
        handleShow,
        handleDrawerClose,
        setTitle,
        showConfirmDialog,
        showSnackBar,
        closeConfirmDialog,
        setLinearLoading,
        handleDrawerOpen,
        handleConnectionChange,
        isLoading,
        userHasAuthenticated,
        handleLogout,
        requestToUpdateMWSCredentials,
        requestToUpdateSPAPICredentials,
        hasMWSCredentials,
        setHasMWSCredentials,
        hasSPAPICredentials,
        setHasSPAPICredentials,
        hasProductsFetched,
        requestMerchantListing,
        updateMWSCredentials,
        updateSPAPICredentials,
        updateUser,
        handleNotificationsClose,
        showNotifications,
        setNotifications,
        setAuthenticating,
        showProgressDialog,
        updateProgressMessage,
        closeProgressDialog,
        setDialogFieldText,
        mobileView,
        userWantsToTour,
        setTourSteps,
        tourAvailable,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
