import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import DonutLargeOutlinedIcon from '@material-ui/icons/DonutLargeOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

import {
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  IconButton,
  Container,
  List,
  Collapse,
  makeStyles,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classNames from 'classnames';
import { AppContext } from '../AppContextProvider';

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9) + 1,
        },
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
      sectionDesktop: {
        display: 'flex',
        fontSize: 16,
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      menuIcon: {
        minWidth: 32
      },
      subMenuTitle: {
        // paddingLeft: 32, 
        paddingTop: 0, 
        paddingBottom: 0
      },
      subMenuItem: {
        color: 'black',
        // paddingTop: 0, 
        // paddingBottom: 0
      },
      nested: {
        paddingLeft: 48, 
        // color: 'black',
        paddingTop: 0, 
        paddingBottom: 0,
      },
      link: {
        textDecoration: 'none !important', 
        color: 'black' 
      },
}));

export default function AppDrawer(props) {

    const classes = useStyles();
    const {state, handleDrawerClose, hasSPAPICredentials} = useContext(AppContext);

    const [openDrawer, setOpenDrawer] = React.useState({
        products: false,
        reports: false,
        customer: false,
        performance: false,
        tools: false,
        geo: false,
        inventory: false,
    })

    const handleClick = (type) => {
        // openDrawer[type] = !openDrawer[type];
        setOpenDrawer(openDrawer => ({...openDrawer, [type]: !openDrawer[type]}))
    }

    return (
        <Drawer
            anchor="left"
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: state.open,
                [classes.drawerClose]: !state.open,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: state.open,
                    [classes.drawerClose]: !state.open,
                }),
            }}
            open={state.open}
            onClose={handleDrawerClose}
        >
            <div className={classes.toolbar}>
                <div style={{ float: "left", width: "100%", marginLeft: 2 }}>
                    <img
                        src={process.env.PUBLIC_URL + "/images/icon.png"}
                        alt="icon"
                        height={40}
                    />
                </div>
                <IconButton onClick={handleDrawerClose}>
                    {/* <AccountCircleIcon className={classes.accountCircle}/> */}
                    {state.isAuthenticated && (
                        <section className={classes.sectionDesktop}>
                            {state.user.name}
                        </section>
                    )}
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <div style={{ height: "85vh", overflow: "auto" }}>
                <Divider />
                <Link
                    to="/"
                    style={{ textDecoration: "none", color: "black" }}
                    onClick={handleDrawerClose}
                >
                    <ListItem button key="Dashboard">
                        <ListItemIcon className={classes.menuIcon}>
                            <DonutLargeOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography variant="subtitle2">Dashboard</Typography>}
                        />
                    </ListItem>
                </Link>
                <Divider />
                {hasSPAPICredentials && (
                    <div>
                        <List className={classes.subMenuTitle}>
                            <ListItem button onClick={(e) => handleClick("products")}>
                                <ListItemIcon className={classes.menuIcon}>
                                    <ShoppingCartOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="subtitle2"
                                            align="left"
                                            className="sub-menu"
                                        >
                                            Products
                                        </Typography>
                                    }
                                />
                                {openDrawer["products"] ? (
                                    <ExpandLess />
                                ) : (
                                        <ExpandMore />
                                    )}
                            </ListItem>
                            <Collapse
                                in={openDrawer["products"]}
                                timeout="auto"
                                unmountOnExit
                                className={classes.subMenuItem}
                            >
                                <List
                                    component="div"
                                    disablePadding
                                    onClick={handleDrawerClose}
                                >
                                    <Link to="/product/asin" className={classes.link}>
                                        <ListItem button key="ASIN" className={classes.nested}>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">ASIN</Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link to="/product/asin/metrics" className={classes.link}>
                                        <ListItem
                                            button
                                            key="ASIN Metrics"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        ASIN Metrics
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link to="/product/track/competitor" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Track Competitor"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Track Competitor
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    {/* <Link to="/product/ranking/metrics" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Ranking Metrics"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Ranking Metrics
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link> */}
                                </List>
                            </Collapse>
                        </List>
                        <Divider />
                        <List className={classes.subMenuTitle}>
                            <ListItem button onClick={(e) => handleClick("performance")}>
                                <ListItemIcon className={classes.menuIcon}>
                                    <BarChartOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="subtitle2"
                                            align="left"
                                            className="sub-menu"
                                        >
                                            Performance
                                        </Typography>
                                    }
                                />
                                {openDrawer["performance"] ? (
                                    <ExpandLess />
                                ) : (
                                        <ExpandMore />
                                    )}
                            </ListItem>
                            <Collapse
                                in={openDrawer["performance"]}
                                timeout="auto"
                                unmountOnExit
                                className={classes.subMenuItem}
                            >
                                <List
                                    component="div"
                                    disablePadding
                                    onClick={handleDrawerClose}
                                >
                                    <Link to="/performance/product" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Top Performing Product (in Sales)"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Top Performing Product (in Sales)
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link
                                        to="/performance/product/graphical"
                                        className={classes.link}
                                    >
                                        <ListItem
                                            button
                                            key="Top Performing Products (Graphical)"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Top Performing Products (Graphical)
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    {/* <Link to="/performance/customer" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Top Customers"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Top Customers
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link> */}
                                    <Link to="/performance/orders" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Orders Report"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                    Orders Report
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link to="/performance/sales" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Sales Report"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                    Sales Report
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link
                                        to="/performance/sales/velocity"
                                        className={classes.link}
                                    >
                                        <ListItem
                                            button
                                            key="Sales Velocity"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Sales Velocity
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link to="/geo/analyze/asin" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Analyze by ASIN"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Analyze by ASIN
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link to="/geo/analyze/location" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Analyze by Location"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Analyze by Location
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link to="/geo/analyze/zonal" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Zonal ASIN Sales"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Zonal ASIN Sales
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link to="/product/reviews" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Product Reviews"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Product Reviews
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link to="/sales/vs/inventory" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Sales vs Inventory"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Sales vs Inventory
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                </List>
                            </Collapse>
                        </List>
                        <Divider />
                        {/* <Link
                            to="/customer"
                            style={{ textDecoration: "none", color: "black" }}
                            onClick={handleDrawerClose}
                        >
                            <ListItem button key="Customers">
                                <ListItemIcon className={classes.menuIcon}>
                                    <PeopleOutlineOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2">Customers</Typography>
                                    }
                                />
                            </ListItem>
                        </Link>
                        <Divider /> */}
                        <List className={classes.subMenuTitle}>
                            <ListItem button onClick={(e) => handleClick("customer")}>
                                <ListItemIcon className={classes.menuIcon}>
                                    <PeopleOutlineOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="subtitle2"
                                            align="left"
                                            className="sub-menu"
                                        >
                                            Customers
                                        </Typography>
                                    }
                                />
                                {openDrawer["customer"] ? (
                                    <ExpandLess />
                                ) : (
                                        <ExpandMore />
                                    )}
                            </ListItem>
                            <Collapse
                                in={openDrawer["customer"]}
                                timeout="auto"
                                unmountOnExit
                                className={classes.subMenuItem}
                            >
                                <List
                                    component="div"
                                    disablePadding
                                    onClick={handleDrawerClose}
                                >
                                    <Link to="/performance/customer" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Top Customers"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Top Customers
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link to="/performance/customer/advance/search" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Advanced Customer Search"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Advanced Customer Search
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link to="/seller/feedback" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Seller Feedback"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Seller Feedback
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                </List>
                            </Collapse>
                        </List>
                        <Divider />
                        <List className={classes.subMenuTitle}>
                            <ListItem button onClick={(e) => handleClick("inventory")}>
                                <ListItemIcon className={classes.menuIcon}>
                                    <StoreOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="subtitle2"
                                            align="left"
                                            className="sub-menu"
                                        >
                                            Inventory
                                        </Typography>
                                    }
                                />
                                {openDrawer["inventory"] ? (
                                    <ExpandLess />
                                ) : (
                                        <ExpandMore />
                                    )}
                            </ListItem>
                            <Collapse
                                in={openDrawer["inventory"]}
                                timeout="auto"
                                unmountOnExit
                                className={classes.subMenuItem}
                            >
                                <List
                                    component="div"
                                    disablePadding
                                    onClick={handleDrawerClose}
                                >
                                    <Link to="/inventory/report" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Inventory Report"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Inventory Report
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Link to="/inventory/graphical" className={classes.link}>
                                        <ListItem
                                            button
                                            key="Historical Report"
                                            className={classes.nested}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Historical Report
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                </List>
                            </Collapse>
                        </List>
                        <Divider />
                    </div>
                )}
                {/* <List className={classes.subMenuTitle}>
                    <ListItem button onClick={(e) => handleClick('geo')}>
                      <ListItemIcon className={classes.menuIcon}><Icon>map</Icon></ListItemIcon>
                      <ListItemText primary={<Typography variant="subtitle2" align="left" className="sub-menu">Geo Analyze</Typography>} />
                      {openDrawer['geo'] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openDrawer['geo']} timeout="auto" unmountOnExit className={classes.subMenuItem}>
                      <List component="div" disablePadding onClick={handleDrawerClose}>
                          
                      </List>
                    </Collapse>
                  </List>
                  <Divider /> */}
                {/* <List className={classes.subMenuTitle}>
                    <ListItem button onClick={(e) => handleClick('tools')}>
                      <ListItemIcon className={classes.menuIcon}><SettingsOutlinedIcon/></ListItemIcon>
                      <ListItemText primary={<Typography variant="subtitle2" align="left" className="sub-menu">Tools</Typography>} />
                      {openDrawer['tools'] ? <ExpandLess /> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={openDrawer['tools']} timeout="auto" unmountOnExit className={classes.subMenuItem}>
                      <List component="div" disablePadding onClick={handleDrawerClose}>
                          <Link to="/account/settings" className={classes.link}>
                              <ListItem button key="Settings"  className={classes.nested}>
                                <ListItemText primary={<Typography variant="subtitle2">Settings</Typography>} />
                              </ListItem>
                          </Link>
                          <Link to="/account/settings/jobs" className={classes.link}>
                              <ListItem button key="Job Configuration"  className={classes.nested}>
                                <ListItemText primary={<Typography variant="subtitle2">Job Configuration</Typography>} />
                              </ListItem>
                          </Link>
                      </List>
                    </Collapse>
                  </List>
                  <Divider /> */}
            </div>
            <div>
                <Container maxWidth="sm" style={{ position: "absolute", bottom: 0 }}>
                    <Typography variant="subtitle2" style={{ textAlign: "center", fontSize: 10 }}>
                        Version: {global.appVersion}
                        <React.Fragment>
                            <br />
                                Powered by ASG Mantra & Sysbrew
                        </React.Fragment>
                    </Typography>
                </Container>
            </div>
        </Drawer>
    );
}
