import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Divider, Table, TableBody, TableRow, TablePagination, IconButton, useTheme } from "@material-ui/core";
import {red, green, blue} from '@material-ui/core/colors';
import ItemSelection from '../../components/ItemSelection';
import EnhancedTableHead from "../../components/EnhancedTableHead";
import EnhancedTableToolbar from "../../components/EnhancedTableToolbar";
import { toInt, getTimeInIST, BootstrapTooltip, StyledTableCell, HtmlTooltip, formatNumber } from "../../Utils";
import { RowCountOptions, TABLE_COUNTS } from "../../Constants";
import { MasterService } from "../../Services/MasterService";
import { ProductService } from "../../Services/ProductService";
import BSRGraph from "../../components/BSRGraph";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ClearIcon from '@material-ui/icons/Clear';
import MyDialog from "../../components/MyDialog";
import ASINDetails from "../../components/ASINDetails";
import SyncIcon from '@material-ui/icons/Sync';
import { AppContext } from "../../AppContextProvider";
import queryString from 'query-string';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1), 
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    green: {
        color: green[700],
    },
    red: {
        color: red[500],
    }
}));

export default function ASINMetrics(props){
    
    const {setTitle, setLinearLoading, showSnackBar, setTourSteps, showConfirmDialog, closeConfirmDialog, showProgressDialog, closeProgressDialog} = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();
    const [filter, setFilter] = React.useState('');
    const [selectedTag, setSelectedTag] = React.useState(null);
    const [tags, setTags] = React.useState(null);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('asin');
    const [generate, setGenerate] = React.useState(true);
    const [showDialog, setShowDialog] = React.useState(false);
    const [dialogAsin, setDialogASIN] = React.useState(null);
    const [dialogDetails, setDialogDetails] = React.useState(null);
    const [showDetailsDialog, setShowDetailsDialog] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(true);
    const [dialogDetailsAsin, setDialogDetailsASIN] = React.useState(null);
    const [dialogDetailsTitle, setDialogDetailsTitle] = React.useState(null);
    const [data , setData] = React.useState([]);

    const columns = [
        { id: 'name', align: 'left', numeric: false, disablePadding: false, label: 'Product', sortable: true },
        { id: 'asin', align: 'left', numeric: false, disablePadding: true, label: 'ASIN', sortable: true },
        { id: 'seller_sku', align: 'left', numeric: false, disablePadding: true, label: 'SKU', sortable: true },
        { id: 'bsr', align: 'center', numeric: false, disablePadding: true, label: 'BSR', sortable: false, tooltip: 'Best Seller Ranking' },
        { id: 'top_level_category', align: 'center', numeric: false, disablePadding: true, label: 'Category', sortable: false, tooltip: 'Top Level Category' },
        { id: 'competitive_pricing', align: 'center', numeric: false, disablePadding: true, label: 'Price', sortable: false },
        { id: 'no_of_listings', align: 'center', numeric: false, disablePadding: true, label: 'Listings', sortable: false, tooltip: 'No. of Listing' },
        { id: 'status', align: 'center', numeric: false, disablePadding: false, label: 'Status', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.asin_metrics_tab_count_key) ? toInt(localStorage.getItem(TABLE_COUNTS.asin_metrics_tab_count_key)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    // const [totalPages, setTotalPages] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.asin_metrics_tab_count_key, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="merchant"]',
                content: `Resync Merchant Listing from Amazon`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=showdetails]',
                content: `Click to Show ASIN Details`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=bsr]',
                content: `Click to Show BSR Chart`,
                style: {
                    backgroundColor: '#fff',
                },
            }
        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    React.useEffect(() => {
        if(props.location.search){
            let qString = queryString.parse(props.location.search);
            if(qString.asin){
                setFilter(qString.asin);
                setShowLoader(true);
                setGenerate(true);
            }
        }
    }, [props.location.search]);

    React.useEffect(() => {
        setTitle("ASIN Metrics");
        if(props.location.search){
            let qString = queryString.parse(props.location.search);
            if(qString.asin){
                setFilter(qString.asin);
            }
        }
        MasterService.getTags()
        .then(data => {
            // console.log(data);
            let allTags = {label: 'All Tags', tag: '-1'};
            setTags([allTags, ...data]);
            setSelectedTag(allTags);
        })
        .catch(error => console.log(error));

    }, [true]);

    React.useEffect(() => {
        if(generate && selectedTag){
            setLinearLoading(true);
            if(showLoader){
                showProgressDialog();
            }
            let tag = selectedTag.tag !== '-1' ? selectedTag.tag : null;
            setTotalItems(0);
            setCurrentPageNumber(0);
            ProductService.listASINMetricsCount(filter, tag)
            .then(data => {
                // console.log(data);
                setTotalItems(toInt(data.count));
                if(toInt(data.count) === 0){
                    setData([]);
                    if(showLoader){
                        closeProgressDialog();
                    }
                }
                // setTotalPages(Math.ceil(data.count / numItemsPerPage) - 1);
                setFetchPage(true);
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                if(showLoader){
                    closeProgressDialog();
                }
            });
            setGenerate(false);
        }
    }, [generate, selectedTag, order, orderBy]);

    React.useEffect(() => {
        if(fetchPage && selectedTag && totalItems > 0){
            setLinearLoading(true);
            let tag = selectedTag.tag !== '-1' ? selectedTag.tag : null;
            ProductService.listASINMetrics(filter, tag, 'internal', orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                setData(data);
                // console.log(data);
                setLinearLoading(false);
                if(showLoader){
                    closeProgressDialog();
                }
            }).catch((error) => {
                console.log(error);
                setLinearLoading(false);
                if(showLoader){
                    closeProgressDialog();
                }
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const refetchMerchantListing = () => {
        showConfirmDialog('Confirm Request Merchant Listing? It may take sometime for the job to complete.', '', () => {
            closeConfirmDialog();
            let headers = {
                type: 'MERCHANT_LISTING'
            }
            ProductService.executeReportJob(headers)
            .then(response => {
                if (response.status === 500 || response.status === 503 || response.status === 201) {
                    return response.json()
                }
            })
            .then(data => {
                    showSnackBar(data.message, 'info');
            })
            .catch((error) => {
                console.log('error fetching status');
            });
        });
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={10} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item xs={12} lg={2}>
                                    <ItemSelection 
                                        label='Select Tags' 
                                        value={selectedTag} 
                                        optionLabel='label' 
                                        options={tags} 
                                        selected={(tag) => {
                                            setSelectedTag(tag);
                                            setGenerate(true);
                                        }
                                        }/>
                                </Grid>
                                <Grid item xs={9} lg={9}>
                                    <EnhancedTableToolbar 
                                            onSearchTextChange={(e) => {
                                                setFilter(e.target.value);
                                                setGenerate(true);
                                                setShowLoader(false);
                                            }}
                                            searchText={filter}
                                            searchHint={'Search By ASIN, SKU, Title'}/>
                                </Grid>
                                <Grid item>
                                    <BootstrapTooltip title={"Click here to Re-Fetch Merchant listing."}>
                                        <IconButton
                                            data-tour="merchant"
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            className={classes.button}
                                            onClick={(e) => refetchMerchantListing()}
                                        >
                                            <SyncIcon />
                                        </IconButton>
                                    </BootstrapTooltip>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 10, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows ={columns} />
                                            <TableBody>
                                                {
                                                    data.map((element, index) => {
                                                            let bsr1 = (element.bsr.length > 0 && element.bsr[0].length > 0) ? element.bsr[0] : [];
                                                            let bsr2 = (element.bsr.length === 2 && element.bsr[1].length > 0) ? element.bsr[1] : [];
                                                            bsr1.sort((a, b) => (getTimeInIST(a.modified_date).isAfter(getTimeInIST(b.modified_date)) ? -1 : 1));
                                                            bsr2.sort((a, b) => (getTimeInIST(a.modified_date).isAfter(getTimeInIST(b.modified_date)) ? -1 : 1));

                                                            let diff1 = ((bsr1[1] && bsr1[1].rank) || 0) - ((bsr1[0] && bsr1[0].rank) || 0);
                                                            let diff2 = ((bsr2[1] && bsr2[1].rank) || 0) - ((bsr2[0] && bsr2[0].rank) || 0);
                                                            
                                                                // element.categories && `${element.categories.top_category_name} as of ${getTimeInIST(bsr2[0].modified_date).format(format_display_fancy_notime)}`
                                                                // + element.categories && `${element.categories.category_name}\n as of ${getTimeInIST(bsr1[0].modified_date).format(format_display_fancy_notime)}`
                                                            return (
                                                                <TableRow 
                                                                    key={index} 
                                                                    hover 
                                                                    style={element.status !== 'ACTIVE' ? {backgroundColor: red[100]} : {}}>
                                                                    <StyledTableCell
                                                                        data-tour="showdetails" 
                                                                        onClick={(e) => {
                                                                            setDialogDetailsASIN(element.asin);
                                                                            setDialogDetailsTitle(element.name);
                                                                            setShowDetailsDialog(true);
                                                                        }} 
                                                                        
                                                                        scope="row" 
                                                                        align="left" 
                                                                        padding='default' 
                                                                        style={{ cursor: 'pointer', paddingLeft:8, paddingRight:8, width: '22vw', paddingTop: 5, paddingBottom: 5}}>
                                                                            <div align="left" style={{verticalAlign: 'middle', display: 'inline-flex'}}>
                                                                                <div style={{marginRight: 15, maxWidth: 110}}>
                                                                                    <img src={element.image_url ? element.image_url : null} height="50" width="50" alt=""/>
                                                                                </div>
                                                                                <BootstrapTooltip title={element.name}>
                                                                                    <span>{`${element.name.substring(0, 125)}${element.name.length > 125 ? '...' : ''}`}</span>
                                                                                </BootstrapTooltip>
                                                                            </div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell 
                                                                        scope="row" 
                                                                        align="center" 
                                                                        padding='default' 
                                                                        style={{cursor: 'pointer', width: '8vw', paddingLeft: 8, paddingRight:8}}
                                                                        onClick={(e) => {
                                                                            setDialogDetailsASIN(element.asin);
                                                                            setDialogDetailsTitle(element.name);
                                                                            setShowDetailsDialog(true);
                                                                        }}>
                                                                        {element.asin}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell 
                                                                        scope="row" 
                                                                        align="justify" 
                                                                        padding='default' 
                                                                        style={{cursor: 'pointer', width: '22vw', paddingLeft: 8, paddingRight:8}}
                                                                        onClick={(e) => {
                                                                            setDialogDetailsASIN(element.asin);
                                                                            setDialogDetailsTitle(element.name);
                                                                            setShowDetailsDialog(true);
                                                                        }}>
                                                                       {element.seller_sku}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell 
                                                                        data-tour="bsr"
                                                                        align="center" 
                                                                        padding='default' 
                                                                        style={{width: '8vw', paddingRight:8, paddingLeft: 8}}>
                                                                        {
                                                                            bsr1.length === 0 && bsr2.length === 0 ? 
                                                                                '-'
                                                                            :
                                                                                <div 
                                                                                style={{cursor: "pointer"}}
                                                                                onClick={(e) => {
                                                                                                console.log(element.asin, element.categories.top_category_name);
                                                                                                //setDialogASIN(element.asin);
                                                                                                //setDialogDetailsTitle(element.name);
                                                                                                //setDialogDetails(element);
                                                                                                //setShowDialog(true); 
                                                                                                setDialogDetailsASIN(element.asin);
                                                                                                setDialogDetailsTitle(element.name);
                                                                                                setShowDetailsDialog(true);
                                                                                }}>
                                                                                        <HtmlTooltip arrow 
                                                                                            title= {<React.Fragment>
                                                                                                        <table>
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td><b>{element.categories.top_category_name}</b></td>
                                                                                                                    <td align="right">{(bsr2[0] && bsr2[0].rank) || 0}</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td><b>{element.categories.category_name}</b></td>
                                                                                                                    <td align="right">{(bsr1[0] && bsr1[0].rank) || 0}</td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </React.Fragment>}>
                                                                                            <div>
                                                                                            {
                                                                                                bsr2.length > 0 &&
                                                                                                    <div>
                                                                                                        {(bsr2[0] && bsr2[0].rank) || 0}   (<span className={(diff2 > 0 ? classes.green: classes.red)}>{diff2 > 0 ? `+${diff2}` : `${diff2}`}</span>)
                                                                                                    </div>
                                                                                            }
                                                                                            {
                                                                                                bsr1.length > 0 &&
                                                                                                    <div>
                                                                                                        {(bsr1[0] && bsr1[0].rank) || 0}   (<span className={(diff1 > 0 ? classes.green: classes.red)}>{diff1 > 0 ? `+${diff1}` : `${diff1}`}</span>)
                                                                                                    </div>
                                                                                            }
                                                                                            </div>
                                                                                        </HtmlTooltip>
                                                                                </div>
                                                                        }
                                                                    </StyledTableCell>
                                                                    <StyledTableCell scope="row" align="center" padding='default' style={{width: '8vw', paddingLeft: 8, paddingRight:8}}>
                                                                        {(element.categories && element.categories.top_category_name) || '-'}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell scope="row" align="center" padding='default' style={{width: '6vw', paddingLeft: 8, paddingRight:8}}>
                                                                        {element.competitive_pricing > 0 ? formatNumber(element.competitive_pricing) : '-'}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell scope="row" align="center" padding='default' style={{width: '2vw', paddingLeft: 8, paddingRight:8}}>
                                                                        { element.no_of_listings > 0 ?
                                                                            <div className={element.no_of_listings > 1 ? classes.red: classes.blue}>{element.no_of_listings}</div>
                                                                            :
                                                                            <IconButton className={classes.red} component="span">
                                                                                <ClearIcon />
                                                                            </IconButton>
                                                                        }
                                                                    </StyledTableCell>
                                                                    <StyledTableCell scope="row" align="center" padding='none' style={{width: '9vw', paddingLeft: 4, paddingRight: 4, color: theme.palette.white}}>
                                                                        <div>
                                                                            {
                                                                                element.status ? 
                                                                                    element.status === 'ACTIVE' ?  
                                                                                        <IconButton component="span" style={{paddingTop: 0, paddingBottom: 16}} className={classes.green}>
                                                                                            <FiberManualRecordIcon />
                                                                                        </IconButton>
                                                                                    : 
                                                                                        <IconButton className={classes.red} style={{paddingTop: 0, paddingBottom: 16}} component="span">
                                                                                            <FiberManualRecordIcon />
                                                                                        </IconButton>
                                                                                : 
                                                                                    '-'
                                                                            }
                                                                            {
                                                                                <HtmlTooltip arrow title={
                                                                                    <div>
                                                                                        <Typography variant="caption" color="inherit" component="div">{`Current Stock: ${element.sellable_stock}`}</Typography>
                                                                                        <Typography variant="caption" color="inherit" component="div">{`Threshold: ${element.min_stock_threshold}`}</Typography>
                                                                                    </div>
                                                                                    }>
                                                                                    <NotificationsActiveIcon style={{marginRight: 8}} color={element.sellable_stock < element.min_stock_threshold ? "error" : "primary"}/>
                                                                                </HtmlTooltip>
                                                                            }
                                                                            {
                                                                                <HtmlTooltip arrow title={
                                                                                    <div>
                                                                                        <Typography variant="caption" color="inherit" component="div">{`Defective Stock: ${element.defective_stock}`}</Typography>
                                                                                        <Typography variant="caption" color="inherit" component="div">{`Threshold: ${element.defective_stock_threshold}`}</Typography>
                                                                                    </div>
                                                                                    }>
                                                                                    <NotificationsActiveIcon  color={element.defective_stock > element.defective_stock_threshold ? "error" : "primary"}/>
                                                                                </HtmlTooltip>
                                                                            }
                                                                        </div>
                                                                    </StyledTableCell>
                                                                </TableRow>);
                                                        })
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        data.length === 0 && 
                                            <Typography color="error" variant="subtitle2">No Data</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <MyDialog
                    content={
                        <BSRGraph
                            showDetails
                            asin={dialogAsin}
                            details={dialogDetails}
                            open={showDialog} />
                        }
                    title={dialogDetailsTitle}
                    open={showDialog}
                    close={() => {
                        setShowDialog(false);
                        setDialogASIN(null);
                        setDialogDetails(null);
                        setDialogDetailsTitle(null);
                    }}/>
                <MyDialog
                    fullWidth
                    maxWidth="md"
                    content={
                        <ASINDetails
                            show={showDetailsDialog}
                            asin={dialogDetailsAsin}
                            />
                        }
                    title={dialogDetailsTitle}
                    open={showDetailsDialog}
                    close={() => {
                        setShowDetailsDialog(false);
                        setDialogDetailsASIN(null);
                        setDialogDetailsTitle(null);
                    }}/>
            </div>
        </div>
    );

};