import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, FormHelperText} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { format_display_fancy_notime } from "../Utils";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
        // maxWidth: 140,
        marginLeft: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
}));

export default function SingleDate(props){
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [date, setDate] = React.useState(moment().startOf('day').add(-30, 'd'));

    React.useEffect(() => {
        if(props.date) setDate(props.date);
    }, [props]);

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        disableToolbar
                        fullWidth
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        variant="inline"
                        inputVariant="outlined"
                        margin="dense"
                        label="Date"
                        value={date}
                        maxDate={props.maxDate}
                        onChange={(date) => {
                            setDate(date);
                            props.setDate(date);
                            setOpen(false);
                        }}
                        format='DD MMM YYYY'
                        className={classes.formControl}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            {
                props.maxDate &&
                <Grid item xs={12} lg={12}>
                    <FormHelperText style={{fontSize: 10, textAlign: 'right'}}>Data available till {moment(props.maxDate).format(format_display_fancy_notime)}</FormHelperText>
                </Grid>
            }
        </Grid>
    );
};

SingleDate.propTypes = {
    date: PropTypes.object.isRequired,
    setDate: PropTypes.func.isRequired,
};
  