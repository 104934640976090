import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import gradients from '../../../../utils/gradients';
import { getTimeInIST } from '../../../../Utils';

const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.hover
    }
  },
  avatarBlue: {
    backgroundImage: gradients.blue
  },
  avatarGreen: {
    backgroundImage: gradients.green
  },
  avatarOrange: {
    backgroundImage: gradients.orange
  },
  avatarIndigo: {
    backgroundImage: gradients.indigo
  },
  arrowForwardIcon: {
    color: theme.palette.icon,
    cursor: 'pointer',
  },
  listitem: {
    cursor: 'pointer',
  },
  unread: {
    backgroundColor: theme.palette.unread,
  }
}));

const NotificationList = props => {
  const { notifications, className, markRead, removeNotification, onClose, ...rest } = props;

  const history = useHistory();
  const classes = useStyles();

  // const avatars = {
  //   order: (
  //     <Avatar className={classes.avatarBlue}>
  //       <PaymentIcon />
  //     </Avatar>
  //   ),
  //   user: (
  //     <Avatar className={classes.avatarOrange}>
  //       <PeopleIcon />
  //     </Avatar>
  //   ),
  //   project: (
  //     <Avatar className={classes.avatarGreen}>
  //       <StoreIcon />
  //     </Avatar>
  //   ),
  //   inventory: (
  //     <Avatar className={classes.avatarIndigo}>
  //       <CodeIcon />
  //     </Avatar>
  //   )
  // };

  const openLink = notification => e => {
    markRead(notification.id);
    history.push(notification.link ? notification.link : '#');
    if(notification.link){
      onClose && onClose();
    }
  }

  const remove = id => {
      removeNotification(id);
  }

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
      disablePadding
    >
      {notifications.map((notification, i) => (
        <ListItem
          className={clsx(classes.listItem, notification.unread === '1' ? classes.unread: null)}
          component={'div'}
          divider={i < notifications.length - 1}
          key={notification.id}
        >
          {/* <ListItemAvatar>{avatars[notification.type]}</ListItemAvatar> */}
          {/* notification.unread === '1' ? '1' : null */}
            <ListItemText
              onClick={openLink(notification)}
              primary={notification.message}
              className={classes.listitem}
              primaryTypographyProps={{ variant: 'caption' }}
              secondary={getTimeInIST(notification.created_date).fromNow()}
            />
            <DeleteIcon className={classes.arrowForwardIcon} onClick={(e) => remove(notification.id)}/>
        </ListItem>
      ))}
    </List>
  );
};

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  removeNotification: PropTypes.func.isRequired,
  markRead: PropTypes.func.isRequired,
};

export default NotificationList;
