import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, Tooltip, TableSortLabel } from '@material-ui/core';

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
      this.props.onRequestSort(event, property);
    };
  
    render() {
      const { rows, order, orderBy } = this.props;
      const fixedColumns = this.props.fixedColumns || [];
  
      return (
        <TableHead>
          <TableRow>
            {rows.map(
              (row, i) => (
                <TableCell
                  style={{backgroundColor: '#c0bfbf', fontSize: 12, zIndex: fixedColumns.includes(i) ? 10: 1, paddingLeft: row.disablePadding ? 16 : 32, paddingRight: row.disablePadding ? 16 : 32}}
                  key={row.id}
                  align={row.numeric ? 'right' : (row.align ? row.align : 'center')}
                  padding={row.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === row.id ? order : false} 
                  variant='head'>
                  { row.sortable ? 
                        <Tooltip
                            title="Sort"
                            placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                            enterDelay={300} >
                            <TableSortLabel
                              active={orderBy === row.id}
                              direction={order}
                              onClick={this.createSortHandler(row.id)} >
                              <div style={{fontWeight: 700}} >{row.label}</div>
                            </TableSortLabel>
                        </Tooltip>
                        : 
                        <Tooltip title={row.tooltip || ''}>
                          <div style={{fontWeight: 700}} >{row.label}</div>
                        </Tooltip>
                  }
                </TableCell>
              ),
              this,
            )}
          </TableRow>
        </TableHead>
      );
    }
  }

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  fixedColumns: PropTypes.array,
};

export default EnhancedTableHead;