import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Table, TableBody, TableRow, Switch, Divider, Button, Checkbox, IconButton, lighten, FormControlLabel, FormControl, TextField, Badge, Card, CardContent, CardHeader, CardActions, InputAdornment } from "@material-ui/core";
import {red, blue, green} from '@material-ui/core/colors';
import EnhancedTableHead from "../../components/EnhancedTableHead";
import EnhancedTableToolbar from "../../components/EnhancedTableToolbar";
import {formatNumber, StyledTableCell, BootstrapTooltip, toInt} from "../../Utils";
import { MasterService } from "../../Services/MasterService";
import { ProductService } from "../../Services/ProductService";
import ItemSelection from "../../components/ItemSelection";
import { AddTag } from "../../components/AddTags";
import AddASIN from "../../components/AddASIN";
import MyDialog from "../../components/MyDialog";
import ASINDetails from "../../components/ASINDetails";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { AppContext } from "../../AppContextProvider";
import { useCallback } from "react";
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Popper from '@material-ui/core/Popper';
import clsx from 'clsx';
import SyncIcon from '@material-ui/icons/Sync';

const filterOp = createFilterOptions();

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    section: {
        width: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        float: 'left'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 300,
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'left',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize: 12,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    green: {
        color: green[700],
    },
    red: {
        color: red[500],
    },
    highlight: theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
    },
    checkbox: {
        height: 24
    },
    customBadge: {
        fontSize: 10,
        height: 16
    }
}));

export default function ASINPage(props) {

    const {setTitle, setLinearLoading, showSnackBar, showProgressDialog, closeProgressDialog, setTourSteps, showConfirmDialog, closeConfirmDialog} = useContext(AppContext);

    const classes = useStyles();
    const [filter, setFilter] = React.useState('');
    const [data, setData] = React.useState([]);
    const [orgdata, setOrgData] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [selectedTag, setSelectedTag] = React.useState(null);
    const [tags, setTags] = React.useState(null);
    const [masterTags, setMasterTags] = React.useState(null);
    const [masterKeywords, setMasterKeywords] = React.useState(null);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('asin');
    const [sortingAction, setSortingAction] = React.useState(false);
    const [addASINOpen, setAddASINOpen] = React.useState(false);
    const [enabledFlag, setEnabledFlag] = React.useState(true);
    const [showDetailsDialog, setShowDetailsDialog] = React.useState(false);
    const [dialogDetailsAsin, setDialogDetailsASIN] = React.useState(null);
    const [showImageDialog, setShowImageDialog] = React.useState(false);
    const [dialogDetailsURL, setDialogDetailsURL] = React.useState(null);
    const [dialogDetailsTitle, setDialogDetailsTitle] = React.useState(null);
    const [selectEnabled, setSelectEnabled] = React.useState(false);
    const [checkBoxList, setCheckBoxList] = React.useState([]);

    const [enableMonitoringStats, setEnableMonitoringStats] = React.useState(true);
    const [enableMonitoringReviews, setenableMonitoringReviews] = React.useState(true);
    const [enableASIN, setEnableASIN] = React.useState(true);
    const [globalTags, setGlobalTags] = React.useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [clickedRow, setClickedRow] = React.useState(null);
    const openThreshold = Boolean(anchorEl);
    const id = openThreshold ? 'simple-popper' : undefined;

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="checkbox-asin"]',
                content: `Click to select ASIN's`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour="showenabled"]',
                content: `Filter Enabled / Disabled Products`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour="addasin"]',
                content: `Add ASIN if not already added by Merchant Listing Job`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=addtag]',
                content: `Click to Add / Remove Tags`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=enablestats]',
                content: `Click to Enable / Disable Monitoring Product Stats`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=enablereview]',
                content: `Click to Enable / Disable Monitoring Product Reviews`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=enableproduct]',
                content: `Click to Enable / Disable products`,
                style: {
                    backgroundColor: '#fff',
                },
            },

        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    const columns = [
        { id: 'checkbox', align: 'left', numeric: false, disablePadding: false, label: '', sortable: false },
        { id: 'seller_sku', align: 'left', numeric: false, disablePadding: false, label: 'Product', sortable: true },
        { id: 'asin', align: 'left', numeric: false, disablePadding: true, label: 'ASIN', sortable: true },
        // { id: 'seller_sku', align: 'left', numeric: false, disablePadding: true, label: 'SKU', sortable: true },
        { id: 'price', align: 'center', numeric: false, disablePadding: true, label: 'Price', sortable: true },
        // { id: 'maximum_retail_price', align: 'center', numeric: false, disablePadding: true, label: 'MRP', sortable: true },
        { id: 'tags', align: 'center', numeric: false, disablePadding: true, label: 'Tags', sortable: false },
        { id: 'keywords', align: 'center', numeric: false, disablePadding: true, label: 'Keywords', sortable: false },
        { id: 'enable_monitoring_stats', align: 'center', numeric: false, disablePadding: true, label: 'Actions', sortable: false },
        // { id: 'enable_monitoring_reviews', align: 'center', numeric: false, disablePadding: true, label: 'Review Monitoring', sortable: false },
        // { id: 'internal_status', align: 'center', numeric: false, disablePadding: false, label: 'Status', sortable: false },
    ];

    React.useEffect(() => {
        showProgressDialog();
        setTitle("ASIN");
        fetchTags();
        fetchKeywords();
    }, [true]);

    const fetchTags = () => {
        MasterService.getTags()
        .then(data => {
            // console.log(data);
            let allTags = {label: 'All Tags', tag: '-1'};
            setSelectedTag(null);
            setTags([allTags, ...data]);
            setMasterTags(data);
            setSelectedTag(allTags);
            setGenerate(true);
        })
        .catch(error => console.log(error));
    }
    
    const fetchKeywords = () => {
        MasterService.getKeywords()
        .then(data => {
            setMasterKeywords(data);
        })
        .catch(error => console.log(error));
    }

    React.useEffect(() => {
        if(generate){
            fetchReport();
            setGenerate(false);
        }
    }, [generate, order, orderBy]);

    const handleChange = (row) => e => {
        let enabled = e.target.checked;
        let field = e.target.name;
        if(row.asin && field && null !== enabled){
            // showConfirmDialog("Confirm change?", undefined, () => {
                confirmChange(row, field, enabled);
            // }, "Cancel", "Yes");
        }
    }

    const handleThresholdChange = e => {
        let value = e.target.value;
        let field = e.target.name;
        setClickedRow(prevValue => ({...prevValue, [field]: value}))
    }

    const confirmChange = (row, field, enabled) => {
        setLinearLoading(true);
        ProductService.productFieldUpdate(row.asin, field, enabled)
        .then((data1) => {
            // console.log(data);
            // setGenerate(true);
            if(data1.success){
                row[field] = enabled ? '1' : '0';
                setSortingAction(true);
                // showSnackBar(data.message, "success");
            } else {
                showSnackBar(data1.message, "error");
            }
            // closeConfirmDialog();
            setLinearLoading(false);
        }).catch((error) => {
            showSnackBar("Something went wrong. Try again later.", "error");
            // closeConfirmDialog();
            setLinearLoading(false);
        });
    }

    const changeThreshold = e => {
        setLinearLoading(true);

        Promise.all([
            ProductService.productThresholdUpdate(clickedRow.asin, 'min_stock_threshold', toInt(clickedRow.new_min_stock_threshold)),
            ProductService.productThresholdUpdate(clickedRow.asin, 'defective_stock_threshold', toInt(clickedRow.new_defective_stock_threshold))
        ])
        .then((data1) => {
            // console.log(data);
            // setGenerate(true);
            let success = true;
            data1.forEach(d => {
                success = success && d.success;
            })
            if(success){
                showSnackBar('Updated the thresholds', "info");
                let index = data.findIndex(r => r.asin === clickedRow.asin);
                let newArr = [...data];
                newArr[index].min_stock_threshold = toInt(clickedRow.new_min_stock_threshold);
                newArr[index].defective_stock_threshold = toInt(clickedRow.new_defective_stock_threshold);
                setData(newArr);
            } else {
                showSnackBar(data1[0].message, "error");
            }
            handleThresholdClose(null);
            setLinearLoading(false);
        }).catch((error) => {
            showSnackBar("Something went wrong. Try again later.", "error");
            setLinearLoading(false);
        });
    }

    const deleteTag = (asin, tag) => {
        // showConfirmDialog('Confirm Remove Tag', undefined, () => {
        //     closeConfirmDialog();
            showProgressDialog('Removing Tag...');
            ProductService.removeTag(asin, tag)
            .then((data) => {
                if(data){
                    fetchTags();
                    closeProgressDialog();
                } else {
                    showSnackBar('Could not remove Tag', 'error');
                    closeProgressDialog();
                }
            })
        // })
    };
    
    const deleteKeyword = (asin, keyword) => {
        // showConfirmDialog('Confirm Remove Tag', undefined, () => {
        //     closeConfirmDialog();
            showProgressDialog('Removing Keyword...');
            ProductService.removeKeyword(asin, keyword)
            .then((d) => {
                if(d){
                    let index = data.findIndex(r => r.asin === asin);
                    let newArr = [...data];
                    newArr[index].keywords = newArr[index].keywords.filter(k => k!== keyword);
                    setData(newArr);
                    fetchKeywords();
                    closeProgressDialog();
                } else {
                    showSnackBar('Could not remove Keyword', 'error');
                    closeProgressDialog();
                }
            })
        // })
    };

    const addTag = (asin, tag) => {
        showProgressDialog('Adding Tag...');
        ProductService.addTag(asin, tag)
        .then((data) => {
            if(data.status === 'success'){
                fetchTags();
            } else {
                showSnackBar('Could not add Tag', 'error');
            }
            closeProgressDialog();
        })
    };
    
    const addKeyword = (asin, keyword) => {
        showProgressDialog('Adding Keyword...');
        ProductService.addKeyword(asin, keyword)
        .then((d) => {
            if(d.status === 'success'){
                let index = data.findIndex(r => r.asin === asin);
                let newArr = [...data];
                newArr[index].keywords = [...newArr[index].keywords, keyword];
                setMasterKeywords([...masterKeywords, {label: keyword, keyword}])
                setData(newArr);
                fetchKeywords();
            } else {
                showSnackBar('Could not add Keyword', 'error');
            }
            closeProgressDialog();
        })
    };


    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
    }

    const handleSelectOne = useCallback((event, asin) => {
        
        if(event.target.checked){
            //console.log(checkBoxList.includes(element.asin));
            if(checkBoxList.indexOf(asin) === -1)
                setCheckBoxList(checkBoxList => [...checkBoxList, asin]);
        } else {
            checkBoxList.splice(checkBoxList.indexOf(asin), 1)
            setCheckBoxList(checkBoxList => [...checkBoxList]);
        }
        // setSortingAction(true);
    },[checkBoxList]);

    React.useEffect(() => {
        if(sortingAction && masterTags){
            setLinearLoading(true);
            let l = filter.length > 0 
            ? orgdata.filter(d => d.internal_status === (enabledFlag ? '1' : '0') && (
                d.name.toString().toLowerCase().includes(filter.toLowerCase()) || 
                d.asin.toString().toLowerCase().includes(filter.toLowerCase()) || 
                d.seller_sku.toString().toLowerCase().includes(filter.toLowerCase())))
            : 
                orgdata.filter(d => d.internal_status === (enabledFlag ? '1' : '0'));
            let tag = selectedTag && selectedTag.tag !== '-1' ? selectedTag.tag : null;
            if(null !== tag){
                l = l.filter(d => d.tags.includes(tag));
            }
            setData(l);
            // l.
            // setCurrentRows(rows);
            setSortingAction(false);
            setLinearLoading(false);
        }
    }, [sortingAction, masterTags, selectEnabled, filter]);

    const fetchReport = () => {
        // console.log(1, new Date());
        setLinearLoading(true);
        ProductService.listASINs()
        .then(data => {
            // console.log(2, new Date());
            // if(order === 'asc'){
            //     data.sort((a, b) => (a[orderBy] > b[orderBy]) ? 1 : -1);
            // } else {
            //     data.sort((a, b) => (a[orderBy] > b[orderBy]) ? -1 : 1);
            // }
            // setData(data);
            setOrgData(data);
            // console.log(3, new Date());
            setTitle(`ASIN (${data.length})`);
            setSortingAction(true);
            setLinearLoading(false);
            closeProgressDialog();
            // console.log(4, new Date());
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false); 
            closeProgressDialog();
        });
    };

    const handleChangeGlobal = event => {
        if(event.target.name === 'enable_monitoring_stats'){
            setEnableMonitoringStats(event.target.checked);
        } else if(event.target.name === 'enable_monitoring_reviews'){
            setenableMonitoringReviews(event.target.checked);
        } else if(event.target.name === 'internal_status'){
            setEnableASIN(event.target.checked);
        }
    };

    const cancelGlobalUpdate = (e) => {
        setCheckBoxList([]);
        setEnableASIN(true);
        setEnableMonitoringStats(true);
        setenableMonitoringReviews(true);
        setGlobalTags([]);
        setSelectEnabled(false);
        setSortingAction(true);
    }

    const saveGlobalUpdate = (e) => {
        console.log(globalTags, enableASIN, enableMonitoringReviews, enableMonitoringStats);

        if(checkBoxList.length > 0){
            let operations = [
                ProductService.productFieldUpdate(checkBoxList.join(','), 'internal_status', enableASIN),
                ProductService.productFieldUpdate(checkBoxList.join(','), 'enable_monitoring_reviews', enableMonitoringReviews),
                ProductService.productFieldUpdate(checkBoxList.join(','), 'enable_monitoring_stats', enableMonitoringStats)
             ];
            if(globalTags.length > 0){
                operations.push(ProductService.addTags(checkBoxList.join(','), globalTags.map(t => t.tag).join(',')));
            }

            Promise.all(operations)
            .then(data => {
                let datas = [];
                data.forEach((d, i) => {
                    if(i === 0 || i === 1 || i === 2){
                        datas.push(d.success);
                    } else {
                        datas.push(d.status === 'success');
                    }
                });
                if(datas.reduce((sum, next) => sum && next, true)){
                    showSnackBar('Made changes', "info");
                } else if((datas[0] !== true || datas[1] !== true || datas[1] !== true) && datas.slice(3, datas.length + 1).reduce((sum, next) => sum && next, true)){
                    showSnackBar('Something went wrong while updating Monitoring Flags or status. Please Update Manually', "warning");
                } else if(datas[0] === true && datas[1] === true && datas[2] === true && !datas.slice(3, datas.length + 1).reduce((sum, next) => sum && next, true)){
                    showSnackBar('Something went wrong while updating Tags. Please try manually', "warning");
                } else {
                    showSnackBar('Something went wrong while updating Monitoring flags and Tags. Please update manually', "warning");
                }
                setCheckBoxList([]);
                setEnableASIN(true);
                setEnableMonitoringStats(true);
                setenableMonitoringReviews(true);
                setGlobalTags([]);
                setSelectEnabled(false);
                setGenerate(true);
            });
        }
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
      
    function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
    }
      
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleThresholdClick = row => event => {
        row.new_min_stock_threshold = row.min_stock_threshold;
        row.new_defective_stock_threshold = row.defective_stock_threshold;
        setClickedRow(row);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleThresholdClose = (event) => {
        setClickedRow(null);
        if (anchorEl) {
          anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const refetchMerchantListing = () => {
        showConfirmDialog('Confirm Request Merchant Listing? It may take sometime for the job to complete.', '', () => {
            closeConfirmDialog();
            let headers = {
                type: 'MERCHANT_LISTING'
            }
            ProductService.executeReportJob(headers)
            .then(response => {
                if (response.status === 500 || response.status === 503 || response.status === 201) {
                    return response.json()
                }
            })
            .then(data => {
                    showSnackBar(data.message, 'info');
            })
            .catch((error) => {
                console.log('error fetching status');
            });
        });
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={2} justify="center">
                    <Grid item xl={10} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container alignItems="center">
                                {
                                    checkBoxList.length > 0 ? 
                                    (
                                        <Grid container item spacin={1} alignItems="center" xs={12} lg={12} className={classes.highlight} style={{height: 85}}>
                                            <Grid item xs={2} lg={2}>
                                                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                                                    {checkBoxList.length} selected
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} lg={4}>
                                                <FormControl component="fieldset">
                                                    <FormControlLabel
                                                        className={classes.checkbox}
                                                        control={
                                                        <Checkbox
                                                            checked={enableMonitoringStats}
                                                            onChange={handleChangeGlobal}
                                                            name="enable_monitoring_stats"
                                                            color="primary"
                                                        />
                                                        }
                                                        label="Enable Monitoring Stats"
                                                    />
                                                    <FormControlLabel
                                                        className={classes.checkbox}
                                                        control={
                                                        <Checkbox
                                                            checked={enableMonitoringReviews}
                                                            onChange={handleChangeGlobal}
                                                            name="enable_monitoring_reviews"
                                                            color="primary"
                                                        />
                                                        }
                                                        label="Enable Monitoring Reviews"
                                                    />
                                                    <FormControlLabel
                                                        className={classes.checkbox}
                                                        control={
                                                        <Checkbox
                                                            checked={enableASIN}
                                                            onChange={handleChangeGlobal}
                                                            name="internal_status"
                                                            color="primary"
                                                        />
                                                        }
                                                        label="Enable/Disable ASIN"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} lg={3}>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        value={globalTags}
                                                        multiple
                                                        onChange={(event, newValue) => {
                                                            if(newValue.length > 0){
                                                                newValue[newValue.length - 1].label = newValue[newValue.length - 1].tag
                                                            }
                                                            setGlobalTags(newValue);
                                                        }}
                                                        filterOptions={(options, params) => {
                                                            const filtered = filterOp(options, params);

                                                            if (params.inputValue !== '' && options.filter(t => t.tag === params.inputValue).length === 0) {
                                                                let nv = {
                                                                    label: `Add "${params.inputValue}"`,
                                                                    tag: params.inputValue
                                                                };
                                                                filtered.push(nv);
                                                                masterTags.push(nv);
                                                            }

                                                            return filtered;
                                                        }}
                                                        id="free-solo-dialog-demo"
                                                        options={masterTags}
                                                        getOptionLabel={(option) => {
                                                                            return option.label;
                                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Add Tags" variant="outlined" />
                                                        )}
                                                        ChipProps={{
                                                            color: "primary",
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2} lg={1}>
                                                <Button size="small" variant="contained" color="inherit" className={classes.margin} onClick={cancelGlobalUpdate}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={2} lg={1}>
                                                <Button size="small" variant="contained" color="primary" className={classes.margin} onClick={saveGlobalUpdate}>
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <Grid container item spacin={1} alignItems="center" justify="space-between" xs={12} lg={12}>
                                            <Grid item>
                                                <IconButton
                                                    style={{marginLeft: 6, marginTop: 8}}
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    color={selectEnabled ? 'secondary' : 'default'}
                                                    disabled={data.length === 0}
                                                    onClick={() => {
                                                        if(!selectEnabled){
                                                            setSelectEnabled(true);
                                                            //setSortingAction(true);
                                                        } else {
                                                            setSelectEnabled(false);
                                                            //setSortingAction(true);
                                                        }       
                                                    }}
                                                    data-tour="checkbox-asin"
                                                >
                                                    <CheckBoxIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={10} lg={2}>
                                                <ItemSelection 
                                                    label='Select Tags' 
                                                    value={selectedTag} 
                                                    optionLabel='label' 
                                                    data-tour="tags-dropdown"
                                                    options={tags} 
                                                    selected={(tag) => {
                                                        setSelectedTag(tag);
                                                        setSortingAction(true);
                                                    }
                                                    }/>
                                            </Grid>
                                            <Grid item xs={4} lg={2}>
                                                <Button 
                                                    variant="contained" 
                                                    color="primary" 
                                                    data-tour="addasin"
                                                    className={classes.button}
                                                    onClick={(e) => setAddASINOpen(true)}
                                                    disabled={!masterTags}
                                                    >
                                                    Add ASIN
                                                </Button>
                                            </Grid>
                                            <Grid item xs={8} lg={2}>
                                                <Typography component="div" className={classes.heading}>
                                                    <Grid component="label" container alignItems="center" data-tour="showenabled" spacing={1}>
                                                        <Grid item>Disabled</Grid>
                                                        <Grid item>
                                                            <Switch
                                                                checked={enabledFlag}
                                                                onChange={(e) => {
                                                                        setEnabledFlag(e.target.checked);
                                                                        setSortingAction(true);
                                                                    }}
                                                                color="primary"
                                                                name="job_status"
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />
                                                        </Grid>
                                                        <Grid item>Enabled</Grid>
                                                    </Grid>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <EnhancedTableToolbar 
                                                    onSearchTextChange={(e) => {
                                                        setFilter(e.target.value);
                                                        setSortingAction(true);
                                                    }}
                                                    searchText={filter}
                                                    searchHint={'Search By ASIN, SKU, Title'}/>
                                            </Grid>
                                            <Grid item>
                                                <BootstrapTooltip title={"Click here to Re-Fetch Merchant listing."}>
                                                    <IconButton
                                                        data-tour="merchant"
                                                        variant="contained"
                                                        color="primary"
                                                        size="medium"
                                                        className={classes.button}
                                                        onClick={(e) => refetchMerchantListing()}
                                                    >
                                                        <SyncIcon />
                                                    </IconButton>
                                                </BootstrapTooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={12}>
                                                <Divider style={{marginBottom: 10, marginTop: 10}}/>
                                            </Grid>
                                        </Grid>
                                )
                            }
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows ={selectEnabled ? columns: columns.filter(c => c.id !== 'checkbox')} />
                                            <TableBody>
                                                {stableSort(data, getComparator(order, orderBy))
                                                    //.filter(d => 
                                                    //            d.internal_status === (enabledFlag ? '1' : '0') 
                                                    //            && (filter.length === 0 || (
                                                    //                d.name.toString().toLowerCase().includes(filter.toLowerCase()) || 
                                                    //                d.asin.toString().toLowerCase().includes(filter.toLowerCase()) || 
                                                    //                d.seller_sku.toString().toLowerCase().includes(filter.toLowerCase())) 
                                                    //        ))
                                                    //.filter(d => selectedTag && selectedTag.tag !== '-1' ? 
                                                    //                d.tags.includes(selectedTag.tag) :
                                                    //                true )
                                                    .map((element, index) => 
                                                    <TableRow key={index} hover selected={checkBoxList.indexOf(element.asin) !== -1}>
                                                        <StyledTableCell 
                                                            style={{display: (selectEnabled ? 'table-cell' : 'none'), width: '2vw'}} 
                                                            scope="row" 
                                                            align="left" 
                                                            padding='default' 
                                                            >
                                                                <Checkbox
                                                                    checked={checkBoxList.indexOf(element.asin) !== -1}
                                                                    onChange={event =>
                                                                                handleSelectOne(event, element.asin)}
                                                                    inputProps={{ 'aria-labelledby': element.asin }}
                                                                    />
                                                        </StyledTableCell>
                                                        <StyledTableCell 
                                                            scope="row" 
                                                            align="left" 
                                                            padding='default' 
                                                            style={{ paddingRight:8, width: '40vw', paddingTop: 8, paddingBottom: 4}}
                                                            //onClick={(e) => {
                                                            //        setDialogDetailsASIN(element.asin);
                                                            //        setDialogDetailsTitle(element.name);
                                                            //        setShowDetailsDialog(true);
                                                            //}}
                                                            >
                                                            <div style={{verticalAlign: 'middle', display: 'inline-flex'}}>
                                                                <BootstrapTooltip title={element.image_url ? "Click to enlarge" : ""}>
                                                                    <div 
                                                                        style={{marginRight: 15, maxWidth: 110, cursor: 'pointer'}} 
                                                                        onClick={(e) => {
                                                                            if(element.image_url){
                                                                                setShowImageDialog(true);
                                                                                setDialogDetailsTitle(element.seller_sku);
                                                                                setDialogDetailsURL(element.image_url.replace('_SL75_', '_SL300_'));
                                                                            }
                                                                        }}>
                                                                        <img src={element.image_url ? element.image_url : null} height="75" width="75" alt="" style={{minWidth: 75, minHeight: 75}}/>
                                                                    </div>
                                                                </BootstrapTooltip>
                                                                <div>
                                                                    <BootstrapTooltip title={element.name}>
                                                                        <div>
                                                                            <span>{`${element.name.substring(0, 125)}${element.name.length > 125 ? '...' : ''}`}</span><br/>
                                                                            <b>{'SKU: '}</b>{element.seller_sku}
                                                                        </div>
                                                                    </BootstrapTooltip>
                                                                <div style={{marginTop: 12, paddingLeft: 16}}>
                                                                    <BootstrapTooltip title={`Click to set Minimum Stock Notification`}>
                                                                        {
                                                                            element.min_stock_threshold && element.min_stock_threshold > 0 ?  
                                                                                <Badge 
                                                                                    badgeContent={element.min_stock_threshold || 0} 
                                                                                    color="primary"
                                                                                    classes={{ badge: classes.customBadge }}
                                                                                    anchorOrigin={{
                                                                                        vertical: 'top',
                                                                                        horizontal: 'left',
                                                                                    }}
                                                                                    max={9999}>
                                                                                    <NotificationsIcon
                                                                                        aria-describedby={id} 
                                                                                        fontSize="small"
                                                                                        onClick={handleThresholdClick(element)}
                                                                                        style={{marginRight: 20}}
                                                                                        /> 
                                                                                </Badge>
                                                                            :
                                                                            <Badge 
                                                                                badgeContent={0} 
                                                                                classes={{ badge: classes.customBadge }}
                                                                                color="primary">
                                                                                <NotificationsOffIcon 
                                                                                    aria-describedby={id} 
                                                                                    fontSize="small"
                                                                                    onClick={handleThresholdClick(element)}
                                                                                    style={{marginRight: 20}}/>
                                                                            </Badge>
                                                                        }
                                                                    </BootstrapTooltip>
                                                                    <BootstrapTooltip title={`Click to set Defective Stock Notification`}>
                                                                        {
                                                                            <Badge 
                                                                                badgeContent={element.defective_stock_threshold || 1}
                                                                                aria-describedby={id} 
                                                                                classes={{ badge: classes.customBadge }}
                                                                                fontSize="small"
                                                                                onClick={handleThresholdClick(element)} 
                                                                                color="error">
                                                                                <NotificationImportantIcon />
                                                                            </Badge>
                                                                        }
                                                                    </BootstrapTooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </StyledTableCell>
                                                        <StyledTableCell 
                                                            scope="row" 
                                                            align="justify" 
                                                            padding='default' 
                                                            style={{width: '6vw'}}
                                                            //onClick={(e) => {
                                                            //        setDialogDetailsASIN(element.asin);
                                                            //        setDialogDetailsTitle(element.name);
                                                            //        setShowDetailsDialog(true);
                                                            //}}
                                                            >
                                                            {element.asin}
                                                        </StyledTableCell>
                                                        {/* <StyledTableCell 
                                                            
                                                            scope="row" 
                                                            align="justify" 
                                                            padding='default' 
                                                            style={{cursor: 'pointer', paddingRight:8, width: '25vw'}}
                                                            onClick={(e) => {
                                                                setDialogDetailsASIN(element.asin);
                                                                setShowDetailsDialog(true);
                                                            }}>
                                                            
                                                        </StyledTableCell> */}
                                                        <StyledTableCell align="center" padding='default' style={{ paddingLeft: 8, paddingRight:8, width: '10vw'}}>
                                                            <Grid container justify="space-between">
                                                                <Grid item xs={6}>
                                                                    MRP
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    {formatNumber(element.maximum_retail_price)}
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    Price
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    {formatNumber(element.price)}
                                                                </Grid>
                                                            </Grid>
                                                        </StyledTableCell>
                                                        {/* <StyledTableCell align="center" padding='default' style={{ paddingRight:8, width: '6vw'}}>
                                                            <Typography variant="subtitle2">
                                                                {formatNumber(element.maximum_retail_price)}
                                                            </Typography>
                                                        </StyledTableCell> */}
                                                        <StyledTableCell data-tour="addtag" align="center" padding='default' style={{padding: 8, minWidth: '15vw'}}>
                                                            <AddTag masterTags={masterTags} tags={element.tags} asin={element.asin} deleteTag={deleteTag} addTag={addTag}/>
                                                        </StyledTableCell>
                                                        <StyledTableCell data-tour="addtag" align="center" padding='default' style={{padding: 8, minWidth: '15vw'}}>
                                                            <AddTag masterTags={masterKeywords.map(k => ({...k, tag: k.keyword}))} tags={element.keywords} asin={element.asin} deleteTag={deleteKeyword} addTag={addKeyword}/>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" padding='default' style={{width: '10vw', paddingLeft: 8, paddingRight: 8}}>
                                                            <Grid container justify="space-between" alignItems="center">
                                                                <Grid item xs={6}>
                                                                    Stats
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <BootstrapTooltip title={`${element.enable_monitoring_stats === '1' ? 'Disable' : 'Enable'} Stats Monitoring`}>
                                                                        <Switch
                                                                            size="small"
                                                                            data-tour="enablestats"
                                                                            checked={element.enable_monitoring_stats === '1'}
                                                                            onChange={handleChange(element)}
                                                                            color="primary"
                                                                            name="enable_monitoring_stats"
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </BootstrapTooltip>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    Review
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <BootstrapTooltip title={`${element.enable_monitoring_reviews === '1' ? 'Disable' : 'Enable'} Review Monitoring`}>
                                                                        <Switch
                                                                            size="small"
                                                                            data-tour="enablereview"
                                                                            checked={element.enable_monitoring_reviews === '1'}
                                                                            onChange={handleChange(element)}
                                                                            color="primary"
                                                                            name="enable_monitoring_reviews"
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </BootstrapTooltip>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    Status
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <BootstrapTooltip title={`${element.internal_status === '1' ? 'Disable' : 'Enable'} Product`}>
                                                                        <Switch
                                                                            size="small"
                                                                            data-tour="enableproduct"
                                                                            checked={element.internal_status === '1'}
                                                                            onChange={handleChange(element)}
                                                                            color="primary"
                                                                            name="internal_status"
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </BootstrapTooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </StyledTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        data.length === 0 && 
                                            <Typography color="error" variant="subtitle2">No Data</Typography>
                                    }
                                </Grid>
                                <AddASIN 
                                    {...props} 
                                    onError={() => {
                                        setAddASINOpen(false);
                                    }} 
                                    onSuccess={() => {
                                        setAddASINOpen(false);
                                        fetchTags();
                                    }} 
                                    onCancel={() => setAddASINOpen(false)} 
                                    open={addASINOpen} 
                                    title="Add Internal ASIN" 
                                    placeholder="ASIN" 
                                    internal
                                    excludeList={(orgdata && orgdata.map(o => o.asin)) || []} />
                                <Popper 
                                    id={id} 
                                    open={openThreshold}
                                    placement="left" 
                                    keepMounted
                                    anchorEl={anchorEl} 
                                    className={classes.popper}>
                                        <div style={{zIndex: 2000}}>
                                        {   
                                            clickedRow &&
                                            <Card>
                                                <CardHeader
                                                    title={clickedRow.asin}
                                                    titleTypographyProps={{align: 'left', variant: 'caption'}}
                                                    />
                                                <Divider />
                                                <CardContent>
                                                    <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" fullWidth>
                                                        <TextField
                                                            label="Min Stock Threshold"
                                                            id="outlined-min-stock-adornment"
                                                            className={clsx(classes.margin, classes.textField)}
                                                            value={clickedRow.new_min_stock_threshold}
                                                            name="new_min_stock_threshold"
                                                            onChange={handleThresholdChange}
                                                            type="number"
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">Units</InputAdornment>,
                                                                maxLength: 4
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                                    <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" fullWidth>
                                                        <TextField
                                                            label="Defective Stock Threshold"
                                                            id="outlined-defective-stock-adornment"
                                                            className={clsx(classes.margin, classes.textField)}
                                                            value={clickedRow.new_defective_stock_threshold}
                                                            name="new_defective_stock_threshold"
                                                            onChange={handleThresholdChange}
                                                            type="number"
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">Units</InputAdornment>,
                                                                maxLength: 4
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                                </CardContent>
                                                <CardActions className={classes.actions}>
                                                    <Button
                                                        color="secondary"
                                                        size="small"
                                                        variant="contained"
                                                        onClick={handleThresholdClose}
                                                    >
                                                      Cancel  
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        size="small"
                                                        variant="text"
                                                        onClick={changeThreshold}
                                                    >
                                                      Save  
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        }
                                        </div>
                                </Popper>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <MyDialog
                    content={
                        <ASINDetails
                            show={showDetailsDialog}
                            asin={dialogDetailsAsin}
                            />
                        }
                    title={dialogDetailsTitle}
                    open={showDetailsDialog}
                    close={() => {
                        setShowDetailsDialog(false);
                        setDialogDetailsASIN(null);
                        setDialogDetailsTitle(null);
                    }}/>
                <MyDialog
                    maxWidth={"sm"}
                    content={
                            <img 
                                src={dialogDetailsURL} 
                                height="300" width="300" 
                                alt=""/>
                        }
                    title={dialogDetailsTitle}
                    open={showImageDialog}
                    close={() => {
                        setShowImageDialog(false);
                        setDialogDetailsURL(null);
                        setDialogDetailsTitle(null);
                    }}/>
            </div>
        </div>
    );
}
 