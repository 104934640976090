import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Divider, useTheme, Card, CardHeader, CardContent, IconButton, Table, TableBody, TableRow } from "@material-ui/core";
import moment from "moment";
import {format_display_fancy_notime, colorGenerator, format_without_time, formatNumber, formatQty, HtmlTooltip, BootstrapTooltip, toInt, StyledTableCell} from "../../Utils";
import { ReportService } from "../../Services/ReportService";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import DateRangePickerCustom, { createStaticRanges } from "../../components/DateRangePickerCustom";
import { AppContext } from "../../AppContextProvider";
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    startOfYear,
    endOfYear,
    addYears,
    addWeeks,
    getWeek
  } from 'date-fns';
import { Line } from "react-chartjs-2";
import { setWeek } from "date-fns/esm";
import { Skeleton } from "@material-ui/lab";
import XLSX from 'xlsx';
import { ProductService } from "../../Services/ProductService";
import DataBlock from "../../components/DataBlock";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import { MasterService } from "../../Services/MasterService";
import ItemSelection from "../../components/ItemSelection";

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    sectionGraph: {
        // width: '60vw',
        height: '60vh',
        marginTop: theme.spacing(3),
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    row: {
        '&:nth-of-type(even)': {
          backgroundColor: theme.palette.background.default,
        },
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontWeight: 700,
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function SalesReport(props){
    
    const {setTitle: setPageTitle, setTourSteps, showSnackBar, setLinearLoading, closeProgressDialog, showProgressDialog} = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();
    const [startdate, setStartDate] = React.useState(moment().startOf('day'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [generate, setGenerate] = React.useState(false);
    const [staticRangeSelected, setStaticRangeSelected] = React.useState(null);
    const [lineUnitsOrderedData, setLineUnitsOrderedData] = React.useState({});
    const [lineSalesAmountData, setLineSalesAmountData] = React.useState({});
    const [finalUnit, setFinalUnit] = React.useState(null);
    const [productRowLoading, setProductRowsLoading] = React.useState(false);
    const [showProductRows, setShowProductRows] = React.useState(false);
    const [productRowTitle, setProductRowTitle] = React.useState('');
    const [productSubheader, setProductSubheader] = React.useState('');
    const [productData, setProductData] = React.useState([]);
    const [asinList, setASINList] = React.useState([]);
    const [totalShippedOrders, setTotalShippedOrders] = React.useState(0);
    const [totalCanceledOrders, setTotalCanceledOrders] = React.useState(0);
    const [totalPendingOrders, setTotalPendingOrders] = React.useState(0);
    const [totalUnits, setTotalUnits] = React.useState(0);
    const [totalSales, setTotalSales] = React.useState(0);
    const [fromPrevious, setFromPrevious] = React.useState([]);
    const [asin, setASIN] = React.useState([]);
    const [selectedTag, setSelectedTag] = React.useState(null);
    const [tags, setTags] = React.useState(null);

    const columns = [
        { id: 'product', align: 'justify', numeric: false, disablePadding: false, label: 'Product', sortable: false },
        { id: 'sales', align: 'center', numeric: false, disablePadding: true, label: 'Sales Figure', sortable: false },
        { id: 'units', align: 'right', numeric: false, disablePadding: true, label: 'Units', sortable: false }
    ];

    const lineOptions = {
        responsive : true, 
        maintainAspectRatio: false,
        spanGaps: true,
        layout: {
            padding: 16,
        },
        legend: {
            position: 'bottom'
        },
        elements: {
            arc: {
                borderWidth: 5,
                borderColor: theme.palette.white,
                backgroundColor: theme.palette.white
            },
        },
        tooltips: {
            position: 'sales',
            // Disable the on-canvas tooltip
            enabled: false,
            intersect: false,
            caretSize: 10,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.black,
            titleFontColor: theme.palette.white,
            bodyFontColor: theme.palette.white,
            footerFontColor: theme.palette.white,
            custom: function(tooltipModel, data) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    // var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<thead>';

                    // titleLines.forEach(function(title) {
                    //     console.log(title);
                    //     innerHtml += '<tr><th>' + moment(title).format(format_display_fancy_notime) + '</th></tr>';
                    // });
                    innerHtml += '</thead><tbody>';

                    // console.log(tooltipModel);
                    // let dataIndex = tooltipModel.dataPoints[0].datasetIndex;
                    let index = tooltipModel.dataPoints[0].index;


                    // let fcs = lineUnitsOrderedData.datasets[dataIndex].fcData[index];

                    // console.log(bodyLines);
                    if(bodyLines.length > 0){
                        var colors = tooltipModel.labelColors[0];
                        var style = 'background:' + colors.backgroundColor;
                        style += '; border-color:' + colors.borderColor;
                        style += '; border-width: 2px';
                        var span = '<span style="' + style + '"></span>';
                        // innerHtml += '<tr><td colspan="2">' + span + '<b>' + body + '</b></td></tr>';
                        // innerHtml += '<tr><td colspan="2">' + span + '<hr style="margin-top: 0px;margin-bottom: 0px;border: 1px solid white;"/></td></tr>';
                        // Object.keys(fcs).forEach(key => {
                        //     innerHtml += '<tr><td>' + span + '<b>' + key + '</b></td><td align="right">' + span + fcs[key] + '</td></tr>';
                        // });
                        lineUnitsOrderedData.datasets.forEach(d => {
                            let o = d.otherData[index];
                            // console.log(index, o);
                            if(o){
                                innerHtml += '<tr><td colspan="2" style="color:'+d.borderColor+'">' + span + d.label + ' - ' + moment(o.orderdate).format(finalUnit.label === 'This Year' ? 'MMM YYYY' : format_display_fancy_notime) + '</td></tr>'+
                                '<tr><td><b>' + span + formatQty(o.units) + '</b> Units</td><td align="right"><b>'+ formatNumber(o.sales) + '</b></td></tr>';
                            }
                        })
                    }
                    /**
                     bodyLines.forEach(function(body, i) {
                        var colors = tooltipModel.labelColors[i];
                        var style = 'background:' + colors.backgroundColor;
                        style += '; border-color:' + colors.borderColor;
                        style += '; border-width: 2px';
                        var span = '<span style="' + style + '"></span>';
                        // innerHtml += '<tr><td colspan="2">' + span + '<b>' + body + '</b></td></tr>';
                        // innerHtml += '<tr><td colspan="2">' + span + '<hr style="margin-top: 0px;margin-bottom: 0px;border: 1px solid white;"/></td></tr>';
                        // Object.keys(fcs).forEach(key => {
                        //     innerHtml += '<tr><td>' + span + '<b>' + key + '</b></td><td align="right">' + span + fcs[key] + '</td></tr>';
                        // });
                        lineUnitsOrderedData.datasets.forEach(d => {
                            let o = d.otherData[index];
                            // console.log(index, o);
                            if(o){
                                innerHtml += '<tr><td colspan="2" style="color:'+d.borderColor+'">' + span + d.label + ' - ' + moment(o.orderdate).format(finalUnit.label === 'This Year' ? 'MMM YYYY' : format_display_fancy_notime) + '</td></tr>'+
                                '<tr><td><b>' + span + formatQty(o.units) + '</b> Units</td><td align="right"><b>'+ formatNumber(o.sales) + '</b></td></tr>';
                            }
                        })
                    });
                    */
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.backgroundColor = theme.palette.white;
                tooltipEl.style.color = theme.palette.black;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left =  position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                tooltipEl.style.pointerEvents = 'none';
                tooltipEl.style.border = '1px solid #b7b7b7';
                tooltipEl.style.lineHeight = '10px';
            }
        },
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        stepSize: finalUnit && finalUnit.stepSize ? finalUnit.stepSize : undefined,
                        unit: finalUnit && finalUnit.timeUnit ? finalUnit.timeUnit : undefined,
                        displayFormats: {
                            hour: 'hA',
                            week : 'll',
                            month: finalUnit && finalUnit.xAxisFormat ? finalUnit.xAxisFormat : 'MMM YYYY',
                            day: finalUnit && finalUnit.xAxisFormat ? finalUnit.xAxisFormat : 'MMM D',
                        },
                    }
                }
            ],
            yAxes: [{
                ticks: {
                    beginAtZero:true,
                }
                //ticks: {
                //    callback: function(label, index, labels) {
                //        return label
                //    }
                //},
            }]
        }
    };

    const colors = colorGenerator(4);

    const staticRanges = createStaticRanges([
        {
            label: 'Today',
            range: () => ({
                startDate: startOfDay(new Date()),
                endDate: endOfDay(new Date()),
            }),
            color: colors[0],
            otherLines: [
                {
                    label: 'Yesterday',
                    range: {
                        startDate: startOfDay(addDays(new Date(), -1)),
                        endDate: endOfDay(addDays(new Date(), -1)),
                    },
                    color: colors[1],
                },
                {
                    label: 'Same Day Last Week',
                    range: {
                        startDate: startOfDay(addDays(new Date(), -7)),
                        endDate: endOfDay(addDays(new Date(), -7)),
                    },
                    color: colors[2],
                },
                {
                    label: 'Same Day Last Year',
                    range: {
                        startDate: startOfDay(addYears(new Date(), -1)),
                        endDate: endOfDay(addYears(new Date(), -1)),
                    },
                    color: colors[3],
                }
            ],
            stepSize: 2,
            xAxisFormat: '',
            timeUnit: '',
            dataFilter: 'hour',
        },
        {
            label: 'This Week',
            range: () => ({
                startDate: startOfWeek(new Date()),
                endDate: endOfWeek(new Date()),
            }),
            color: colors[0],
            otherLines: [
                {
                    label: 'Last Week',
                    range: {
                        startDate: addWeeks(startOfWeek(new Date()), -1),
                        endDate: addWeeks(endOfWeek(new Date()), -1),
                    },
                    color: colors[1],
                },
                {
                    label: 'Same Week Last Year',
                    range: { 
                        startDate: startOfWeek(setWeek(addYears(new Date(), -1), getWeek(new Date()))),
                        endDate: endOfWeek(setWeek(addYears(new Date(), -1), getWeek(new Date()))),
                    },
                    color: colors[2],
                }
            ],
            xAxisFormat: 'dddd',
            timeUnit: 'day',
            dataFilter: 'day',
        },
        {
            label: 'This Month',
            range: () => ({
                startDate: startOfMonth(new Date()),
                endDate: endOfMonth(new Date()),
            }),
            color: colors[0],
            otherLines: [
                {
                    label: 'Last Month',
                    range: {
                        startDate: startOfMonth(addMonths(new Date(), -1)),
                        endDate: endOfMonth(addMonths(new Date(), -1)),
                    },
                    color: colors[1],
                },
                {
                    label: 'Same Month Last Year',
                    range: {
                        startDate: startOfMonth(addYears(new Date(), -1)),
                        endDate: endOfMonth(addYears(new Date(), -1)),
                    },
                    color: colors[2],
                }
            ],
            stepSize: 5,
            xAxisFormat: 'D',
            timeUnit: 'day',
            dataFilter: 'date',
        },
        {
            label: 'This Year',
            range: () => ({
                startDate: startOfYear(new Date()),
                endDate: endOfYear(new Date()),
            }),
            color: colors[0],
            otherLines: [
                {
                    label: 'Last Year',
                    range: {
                        startDate: startOfYear(addYears(new Date(), -1)),
                        endDate: endOfYear(addYears(new Date(), -1)),
                    },
                    color: colors[1],
                }
            ],
            timeUnit: 'month',
            dataFilter: 'month',
            xAxisFormat: 'MMM',
        },
        {
            label: 'Last 7 Days',
            range: () => ({
                startDate: addDays(startOfDay(new Date()), -6),
                endDate: endOfDay(new Date()),
            }),
            color: colors[0],
            otherLines: [
                {
                    label: 'Same date range previous month',
                    range: {
                        startDate: addMonths(addDays(startOfDay(new Date()), -6), -1),
                        endDate: addMonths(endOfDay(new Date()), -1),
                    },
                    color: colors[1],
                },
                {
                    label: 'Same date range one year ago',
                    range: {
                        startDate: addYears(addDays(startOfDay(new Date()), -6), -1),
                        endDate: addYears(endOfDay(new Date()), -1),
                    },
                    color: colors[2],
                }
            ],
            timeUnit: 'day',
            dataFilter: 'date',
        },
        {
            label: 'Last 30 Days',
            range: () => ({
                startDate: startOfDay(addDays(new Date(), -29)),
                endDate: endOfDay(new Date()),
            }),
            color: colors[0],
            otherLines: [
                {
                    label: 'Previous 30 days',
                    range: {
                        startDate: addMonths(addDays(startOfDay(new Date()), -29), -1),
                        endDate: addMonths(endOfDay(new Date()), -1),
                    },
                    color: colors[1],
                },
                {
                    label: 'Same date range one year ago',
                    range: {
                        startDate: addYears(addDays(startOfDay(new Date()), -29), -1),
                        endDate: addYears(endOfDay(new Date()), -1),
                    },
                    color: colors[2],
                }
            ],
            timeUnit: 'day',
            dataFilter: 'date',
        },
        {
            label: 'Last 12 Months',
            range: () => ({
                startDate: startOfMonth(addMonths(new Date(), -12)),
                endDate: endOfMonth(new Date()),
            }),
            color: colors[0],
            otherLines: [
                {
                    label: 'Previous Period',
                    range: {
                        startDate: startOfMonth(addMonths(new Date(), -24)),
                        endDate: endOfMonth(addMonths(new Date(), -13)),
                    },
                    color: colors[1],
                }
            ],
            dataFilter: 'month',
            timeUnit: 'month',
            xAxisFormat: 'MMM YYYY',
        },
        {
            label: 'Custom',
            range: () => ({
                
            }),
            otherLines: [],
            color: colors[0],
            dataFilter: 'custom'
        },
    ]);

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour^=selectdaterange]',
                content: `Filter by Date`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour="selectasin"]',
                content: `Filter by ASIN`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selecttag]',
                content: `Filter by Tag`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=chart]',
                content: `Click Data points on chart to view Itemwise sales for the Date Range Selected`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            
        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    React.useEffect(() => {
        setPageTitle("Sales Report");

        ProductService.listASINs()
        .then(data => {
            let list = data.map(d => ({...d, label : `[${d.asin}][${d.seller_sku}] ${d.name.substring(0, 120)}${d.name.length > 120 ? '...' : ''}`}));
            setASINList(list);
            setGenerate(true);
        })
        .catch(error => console.log(error));

        MasterService.getTags()
        .then(data => {
            // console.log(data);
            let allTags = {label: 'All Tags', tag: '-1'};
            setTags([allTags, ...data]);
            setSelectedTag(allTags);
        })
        .catch(error => console.log(error));
    }, [true]);

    const fetchOtherOrders = (startdate, enddate, trimToDay, rASIN) => {
        ReportService.fetchSalesStatsReport(startdate, enddate, trimToDay, "Pending", rASIN.join(','))
        .then(data => {
            setTotalPendingOrders(data.reduce((sum, next) => sum + next.OrderCount, 0));
        });
        ReportService.fetchSalesStatsReport(startdate, enddate, trimToDay, "Canceled", rASIN.join(','))
        .then(data => {
            setTotalCanceledOrders(data.reduce((sum, next) => sum + next.OrderCount, 0));
        });
    }

    React.useEffect(() => {
        if(generate && (asin.length > 0 || selectedTag) ){

            let rASIN = [];
            if (asin.length > 0){
                rASIN = [...asin].map(a => a.asin);
            } else if(selectedTag.tag === '-1'){
                rASIN = [...asinList].map(a => a.asin);
            } else if(selectedTag.tag !== -1){
                rASIN = [...asinList.filter(a => a.tags.includes(selectedTag.tag))].map(a => a.asin);
            }

            setLinearLoading(true);
            showProgressDialog();
            setProductData([]);
            setShowProductRows(false);
            setFinalUnit(staticRangeSelected);
            let trimToDay = staticRangeSelected.label !== 'Today';
            let plotPlan = staticRanges.filter(s => s.label === staticRangeSelected.label)[0];
            let uList = [   ReportService.fetchSalesStatsReport(startdate.startOf('d'), enddate.endOf('d'), trimToDay, "Shipped", rASIN.join(',')), 
                            ...plotPlan.otherLines.map(l => ReportService.fetchSalesStatsReport(l.range.startDate, l.range.endDate, trimToDay, "Shipped", rASIN.join(',')))
                        ];
            fetchOtherOrders(startdate, enddate, trimToDay, rASIN);
            Promise.all(uList)
            .then(datas => {
                let labels = [];
                let datasetsCount = [], datasetsSales = [];

                let currentSales = datas[0].reduce((sum, next) => sum + (next.TotalAmount || 0), 0);
                setTotalShippedOrders(datas[0].reduce((sum, next) => sum + next.OrderCount, 0));
                setTotalUnits(datas[0].reduce((sum, next) => sum + next.TotalCount, 0));
                setTotalSales(currentSales);
                let previousPeriod = [];
                datas.slice(1).forEach((data, i) => {
                    let prevPeriodSales = data.reduce((sum, next) => sum + (next.TotalAmount || 0), 0);
                    let diffPercentage = 0;
                    if(prevPeriodSales > 0){
                        diffPercentage = parseFloat(((currentSales - prevPeriodSales) / prevPeriodSales) * 100);
                    }
                    previousPeriod.push({label: plotPlan.otherLines[i].label, value: diffPercentage.toFixed(2), direction: diffPercentage > 0 ? 1 : diffPercentage < 0 ? -1 : 0});
                });
                setFromPrevious(previousPeriod);


                if(staticRangeSelected.dataFilter === 'hour'){
                    let hr = 0;
                    do{
                        labels.push(moment({h: hr, m: 0, s: 0}));
                        getLineData(datas, hr);
                        hr++;
                    } while(hr < 24);
                    function getLineData(datas, hr){
                        datas.forEach((data, index) => {
                            if(hr === 0){
                                datasetsCount.push({
                                    label: index === 0 ? staticRangeSelected.label : plotPlan.otherLines[index - 1].label,
                                    data: [],
                                    otherData: [],
                                    backgroundColor: '#FFF',
                                    borderColor: index === 0 ? staticRangeSelected.color : plotPlan.otherLines[index - 1].color,
                                    borderWidth: 1,
                                    fill: false,
                                    pointRadius: 2,
                                    hoverBorderWidth: 5,
                                    lineTension: 0,
                                });
                                datasetsSales.push({
                                    label: index === 0 ? staticRangeSelected.label : plotPlan.otherLines[index - 1].label,
                                    data: [],
                                    otherData: [],
                                    backgroundColor: '#FFF',
                                    borderColor: index === 0 ? staticRangeSelected.color : plotPlan.otherLines[index - 1].color,
                                    borderWidth: 1,
                                    fill: false,
                                    pointRadius: 2,
                                    hoverBorderWidth: 5,
                                    lineTension: 0,
                                });
                            }

                            let rows = data.filter(d => moment(d.orderdate).hours() === hr);
                            if(rows.length > 0){
                                let units = rows.reduce((sum, next) => sum + next.TotalCount, 0);
                                let sales = rows.reduce((sum, next) => sum + (next.TotalAmount || 0), 0);
                                let otherData = {units, sales, orderdate: moment(rows[0].orderdate).format(format_without_time)};
                                datasetsCount[index].data.push(units);
                                datasetsCount[index].otherData.push(otherData);
                                datasetsSales[index].data.push(sales);
                                datasetsSales[index].otherData.push(otherData);
                            } else {
                                datasetsCount[index].data.push(null);
                                datasetsCount[index].otherData.push(null);
                                datasetsSales[index].data.push(null);
                                datasetsSales[index].otherData.push(null);
                            }
                        });
                    }
                } else if(staticRangeSelected.dataFilter === 'day'){
                    let day = 7;
                    let count = 0;
                    do{
                        labels.push(moment({h: 0, m: 0, s: 0}).isoWeekday(day === 7 ? 0 : day));
                        getLineData(datas, day);
                        day++;
                        if(day > 7){
                            day = 1;
                        }
                        count++;
                    } while(count < 7);
                    function getLineData(datas, day){
                        datas.forEach((data, index) => {
                            if(day === 7){
                                datasetsCount.push({
                                    label: index === 0 ? staticRangeSelected.label : plotPlan.otherLines[index - 1].label,
                                    data: [],
                                    otherData: [],
                                    backgroundColor: '#FFF',
                                    borderColor: index === 0 ? staticRangeSelected.color : plotPlan.otherLines[index - 1].color,
                                    borderWidth: 1,
                                    fill: false,
                                    pointRadius: 1,
                                    hoverBorderWidth: 5,
                                    lineTension: 0,
                                });
                                datasetsSales.push({
                                    label: index === 0 ? staticRangeSelected.label : plotPlan.otherLines[index - 1].label,
                                    data: [],
                                    otherData: [],
                                    backgroundColor: '#FFF',
                                    borderColor: index === 0 ? staticRangeSelected.color : plotPlan.otherLines[index - 1].color,
                                    borderWidth: 1,
                                    fill: false,
                                    pointRadius: 1,
                                    hoverBorderWidth: 5,
                                    lineTension: 0,
                                });
                            }
                            // console.log(data.map(d => moment(d.orderdate).isoWeekday()), day);
                            let rows = data.filter(d => moment(d.orderdate).isoWeekday() === day);
                            // console.log(day, rows);
                            if(rows.length > 0){
                                let units = rows.reduce((sum, next) => sum + next.TotalCount, 0);
                                let sales = rows.reduce((sum, next) => sum + (next.TotalAmount || 0), 0);
                                let otherData = {units, sales, orderdate: moment(rows[0].orderdate).format(format_without_time)};
                                datasetsCount[index].data.push(units);
                                datasetsCount[index].otherData.push(otherData);
                                datasetsSales[index].data.push(sales);
                                datasetsSales[index].otherData.push(otherData);
                            } else {
                                datasetsCount[index].data.push(null);
                                datasetsCount[index].otherData.push(null);
                                datasetsSales[index].data.push(null);
                                datasetsSales[index].otherData.push(null);
                            }
                        });
                    }
                } else if(staticRangeSelected.dataFilter === 'date'){
                    let count = 0;
                    let total = enddate.diff(startdate, 'days');
                    let start = startdate.clone();
                    do{
                        labels.push(start);
                        getLineData(datas, start);
                        start = moment(start).add(1, 'day');
                        count++;
                    } while(count <= total);
                    function getLineData(datas, start){
                        datas.forEach((data, index) => {
                            if(count === 0){
                                datasetsCount.push({
                                    label: index === 0 ? staticRangeSelected.label : plotPlan.otherLines[index - 1].label,
                                    data: [],
                                    otherData: [],
                                    backgroundColor: '#FFF',
                                    borderColor: index === 0 ? staticRangeSelected.color : plotPlan.otherLines[index - 1].color,
                                    borderWidth: 1,
                                    fill: false,
                                    pointRadius: 1,
                                    hoverBorderWidth: 5,
                                    lineTension: 0,
                                });
                                datasetsSales.push({
                                    label: index === 0 ? staticRangeSelected.label : plotPlan.otherLines[index - 1].label,
                                    data: [],
                                    otherData: [],
                                    backgroundColor: '#FFF',
                                    borderColor: index === 0 ? staticRangeSelected.color : plotPlan.otherLines[index - 1].color,
                                    borderWidth: 1,
                                    fill: false,
                                    pointRadius: 1,
                                    hoverBorderWidth: 5,
                                    lineTension: 0,
                                });
                            }
                            // console.log(data.map(d => moment(d.orderdate).date()), start.date());
                            let rows = data.filter(d => moment(d.orderdate).date() === start.date());
                            if(rows.length > 0){
                                let units = rows.reduce((sum, next) => sum + next.TotalCount, 0);
                                let sales = rows.reduce((sum, next) => sum + (next.TotalAmount || 0), 0);
                                let otherData = {units, sales, orderdate: moment(rows[0].orderdate).format(format_without_time)};
                                datasetsCount[index].data.push(units);
                                datasetsCount[index].otherData.push(otherData);
                                datasetsSales[index].data.push(sales);
                                datasetsSales[index].otherData.push(otherData);
                            } else {
                                datasetsCount[index].data.push(null);
                                datasetsCount[index].otherData.push(null);
                                datasetsSales[index].data.push(null);
                                datasetsSales[index].otherData.push(null);
                            }
                        });
                    }
                } else if(staticRangeSelected.dataFilter === 'month'){
                    let start = startdate.clone();
                    let month = null;
                    let year = null;
                    let first = true;
                    do{
                        month = start.month();
                        year = start.year();
                        labels.push(moment({h: 0, m: 0, s: 0}).month(month).year(year).startOf('month'));
                        getLineData(datas, month, year, first);
                        start = moment(start).add(1, 'month');
                        first = false;
                    } while(start.isSameOrBefore(enddate));
                    function getLineData(datas, month, year, first){
                        datas.forEach((data, index) => {
                            if(first){
                                datasetsCount.push({
                                    label: index === 0 ? staticRangeSelected.label : plotPlan.otherLines[index - 1].label,
                                    data: [],
                                    otherData: [],
                                    backgroundColor: '#FFF',
                                    borderColor: index === 0 ? staticRangeSelected.color : plotPlan.otherLines[index - 1].color,
                                    borderWidth: 1,
                                    fill: false,
                                    pointRadius: 1,
                                    hoverBorderWidth: 5,
                                    lineTension: 0,
                                });
                                datasetsSales.push({
                                    label: index === 0 ? staticRangeSelected.label : plotPlan.otherLines[index - 1].label,
                                    data: [],
                                    otherData: [],
                                    backgroundColor: '#FFF',
                                    borderColor: index === 0 ? staticRangeSelected.color : plotPlan.otherLines[index - 1].color,
                                    borderWidth: 1,
                                    fill: false,
                                    pointRadius: 1,
                                    hoverBorderWidth: 5,
                                    lineTension: 0,
                                });
                            }
                            // console.log(data.map(d => moment(d.orderdate).isoWeekday()), day);
                            let rows = data.filter(d => {
                                let y = moment(d.orderdate).year() - year;
                                return (moment(d.orderdate).month() === month && moment(d.orderdate).year() === year + y)
                            });
                            // console.log(day, rows);
                            if(rows.length > 0){
                                let units = rows.reduce((sum, next) => sum + next.TotalCount, 0);
                                let sales = rows.reduce((sum, next) => sum + (next.TotalAmount || 0), 0);
                                let otherData = {units, sales, orderdate: moment(rows[0].orderdate).format(format_without_time)};
                                datasetsCount[index].data.push(units);
                                datasetsCount[index].otherData.push(otherData);
                                datasetsSales[index].data.push(sales);
                                datasetsSales[index].otherData.push(otherData);
                            } else {
                                datasetsCount[index].data.push(null);
                                datasetsCount[index].otherData.push(null);
                                datasetsSales[index].data.push(null);
                                datasetsSales[index].otherData.push(null);
                            }
                        });
                    }
                } else if(staticRangeSelected.dataFilter === 'custom'){
                    let count = 0;
                    let total = enddate.diff(startdate, 'days');
                    let start = startdate.clone();
                    do{
                        labels.push(start);
                        getLineData(datas, start);
                        start = moment(start).add(1, 'day');
                        count++;
                    } while(count <= total);
                    function getLineData(datas, start){
                        datas.forEach((data, index) => {
                            if(count === 0){
                                datasetsCount.push({
                                    label: index === 0 ? staticRangeSelected.label : plotPlan.otherLines[index - 1].label,
                                    data: [],
                                    otherData: [],
                                    backgroundColor: '#FFF',
                                    borderColor: index === 0 ? staticRangeSelected.color : plotPlan.otherLines[index - 1].color,
                                    borderWidth: 1,
                                    fill: false,
                                    pointRadius: 1,
                                    hoverBorderWidth: 5,
                                    lineTension: 0,
                                });
                                datasetsSales.push({
                                    label: index === 0 ? staticRangeSelected.label : plotPlan.otherLines[index - 1].label,
                                    data: [],
                                    otherData: [],
                                    backgroundColor: '#FFF',
                                    borderColor: index === 0 ? staticRangeSelected.color : plotPlan.otherLines[index - 1].color,
                                    borderWidth: 1,
                                    fill: false,
                                    pointRadius: 1,
                                    hoverBorderWidth: 5,
                                    lineTension: 0,
                                });
                            }
                            // console.log(data.map(d => moment(d.orderdate).date()), start.date());
                            let rows = data.filter(d => moment(d.orderdate).isSame(start));
                            if(rows.length > 0){
                                let units = rows.reduce((sum, next) => sum + next.TotalCount, 0);
                                let sales = rows.reduce((sum, next) => sum + (next.TotalAmount || 0), 0);
                                let otherData = {units, sales, orderdate: moment(rows[0].orderdate).format(format_without_time)};
                                datasetsCount[index].data.push(units);
                                datasetsCount[index].otherData.push(otherData);
                                datasetsSales[index].data.push(sales);
                                datasetsSales[index].otherData.push(otherData);
                            } else {
                                datasetsCount[index].data.push(null);
                                datasetsCount[index].otherData.push(null);
                                datasetsSales[index].data.push(null);
                                datasetsSales[index].otherData.push(null);
                            }
                        });
                    }
                }
                // console.log(labels);
                setLineUnitsOrderedData({labels: labels, datasets: datasetsCount});
                setLineSalesAmountData({labels: labels, datasets: datasetsSales});
                closeProgressDialog();
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                closeProgressDialog();
                setLinearLoading(false);
            })
            setGenerate(false);
        }
    }, [generate, asin, selectedTag]);

    const chartClick = (elems) => {
        if(elems.length > 0){
            setShowProductRows(true);
            // console.log(elems);
            let x = elems[0]._datasetIndex;
            let y =  elems[0]._index;
            let value = lineUnitsOrderedData.datasets[x].otherData[y];
            // let label = lineUnitsOrderedData.labels[y];
            console.log(x, y, value, staticRangeSelected.dataFilter);
            // if month - get for full month
            let startdate = moment(value.orderdate).startOf('day');
            let enddate = moment(value.orderdate).endOf('day');
            if(staticRangeSelected.dataFilter === 'month'){
                startdate = moment(value.orderdate).startOf('month');
                enddate = moment(value.orderdate).endOf('month');
                setProductRowTitle(startdate.format('MMM YYYY'))
            } else {
                setProductRowTitle(startdate.format(format_display_fancy_notime))
            }
            if(staticRangeSelected.dataFilter === 'hour'){
                let units = lineUnitsOrderedData.datasets[x].otherData.filter(x => x !== null).reduce((sum, next) => sum + next.units, 0);
                let sales = lineUnitsOrderedData.datasets[x].otherData.filter(x => x !== null).reduce((sum, next) => sum + next.sales, 0);
                setProductSubheader(`Total Units: ${formatQty(units)} Total Sales: ${formatNumber(sales)}`);
            } else {
                setProductSubheader(`Total Units: ${formatQty(value.units)} Total Sales: ${formatNumber(value.sales)}`);
            }
            fetchLineData(startdate, enddate);
            // label = 'GC Sheet';
            // setSelectedState(label);
            // fetchCityWiseData(label);
            // fetchRelativeData(label);
        }
    }

    const fetchLineData = (startdate, endddate) => {
        setProductData([]);
        setProductRowsLoading(true);
        let rASIN = [];
        if (asin.length > 0){
            rASIN = [...asin].map(a => a.asin);
        } else if(selectedTag.tag === '-1'){
            rASIN = [...asinList].map(a => a.asin);
        } else if(selectedTag.tag !== -1){
            rASIN = [...asinList.filter(a => a.tags.includes(selectedTag.tag))].map(a => a.asin);
        }
        ReportService.fetchSalesProductStatsReport(startdate, endddate, "Shipped", rASIN.join(','))
        .then(data => {
            setProductData(data);
            setProductRowsLoading(false);
        })
        .catch(error => {
            console.log(error);
            setProductRowsLoading(false);
        })
    }

    const exportReport = e => {
        setLinearLoading(true);
        let row = [];
        let filename = `Sales Report ${productRowTitle}`;
        if(productData.length > 0){
            let header = ['Title', 'ASIN', 'Seller SKU', 'Units', 'Sales'];
            row.push(header);
            productData.forEach((d) => {
                row.push([d.Title, d.ASIN, d.SellerSKU, toInt(d.TotalCount), parseFloat(d.TotalAmount || 0)]);
            });
            const wb = XLSX.utils.book_new();
            const wsAll = XLSX.utils.aoa_to_sheet(row);
            XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
            XLSX.writeFile(wb, filename + ".xlsx");
        } else {
            showSnackBar("No data to export" , 'warning');
        }
        setLinearLoading(false);
        
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={10} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center" justify="flex-start" style={{marginLeft: 8, marginRight: 8}}>
                                <Grid item xs={12} lg={2} data-tour="selectdaterange">
                                    {/* <Timeline startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/> */}
                                    <DateRangePickerCustom 
                                        startdate={startdate} 
                                        setStartDate={setStartDate} 
                                        enddate={enddate} 
                                        setEndDate={setEndDate}
                                        staticRanges={staticRanges}
                                        showLabel={true}
                                        setStaticRangeSelected={setStaticRangeSelected}/>
                                </Grid>
                                <Grid item xs={6} lg={3} data-tour="selectasin">
                                    <div style={{marginLeft: 24}}>
                                        <ItemSelection 
                                            multiple
                                            clearable={true}
                                            label='Select ASIN' 
                                            value={asin}
                                            optionLabel='label' 
                                            options={asinList} 
                                            selected={setASIN}
                                            limitTags={2}
                                            disabled={selectedTag && selectedTag.tag !== '-1'}/>
                                    </div>
                                </Grid>
                                <Grid item xs={6} lg={2} data-tour="selecttag">
                                    <div style={{marginRight: 24}}>
                                        <ItemSelection 
                                            label='Select Tags' 
                                            clearable={true}
                                            value={selectedTag} 
                                            optionLabel='label' 
                                            options={tags} 
                                            disabled={asin && asin.length > 0}
                                            selected={(tag) => {
                                                setSelectedTag(tag);
                                            }}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Button variant="contained" className={classes.button} color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                        Generate Report
                                    </Button>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 10, marginTop: 10, width: '99%'}}/>
                                </Grid>
                                {
                                    lineUnitsOrderedData.labels && lineUnitsOrderedData.labels.length > 0 && (
                                        <Grid item container xs={12} lg={12}>
                                            <Grid item xs={6} lg={1}>
                                                <DataBlock title={formatQty(totalShippedOrders || 0)} subtitle={'Shipped orders'}/>
                                            </Grid>
                                            <Grid item xs={6} lg={1}>
                                                <DataBlock color='red' title={formatQty(totalCanceledOrders || 0)} subtitle={'Canceled orders'}/>
                                            </Grid>
                                            <Grid item xs={6} lg={1}>
                                                <DataBlock color='yellow' title={formatQty(totalPendingOrders || 0)} subtitle={'Pending orders'}/>
                                            </Grid>
                                            <Grid item xs={6} lg={1}>
                                                <DataBlock title={formatQty(totalUnits || 0)} subtitle={'Units ordered'}/>
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                <DataBlock title={formatNumber(totalSales || 0)} subtitle={'Shipped product sales'}/>
                                            </Grid>
                                            {
                                                fromPrevious.length > 0 && (
                                                    fromPrevious.map((prev, i) => 
                                                        <Grid item key={i} xs={6} lg={2}>
                                                            <DataBlock title={prev.value || 0} subtitle={prev.label} direction={prev.direction}/>
                                                        </Grid>
                                                    )
                                                )
                                            }
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} lg={6}>
                                    {
                                        lineUnitsOrderedData.labels && lineUnitsOrderedData.labels.length > 0 &&
                                        <div data-tour="chart" className={classes.sectionGraph}>
                                                
                                            <Line 
                                                options={{...lineOptions, title: {text: 'Units', position: 'top', display: true }}} 
                                                data={lineUnitsOrderedData} 
                                                getElementAtEvent={elems => chartClick(elems)} 
                                                //onElementsClick={elems => chartClick(elems)}
                                            />
                                        </div> 
                                    }
                                    {
                                        lineUnitsOrderedData.labels && lineUnitsOrderedData.labels.length === 0 && 
                                            <Typography color="error" variant="subtitle2">No Data</Typography>
                                    }
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    {
                                        lineSalesAmountData.labels && lineSalesAmountData.labels.length > 0 &&
                                        <div className={classes.sectionGraph} style={{marginRight: 16}}>
                                            <Line 
                                                options={{...lineOptions, 
                                                                title: {text: 'Value', position: 'top', display: true }, 
                                                                scales: {
                                                                    ...lineOptions.scales,
                                                                    yAxes: [{
                                                                        ticks: {
                                                                            beginAtZero:true,
                                                                            callback: function(label, index, labels) {
                                                                                return formatNumber(label)
                                                                            }
                                                                        }
                                                                    }]
                                                                }
                                                        }} 
                                                data={lineSalesAmountData}
                                                getElementAtEvent={elems => chartClick(elems)} 
                                            />
                                        </div> 
                                    }
                                    {
                                        lineSalesAmountData.labels && lineSalesAmountData.labels.length === 0 && 
                                            <Typography color="error" variant="subtitle2">No Data</Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    {
                        showProductRows && (
                        <Grid item xl={8} lg={10} xs={12} style={{marginTop: 30}}>
                            <Card>
                                <CardHeader 
                                    title={`Showing product sales for ${productRowTitle}`}
                                    titleTypographyProps={{align: 'left'}}
                                    subheader={productSubheader}
                                    subheaderTypographyProps={{align: 'left', variant: 'body2'}}
                                    action={
                                        <BootstrapTooltip title="Export">
                                            <IconButton aria-label="export" onClick={exportReport}>
                                                <SaveAltIcon/>
                                            </IconButton>
                                        </BootstrapTooltip>
                                    }
                                     />
                                <Divider />
                                <CardContent style={{padding: 0}}>
                                    <Grid container>
                                        {
                                            productData.length > 0 && (
                                                <Table className={classes.table} stickyHeader>
                                                    <EnhancedTableHead
                                                        order={''}
                                                        orderBy={''}
                                                        onRequestSort={() => {}}
                                                        rows={columns} />
                                                    <TableBody>
                                                        {productData.map((d, _i) => {
                                                            let product = asinList.filter(e => e.asin === d.ASIN)[0];
                                                            return (
                                                                <TableRow className={classes.row} key={_i} style={{paddingBottom: 10, paddingTop: 10}} hover>
                                                                    <StyledTableCell scope="row" align="justify"  padding='default'>
                                                                        <HtmlTooltip arrow title={
                                                                            <div>
                                                                                <Typography variant="caption" component="span" color="inherit">
                                                                                    <b>{'SKU: '}</b><span>{d.SellerSKU}</span><br/>
                                                                                    <b>{'ASIN: '}</b>{d.ASIN}<br/>
                                                                                </Typography>
                                                                            </div>
                                                                        }>
                                                                            <div>{product.name}</div>
                                                                        </HtmlTooltip>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell scope="row" align="right"  padding='default' style={{width: 190}}>
                                                                        {formatNumber(d.TotalAmount || 0)}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell scope="row" align="right"  padding='default' style={{width: 40}}>
                                                                        {formatQty(d.TotalCount || 0)}
                                                                    </StyledTableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            )
                                        }
                                        {
                                            productRowLoading && (
                                                Array.from(Array(5), (_, i) => (
                                                    <Grid  key={i} item xs={12} lg={12}>
                                                        <Skeleton width={'92.2vw'} height={50} />
                                                    </Grid>))
                                            )
                                        }
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );

};