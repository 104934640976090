import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Divider, Table, TableBody, TableRow, ListItemText, useTheme } from "@material-ui/core";
import { ReportService } from "../../Services/ReportService";
import {red, blue} from '@material-ui/core/colors';
import { ProductService } from "../../Services/ProductService";
import moment from "moment";
import ItemSelection from '../../components/ItemSelection';
import EnhancedTableHead from "../../components/EnhancedTableHead";
import EnhancedTableToolbar from "../../components/EnhancedTableToolbar";
import { Line } from "react-chartjs-2";
import { toInt, toPercentage, formatNumber, format_display_fancy_notime, format_without_time, formatQty, StyledTableCell } from "../../Utils";
import queryString from 'query-string';
import XLSX from 'xlsx';
import DateRangePickerCustom from "../../components/DateRangePickerCustom";
import { AppContext } from "../../AppContextProvider";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export default function TopProductsGraphical(props) {

    const {setTitle, setLinearLoading, closeProgressDialog, showProgressDialog} = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();

    const [asin, setASIN] = React.useState(null);
    const [asinList, setASINList] = React.useState([]);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').add(-30, 'd'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [regionData, setRegionData] = React.useState({});
    const [data, setData] = React.useState({});
    const order = 'desc';
    const orderBy = 'Title';
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [totalCount, setTotalCount] = React.useState(0);
    const [totalAmount, setTotalAmount] = React.useState(0);
    const [totalUnits, setTotalUnits] = React.useState(0);

    const columns = [
        // { id: 'index', align: 'left', numeric: false, disablePadding: true, label: 'Sl. No', sortable: false },
        { id: 'orderdate', align: 'center', numeric: false, disablePadding: false, label: 'Date', sortable: false },
        { id: 'OrderCount', align: 'center', numeric: false, disablePadding: true, label: 'Total Orders', sortable: false },
        { id: 'TotalUnits', align: 'center', numeric: false, disablePadding: true, label: 'Total Units', sortable: false },
        { id: 'Percentage', align: 'center', numeric: false, disablePadding: true, label: 'Percentage', sortable: false },
        { id: 'TotalAmount', align: 'center', numeric: false, disablePadding: true, label: 'Total Amount', sortable: false  }
    ];

    const lineOptions = {
        responsive : true, 
        maintainAspectRatio: false,
        layout: {
            padding: 32
        },
        elements: {
            arc: {
                borderWidth: 5,
                borderColor: theme.palette.white,
                backgroundColor: theme.palette.white
            },
        },
        tooltips: {
            position: 'custom',
            // Disable the on-canvas tooltip
            enabled: false,
            intersect: false,
            caretSize: 10,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.black,
            titleFontColor: theme.palette.white,
            bodyFontColor: theme.palette.white,
            footerFontColor: theme.palette.white,
            custom: function(tooltipModel, data) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<thead>';

                    titleLines.forEach(function(title) {
                        innerHtml += '<tr><th>' + moment(title, format_without_time).format(format_display_fancy_notime) + '</th></tr>';
                    });
                    innerHtml += '</thead><tbody>';

                    let dataIndex = tooltipModel.dataPoints[0].datasetIndex;
                    let index = tooltipModel.dataPoints[0].index;
                    let otherData = regionData.datasets[dataIndex].otherData[index];


                    bodyLines.forEach(function(body, i) {
                        var colors = tooltipModel.labelColors[i];
                        var style = 'background:' + colors.backgroundColor;
                        style += '; border-color:' + colors.borderColor;
                        style += '; border-width: 2px';
                        var span = '<span style="' + style + '"></span>';
                        innerHtml += '<tr><td colspan="2">' + span + '<b>' + body + '</b></td></tr>';
                        innerHtml += '<tr><td colspan="2">' + span + '<hr style="margin-top: 0px;margin-bottom: 0px;border: 1px solid black;"/></td></tr>';
                        // Other Data
                        innerHtml += '<tr><td>' + span + '<b>Order Count</b></td><td align="right">' + span + otherData.OrderCount + '</td></tr>';
                        innerHtml += '<tr><td>' + span + '<b>Total Amount</b></td><td align="right">' + span + formatNumber(otherData.TotalAmount) + '</td></tr>';
                    });
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.backgroundColor = theme.palette.black;
                tooltipEl.style.color = theme.palette.white;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            week : "ll",
                            month: "MMM YYYY"
                        },
                    }
                }
            ],
            yAxes: [{
                ticks: {
                    beginAtZero:true,
                    stepSize: 10
                }
                //ticks: {
                //    callback: function(label, index, labels) {
                //        return label
                //    }
                //},
            }]
        }
    };

    React.useEffect(() => {
        setTitle("Top Performing Products (Graphical)");
        let asin = null;
        if(props.location.search){
            let qString = queryString.parse(props.location.search);
            if(qString.asin){
                asin = qString.asin;
                if(qString.startdate)
                    setStartDate(moment(qString.startdate));
                if(qString.enddate)
                    setEndDate(moment(qString.enddate));
                setLinearLoading(true);    
                setGenerate(true);
            }
            // setASIN(props.location.query.asin);
        }

        ProductService.listProductNames()
        .then(data => {
            let list = data.map(d => ({...d, label : `[${d.asin}][${d.seller_sku}] ${d.name.substring(0, 120)}${d.name.length > 120 ? '...' : ''}`}));
            setASINList(list);
            if(asin){
                let a = list.filter(l => l.asin === asin);
                setASIN(a.length > 0 ? a[0] : null);
                // if(a.length > 0) {
                //     generateReport();
                // }
            }
        })
        .catch(error => console.log(error));

    }, [true]);

    React.useEffect(() => {
        if(generate && asin && asin.asin){
            generateReport();
            setGenerate(false);
        }
    }, [generate, asin]);

    const generateReport = () => {
        setRegionData({});
        setCurrentRows([]);
        setLinearLoading(true);
        showProgressDialog();
        ReportService.fetchASINDailySales(asin.asin, startdate, enddate)
        .then(data => {

            let labels = [], count = [];
            let rows = [], otherData = [];
            let accumulator = 0, totalAmount = 0, totalUnits = 0;
            let total = data.reduce(function(accumulator, currentValue) {
                return accumulator + parseFloat(currentValue.OrderCount || 0);
            }, accumulator);
            setTotalCount(total);
            totalAmount = data.reduce(function(accumulator, currentValue) {
                return accumulator + parseFloat(currentValue.TotalAmount || 0);
            }, totalAmount);
            setTotalAmount(totalAmount);
            totalUnits = data.reduce(function(accumulator, currentValue) {
                return accumulator + parseFloat(currentValue.TotalUnits || 0);
            }, totalUnits);
            setTotalUnits(totalUnits);
            setData(data);
            data.forEach((element, index) => {
                labels.push(element.orderdate);
                count.push(toInt(element.TotalUnits));
                otherData.push(element);
                rows.push(
                    <TableRow key={index} hover>
                        {/* <TableCell scope="row" align="center" padding='none' style={{width: 50}}>
                            <Typography variant="subtitle1">{index + 1}</Typography>
                        </TableCell> */}
                        <StyledTableCell scope="row" align="right" padding='default' style={{paddingRight: 48}}>
                            {moment(element.orderdate, format_without_time).format(format_display_fancy_notime)}
                        </StyledTableCell>
                        <StyledTableCell scope="row" align="center" padding='default'>
                            {element.OrderCount}
                        </StyledTableCell>
                        <StyledTableCell scope="row" align="center" padding='default'>
                            {element.TotalUnits}
                        </StyledTableCell>
                        <StyledTableCell align="center" padding='default'>
                                {toPercentage(element.TotalUnits, total)}
                        </StyledTableCell>
                        <StyledTableCell align="right" padding='default' style={{paddingRight: 48}}>
                                {formatNumber(element.TotalAmount)}
                        </StyledTableCell>
                    </TableRow>)
                });
            let rd = {
                labels: labels,
                datasets: [{
                    label: "Daily Sales",
                    data: count,
                    otherData: otherData,
                    backgroundColor: '#FFF',
                    borderColor: '#F85F73',
                    borderWidth: 3,
                    fill: false,
                    pointRadius: 5,
                }]
            };
            setRegionData(rd);
            setCurrentRows(rows);
            setLinearLoading(false);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false); 
            closeProgressDialog();
        });
        // fetchCityWiseData(null);
    };

    const exportReport = e => {
        setLinearLoading(true);
        let row = [];
        let filename = asin.asin;
        row.push(columns.map(c => c.label));
        data.forEach((d, index) => {
            row.push([index+1, moment(d.orderdate, format_without_time).format(format_display_fancy_notime), toInt(d.OrderCount), toInt(d.TotalUnits), toPercentage(d.TotalUnits, totalCount), parseFloat(d.TotalAmount)]);
        });
        filename += ' - Daily Sales Report';
        const wb = XLSX.utils.book_new();
        const wsAll = XLSX.utils.aoa_to_sheet(row);
        XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
        XLSX.writeFile(wb, filename + ".xlsx");
        setLinearLoading(false);
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" justify="center" spacing={2}>
                    <Grid item xl={9} lg={11} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item xs={12} lg={4}>
                                    <ItemSelection clearable={true} required label='Select ASIN' value={asin} optionLabel='label' options={asinList} selected={setASIN}/>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    {/* <Timeline startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/> */}
                                    <DateRangePickerCustom startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Button variant="contained" disabled={!asin} className={classes.button} color="primary" onClick={generateReport}>
                                        Generate Report
                                    </Button>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 10, marginTop: 10}}/>
                                </Grid>
                                { data.length === 0 &&
                                    <Grid item xs={12} lg={12}>
                                        <Typography align="center" variant="subtitle2" gutterBottom>No Data Available</Typography>
                                    </Grid>
                                }
                                <Grid container alignItems={'flex-start'} item xs={12} lg={12}>
                                    { data.length > 0 &&
                                        (<Grid item xs={12} xl={12} lg={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} lg={12}>
                                                    <Typography align="left" variant="subtitle2" className={classes.title} gutterBottom>Sales Per Day</Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={12}>
                                                    <div style={{height: 380}}>
                                                        <Line 
                                                            options={lineOptions} 
                                                            data={regionData}/>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    { currentRows.length > 0 && 
                                        (<Grid item xs={12} xl={12} lg={12}>
                                            <EnhancedTableToolbar 
                                                title={
                                                    <ListItemText 
                                                        primaryTypographyProps={{align: 'left', variant: 'subtitle2'}} 
                                                        secondaryTypographyProps={{align: 'left'}} 
                                                        primary={`Total Orders: ${formatQty(totalCount)} Total Units: ${formatQty(totalUnits)}`} 
                                                        secondary={`Total Amount: ${formatNumber(totalAmount)}`}/>
                                                } 
                                                exportReport={exportReport}/>
                                            <div style={{height: 500, overflowY: 'auto', display: 'block'}}>
                                                <Table className={classes.table} stickyHeader>
                                                    <EnhancedTableHead
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={() => {}}
                                                        rows ={columns} />
                                                    <TableBody>
                                                        {currentRows}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
 