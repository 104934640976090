import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Divider, Table, TableBody, TableRow, ListItemText, TablePagination, Tooltip, IconButton, Icon, CircularProgress, useTheme } from "@material-ui/core";
import {red, blue} from '@material-ui/core/colors';
import { ProductService } from "../../Services/ProductService";
import { FCService } from "../../Services/FCService";
import { DispositionService } from "../../Services/DispositionService";
import moment from "moment";
import ItemSelection from '../../components/ItemSelection';
import SingleDate from "../../components/SingleDate";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import { toInt, getTimeInIST, format_display_fancy_notime, formatQty, StyledTableCell, colorGenerator} from "../../Utils";
import XLSX from 'xlsx';
import { RowCountOptions, TABLE_COUNTS } from "../../Constants";
import { InventoryService } from "../../Services/InventoryService";
import { Bar } from "react-chartjs-2";
import MyDialog from "../../components/MyDialog";
import { AppContext } from "../../AppContextProvider";
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },

    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
        // height: theme.spacing(7),
        //float: 'right',
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        //marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function InventoryTabular(props){

    const {setTitle: setTitles, setTourSteps, setLinearLoading, showSnackBar} = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();

    const [asin, setASIN] = React.useState(null);
    const [selectedFC, setSelectedFC] = React.useState(null);
    const [selectedDisposition, setSelectedDisposition] = React.useState(null);
    const [dispositions, setDispositions] = React.useState(null);
    const [fcs, setFCs] = React.useState(null);
    const [asinList, setASINList] = React.useState([]);
    const [startdate, setStartDate] = React.useState(moment().startOf('day'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('snapshot_date');
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(false);
    const [generateGraph, setGenerateGraph] = React.useState(false);
    const [showFC, setShowFC] = React.useState(true);
    const [sortingAction, setSortingAction] = React.useState(false);
    const [showGraph, setShowGraph] = React.useState(true);
    const [graphData, setGraphData] = React.useState({});
    const [splitScreen, setSplitScreen] = React.useState(false);

    const columns = [
        // { id: 'snapshot_date', align: 'left', numeric: false, disablePadding: true, label: 'Date', sortable: true },
        { id: 'asin', align: 'left', numeric: false, disablePadding: true, label: 'ASIN', sortable: true },
        { id: 'fnsku', align: 'left', numeric: false, disablePadding: true, label: 'FNSKU', sortable: true },
        { id: 'sku', align: 'left', numeric: false, disablePadding: true, label: 'SKU', sortable: true },
        { id: 'fulfillment_center_id', align: 'left', numeric: false, disablePadding: false, label: 'FC', sortable: true },
        { id: 'quantity', align: 'left', numeric: true, disablePadding: true, label: 'Quantity', sortable: true },
        // { id: 'detailed_disposition', numeric: false, disablePadding: true, label: 'Disposition', sortable: false  }
    ];

    const [finalColumns, setFinalColumns] = React.useState([]);
    const [title, setTitle] = React.useState('');
    const [finalASIN, setFinalASIN] = React.useState(null);

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.inv_tab_count_key) ? toInt(localStorage.getItem(TABLE_COUNTS.inv_tab_count_key)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [subtitle, setSubtitle] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);
    const [openUI, setOpenUI] = React.useState(false);
    const [tooltipUI, setToolTipUI] = React.useState(undefined);
    const [dialogTitle, setDialogTitle] = React.useState(undefined);
    const [dialogSubtitle, setDialogSubtitle] = React.useState(undefined);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        cornerRadius: 20,
        legend: {
          display: false
        },
        layout: {
          padding: 32
        },
        scales: {
          xAxes: [
            {
                stacked: true,
                gridLines: {
                    display: false,
                    drawBorder: false
                },
                ticks: {
                    padding: 20,
                    fontColor: theme.palette.text.secondary
                }
            }
          ],
          yAxes: [
                {
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider
                    },
                    ticks: {
                        padding: 20,
                        fontColor: theme.palette.text.secondary,
                        beginAtZero: true,
                        min: 0,
                        maxTicksLimit: 5,
                        callback: value => {
                            return value;
                        }
                    }
                }
            ]
        },
        tooltips: {
            displayColors: false,
            enabled: true,
            mode: 'index',
            intersect: false,
            caretSize: 10,
            yPadding: 20,
            xPadding: 20,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.black,
            titleFontColor: theme.palette.white,
            bodyFontColor: theme.palette.white,
            footerFontColor: theme.palette.white,
            callbacks: {
                title: () => {},
                label: function(tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    let od = dataset.otherData[tooltipItem.index];
                    // let fcs = Object.keys(od).map(f => `${f}: ${od[f]}`);
                    // return " Count: " + currentValue + ' (' + percentage + '%)';
                    return [ od.seller_sku, ' ', `Available Units: ${tooltipItem.yLabel}`];
                }
            }
        }
    };

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.inv_tab_count_key, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="selectasin"]',
                content: `Select ASIN to view Competitor Stats`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selectfc]',
                content: `Filter by FC`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selectdeposition]',
                content: `Filter by Diposition`,
                style: {
                    backgroundColor: '#fff',
                },
            }
        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    React.useEffect(() => {
        if(props.location.search){
            let qString = queryString.parse(props.location.search);
            if(asinList && qString.asin){
                if(asinList.length > 0){
                    setASIN(asinList.find(l => l.asin === qString.asin));
                }
                
            }
            if(fcs && qString.fc){
                if(fcs.length > 0){
                    setSelectedFC(fcs.find(f => f.value === qString.fc));
                }
            }
            if(dispositions && qString.disposition){
                if(dispositions.length > 0){
                    setSelectedDisposition(dispositions.find(f => f.value === qString.disposition));
                }
            }
            if(asinList && fcs && dispositions){
                setGenerate(true);
            }
        }
    }, [props.location.search]);

    React.useEffect(() => {
        setTitles("Inventory Report");
        let asin = null;
        let fc = null;
        let disposition = 'SELLABLE';
        if(props.location.search){
            let qString = queryString.parse(props.location.search);
            if(qString.asin){
                asin = qString.asin;
                if(qString.startdate)
                    setStartDate(moment(qString.startdate));
                if(qString.enddate)
                    setEndDate(moment(qString.enddate));
            }
            if(qString.fc){
                fc = qString.fc;
            }
            if(qString.disposition){
                disposition = qString.disposition;
            }
        }

        setFinalColumns(columns); 

        InventoryService.fetchInventoryMaxDate()
        .then(data => {
            if(data.snapshot_date){
                setStartDate(getTimeInIST(data.snapshot_date).startOf('day'));
                setEndDate(getTimeInIST(data.snapshot_date).endOf('day'));
            }
        })
        .catch(error => console.log(error));

        ProductService.listProductNames()
        .then(data => {
            let list = data.map(d => ({...d, label : `[${d.asin}][${d.seller_sku}] ${d.name.substring(0, 120)}${d.name.length > 120 ? '...' : ''}`}));
            let allASIN = {asin: '-1', label: 'All ASIN'};
            list.splice(0, 0, allASIN)
            setASINList(list);
            setASIN(allASIN);
            if(asin){
                let a = list.find(l => l.asin === asin);
                setASIN(a ? a : null);
            }
            setGenerate(true);
            setGenerateGraph(true);
        })
        .catch(error => console.log(error));

        FCService.getFCs()
        .then(data => {
            let allFC = {name: 'All FC', value: '-1'};
            data.splice(0, 0, allFC);
            setFCs(data);
            if(fc){
                setSelectedFC(data.find(f => f.value === fc));
                setShowFC(true);
            } else {
                setSelectedFC(allFC);
            }
        })
        .catch(error => console.log(error));

        DispositionService.getDispositions()
        .then(data => {
            setDispositions(data);
            if(data.length > 0){
                if(disposition){
                    setSelectedDisposition(data.find(f => f.value === disposition));
                }
            }
        })
        .catch(error => console.log(error));

    }, [true]);

    React.useEffect(() => {
        if(generate && asin && selectedDisposition && selectedFC){
            if(!sortingAction){
                setCurrentRows([]);
                setGraphData({});
            }
            setFinalASIN(null);
            setSortingAction(false);
            setLinearLoading(true);
            let fc = selectedFC.value !== '-1' ? selectedFC.value : null;
            let selASIN = asin.asin !== '-1' ? asin.asin : null;
            setShowFC(fc === null);
            setTotalItems(0);
            setCurrentPageNumber(0);
            setSplitScreen(fc === null && selASIN);
            InventoryService.fetchInventoryCount(selASIN, startdate, enddate, fc, selectedDisposition.value)
            .then(data => {
                setFinalASIN(selASIN ? asin : null);
                setFinalColumns(selASIN !== null
                    ? 
                        (fc === null ? columns : columns.filter(c => c.id !== 'fulfillment_center_id'))
                            .filter(c => !['asin', 'fnsku', 'sku'].includes(c.id))
                    :   
                        (fc === null ? columns : columns.filter(c => c.id !== 'fulfillment_center_id')));
                setTitle(asin.name);
                setTotalItems(toInt(data.count));
                setSubtitle(toInt(data.total_qty || 0));
                // setTotalPages(Math.ceil(data.count / numItemsPerPage) - 1);
                setFetchPage(true);
                if(toInt(data.count) === 0){
                    setCurrentRows([]);
                }
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
            });
            setGenerate(false);
        }
    }, [generate, asin, selectedDisposition, selectedFC, order, orderBy]);

    React.useEffect(() => {
        if(generateGraph && selectedFC && asin && selectedDisposition){
            let fc = selectedFC.value !== '-1' ? selectedFC.value : null;
            let selASIN = asin.asin !== '-1' ? asin.asin : null;
            if(!(fc && selASIN)){
                setShowGraph(true);
                InventoryService.fetchInventoryGraphicalData(selASIN, startdate, enddate, fc, selectedDisposition.value, orderBy, order, 'v2')
                .then(d => {
                    let datasets = [], labels = [], data = [], otherData = [], label = 'All ASIN';
                    if(selASIN === null){
                        Object.keys(d[0]).forEach((key) => {
                            let dateObj = d[0][key];
                            Object.keys(dateObj).forEach((asin) => {
                                labels.push(asin);
                                data.push(dateObj[asin]['quantity']);
                                otherData.push({ fc_list: dateObj[asin]['fc_list'], seller_sku: dateObj[asin]['seller_sku']});
                            });
                        });
                    } else if(selASIN && fc === null && d.length > 0){
                        Object.keys(d[0]).forEach((key) => {
                            let dateObj = d[0][key];
                            Object.keys(dateObj).forEach((asin) => {
                                let fcs = dateObj[asin]['fc_list'];
                                Object.keys(fcs).forEach(fc => {
                                    labels.push(fc);
                                    data.push(fcs[fc]);
                                    otherData.push({seller_sku: dateObj[asin]['seller_sku']});
                                });
                            });
                        });
                    }
                    
                    datasets.push({
                        label: label,
                        backgroundColor: colorGenerator(data.length),
                        data,
                        otherData,
                        // barThickness: 15,
                        // maxBarThickness: 15,
                        // barPercentage: 0.9,
                        // categoryPercentage: 1,
                    })
                    
                    // console.log({datasets, labels})
                    setGraphData({datasets, labels});
                    setGenerateGraph(false);
                })
                .catch(error => {
                    console.log(error);
                    setGenerateGraph(false);
                });
            } else {
                setShowGraph(false);
                setGenerateGraph(false);
            }
        }

    }, [generateGraph, selectedFC, asin, selectedDisposition]);

    React.useEffect(() => {
        let rows = [];
        if(fetchPage && asin && selectedFC && selectedDisposition && totalItems > 0){
            setLinearLoading(true);
            let fc = selectedFC.value !== '-1' ? selectedFC.value : null;
            let selASIN = asin.asin !== '-1' ? asin.asin : null;
            InventoryService.fetchInventoryTxns(selASIN, startdate, enddate, fc, selectedDisposition.value, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                setFinalASIN(selASIN ? {...asin, fnsku: data.length > 0 ? data[0].fnsku: undefined} : null);

                data.forEach( (element, index) => {
                    rows.push(
                        <TableRow key={index} hover>
                            {/* <TableCell scope="row" align="left" padding='default'  style={{paddingRight: 16}}>
                                <Typography variant="subtitle1">{getTimeInIST(element.snapshot_date).format(format_display_fancy_notime)}</Typography>
                            </TableCell> */}
                            {
                                !selASIN && (
                                <StyledTableCell scope="row" align="justify" padding='default' style={{paddingLeft: 32}}>
                                    {element.asin}
                                </StyledTableCell>)
                            }
                            {
                                !selASIN && (
                                <StyledTableCell scope="row" align="justify" padding='default' >
                                    {element.fnsku || '-'}
                                </StyledTableCell>)
                            }
                            {
                                !selASIN && (
                                <StyledTableCell scope="row" align="justify" padding='default'>
                                    {element.sku}
                                </StyledTableCell>)
                            }
                            { showFC && (
                                <StyledTableCell scope="row" align="left" padding='default' style={{width: '8vw'}}>
                                    {element.fulfillment_center_id}
                                </StyledTableCell>
                            )}
                            <StyledTableCell align="right" padding='default' style={{paddingRight: 32, width: '9vw'}}>
                                {element.quantity}
                            </StyledTableCell>
                        </TableRow>);
                });
                setCurrentRows(rows);
                setLinearLoading(false);
            }).catch((error) => {
                console.log(error);
                setLinearLoading(false);
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setSortingAction(true);
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const barClick = (elems) => {
        // console.log(selectedFC)
        if((selectedFC.value === '-1' && asin.asin === '-1') && elems.length > 0){
            // console.log(elems)
            // let x = elems[0]._datasetIndex;
            let dataIndex = elems[0]._datasetIndex;
            let otherData = graphData.datasets[dataIndex].otherData[elems[0]._index];
            let fcs = otherData.fc_list;

            let fcUI = Object.keys(fcs).map(k => <Grid key={k} item container justify="space-between" alignItems="center" spacing={0}>
                                                    <Grid item xs={6} lg={6}>
                                                        <Typography variant="subtitle2">{k}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} lg={6}>
                                                        <Typography variant="subtitle2" align="right">{fcs[k]}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} lg={12}>
                                                        <Divider/>
                                                    </Grid>
                                                </Grid>);
            setDialogTitle(graphData.labels[elems[0]._index]);
            setDialogSubtitle(otherData.seller_sku);
            setToolTipUI(
                <Grid container style={{width: 280}}  alignItems="center" spacing={0}>
                    <Grid item xs={6} lg={6}>
                        <Typography variant="h5" style={{paddingTop: 5}}>FC</Typography>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <Typography variant="h5" style={{paddingTop: 5}} align="right">Total Units</Typography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Divider/>
                    </Grid>
                    {fcUI}
                </Grid>
            )
            setOpenUI(true);
        }
    }

    const handleClose = () => {
        setOpenUI(false);
        setToolTipUI(undefined);
        setDialogTitle(null);
        setDialogSubtitle(null);
    }

    const exportReport = e => {
        setLinearLoading(true);
        let row = [];
        let filename = `Inventory Report ${asin.asin}`;
        let fc = selectedFC.value !== '-1' ? selectedFC.value : null;
        let selASIN = asin.asin !== '-1' ? asin.asin : null;
        InventoryService.fetchInventoryTxns(selASIN, startdate, enddate, fc, selectedDisposition.value, orderBy, order, 0, totalItems)
        .then((data) => {
            if(data.length > 0){
                let header = ['Date', ...columns.map(c => c.label), 'Disposition'];
                row.push(header);
                data.forEach((d, index) => {
                    row.push([getTimeInIST(d.snapshot_date).format(format_display_fancy_notime), d.asin, d.fnsku, d.sku, d.fulfillment_center_id, toInt(d.quantity), d.detailed_disposition]);
                });
                const wb = XLSX.utils.book_new();
                const wsAll = XLSX.utils.aoa_to_sheet(row);
                XLSX.utils.book_append_sheet(wb, wsAll, 'Report');
                XLSX.writeFile(wb, filename + ".xlsx");
            } else {
                showSnackBar("No data to export" , 'warning');
            }
            setLinearLoading(false);
        })
        .catch(error => {
            console.log(error);
            showSnackBar("Failed to export. Please try again." , 'error');
            setLinearLoading(false);
        });
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={11} lg={11} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center" justify="center">
                                <Grid container item style={{marginLeft: 24, marginRight: 24}} spacing={2}>
                                    <Grid item xs={12} lg={4} data-tour="selectasin">
                                        <ItemSelection 
                                            clearable={true} 
                                            required 
                                            label='Select ASIN' 
                                            value={asin} 
                                            optionLabel='label' 
                                            options={asinList} 
                                            selected={setASIN}/>
                                    </Grid>
                                    <Grid item xs={6} lg={2} data-tour="selectfc">
                                        <ItemSelection 
                                            required 
                                            label='Select FC' 
                                            value={selectedFC} 
                                            optionLabel='name' 
                                            options={fcs} 
                                            selected={setSelectedFC}/>
                                    </Grid>
                                    <Grid item xs={6} lg={2} data-tour="selectdeposition">
                                        <ItemSelection required label='Select Disposition' value={selectedDisposition} optionLabel='name' options={dispositions} selected={setSelectedDisposition}/>
                                    </Grid>
                                    <Grid item xs={6} lg={2}>
                                        <SingleDate 
                                            date={startdate} 
                                            maxDate={startdate}
                                            setDate={(date) => {
                                                setStartDate(date);
                                                setEndDate(moment(date).endOf('day'));
                                            }}/>
                                    </Grid>
                                    <Grid item xs={6} lg={2}>
                                        <Button 
                                            variant="contained" 
                                            className={classes.button} 
                                            color="primary" 
                                            onClick={() => {
                                                if(!generate) {
                                                    setGenerate(true);
                                                    setGenerateGraph(true);
                                                }}}>
                                            Generate Report
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 10, marginTop: 10}}/>
                                </Grid>
                                <Grid container item xs={12} lg={12} xl={12} alignContent="center" justify="center">
                                    <Grid item xs={12} lg={11}>
                                        <ListItemText
                                            style={{marginLeft: 8}} 
                                            primaryTypographyProps={{align: 'left'}} 
                                            secondaryTypographyProps={{align: 'left', variant: 'subtitle1', style: {fontWeight: 'bold'}}}  
                                            primary={finalASIN && 
                                                <Grid item container justify="flex-start">
                                                    <Grid item xl={1} md={1} xs={3}>
                                                        <Typography align="left" variant="subtitle2">
                                                            <b>Title:</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xl={11} md={11} xs={9}>
                                                        <Typography align="left" variant="subtitle2">
                                                            {title}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xl={1} md={1} xs={3}>
                                                        <Typography align="left" variant="subtitle2">
                                                            <b>ASIN:</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xl={11} md={11} xs={9}>
                                                        <Typography align="left" variant="subtitle2">
                                                            {finalASIN.asin}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xl={1} md={1} xs={3}>
                                                        <Typography align="left" variant="subtitle2">
                                                            <b>SKU:</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xl={11} md={11} xs={9}>
                                                        <Typography align="left" variant="subtitle2">
                                                            {finalASIN.seller_sku}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xl={1} md={1} xs={3}>
                                                        <Typography align="left" variant="subtitle2">
                                                            <b>FNSKU:</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xl={11} md={11} xs={9}>
                                                        <Typography align="left" variant="subtitle2">
                                                            {finalASIN.fnsku || '-'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>} 
                                            secondary={
                                                <Grid item container justify="flex-start">
                                                    <Grid item xl={1} md={1} xs={3}>
                                                        <Typography align="left" variant="subtitle2">
                                                            <b>Total Qty:</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xl={11} md={11} xs={9}>
                                                        <Typography align="left" variant="subtitle2">
                                                            {`${formatQty(subtitle)} Units`}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }/>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Export">
                                            <IconButton aria-label="Export" onClick={(e) => exportReport()}>
                                                <Icon>save_alt</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                {
                                    showGraph && (
                                        <Grid item xs={12} lg={splitScreen ? 6 : 12} xl={splitScreen ? 6 : 12} style={{marginTop: 20, marginBottom: 20}}>
                                            {
                                                generateGraph && <CircularProgress />
                                            }
                                            {
                                                !generateGraph && 
                                                    ( splitScreen 
                                                        ? 
                                                        <Bar 
                                                            height={300} 
                                                            onElementsClick={elems => barClick(elems)}  
                                                            data={graphData} 
                                                            options={options}/>
                                                        :
                                                        <Bar 
                                                            height={300} 
                                                            onElementsClick={elems => barClick(elems)}  
                                                            data={graphData} 
                                                            options={options}/>
                                                        
                                                    )
                                            }
                                        </Grid>
                                    )   
                                }
                                {
                                    (showFC || (asin && asin.asin === '-1')) && ( 
                                    <Grid item xs={12} lg={splitScreen ? 6 : 12} xl={splitScreen ? 6 : 12}>
                                        <div style={{minHeight: 100, maxHeight: '55vh', overflowY: 'auto', display: 'block'}}>
                                            <Table className={classes.table} stickyHeader>
                                                <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={sortBy}
                                                    rows={finalColumns} />
                                                <TableBody>
                                                    {currentRows}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        {
                                            totalItems === 0 && 
                                                <Typography color="error" variant="subtitle2">No Data</Typography>
                                        }
                                        <TablePagination
                                            rowsPerPageOptions={RowCountOptions}
                                            component="div"
                                            count={totalItems}
                                            rowsPerPage={numItemsPerPage}
                                            page={currentPageNumber}
                                            backIconButtonProps={{
                                                'aria-label': 'Previous Page',
                                            }}
                                            nextIconButtonProps={{
                                                'aria-label': 'Next Page',
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            classes={{ selectRoot : classes.selectRoot}}/>
                                    </Grid>
                                    )
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <MyDialog
                    maxWidth={false}
                    fullWidth={false}
                    content={tooltipUI}
                    title={dialogTitle}
                    subtitle={dialogSubtitle}
                    open={openUI}
                    close={() => {
                        handleClose();
                    }}/>
            </div>
        </div>
    );

};