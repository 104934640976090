import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import { colorGenerator, formatNumber, formatQty } from '../../Utils';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: 200
  }
}));

const BarChart = props => {
  const { data: dataProp, labels, type, onClick, className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        label: '',
        backgroundColor: colorGenerator(5),
        data: dataProp,
        barThickness: 22,
        maxBarThickness: 25,
      },
    ],
    labels
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cornerRadius: 8,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          stacked: false,
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            beginAtZero: true,
            display: false
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.black,
      titleFontColor: theme.palette.white,
      bodyFontColor: theme.palette.white,
      footerFontColor: theme.palette.white,
      custom: function(tooltip) {
        if (!tooltip) return;
        // disable displaying the color box;
        tooltip.displayColors = false;
      },
      callbacks: {
        legend: () => {},
        title: () => {},
        label: tooltipItem => {
          let label = `${tooltipItem.xLabel}: ${tooltipItem.yLabel}`;
          if(type === 'units'){
            label = [`${tooltipItem.xLabel}`, `${formatQty(tooltipItem.yLabel)} Units`];
          } else if(type === 'sales'){
            label = [`${tooltipItem.xLabel}`, `${formatNumber(tooltipItem.yLabel)}`];
          }

          return label;
        }
      }
    }
  };

  const onChartClick = (elems) => {
    if(elems.length > 0){
      if(onClick){
        onClick(elems[0]._index);
      }
    }
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Bar
        data={data}
        options={options}
        onElementsClick={onChartClick}
      />
    </div>
  );
};

BarChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired
};

export default BarChart;
