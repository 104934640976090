import Config from '../Config';
import {RestService} from './RestService';

export const NotificationService = {
    getNotificationCategories: async function(headers = {}){
        return RestService.get(Config.notification_category_list_url, headers)
                .then(response => response.json());
    },
    getUnreadCount: async function(headers = {}){
        return RestService.get(Config.unread_notification_count_url, headers)
                .then(response => response.json());
    },
    getNotificationCount: async function(headers = {}){
        return RestService.get(Config.notification_count_url, headers)
                .then(response => response.json());
    },
    getNotifications: async function(type = null, unread = null, from = 0, offset = 10, headers = {}){
        return RestService.get(`${Config.get_notifications_url}${type ? `&type=${type}`: ''}${unread ? `&unread=${unread}`: ''}${from ? `&from=${from}`: ''}${offset ? `&offset=${offset}`: ''}`, headers)
                .then(response => response.json());
    },
    removeNotifications: async function(id = null, headers = {}){
        return RestService.delete(`${Config.remove_notifications_url}${id ? `&id=${id}`: ''}`, headers)
                .then(response => response.json());
    },
    removeAllNotifications: async function(headers = {}){
        return RestService.delete(`${Config.remove_all_notifications_url}`, headers)
                .then(response => response.json());
    },
    markAsReadNotifications: async function(ids = null, headers = {}){
        return RestService.post(`${Config.mark_read_notifications_url}${ids ? `&id=${ids}`: ''}`, headers)
                .then(response => response.json());
    },
    updatePreference: async function(category, mode, pref, headers = {}){
        return RestService.post(`${Config.update_notification_pref_url}${category ? `&category=${category}`: ''}${mode ? `&mode=${mode}`: ''}${pref ? `&preference=${pref}`: ''}`, headers)
                .then(response => response.json());
    },

};