import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Divider, TableRow, TextField, InputAdornment, IconButton, Icon, Table, TableBody, TablePagination } from "@material-ui/core";
import {red, blue} from '@material-ui/core/colors';
import { ProductService } from "../../Services/ProductService";
import moment from "moment";
import ItemSelection from '../../components/ItemSelection';
import { getTimeInIST, format_display_fancy_notime, toInt, StyledTableCell } from "../../Utils";
import SearchIcon from '@material-ui/icons/Search';
import DateRangePickerCustom from "../../components/DateRangePickerCustom";
import { useContext } from "react";
import { AppContext } from "../../AppContextProvider";
import { TABLE_COUNTS, RowCountOptions } from "../../Constants";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import { Rating } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    sectionGraph: {
        // width: '60vw',
        height: '60vh',
        marginTop: theme.spacing(3),
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    txn_details: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        float: "right",
        marginRight: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    formControlLeft: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    card: {
        maxWidth: 600,
        minWidth: 300,
        minHeight: 184,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    formControlAutoComplete: {
        zIndex: 2,
        margin: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function ProductReviews(props){
    const {setTitle, setTourSteps, showProgressDialog, closeProgressDialog, setLinearLoading} = useContext(AppContext);
    const classes = useStyles();
    const [searchFilter, setSearchFilter] = React.useState('');
    const [asin, setASIN] = React.useState(null);
    const [selectedRating, setSelectedRating] = React.useState(null);
    const [selectedReviewers, setSelectedReviewers] = React.useState(null);
    const [reviewers, setReviewers] = React.useState(null);
    const [ratings, setRatings] = React.useState(null);
    const [asinList, setASINList] = React.useState([]);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').add(-30, 'd'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('review_date');
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(true);
    const [sortingAction, setSortingAction] = React.useState(false);

    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: false, label: 'S.No.', sortable: false },
        { id: 'asin', align: 'left', numeric: false, disablePadding: true, label: 'ASIN', sortable: true },
        { id: 'rating', align: 'left', numeric: false, disablePadding: true, label: 'Rating', sortable: true },
        { id: 'name', align: 'left', numeric: false, disablePadding: true, label: 'Customer Name', sortable: true },
        { id: 'title', align: 'center', numeric: false, disablePadding: true, label: 'Title', sortable: false },
        { id: 'review', align: 'center', numeric: false, disablePadding: true, label: 'Review', sortable: false },
        { id: 'review_date', align: 'center', numeric: false, disablePadding: true, label: 'Review Date', sortable: true },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.product_review_tab_count_key) ? toInt(localStorage.getItem(TABLE_COUNTS.product_review_tab_count_key)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.product_review_tab_count_key, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTourSteps([
            {
                selector: '[data-tour="selectasin"]',
                content: `Select ASIN to view Competitor Stats`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selectRating]',
                content: `Filter by Rating`,
                style: {
                    backgroundColor: '#fff',
                },
            },
            {
                selector: '[data-tour^=selectReviewers]',
                content: `Filter by Reviewers`,
                style: {
                    backgroundColor: '#fff',
                },
            }
        ]);
        return () => {
            setTourSteps([]);
        }
    }, [true]);

    React.useEffect(() => {
        setTitle("Product Reviews");

        ProductService.listProductNames()
        .then(data => {
            let list = data.map(d => ({...d, label : `[${d.asin}][${d.seller_sku}] ${d.name.substring(0, 120)}${d.name.length > 120 ? '...' : ''}`}));
            setASINList(list);
            if(asin){
                let a = list.filter(l => l.asin === asin);
                setASIN(a.length > 0 ? a[0] : null);
            }
        })
        .catch(error => console.log(error));

        let ratings = [
            {name: 'All Stars', value: null},
            {name: '5 Star', value: 5},
            {name: '4 Star', value: 4},
            {name: '3 Star', value: 3},
            {name: '2 Star', value: 2},
            {name: '1 Star', value: 1},
        ]
        setRatings(ratings)
        setSelectedRating(ratings[0])

        let reviewers = [
            {name: 'All Reviewers', value: false},
            {name: 'Verified purchase only', value: true},
        ]
        setReviewers(reviewers)
        setSelectedReviewers(reviewers[0])

    }, [true]);

    React.useEffect(() => {
        if(!sortingAction){
            setCurrentRows([]);
        }
        
        if(generate){
            setSortingAction(false);
            let rating = selectedRating ? selectedRating.value : null;
            let verifiedPurchaseValue = selectedReviewers ? selectedReviewers.value : false;
            showProgressDialog();
            ProductService.getProductReviewsCount(asin && asin.asin, rating, verifiedPurchaseValue, searchFilter, startdate, enddate)
            .then(data => {
                setTotalItems(toInt(data.count));
                setFetchPage(true);
                if(toInt(data.count) === 0){
                    setCurrentRows([]);
                }
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                closeProgressDialog();
            });
            setGenerate(false);
        }
    }, [generate]);

    React.useEffect(() => {
        let rows = [];
        if(fetchPage && totalItems > 0){
            let rating = selectedRating ? selectedRating.value : null;
            let verifiedPurchaseValue = selectedReviewers ? selectedReviewers.value : false;
            
            setLinearLoading(true);
            ProductService.getProductReviews(asin && asin.asin, rating, verifiedPurchaseValue, searchFilter, startdate, enddate, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then(data => {
                data.forEach( (element, index) => {
                    rows.push(
                        <TableRow key={index} style={{paddingBottom: 10, paddingTop: 10}} hover>
                            <StyledTableCell scope="row" align="center" padding='none' style={{width: 30}}>
                                <Typography variant="subtitle2">{(currentPageNumber * numItemsPerPage) + index + 1}</Typography>
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="left" padding='none' style={{width: 110, padding: 12}}>
                                <Typography 
                                    variant="subtitle2" 
                                    color="textSecondary" 
                                    style={{cursor: 'pointer'}}>
                                    {element.asin}
                                </Typography>
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="center" padding='none' style={{paddingLeft: 8, paddingRight: 8}}>
                                <Rating name="size-small" style={{flex: 1}} value={element.rating} component="div" size="small" readOnly/>
                                {/* <Typography variant="subtitle2" color="textSecondary" style={{fontSize: 14}}>
                                    {element.rating}
                                </Typography> */}
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="left" padding='none' style={{paddingLeft: 8, paddingRight: 8}}>
                                <Typography variant="subtitle2" color="textSecondary" style={{fontSize: 14}}>
                                    {element.name}
                                </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left" padding='none' style={{width: 150, paddingLeft: 8, paddingRight: 8}}>
                                <Typography variant="subtitle2" color="textSecondary" style={{fontSize: 14}}>
                                    {element.title}
                                </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left" padding='none' style={{width: '40vw', paddingLeft: 8, paddingRight: 8}}>
                                <Typography variant="subtitle2" color="textSecondary" style={{fontSize: 14}}>
                                    {element.review}
                                </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="center" padding='none' style={{width: 150, paddingRight: 16}}>
                                {element.review_date ? getTimeInIST(element.review_date).format(format_display_fancy_notime): '-'}
                            </StyledTableCell>
                        </TableRow>);
                });
                setCurrentRows(rows);
                setLinearLoading(false);
            }).catch((error) => {
                console.log(error);
                setLinearLoading(false);
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setSortingAction(true);
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const exportReport = e => {
        showProgressDialog('Exporting...');
        let rating = selectedRating ? selectedRating.value : null;
        let verifiedPurchaseValue = selectedReviewers ? selectedReviewers.value : false;
        
        setLinearLoading(true);
        ProductService.getProductReviewsExport(asin && asin.asin, rating, verifiedPurchaseValue, searchFilter, startdate, enddate, orderBy, order, 0, totalItems)
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Reviews Report.xlsx');
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            closeProgressDialog();
        })
        .finally(() => setLinearLoading(false));
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={2} justify="center">
                    <Grid item xl={11} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        margin="dense"
                                        data-tour="searchbuyeremail"
                                        fullWidth
                                        style={{marginTop: 12, marginLeft: 10, marginRight: 20, paddingRight: 20}}
                                        id="outlined-required"
                                        label="Search By Name, Review"
                                        variant="outlined"
                                        value={searchFilter || ''}
                                        type="search"
                                        onChange={(e) => setSearchFilter(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon/>
                                                </InputAdornment>
                                                )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3} data-tour="selectasin">
                                    <ItemSelection clearable={true} required label='Select ASIN' value={asin} optionLabel='label' options={asinList} selected={setASIN}/>
                                </Grid>
                                <Grid item xs={6} lg={2} data-tour="selectRating">
                                    <ItemSelection required label='Star Rating' value={selectedRating} optionLabel='name' options={ratings} selected={setSelectedRating}/>
                                </Grid>
                                <Grid item xs={6} lg={2} data-tour="selectReviewers">
                                    <ItemSelection required label='Reviewers' value={selectedReviewers} optionLabel='name' options={reviewers} selected={setSelectedReviewers}/>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <DateRangePickerCustom startdate={startdate} setStartDate={setStartDate} enddate={enddate} setEndDate={setEndDate}/>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Button variant="contained"className={classes.button} color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                        Generate Report
                                    </Button>
                                    <IconButton disabled={totalItems === 0} style={{marginTop: 8}} onClick={exportReport}>
                                        <Icon>save_alt</Icon>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 5, marginTop: 5}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{overflow: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows ={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Data</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );

};