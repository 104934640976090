import React from "react";
import PropTypes from 'prop-types';
import { makeStyles} from "@material-ui/core/styles";
import { Grid, FormHelperText} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { format_display_fancy_notime } from "../Utils";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
        maxWidth: 140,
        marginLeft: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    formDropdown: {
        maxWidth: 200
    }
}));

export default function TimelineMonthYear(props){

    const [startdate, setStartDate] = React.useState(moment().startOf('month'));
    const [enddate, setEndDate] = React.useState(moment().endOf('month'));

    const classes = useStyles();
    const [openStart, setOpenStart] = React.useState(false);
    const [openEnd, setOpenEnd] = React.useState(false);

    React.useEffect(() => {
        if(props.startdate) setStartDate(props.startdate);
        if(props.enddate) setEndDate(props.enddate);
    }, [props.startdate, props.enddate]);

    return (
        <Grid container justify="center" alignContent="flex-start" spacing={0}>
            <Grid item xs={12} lg={12}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        variant="inline"
                        inputVariant="outlined"
                        margin="dense"
                        label="From"
                        open={openStart}
                        onOpen={() => setOpenStart(true)}
                        onClose={() => setOpenStart(false)}
                        value={startdate}
                        minDate={props.minDate || null}
                        onChange={(date) => {
                            setStartDate(date);
                            props.setStartDate(date);
                            //setOpenStart(false);
                        }}
                        format='MMM YYYY'
                        maxDate={enddate}
                        className={classes.formControl}
                        openTo="year"
                        views={["year", "month"]}
                        //helperText={props.minDate ? `Data available from ${moment(props.minDate).format(format_display_fancy_notime)}` : ''}
                    />
                    <DatePicker
                        margin="dense"
                        label="To"
                        open={openEnd}
                        onOpen={() => setOpenEnd(true)}
                        onClose={() => setOpenEnd(false)}
                        value={enddate}
                        onChange={(date) => {
                            setEndDate(date);
                            props.setEndDate(date);
                            //setOpenEnd(false);
                        }}
                        maxDate={moment().endOf('month')}
                        maxDateMessage={"Date should not be a future date"}
                        format='MMM YYYY'
                        className={classes.formControl}
                        variant="inline"
                        inputVariant="outlined"
                        openTo="year"
                        views={["year", "month"]}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            {
                props.minDate &&
                    <Grid item xs={9} lg={10}>
                        <FormHelperText>Data available from {moment(props.minDate).format(format_display_fancy_notime)}</FormHelperText>
                    </Grid>
            }
        </Grid>
    );
};

TimelineMonthYear.propTypes = {
    minDate: PropTypes.object,
    startdate: PropTypes.object.isRequired,
    enddate: PropTypes.object.isRequired,
    setStartDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired,
};
  